import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LuRadioTower } from 'react-icons/lu';
import { FaCommentSms } from 'react-icons/fa6';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { LiaBusinessTimeSolid } from 'react-icons/lia';
import { MdSendToMobile, MdOutlinePower } from 'react-icons/md';
import { GiBatteries } from 'react-icons/gi';

import { getPortableStatus } from '../../Store/Actions/messenger.actions';

export default function MessengerGestion() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Messagerie | Ma Maison Sereine';
    document.description = 'Messagerie  de Ma Maison Sereine';

    const numberSMSNotRead = useSelector(
        (store) => store.Messenger.numberSMSNotRead
    );
    const accessToken = useSelector((store) => store.User.accessToken);

    const pingPort = useSelector((store) => store.Messenger.pingPort);
    const statusPort = useSelector((store) => store.Messenger.portableStatus);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPortableStatus(accessToken));
    }, [accessToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            accessToken !== null && dispatch(getPortableStatus(accessToken));
        }, 120000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
    }, [accessToken]);

    return (
        <main className="d-flex flex-column mx-auto text-primary">
            <h1 className="text-center mt-3 mx-auto display-5 ">
                Messagerie
                <span className={'text-' + pingPort}>
                    <LuRadioTower />{' '}
                </span>
            </h1>
            <div className="mx-auto">
                {statusPort?.telephonies !== undefined && (
                    <>
                        {statusPort?.telephonies.map((item, index) => (
                            <span key={index}>
                                {item?.sim_slot === 0 && item?.display_name}
                            </span>
                        ))}
                    </>
                )}
                <span className="text-xl">
                    <GiBatteries />
                </span>{' '}
                {statusPort?.battery?.level ?? ''}% -{' '}
                {statusPort?.battery?.status ?? ''}{' '}
                {(statusPort?.battery?.status === 'full' ||
                    statusPort?.battery?.status === 'charging') && (
                    <MdOutlinePower />
                )}
            </div>

            <div className="messagerie_onglets flex-row flex-wrap ">
                <Link to="/gestion/messagerie/createmsg" className="mx-auto ">
                    <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                        <span className="me-2">
                            <FaCommentSms /> Créer SMS
                        </span>
                    </button>
                </Link>
                <Link to="/gestion/messagerie/smsrecus" className="mx-auto ">
                    <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                        <span className="me-2">
                            <LuRadioTower />{' '}
                        </span>
                        {numberSMSNotRead !== undefined &&
                            numberSMSNotRead !== 0 && (
                                <span className="pe-2 ">
                                    {numberSMSNotRead}
                                </span>
                            )}
                        SMS reçus
                    </button>
                </Link>
                <Link to="/gestion/messagerie/smsenvoyes" className="mx-auto ">
                    <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                        {' '}
                        SMS envoyés
                    </button>
                </Link>
                <Link to="/gestion/messagerie/portableSMS" className="mx-auto ">
                    <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center ">
                        Port. <MdSendToMobile />
                    </button>
                </Link>
                <Link
                    to="/gestion/messagerie/timelinesmsauto"
                    className="mx-auto "
                >
                    <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                        <span>
                            <LiaBusinessTimeSolid />
                        </span>
                    </button>
                </Link>
                <Link to="/gestion/messagerie/settings" className="mx-auto ">
                    <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                        <span>
                            {' '}
                            <HiOutlineWrenchScrewdriver />{' '}
                        </span>
                    </button>
                </Link>
            </div>
        </main>
    );
}
