import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function AccordeonConceptLocation() {
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className="col-12 col-lg-5 mx-auto">
            <h2 className="text-white my-5 fs-1">
                {translate === 'French' && (
                    <>Le Concept d'une location Airbnb</>
                )}
                {translate === 'English' && (
                    <>The Concept of an Airbnb Rental</>
                )}
            </h2>
            <Accordion open={open} toggle={toggle} className="mb-5">
                <AccordionItem>
                    <AccordionHeader targetId="21">
                        <h3 className="fs-2">
                            {translate === 'French' && (
                                <>Le Concept en détails</>
                            )}
                            {translate === 'English' && (
                                <>The Concept in detail</>
                            )}
                        </h3>
                    </AccordionHeader>
                    <AccordionBody accordionId="21" className="p-1">
                        {translate === 'French' && (
                            <>
                                <p>
                                    On ne présente plus Airbnb, le leader
                                    mondial de l’hébergement qui règne sur le
                                    secteur même s’il a des concurrents comme
                                    Abritel ou Booking. On trouve des logements
                                    Airbnb dans la majorité des pays du monde,
                                    en France et bien entendu à{' '}
                                    <strong>Pornic</strong> et dans le
                                    département de la Loire-Atlantique (44).
                                    Certains propriétaires ont compris l’intérêt
                                    et les avantages d’être dans ce réseau
                                    d’économie de partage.{' '}
                                </p>
                                <p>
                                    Pour tous, sachez que{' '}
                                    <strong className="font-title fs-2 text-primary">
                                        Ma Maison Sereine
                                    </strong>{' '}
                                    se charge, pour votre tranquillité, des
                                    <strong>
                                        {' '}
                                        services de conciergerie
                                    </strong>{' '}
                                    liés à ce type de <strong>location</strong>.
                                    Comme nous le verrons plus loin, vous serez
                                    ainsi mieux notés et appréciés sur la
                                    plateforme, ce qui ne pourra que vous amener
                                    de nouveaux touristes.
                                </p>
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                <p>
                                    We no longer present Airbnb, the leader
                                    world of accommodation that reigns over the
                                    sector even if it has competitors like
                                    Abritel or Booking. We find accommodation
                                    Airbnb in most countries around the world,
                                    in France and of course in{' '}
                                    <strong>Porn</strong> and in the department
                                    of Loire-Atlantique (44). Some owners have
                                    understood the interest and the advantages
                                    of being in this network sharing economy.{' '}
                                </p>
                                <p>
                                    For all, know that{' '}
                                    <strong className="font-title fs-2 text-primary">
                                        My Serene Home
                                    </strong>{' '}
                                    takes care, for your peace of mind, of
                                    <strong> concierge services</strong> related
                                    to this type of <strong>rental</strong>. As
                                    we will see later, you will be thus better
                                    rated and appreciated on the platform, which
                                    can only bring you new tourists.
                                </p>
                            </>
                        )}
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="22">
                        <h3 className="fs-2">
                            {translate === 'French' && (
                                <>
                                    L’esprit et le fonctionnement de la
                                    communauté Airbnb
                                </>
                            )}
                            {translate === 'English' && (
                                <>
                                    The spirit and functioning of the Airbnb
                                    community
                                </>
                            )}
                        </h3>
                    </AccordionHeader>
                    <AccordionBody accordionId="22">
                        {translate === 'French' && (
                            <>
                                <p>
                                    Pour les propriétaires d’hébergements qui
                                    songent à louer leurs biens, il est utile de
                                    rappeler que ce qu’est Airbnb et comment
                                    cette plateforme fonctionne.
                                </p>
                                <p>
                                    Airbnb est une communauté fondée sur le
                                    partage. De nos jours, ce réseau concerne
                                    une quantité impressionnante d’hôtes et de
                                    voyageurs qui utilisent Airbnb dans le
                                    monde. Mais cette plateforme c’est plus que
                                    des relations entre loueurs et locataires,
                                    c’est aussi un partage de centres d’intérêt
                                    entre voyageurs et hôtes et habitants des
                                    lieux de locations.
                                </p>
                                <p>
                                    Il faut aussi rappeler brièvement qu’Airbnb
                                    assure des services sécurisés. En effet, les
                                    profils de loueurs et leurs logements sont
                                    vérifiés, les correspondances se font sur
                                    une messagerie interne et, bien entendu, les
                                    paiements en ligne sont sécurisés. La
                                    plateforme met aussi à disposition un
                                    service d’assistance communautaire 7/7 et
                                    24/24 qui répond à toutes les questions
                                    voire les difficultés rencontrées. Ceci par
                                    exemple pour un changement de réservation,
                                    le paiement de la location, la garantie
                                    Hôte, etc. C'est dans ces cas imprévus qu'il
                                    est utile de pouvoir compter sur une
                                    conciergerie professionnelle située sur
                                    place.
                                </p>
                                <p>
                                    On trouve sur le marché d’autres loueurs de
                                    particulier à particulier qui sont des
                                    concurrents. On peut citer Booking qui fait
                                    aussi de la réservation en hôtel, Abritel du
                                    réseau mondial HomeAway et Gites.fr. Mais
                                    ces derniers ne font pas le poids avec
                                    Airbnb. Nous pouvons gérer vos biens
                                    locatifs peu importe la plateforme de
                                    location que vous utilisez.
                                </p>
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                <p>
                                    For accommodation owners who are thinking of
                                    renting out their property, it is useful to
                                    remember that what Airbnb is and how this
                                    platform works.
                                </p>
                                <p>
                                    Airbnb is a community based on the share.
                                    Nowadays, this network concerns an
                                    impressive number of hosts and travelers who
                                    use Airbnb in the world. But this platform
                                    is more than relations between landlords and
                                    tenants, it is also a sharing of centers of
                                    interest between travelers and hosts and
                                    inhabitants of rental locations.
                                </p>
                                <p>
                                    It should also be briefly recalled that
                                    Airbnb provides secure services. Indeed, the
                                    profiles of landlords and their
                                    accommodations are verified, correspondence
                                    is made on internal messaging and, of
                                    course, the online payments are secure.
                                    There platform also provides a 7/7 community
                                    assistance service and 24/24 who answers all
                                    questions even the difficulties encountered.
                                    This by example for a change of reservation,
                                    the payment of the rental, the guarantee
                                    Host, etc. It is in these unforeseen cases
                                    that he is useful to be able to count on a
                                    professional concierge located on place.
                                </p>
                                <p>
                                    There are other car rental companies on the
                                    market. individual to individual who are
                                    competitors. We can cite Booking which makes
                                    also hotel reservations, Abritel du
                                    worldwide network HomeAway and Gites.fr. But
                                    the latter do not measure up to Airbnb. We
                                    can manage your assets rentals regardless of
                                    the platform rental you use.
                                </p>
                            </>
                        )}
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="23">
                        <h3 className="fs-2">
                            {translate === 'French' && (
                                <> Airbnb à Pornic et ses environs</>
                            )}
                            {translate === 'English' && (
                                <> Airbnb in Pornic and its surroundings</>
                            )}
                        </h3>
                    </AccordionHeader>
                    <AccordionBody accordionId="23">
                        {translate === 'French' && (
                            <>
                                Ainsi, à <strong>Pornic</strong> et ses environs
                                comme <strong>Préfailles</strong>,{' '}
                                <strong>Saint-Michel-Chef-Chef</strong>,{' '}
                                <strong>La Plaine Sur Mer</strong>,{' '}
                                <strong>Saint-Brévin-les-Pins</strong>, on
                                trouve de nombreux logements de tous types à
                                disposition des voyageurs. Outre les
                                hébergements traditionnels (en chambre ou en
                                individuel) maison, appartements, villas avec
                                piscine voire Château, on dispose dans ces
                                villes d’hébergements plus insolites. Comme sur
                                un bateau, bien dépaysant pour les amoureux de
                                la mer, en caravane, en roulotte, en gîte, etc.
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                Thus, in <strong>Pornic</strong> and its
                                surroundings like <strong>Prefails</strong>,{' '}
                                <strong>Saint-Michel-Chef-Chef</strong>,{' '}
                                <strong>La Plaine Sur Mer</strong>,{' '}
                                <strong>Saint-Brévin-les-Pins</strong>, we finds
                                many accommodations of all types in available to
                                travelers. Besides the traditional accommodation
                                (in rooms or in individual) house, apartments,
                                villas with swimming pool or even Castle, we
                                have in these cities with more unusual
                                accommodations. Like on a boat, very exotic for
                                lovers of the sea, in a caravan, in a caravan,
                                in a gite, etc.
                            </>
                        )}
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
}
