const initialState = {
    logsAuthenticationStaff: null,
    logsAuthenticationVoyageurs: null,
    listStaff: null,
};

// eslint-disable-next-line default-param-last
export default function Logs(state = initialState, action) {
    switch (action.type) {
        case 'logsAuthenticationStaff':
            return {
                ...state,
                logsAuthenticationStaff: action.logsAuthenticationStaff,
            };

        case 'logsAuthenticationVoyageurs':
            return {
                ...state,
                logsAuthenticationVoyageurs: action.logsAuthenticationVoyageurs,
            };
        case 'listStaff':
            return {
                ...state,
                listStaff: action.listStaff,
            };
        default:
            return state;
    }
}
