import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import { FaPersonWalkingLuggage } from 'react-icons/fa6';

import {
    getDataCarnet,
    getDataCarnetGoogle,
    getListingSendMsg,
} from '../../../Store/Actions/messenger.actions';
import MessengerGestion from '../Messenger.jsx';

export default function MessengerLastSended() {
    document.title = 'SMS Envoyés | Ma Maison Sereine';
    document.description = 'Messagerie de Ma Maison Sereine';

    const dispatch = useDispatch();
    const accessToken = useSelector((store) => store.User.accessToken);
    const dataMsgSended = useSelector(
        (store) => store.Messenger.listingSendMsg
    );
    const dataCarnet = useSelector((store) => store.Messenger.dataCarnet);
    const dataCarnetGoogle = useSelector(
        (store) => store.Messenger.dataCarnetGoogle
    );
    useEffect(() => {
        dispatch(getListingSendMsg(accessToken));

        if (dataCarnet === null || dataCarnet === undefined) {
            dispatch(getDataCarnet(accessToken));
        }
        if (dataCarnet === null || dataCarnet === undefined) {
            dispatch(getDataCarnetGoogle(accessToken));
        }
    }, [accessToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            accessToken !== null && dispatch(getListingSendMsg(accessToken));
        }, 5000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
    }, [accessToken]);
    const [listCarnet, setListCarnet] = useState(undefined);
    useEffect(() => {
        const listCarnet = [];
        if (dataCarnet !== null) {
            dataCarnet.map((item) => {
                listCarnet.push(item);
            });
        }
        if (dataCarnetGoogle !== null) {
            dataCarnetGoogle.Google_contact.map((item) => {
                listCarnet.push(item);
            });
        }
        setListCarnet(listCarnet);
    }, [dataCarnet, dataCarnetGoogle]);
    return (
        <main className="d-flex flex-column mx-auto text-primary">
            <MessengerGestion />
            <h1 className="text-center mt-3 mx-auto display-5 ">
                50 Derniers Sms Envoyés
            </h1>
            <div className="p-2 m-4 col-11 mx-auto col-lg-8">
                {dataMsgSended !== null && dataMsgSended.length > 0 ? (
                    <>
                        {dataMsgSended.map((msg) => (
                            <div key={msg.id}>
                                Envoyé le {msg.createdAt}{' '}
                                {msg.type === '1' ? 'SMS' : 'Email'} via{' '}
                                {msg.canal !== '0' && msg.canal} -{' '}
                                {msg.type === '1' ? msg.number_port : msg.mail}{' '}
                                <br />
                                {listCarnet !== null &&
                                    listCarnet !== undefined &&
                                    listCarnet.map((element) => (
                                        <>
                                            {element.phone ===
                                                msg.number_port && (
                                                <div>
                                                    <small
                                                        key={
                                                            element.id + msg.id
                                                        }
                                                        className="bg-warning text-white mx-1 rounded p-1"
                                                    >
                                                        {element.first_name}{' '}
                                                        {element.last_name ===
                                                        'GoogleAPI'
                                                            ? ''
                                                            : element.last_name}
                                                    </small>
                                                </div>
                                            )}
                                        </>
                                    ))}{' '}
                                {msg.ref_reservation !== 0 && (
                                    <Link
                                        to={`/gestion/reservation/${msg.ref_reservation}/`}
                                        className="mx-auto"
                                    >
                                        <button className="btn btn-outline-primary p-1">
                                            <FaPersonWalkingLuggage />
                                            <small>Détails</small>
                                            <HiMiniArrowTopRightOnSquare />
                                        </button>
                                    </Link>
                                )}
                                {msg.success ? (
                                    <Alert color="success col-11 mx-auto">
                                        {msg.message}
                                    </Alert>
                                ) : (
                                    <Alert color="danger col-11 mx-auto">
                                        {msg.message}
                                    </Alert>
                                )}
                                <hr />
                            </div>
                        ))}
                    </>
                ) : (
                    <p>Chargement ...</p>
                )}
            </div>
        </main>
    );
}
