import { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { IoCalendarOutline } from 'react-icons/io5';

import CalendarBooking from '../../components/Reservations/CalendarBooking.jsx';
import LoadingK from '../../components/Widget/LoadingK.jsx';
import { getApartments } from '../../Store/Actions/smoobu.actions';

export default function CalendarGestion() {
    const dispatch = useDispatch();
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    document.title = 'Calendriers des disponibilités | Ma Maison Sereine';
    document.description = 'Pages de Calendriers des disponibilités';
    return (
        <main className="d-flex flex-column flex-wrap justify-content-around align-items-center">
            <h1 className="text-center mx-auto display-5">Calendriers</h1>
            {dataApartments !== null ? (
                <Accordion
                    open={open}
                    toggle={toggle}
                    className="m-2 col-12 col-xl-6"
                >
                    {dataApartments !== null ? (
                        dataApartments.map((appart) => (
                            <AccordionItem>
                                <AccordionHeader targetId={appart.id}>
                                    <h2>
                                        <span className="me-2">
                                            <IoCalendarOutline />{' '}
                                        </span>

                                        {appart.name}
                                    </h2>
                                </AccordionHeader>
                                <AccordionBody
                                    accordionId={appart.id}
                                    className="p-1 "
                                >
                                    <CalendarBooking apartment={appart} />
                                </AccordionBody>
                            </AccordionItem>
                        ))
                    ) : (
                        <LoadingK />
                    )}
                </Accordion>
            ) : (
                <LoadingK />
            )}
        </main>
    );
}
