import Button from 'react-bootstrap/Button';
import { Alert, Badge } from 'reactstrap';
import { useSelector } from 'react-redux';

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdLock } from 'react-icons/md';
import { LiaPlaneArrivalSolid, LiaPlaneDepartureSolid } from 'react-icons/lia';
import { PiNotepad } from 'react-icons/pi';
import { FaPencilAlt } from 'react-icons/fa';
import { BsKey } from 'react-icons/bs';
import { BiLogoAirbnb } from 'react-icons/bi';

import logo_cyberevasion from '../../../assets/img/logo/cyberevasion.png';
import bookingLogo from '../../../assets/img/logo/bookingLogo.jsx';
import replaceDay from '../../../utils/functions.jsx';
import WidgetMission from './WidgetMission.jsx';
import WidgetIconRese from './widgetIconRese.jsx';

export default function DetailsReservation(props) {
    const accessToken = useSelector((store) => store.User.accessToken);

    const todayDate = String(new Date().toISOString().split('T')[0]);
    const [idReservation, setIdReservation] = useState(false);
    const [showAlertNotes, setShowAlertNotes] = useState(false);
    const [editAlertNotes, setEditAlertNotes] = useState(false);

    useEffect(() => {
        if (props.element !== undefined) {
            setIdReservation(props.element.id);
        }
    }, [props.element]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        try {
            if (document.getElementById('notes').value !== undefined) {
                formData.append(
                    'notes',
                    document.getElementById('notes').value
                );
            }
        } catch {
            formData.append('notes', '');
        }

        formData.append('idReservation', idReservation);

        async function PutReservationAdmin(idReservation, formData) {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/smoobu/v1/booking/edit_notes/${idReservation}/`,
                    {
                        method: 'put',
                        headers: new Headers({
                            Accept: 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (data.status === 400) {
                    let msg = '';

                    Object.keys(data.validation_messages).forEach((key) =>
                        Object.keys(data.validation_messages[key]).forEach(
                            (key2) =>
                                (msg += data.validation_messages[key][key2])
                        )
                    );
                    toast.error(
                        'Le serveur de réservation distant est en erreur : ' +
                            msg,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (data.status === 401) {
                    toast.error(
                        'Le serveur de réservation distant (Smoobu) est en erreur ' +
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                    );
                } else if (data.status === 404) {
                    toast.error(
                        'La réservation est introuvable côté Smoobu ' +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else {
                    toast.success('MAJ ✅ - Rafraichir la page...', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setEditAlertNotes(!editAlertNotes);
                }
            } catch (err) {
                toast.error(
                    'Oups! Impossible de modifier la note de réservation 😔 Contactez le support',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PutReservationAdmin(idReservation, formData);
    };

    return (
        <li key={props.element.id + 'listing'} className={props.className}>
            <div className="text-start">
                <span>
                    Du {replaceDay(props.element.arrival)} au{' '}
                    {replaceDay(props.element.departure)}
                </span>{' '}
                {props.appartSee === true && (
                    <Badge className="ms-1" color="primary" pill>
                        ++{props.element.apartment.name}
                    </Badge>
                )}
                {props.element.arrival === todayDate &&
                    props.element.price !== 0 && (
                        <Badge
                            className="ms-1 text-center d-inlineblock"
                            color="success"
                            pill
                        >
                            <LiaPlaneArrivalSolid />
                            {props.element['check-in']}
                        </Badge>
                    )}
                {props.element.departure === todayDate &&
                    props.element.price !== 0 && (
                        <Badge
                            className="ms-1 text-center d-inlineblock"
                            color="danger"
                            pill
                        >
                            <LiaPlaneDepartureSolid />
                            {props.element['check-out']}
                        </Badge>
                    )}
                <br />
                {props.element.type !== 'cancellation' ? (
                    <>
                        <div>
                            <Link
                                to={`/gestion/reservation/${props.element.id}/`}
                                className="mx-auto"
                            >
                                <Button variant="light border-primary shadow m-2">
                                    {props.element.channel.name.includes(
                                        'lock'
                                    ) && <MdLock />}
                                    <span className="fw-bold">
                                        {props.element.lastname}
                                    </span>{' '}
                                    <span>{props.element.firstname} </span>
                                </Button>
                            </Link>
                            <span
                                className="btn  fs-3 text-warning"
                                onClick={() =>
                                    setShowAlertNotes(!showAlertNotes)
                                }
                            >
                                <PiNotepad />
                            </span>
                            {showAlertNotes === true && (
                                <Alert color="warning">
                                    {editAlertNotes === false ? (
                                        <>
                                            <span>{props.element.notice}</span>{' '}
                                            <Button
                                                variant="warning border-primary shadow m-2"
                                                onClick={(e) =>
                                                    setEditAlertNotes(
                                                        !editAlertNotes
                                                    )
                                                }
                                            >
                                                <FaPencilAlt />
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <form
                                                className="mx-auto text-center"
                                                onSubmit={(e) => {
                                                    handleSubmit(e);
                                                }}
                                            >
                                                <textarea
                                                    className="text-area mx-auto"
                                                    id="notes"
                                                    name="notes"
                                                    rows="5"
                                                    defaultValue={
                                                        props.element.notice
                                                    }
                                                ></textarea>
                                                <Button
                                                    color="primary"
                                                    className="mx-auto mt-2 mb-5"
                                                    type="submit"
                                                    id="btn-submit"
                                                >
                                                    Sauvegarder
                                                </Button>
                                            </form>
                                        </>
                                    )}
                                </Alert>
                            )}
                            <WidgetMission
                                idReservation={props.element.id}
                                logement={props.element.apartment.name}
                                logementId={props.element.apartment.id}
                                dateCkeckIn={props.element.arrival}
                                code={
                                    props.element.phone !== null
                                        ? (props.element.phone.substring(
                                              props.element.phone.length - 3,
                                              props.element.phone.length - 2
                                          ) ===
                                              ' ') |
                                          (props.element.phone.substring(
                                              props.element.phone.length - 3,
                                              props.element.phone.length - 2
                                          ) ===
                                              '.')
                                            ? props.element.phone.substring(
                                                  props.element.phone.length -
                                                      5,
                                                  props.element.phone.length - 3
                                              ) +
                                              props.element.phone.substring(
                                                  props.element.phone.length -
                                                      2,
                                                  props.element.phone.length
                                              )
                                            : props.element.phone.substring(
                                                  props.element.phone.length -
                                                      4,
                                                  props.element.phone.length
                                              )
                                        : '0000'
                                }
                            />
                        </div>
                        <div>
                            <span>
                                {props.element.channel.id === 2219294 && (
                                    <span className="text-danger fs-3">
                                        <BiLogoAirbnb />
                                    </span>
                                )}
                                {props.element.channel.id === 2219315 && (
                                    <span className="text-blue">
                                        {bookingLogo}
                                    </span>
                                )}
                                {props.element.channel.id === 2219288 && (
                                    <span className="text-success">
                                        <i className="fa-solid fa-hand-holding-hand mx-1"></i>{' '}
                                        {props.element.channel.name}
                                    </span>
                                )}{' '}
                                {props.element.channel.id === 2248897 && (
                                    <img
                                        src={logo_cyberevasion}
                                        alt="Logo - CyberEvasion"
                                        className="logo__img"
                                    />
                                )}
                            </span>
                            {props.element.phone !== null && (
                                <span>
                                    <Badge
                                        color="antique"
                                        className="text-primary border border-1 border-primary"
                                    >
                                        <span className="fs-4 me-2">
                                            <BsKey />
                                        </span>
                                        {props.element.phone !== null
                                            ? (props.element.phone.substring(
                                                  props.element.phone.length -
                                                      3,
                                                  props.element.phone.length - 2
                                              ) ===
                                                  ' ') |
                                              (props.element.phone.substring(
                                                  props.element.phone.length -
                                                      3,
                                                  props.element.phone.length - 2
                                              ) ===
                                                  '.')
                                                ? props.element.phone.substring(
                                                      props.element.phone
                                                          .length - 5,
                                                      props.element.phone
                                                          .length - 3
                                                  ) +
                                                  props.element.phone.substring(
                                                      props.element.phone
                                                          .length - 2,
                                                      props.element.phone.length
                                                  )
                                                : props.element.phone.substring(
                                                      props.element.phone
                                                          .length - 4,
                                                      props.element.phone.length
                                                  )
                                            : 'Aucun N°Tel'}
                                    </Badge>
                                </span>
                            )}

                            <WidgetIconRese element={props.element} />
                        </div>
                    </>
                ) : (
                    <Link
                        to={`/gestion/reservation/${props.element.id}/`}
                        className="mx-auto"
                    >
                        <Button variant="secondary border-primary shadow m-2">
                            {props.element.channel.name.includes('lock') ? (
                                <i className="fa-solid fa-house-lock"></i>
                            ) : (
                                <span>
                                    {' '}
                                    Reservation {
                                        props.element['reference-id']
                                    }{' '}
                                </span>
                            )}
                            <Badge
                                className="ms-1 text-center d-inlineblock"
                                color="danger"
                                pill
                            >
                                <i className="fa-solid fa-handshake-slash"></i>{' '}
                                Annulée !
                            </Badge>
                        </Button>
                    </Link>
                )}
            </div>
        </li>
    );
}
