import { useSelector } from 'react-redux';

export default function WidgetRGPD() {
    const translate = useSelector((store) => store.Template.language);
    return (
        <>
            {translate === 'French' && (
                <>
                    <p>
                        Pour faire une réservation vous devez fournir vos
                        coordonnées. Il vous suffit d’entrer les champs entre
                        parenthèses (indiquez les champs obligatoires ici) ainsi
                        que les données concernant la réservation (p.ex. période
                        de séjour). Nous enregistrons également la date et
                        l’heure de la réservation. Des informations
                        supplémentaires (précisez les champs facultatifs ici) ne
                        sont pas obligatoires.
                    </p>
                    <p>
                        Les informations que vous fournissez sur notre site web,
                        y compris les notes sont des données personnelles et
                        sont traitées et utilisés par nous-mêmes pour nous
                        assurer du bon traitement de la réservation et la
                        prestation du service demandé.
                    </p>
                    <p>
                        Nous utilisons également vos données pour donner des
                        informations pertinentes a la réservation ou durant
                        votre séjour, les données collectées pour les
                        réservations seront transmises aux entités suivantes :
                    </p>
                </>
            )}
            {translate === 'English' && (
                <>
                    <p>
                        To make a reservation you must provide your contact
                        details. Just enter the fields between parentheses
                        (indicate required fields here) as well that the data
                        concerning the reservation (e.g. period stay). We also
                        record the date and the time of the reservation.
                        Informations additional (specify optional fields here)
                        are not mandatory.
                    </p>
                    <p>
                        The information you provide on our website, including
                        notes are personal data and are processed and used by us
                        for us ensure the proper processing of the reservation
                        and the provision of the requested service.
                    </p>
                    <p>
                        We also use your data to provide information relevant to
                        the reservation or during your stay, the data collected
                        for reservations will be forwarded to the following
                        entities:
                    </p>
                </>
            )}
            {translate === 'French' && (
                <p>
                    Smoobu GmbH – Smoobu.com est un logiciel de gestion pour les
                    propriétaires d’appartement
                    <br />
                    Pappelallee 78/79
                    <br />
                    10437 Berlin
                    <br />
                    Deutschland
                    <br /> <br />
                    Lien vers la politique de confidentialité de Smoobu:
                    <br />
                    <a
                        href="https://www.smoobu.com/datenschutz/"
                        target="_blank"
                        className="text-primary"
                    >
                        https://www.smoobu.com/datenschutz/{' '}
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                </p>
            )}
            {translate === 'English' && (
                <p>
                    Smoobu GmbH – Smoobu.com is a management software for
                    apartment owners
                    <br />
                    Pappelallee 78/79
                    <br />
                    10437 Berlin
                    <br />
                    Deutschland
                    <br /> <br />
                    Link to Smoobu privacy policy:
                    <br />
                    <a
                        href="https://www.smoobu.com/datenschutz/"
                        target="_blank"
                        className="text-primary"
                    >
                        https://www.smoobu.com/datenschutz/{' '}
                        <i className="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                </p>
            )}
            {translate === 'French' && (
                <>
                    <p>
                        Nous utilisons également vos données dans le présent
                        site web - www.mamaisonsereine.fr - utilisé aussi en
                        logiciel de gestion - pour des fonctionnalités de
                        gestions d'envoi de SMS, de Mail ainsi que pour le suivi
                        de votre paiement auprès de notre plateforme de paiement
                        Stancer.
                    </p>
                    <p>
                        Vous disposez d'un droit d'accès, de modification, de
                        demande de suppression de ses données en nous contactant
                        par mail à contact@mamaisonsereine.fr (trace écrite)
                        sinon vos données seront détruites du présent site dans
                        un délai de 6 mois (indépendament de la plateforme
                        Smoobu)
                    </p>
                </>
            )}
            {translate === 'English' && (
                <>
                    <p>
                        We also use your data in this website -
                        www.mamaisonsereine.fr - also used in management
                        software - for functionalities of management of sending
                        SMS, Mail as well as for follow-up of your payment with
                        our payment platform Stance.
                    </p>
                    <p>
                        You have the right to access, modify, request to delete
                        their data by contacting us by email to
                        contact@mamaisonsereine.fr (written record) otherwise
                        your data will be destroyed from this site within a
                        period of 6 months (independent of the platform Smoobu)
                    </p>
                </>
            )}
        </>
    );
}
