import { useState } from 'react';
import { Badge, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import { useFetchStancerResaPackVoyageur } from '../../../utils/Api/FetchStancer.jsx';
import logo_stancer from '../../../assets/img/logo/logo_stancer.png';

export default function StatusStancerPackVoyageur(props) {
    const translate = useSelector((store) => store.Template.language);

    const [nberPhone] = useState(
        localStorage.getItem('authVoyageurPort')
            ? localStorage.getItem('authVoyageurPort')
            : undefined
    );
    const [codeSMSActive] = useState(
        localStorage.getItem('authVoyageurCode')
            ? localStorage.getItem('authVoyageurCode')
            : undefined
    );
    const { dataStancerResaPackVoyageur, isLoadingStancerResaPackVoyageur } =
        useFetchStancerResaPackVoyageur(
            props.idReservation,
            nberPhone,
            codeSMSActive
        );

    return (
        <>
            {isLoadingStancerResaPackVoyageur !== true &&
                props.idReservation !== undefined &&
                dataStancerResaPackVoyageur !== undefined && (
                    <div className="bg-white mx-auto col-11 col-lg-8 my-3 border border-primary shadow p-3 rounded">
                        <h3 className=" text-center my-2">
                            <i className="fa-solid fa-cubes"></i>{' '}
                            {translate === 'French' && (
                                <>Listes des Packs soucrits</>
                            )}
                            {translate === 'English' && (
                                <>List Packs Subscribes</>
                            )}
                        </h3>
                        <hr className="col-4 mx-auto" />
                        {dataStancerResaPackVoyageur.length > 0 ? (
                            <>
                                {dataStancerResaPackVoyageur.map((element) => (
                                    <>
                                        {props.onlyNotPay !== undefined &&
                                            props.onlyNotPay === true &&
                                            element.statut !== 'Payé' && (
                                                <div className="bg-antique-light  mx-auto p-2 my-2 rounded border border-warning">
                                                    <div className="d-flex flex-column">
                                                        <span>
                                                            <i className="fa-regular fa-clock"></i>{' '}
                                                            Demande de Pack
                                                            Optionnel du{' '}
                                                            {
                                                                element.date_created
                                                            }
                                                        </span>

                                                        <hr className="col-4 mx-auto" />
                                                        {element.price_spa !==
                                                            0.0 && (
                                                            <span>
                                                                {' '}
                                                                <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        Forfait
                                                                        SPA :{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Spa Pack
                                                                        :{' '}
                                                                    </>
                                                                )}
                                                                {
                                                                    element.price_spa
                                                                }{' '}
                                                                EUR -{' '}
                                                                {
                                                                    element.horaire_spa
                                                                }
                                                            </span>
                                                        )}
                                                        {element.price_menage !==
                                                            0.0 && (
                                                            <span>
                                                                {' '}
                                                                <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        Forfait
                                                                        Ménage :{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Cleaning
                                                                        fee :{' '}
                                                                    </>
                                                                )}
                                                                {
                                                                    element.price_menage
                                                                }{' '}
                                                                EUR
                                                            </span>
                                                        )}
                                                        {element.price_animaux !==
                                                            0.0 && (
                                                            <span>
                                                                <i className="mx-1 fa-solid fa-paw fs-5"></i>{' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        {' '}
                                                                        Forfait
                                                                        Animaux
                                                                        :{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Pets
                                                                        Package{' '}
                                                                    </>
                                                                )}{' '}
                                                                {
                                                                    element.price_animaux
                                                                }{' '}
                                                                €
                                                            </span>
                                                        )}
                                                        {element.nber_linge_2p_blanc !==
                                                            0 && (
                                                            <span>
                                                                {' '}
                                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        {' '}
                                                                        Forfait
                                                                        Pack
                                                                        Linge 2p
                                                                        Blanc :{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        2p White
                                                                        Linen
                                                                        Pack
                                                                        Package{' '}
                                                                    </>
                                                                )}{' '}
                                                                {
                                                                    element.nber_linge_2p_blanc
                                                                }{' '}
                                                                x{' '}
                                                                {
                                                                    props
                                                                        .dataLocationSettings
                                                                        .price_linge_2p_blanc
                                                                }
                                                                €{' => '}{' '}
                                                                {props
                                                                    .dataLocationSettings
                                                                    .price_linge_2p_blanc *
                                                                    element.nber_linge_2p_blanc}{' '}
                                                                €
                                                            </span>
                                                        )}
                                                        {element.nber_linge_1p_blanc !==
                                                            0 && (
                                                            <span>
                                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        {' '}
                                                                        Forfait
                                                                        Pack
                                                                        Linge 1p
                                                                        Blanc :{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        1p White
                                                                        Linen
                                                                        Pack
                                                                        Package{' '}
                                                                    </>
                                                                )}{' '}
                                                                {
                                                                    element.nber_linge_1p_blanc
                                                                }{' '}
                                                                x{' '}
                                                                {
                                                                    props
                                                                        .dataLocationSettings
                                                                        .price_linge_1p_blanc
                                                                }
                                                                €{' => '}{' '}
                                                                {props
                                                                    .dataLocationSettings
                                                                    .price_linge_1p_blanc *
                                                                    element.nber_linge_1p_blanc}{' '}
                                                                €
                                                            </span>
                                                        )}
                                                        {element.nber_linge_2p_couleur !==
                                                            0 && (
                                                            <span>
                                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        {' '}
                                                                        Forfait
                                                                        Pack
                                                                        Linge 2p
                                                                        Couleur
                                                                        :{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        2p Color
                                                                        Linen
                                                                        Pack
                                                                        Package{' '}
                                                                    </>
                                                                )}{' '}
                                                                {
                                                                    element.nber_linge_2p_couleur
                                                                }{' '}
                                                                x{' '}
                                                                {
                                                                    props
                                                                        .dataLocationSettings
                                                                        .price_linge_2p_couleur
                                                                }
                                                                €{' => '}{' '}
                                                                {props
                                                                    .dataLocationSettings
                                                                    .price_linge_2p_couleur *
                                                                    element.nber_linge_2p_couleur}{' '}
                                                                €
                                                            </span>
                                                        )}
                                                        {element.nber_linge_1p_couleur !==
                                                            0 && (
                                                            <span>
                                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        {' '}
                                                                        Forfait
                                                                        Pack
                                                                        Linge 1p
                                                                        Couleur
                                                                        :{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        2p Color
                                                                        Linen
                                                                        Pack
                                                                        Package{' '}
                                                                    </>
                                                                )}{' '}
                                                                {
                                                                    element.nber_linge_1p_couleur
                                                                }{' '}
                                                                x{' '}
                                                                {
                                                                    props
                                                                        .dataLocationSettings
                                                                        .price_linge_1p_couleur
                                                                }
                                                                €{' => '}{' '}
                                                                {props
                                                                    .dataLocationSettings
                                                                    .price_linge_1p_couleur *
                                                                    element.nber_linge_1p_couleur}{' '}
                                                                €
                                                            </span>
                                                        )}
                                                    </div>
                                                    <hr className="col-4 mx-auto" />

                                                    <div className="text-center d-flex flex-column">
                                                        <p className="text-danger text-center">
                                                            <span className="mb-3">
                                                                {translate ===
                                                                    'French' && (
                                                                    <>
                                                                        Total du
                                                                        Pack à
                                                                        régler:{' '}
                                                                    </>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>
                                                                        Total
                                                                        Amount
                                                                        Pack to
                                                                        paid :{' '}
                                                                    </>
                                                                )}
                                                                {
                                                                    element.total_amount
                                                                }{' '}
                                                                EUR
                                                            </span>
                                                        </p>
                                                        {element.statut ===
                                                            'Annulée' && (
                                                            <Badge
                                                                className="m-2 fs-6"
                                                                color="Danger"
                                                                pill
                                                            >
                                                                {element.statut}
                                                            </Badge>
                                                        )}
                                                        {element.statut ===
                                                            'En Attente de Paiement' && (
                                                            <>
                                                                <Badge
                                                                    className="m-2 fs-6"
                                                                    color="warning"
                                                                    pill
                                                                >
                                                                    {
                                                                        element.statut
                                                                    }
                                                                </Badge>

                                                                <a
                                                                    href={
                                                                        element.payment_url_stancer
                                                                    }
                                                                >
                                                                    <Button
                                                                        color="primary"
                                                                        className="my-3"
                                                                    >
                                                                        {translate ===
                                                                            'French' && (
                                                                            <>
                                                                                Je
                                                                                procède
                                                                                au
                                                                                règlement
                                                                                avec{' '}
                                                                            </>
                                                                        )}
                                                                        {translate ===
                                                                            'English' && (
                                                                            <>
                                                                                I
                                                                                proceed
                                                                                to
                                                                                payment
                                                                                with{' '}
                                                                            </>
                                                                        )}
                                                                        <img
                                                                            className="bg-white rounded"
                                                                            src={
                                                                                logo_stancer
                                                                            }
                                                                            alt={
                                                                                'Logo - Stancer'
                                                                            }
                                                                            width={
                                                                                120
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </a>
                                                            </>
                                                        )}

                                                        {element.statut ===
                                                            'Payée' && (
                                                            <>
                                                                <Badge
                                                                    className="m-2 fs-6"
                                                                    color="primary"
                                                                    pill
                                                                >
                                                                    {
                                                                        element.statut
                                                                    }{' '}
                                                                    ID Paiement{' '}
                                                                    {
                                                                        element.payment_id_stancer
                                                                    }
                                                                </Badge>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        {props.onlyNotPay === undefined && (
                                            <div className="bg-antique-light  mx-auto p-2 my-2 rounded border border-warning">
                                                <div className="d-flex flex-column">
                                                    <span>
                                                        <i className="fa-regular fa-clock"></i>{' '}
                                                        Demande de Pack
                                                        Optionnel du{' '}
                                                        {element.date_created}
                                                    </span>

                                                    <hr className="col-4 mx-auto" />
                                                    {element.price_spa !==
                                                        0.0 && (
                                                        <span>
                                                            {' '}
                                                            <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                                            {translate ===
                                                                'French' && (
                                                                <>
                                                                    Forfait SPA
                                                                    :{' '}
                                                                </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>Spa Pack : </>
                                                            )}
                                                            {element.price_spa}{' '}
                                                            EUR -{' '}
                                                            {
                                                                element.horaire_spa
                                                            }
                                                        </span>
                                                    )}
                                                    {element.price_menage !==
                                                        0.0 && (
                                                        <span>
                                                            {' '}
                                                            <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                                            {translate ===
                                                                'French' && (
                                                                <>
                                                                    Forfait
                                                                    Ménage :{' '}
                                                                </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>
                                                                    Cleaning fee
                                                                    :{' '}
                                                                </>
                                                            )}
                                                            {
                                                                element.price_menage
                                                            }{' '}
                                                            EUR
                                                        </span>
                                                    )}
                                                    {element.price_animaux !==
                                                        0.0 && (
                                                        <span>
                                                            <i className="mx-1 fa-solid fa-paw fs-5"></i>{' '}
                                                            {translate ===
                                                                'French' && (
                                                                <>
                                                                    {' '}
                                                                    Forfait
                                                                    Animaux :{' '}
                                                                </>
                                                            )}
                                                            {translate ===
                                                                'English' && (
                                                                <>
                                                                    Pets Package{' '}
                                                                </>
                                                            )}{' '}
                                                            {
                                                                element.price_animaux
                                                            }{' '}
                                                            €
                                                        </span>
                                                    )}
                                                    {element.nber_linge_2p_blanc !==
                                                        0 && (
                                                        <span>
                                                            {' '}
                                                            <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                            Forfait Pack Linge
                                                            2p Blanc :{' '}
                                                            {
                                                                element.nber_linge_2p_blanc
                                                            }{' '}
                                                            x{' '}
                                                            {Math.trunc(
                                                                (new Date(
                                                                    element.departure.slice(
                                                                        0,
                                                                        4
                                                                    ),
                                                                    element.departure.slice(
                                                                        5,
                                                                        7
                                                                    ),
                                                                    element.departure.slice(
                                                                        8,
                                                                        10
                                                                    )
                                                                ) -
                                                                    new Date(
                                                                        element.arrival.slice(
                                                                            0,
                                                                            4
                                                                        ),
                                                                        element.arrival.slice(
                                                                            5,
                                                                            7
                                                                        ),
                                                                        element.arrival.slice(
                                                                            8,
                                                                            10
                                                                        )
                                                                    )) /
                                                                    (1000 *
                                                                        60 *
                                                                        60 *
                                                                        24)
                                                            ) >
                                                            props
                                                                .dataLocationSettings
                                                                .number_night_linge ? (
                                                                <span>
                                                                    {props
                                                                        .dataLocationSettings
                                                                        .price_linge_2p_blanc -
                                                                        props
                                                                            .dataLocationSettings
                                                                            .price_linge_2p_couleur}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {
                                                                        props
                                                                            .dataLocationSettings
                                                                            .price_linge_2p_blanc
                                                                    }{' '}
                                                                    €
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                    {element.nber_linge_1p_blanc !==
                                                        0 && (
                                                        <span>
                                                            <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                            Forfait Pack Linge
                                                            1p Blanc :{' '}
                                                            {
                                                                element.nber_linge_1p_blanc
                                                            }{' '}
                                                            x
                                                            {Math.trunc(
                                                                (new Date(
                                                                    element.departure.slice(
                                                                        0,
                                                                        4
                                                                    ),
                                                                    element.departure.slice(
                                                                        5,
                                                                        7
                                                                    ),
                                                                    element.departure.slice(
                                                                        8,
                                                                        10
                                                                    )
                                                                ) -
                                                                    new Date(
                                                                        element.arrival.slice(
                                                                            0,
                                                                            4
                                                                        ),
                                                                        element.arrival.slice(
                                                                            5,
                                                                            7
                                                                        ),
                                                                        element.arrival.slice(
                                                                            8,
                                                                            10
                                                                        )
                                                                    )) /
                                                                    (1000 *
                                                                        60 *
                                                                        60 *
                                                                        24)
                                                            ) >
                                                            props
                                                                .dataLocationSettings
                                                                .number_night_linge ? (
                                                                <span>
                                                                    {props
                                                                        .dataLocationSettings
                                                                        .price_linge_1p_blanc -
                                                                        props
                                                                            .dataLocationSettings
                                                                            .price_linge_1p_couleur}
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {
                                                                        props
                                                                            .dataLocationSettings
                                                                            .price_linge_1p_blanc
                                                                    }{' '}
                                                                    €
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                    {element.nber_linge_2p_couleur !==
                                                        0 && (
                                                        <span>
                                                            <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                            Forfait Pack Linge
                                                            2p Couleur :{' '}
                                                            {
                                                                element.nber_linge_2p_couleur
                                                            }{' '}
                                                            x{' '}
                                                            {Math.trunc(
                                                                (new Date(
                                                                    element.departure.slice(
                                                                        0,
                                                                        4
                                                                    ),
                                                                    element.departure.slice(
                                                                        5,
                                                                        7
                                                                    ),
                                                                    element.departure.slice(
                                                                        8,
                                                                        10
                                                                    )
                                                                ) -
                                                                    new Date(
                                                                        element.arrival.slice(
                                                                            0,
                                                                            4
                                                                        ),
                                                                        element.arrival.slice(
                                                                            5,
                                                                            7
                                                                        ),
                                                                        element.arrival.slice(
                                                                            8,
                                                                            10
                                                                        )
                                                                    )) /
                                                                    (1000 *
                                                                        60 *
                                                                        60 *
                                                                        24)
                                                            ) >
                                                            props
                                                                .dataLocationSettings
                                                                .number_night_linge ? (
                                                                <span>
                                                                    Inclus
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {
                                                                        props
                                                                            .dataLocationSettings
                                                                            .price_linge_2p_couleur
                                                                    }{' '}
                                                                    €
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                    {element.nber_linge_1p_couleur !==
                                                        0 && (
                                                        <span>
                                                            <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                            Forfait Pack Linge
                                                            1p Couleur :{' '}
                                                            {
                                                                element.nber_linge_1p_couleur
                                                            }{' '}
                                                            x{' '}
                                                            {Math.trunc(
                                                                (new Date(
                                                                    element.departure.slice(
                                                                        0,
                                                                        4
                                                                    ),
                                                                    element.departure.slice(
                                                                        5,
                                                                        7
                                                                    ),
                                                                    element.departure.slice(
                                                                        8,
                                                                        10
                                                                    )
                                                                ) -
                                                                    new Date(
                                                                        element.arrival.slice(
                                                                            0,
                                                                            4
                                                                        ),
                                                                        element.arrival.slice(
                                                                            5,
                                                                            7
                                                                        ),
                                                                        element.arrival.slice(
                                                                            8,
                                                                            10
                                                                        )
                                                                    )) /
                                                                    (1000 *
                                                                        60 *
                                                                        60 *
                                                                        24)
                                                            ) >
                                                            props
                                                                .dataLocationSettings
                                                                .number_night_linge ? (
                                                                <span>
                                                                    Inclus
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {
                                                                        props
                                                                            .dataLocationSettings
                                                                            .price_linge_1p_couleur
                                                                    }{' '}
                                                                    €
                                                                </span>
                                                            )}
                                                        </span>
                                                    )}
                                                </div>
                                                <hr className="col-4 mx-auto" />

                                                <div className="text-center d-flex flex-column">
                                                    {element.statut ===
                                                        'Annulée' && (
                                                        <Badge
                                                            className="m-2 fs-6"
                                                            color="Danger"
                                                            pill
                                                        >
                                                            {element.statut}
                                                        </Badge>
                                                    )}
                                                    {element.statut ===
                                                        'En Attente de Paiement' && (
                                                        <>
                                                            <p className="text-danger text-center">
                                                                <span className="mb-3">
                                                                    {translate ===
                                                                        'French' && (
                                                                        <>
                                                                            Total
                                                                            du
                                                                            Pack
                                                                            à
                                                                            régler:{' '}
                                                                        </>
                                                                    )}
                                                                    {translate ===
                                                                        'English' && (
                                                                        <>
                                                                            Total
                                                                            Amount
                                                                            Pack
                                                                            to
                                                                            paid
                                                                            :{' '}
                                                                        </>
                                                                    )}
                                                                    {
                                                                        element.total_amount
                                                                    }{' '}
                                                                    EUR
                                                                </span>
                                                            </p>
                                                            <Badge
                                                                className="m-2 fs-6"
                                                                color="warning"
                                                                pill
                                                            >
                                                                {element.statut}
                                                            </Badge>

                                                            <a
                                                                href={`https://api.mamaisonsereine.fr/api/locations/stancer_pack/connect/${element.id}/${element.total_amount}/${nberPhone}/${codeSMSActive}/`}
                                                            >
                                                                <Button
                                                                    color="primary"
                                                                    className="my-3"
                                                                >
                                                                    {translate ===
                                                                        'French' && (
                                                                        <>
                                                                            Je
                                                                            procède
                                                                            au
                                                                            règlement
                                                                            du
                                                                            Pack
                                                                            avec{' '}
                                                                        </>
                                                                    )}
                                                                    {translate ===
                                                                        'English' && (
                                                                        <>
                                                                            I
                                                                            proceed
                                                                            to
                                                                            Pack
                                                                            payment
                                                                            with{' '}
                                                                        </>
                                                                    )}
                                                                    <img
                                                                        className="bg-white rounded"
                                                                        src={
                                                                            logo_stancer
                                                                        }
                                                                        alt={
                                                                            'Logo - Stancer'
                                                                        }
                                                                        width={
                                                                            120
                                                                        }
                                                                    />
                                                                </Button>
                                                            </a>
                                                        </>
                                                    )}

                                                    {(element.statut ===
                                                        'Payé' ||
                                                        element.statut ===
                                                            'Accepté - La Banque a autorisé le paiement') && (
                                                        <>
                                                            <p className="text-primary text-center">
                                                                <span className="mb-3">
                                                                    {translate ===
                                                                        'French' && (
                                                                        <>
                                                                            Total
                                                                            réglé
                                                                            :{' '}
                                                                        </>
                                                                    )}
                                                                    {translate ===
                                                                        'English' && (
                                                                        <>
                                                                            Total
                                                                            paid
                                                                            :{' '}
                                                                        </>
                                                                    )}
                                                                    {
                                                                        element.total_amount
                                                                    }{' '}
                                                                    EUR
                                                                </span>
                                                            </p>
                                                            <Badge
                                                                className="m-2 fs-6"
                                                                color="primary"
                                                                pill
                                                            >
                                                                {element.statut}{' '}
                                                                <br />
                                                                <br />
                                                                {element.total_amount !==
                                                                    0 && (
                                                                    <>
                                                                        N°
                                                                        Paiement
                                                                        :{' '}
                                                                        {
                                                                            element.payment_id_stancer
                                                                        }
                                                                    </>
                                                                )}
                                                            </Badge>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </>
                        ) : (
                            <span>Aucun</span>
                        )}
                    </div>
                )}
        </>
    );
}
