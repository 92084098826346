import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RiDeleteBin5Line } from 'react-icons/ri';
import { GiVacuumCleaner } from 'react-icons/gi';
import { MdEditCalendar } from 'react-icons/md';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {
    createMissionToEdit,
    deleteMissionToEdit,
    putMissionToEdit,
    setMissionToEdit,
} from '../../../Store/Actions/gestion.actions';
import { getAllUsers } from '../../../Store/Actions/user.actions';
import replaceDay from '../../../utils/functions.jsx';

export default function WidgetMission(props) {
    const [dataMission, setDataMission] = useState(undefined);
    const accessToken = useSelector((store) => store.User.accessToken);
    const authUser = useSelector((store) => store.User.authUser);
    const allUser = useSelector((store) => store.User.allUsers);
    const missionToEdit = useSelector((store) => store.Gestion.missionToEdit);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [beforeReservation, setBeforeReservation] = useState({
        phone: '0000',
    });

    const handleShow = () => {
        setShow(true);
        dispatch(
            setMissionToEdit({
                creator: authUser.id,
                staff: 0,
                reservation: props.idReservation,
                date_run: props.dateCkeckIn,
                logement: props.logement,
                code_before:
                    beforeReservation.phone !== null
                        ? (beforeReservation.phone.substring(
                              beforeReservation.phone.length - 3,
                              beforeReservation.phone.length - 2
                          ) ===
                              ' ') |
                          (beforeReservation.phone.substring(
                              beforeReservation.phone.length - 3,
                              beforeReservation.phone.length - 2
                          ) ===
                              '.')
                            ? beforeReservation.phone.substring(
                                  beforeReservation.phone.length - 5,
                                  beforeReservation.phone.length - 3
                              ) +
                              beforeReservation.phone.substring(
                                  beforeReservation.phone.length - 2,
                                  beforeReservation.phone.length
                              )
                            : beforeReservation.phone.substring(
                                  beforeReservation.phone.length - 4,
                                  beforeReservation.phone.length
                              )
                        : '0000',
                code_after: props.code,
                success: false,
                notes: '',
            })
        );
    };
    useEffect(() => {
        if (accessToken !== null && allUser === null) {
            dispatch(getAllUsers(accessToken));
        }
    }, [accessToken]);
    const dataReservations = useSelector(
        (store) => store.Smoobu.dataReservations
    );
    useEffect(() => {
        setTimeout(() => {
            if (
                dataReservations !== undefined &&
                dataReservations !== null &&
                dataReservations.bookings !== undefined &&
                dataReservations.bookings !== null &&
                dataReservations.bookings.length > 0
            ) {
                let stop = false;
                let arrivalDate = new Date();
                arrivalDate.setFullYear('2023');

                const checkInDate = new Date();
                checkInDate.setFullYear(props.dateCkeckIn.split('-')[0]);
                checkInDate.setMonth(props.dateCkeckIn.split('-')[1] - 1);
                checkInDate.setDate(props.dateCkeckIn.split('-')[2]);

                dataReservations.bookings.map((element) => {
                    const resaArrivalDate = new Date();
                    resaArrivalDate.setFullYear(element.arrival.split('-')[0]);
                    resaArrivalDate.setMonth(element.arrival.split('-')[1] - 1);
                    resaArrivalDate.setDate(element.arrival.split('-')[2]);

                    if (
                        element.apartment.id === props.logementId &&
                        stop === false &&
                        arrivalDate < resaArrivalDate &&
                        resaArrivalDate < checkInDate
                    ) {
                        arrivalDate = resaArrivalDate;

                        setBeforeReservation(element);
                    } else if (element.id === props.idReservation) {
                        stop = true;
                    }
                });
            }
        }, 1000);
    }, [accessToken]);

    useEffect(() => {
        if (
            props.idReservation !== undefined &&
            props.idReservation !== false
        ) {
            async function fetchDataPack() {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/missions/v1/${props.idReservation}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                if (response.status === 200) {
                    setDataMission(data.missions);
                }
            }
            if (accessToken !== null && accessToken !== undefined) {
                setTimeout(() => {
                    fetchDataPack();
                }, 500);
            }
        }
    }, [accessToken, props.idReservation, show]);
    useEffect(() => {
        async function fetchDataPack() {
            const response = await fetch(
                `https://api.mamaisonsereine.fr/api/missions/v1/${props.idReservation}/`,
                {
                    method: 'get',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json',
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setDataMission(data.missions);
            }
        }

        const interval = setInterval(() => {
            if (
                accessToken !== null &&
                accessToken !== undefined &&
                show === true &&
                props.idReservation !== undefined &&
                props.idReservation !== false
            ) {
                fetchDataPack();
            }
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [accessToken, show]);

    return (
        <span>
            {dataMission === undefined ? (
                <Spinner animation="border" className="text-rose" />
            ) : (
                <>
                    {dataMission === undefined ||
                    dataMission === null ||
                    dataMission.length === 0 ? (
                        <span
                            className="text-secondary fs-4 px-1 py-0"
                            onClick={handleShow}
                        >
                            <GiVacuumCleaner />
                        </span>
                    ) : (
                        <span
                            className="text-rose fs-4 px-1 py-0"
                            onClick={handleShow}
                        >
                            <GiVacuumCleaner />
                        </span>
                    )}

                    <Modal show={show} onHide={handleClose} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title className="text-rose">
                                Mission Ménage - Réservation{' '}
                                {props.idReservation} <br />
                                <small className="text-primary">
                                    {' '}
                                    {props.logement} - CheckIn{' '}
                                    {replaceDay(props.dateCkeckIn)}
                                </small>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {dataMission === undefined ||
                                dataMission === null ||
                                (dataMission.length === 0 && (
                                    <div>
                                        <p>
                                            Il n'y a pas d'affection de missions
                                            pour cette réservation
                                        </p>
                                    </div>
                                ))}
                            {dataMission !== undefined &&
                                dataMission !== null &&
                                dataMission.length !== 0 && (
                                    <div className="w-fit mx-auto">
                                        {dataMission !== undefined &&
                                            dataMission !== null &&
                                            dataMission.length !== 0 &&
                                            dataMission.map((mission) => (
                                                <div className="text-start bg-light p-2 m-2">
                                                    <div className="d-flex flex-row justify-content-around">
                                                        <button
                                                            className="btn btn-outline-success "
                                                            onClick={() =>
                                                                dispatch(
                                                                    setMissionToEdit(
                                                                        mission
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            <MdEditCalendar />
                                                        </button>
                                                        <button
                                                            className="btn btn-outline-danger "
                                                            onClick={() =>
                                                                dispatch(
                                                                    deleteMissionToEdit(
                                                                        accessToken,
                                                                        props.idReservation,
                                                                        mission.id
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            <RiDeleteBin5Line />
                                                        </button>
                                                    </div>
                                                    <p>
                                                        <span className="fw-bold">
                                                            Démarrage de la
                                                            mission:{' '}
                                                        </span>
                                                        {replaceDay(
                                                            mission.date_run
                                                        )}{' '}
                                                        à{' '}
                                                        {
                                                            mission.date_run.split(
                                                                'T'
                                                            )[1]
                                                        }
                                                    </p>
                                                    <p>
                                                        <span className="fw-bold">
                                                            Agent affecté:{' '}
                                                        </span>

                                                        {allUser !==
                                                            undefined &&
                                                            allUser !== null &&
                                                            allUser.length !==
                                                                0 &&
                                                            allUser.map(
                                                                (user) => (
                                                                    <>
                                                                        {mission.staff ===
                                                                            user.id && (
                                                                            <span className="text-rose">
                                                                                {
                                                                                    user.first_name
                                                                                }{' '}
                                                                                {
                                                                                    user.last_name
                                                                                }{' '}
                                                                            </span>
                                                                        )}
                                                                    </>
                                                                )
                                                            )}
                                                    </p>

                                                    <p>
                                                        <span className="fw-bold">
                                                            Notes:{' '}
                                                        </span>
                                                        {mission.notes}
                                                    </p>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            <hr />
                            <h3 className="text-center">
                                Création / Edition Mission
                            </h3>
                            <form className="text-center d-flex flex-row flex-wrap justify-content-around align-items-center">
                                <div className="d-flex flex-column m-3">
                                    <small className="my-3">
                                        Personne Désignée
                                    </small>
                                    <select
                                        className="w-fit mx-auto"
                                        onChange={(e) =>
                                            dispatch(
                                                setMissionToEdit({
                                                    ...missionToEdit,
                                                    staff: parseInt(
                                                        e.target.value,
                                                        10
                                                    ),
                                                })
                                            )
                                        }
                                    >
                                        {' '}
                                        {missionToEdit !== null &&
                                        (missionToEdit.staff === 0 ||
                                            missionToEdit.staff === '0') ? (
                                            <option value={0}>
                                                ----------------------
                                            </option>
                                        ) : (
                                            <option value={missionToEdit.staff}>
                                                {allUser !== undefined &&
                                                    allUser !== null &&
                                                    allUser.length !== 0 &&
                                                    allUser.map((user) => (
                                                        <>
                                                            {missionToEdit.staff ===
                                                                user.id && (
                                                                <span className="text-rose">
                                                                    {
                                                                        user.first_name
                                                                    }{' '}
                                                                    {
                                                                        user.last_name
                                                                    }{' '}
                                                                </span>
                                                            )}
                                                        </>
                                                    ))}
                                            </option>
                                        )}
                                        {allUser !== undefined &&
                                            allUser !== null &&
                                            allUser.length !== 0 &&
                                            allUser.map((user) => (
                                                <option
                                                    value={user.id}
                                                    key={user.id}
                                                >
                                                    {user.first_name}{' '}
                                                    {user.last_name}
                                                </option>
                                            ))}
                                    </select>

                                    <small className="my-3">
                                        Date Démarrage
                                    </small>
                                    {missionToEdit !== null &&
                                    (missionToEdit.date_run === null ||
                                        missionToEdit.date_run ===
                                            undefined) ? (
                                        <input
                                            className="w-fit mx-auto"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        date_run:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                            type="datetime-local"
                                            defaultValue={
                                                props.dateCkeckIn + 'T10:00'
                                            }
                                        />
                                    ) : (
                                        <input
                                            className="w-fit mx-auto text-rose"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        date_run:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                            type="datetime-local"
                                            defaultValue={
                                                missionToEdit.date_run
                                            }
                                        />
                                    )}
                                </div>
                                <div className="d-flex flex-column m-3">
                                    <small className="m-3">Code Avant</small>
                                    {missionToEdit !== null &&
                                    (missionToEdit.code_before === undefined ||
                                        missionToEdit.code_before === null) ? (
                                        <input
                                            className="w-fit mx-auto text-danger"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        code_before:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                            type="text"
                                            maxLength={4}
                                            defaultValue={'0000'}
                                        />
                                    ) : (
                                        <input
                                            className="w-fit mx-auto"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        code_before:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                            type="text"
                                            maxLength={4}
                                            placeholder={
                                                missionToEdit.code_before
                                            }
                                        />
                                    )}

                                    <small className="m-3">Code Après</small>
                                    {missionToEdit !== null &&
                                    (missionToEdit.code_after === undefined ||
                                        missionToEdit.code_after === null) ? (
                                        <input
                                            className="w-fit mx-auto text-danger"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        code_after:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                            type="text"
                                            maxLength={4}
                                            defaultValue={'0000'}
                                        />
                                    ) : (
                                        <input
                                            className="w-fit mx-auto"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        code_after:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                            type="text"
                                            maxLength={4}
                                            placeholder={
                                                missionToEdit.code_after
                                            }
                                        />
                                    )}
                                </div>
                                <div className="d-flex flex-column m-3">
                                    <small className="m-3">Notes</small>
                                    {missionToEdit !== null &&
                                    (missionToEdit.notes === undefined ||
                                        missionToEdit.notes === null) ? (
                                        <textarea
                                            className="w-fit mx-auto"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        notes: e.target.value,
                                                    })
                                                )
                                            }
                                            type="text"
                                            rows={10}
                                        ></textarea>
                                    ) : (
                                        <textarea
                                            className="w-fit mx-auto"
                                            onChange={(e) =>
                                                dispatch(
                                                    setMissionToEdit({
                                                        ...missionToEdit,
                                                        notes: e.target.value,
                                                    })
                                                )
                                            }
                                            type="text"
                                            rows={10}
                                            defaultValue={missionToEdit.notes}
                                        ></textarea>
                                    )}
                                </div>
                                <p>
                                    Pour obtenir les codes automatiquement,
                                    merci de passer par les pages "Occupation en
                                    direct ou Liste des réservations" et qu'il y
                                    ait la réservation précédente dans la liste
                                    affichée
                                </p>
                            </form>
                            <hr />

                            <div className="w-fit mx-auto">
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                    className="m-3"
                                >
                                    Annuler
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        {
                                            missionToEdit !== null &&
                                            missionToEdit.id !== undefined &&
                                            missionToEdit.id !== 0 &&
                                            missionToEdit.id !== '0'
                                                ? dispatch(
                                                      putMissionToEdit(
                                                          missionToEdit,
                                                          accessToken,
                                                          props.idReservation
                                                      )
                                                  )
                                                : dispatch(
                                                      createMissionToEdit(
                                                          missionToEdit,
                                                          accessToken,
                                                          props.idReservation
                                                      )
                                                  );
                                        }
                                        handleClose();
                                    }}
                                    className="m-3"
                                >
                                    {missionToEdit !== null &&
                                    missionToEdit.id !== undefined &&
                                    missionToEdit.id !== 0 &&
                                    missionToEdit.id !== '0' ? (
                                        <span>Sauvegarder</span>
                                    ) : (
                                        <span>Créer</span>
                                    )}
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </span>
    );
}
