import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export function useFetchStancerResaDetails(idResaSmoobu, accessToken) {
    const [dataStancerResaDetails, setData] = useState(undefined);
    const [isLoadingStancerResaDetails, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/locations/stancer/get/reservation/${idResaSmoobu}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                setData(data[0]);
                setLoading(false);
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir le détails Stancer de la Réservation",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
    }, [idResaSmoobu]);
    return { dataStancerResaDetails, isLoadingStancerResaDetails };
}

export function useFetchStancerResaVoyageur(
    idResaSmoobu,
    nberPhone,
    codeSMSActive
) {
    const [dataStancerResaVoyageur, setData] = useState(undefined);
    const [isLoadingStancerResaVoyageur, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/locations/stancer/voyageur_get/${idResaSmoobu}/${nberPhone}/${codeSMSActive}`,
                    {
                        method: 'get',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                setData(data);
                setLoading(false);
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir le détails Stancer de la Réservation",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
    }, [idResaSmoobu, nberPhone, codeSMSActive]);
    return { dataStancerResaVoyageur, isLoadingStancerResaVoyageur };
}

export function useFetchStancerResaPackVoyageur(
    idResaSmoobu,
    nberPhone,
    codeSMSActive
) {
    const [dataStancerResaPackVoyageur, setData] = useState(undefined);
    const [isLoadingStancerResaPackVoyageur, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/locations/stancer/voyageur_get_pack/${idResaSmoobu}/${nberPhone}/${codeSMSActive}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                setData(data);
                setLoading(false);
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir le détails Stancer du pack de la Réservation",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
    }, [idResaSmoobu, nberPhone, codeSMSActive]);
    return { dataStancerResaPackVoyageur, isLoadingStancerResaPackVoyageur };
}
