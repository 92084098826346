const initialState = {
    numberSMSNotRead: 0,
    phoneNumber: null,
    openCarnet: false,
    listingReceivedMsg: null,
    listingSendMsgReservation: null,
    listingSendMsg: null,
    settingsMsg: null,
    pingPort: 'warning',
    lastSendMsg: null,
    editSettingsMsg: null,
    dataCarnet: null,
    dataCarnetGoogle: null,
    TimelineSMSAuto: null,
    portableStatus: null,
    portableSms: null,
};

// eslint-disable-next-line default-param-last
export default function User(state = initialState, action) {
    switch (action.type) {
        case 'SetNumberSMSNotRead':
            return {
                ...state,
                numberSMSNotRead: action.numberSMSNotRead,
            };
        case 'setPhoneNumber':
            return {
                ...state,
                phoneNumber: action.phoneNumber,
            };
        case 'setOpenCarnet':
            return {
                ...state,
                openCarnet: action.openCarnet,
            };
        case 'setDataCarnet':
            return {
                ...state,
                dataCarnet: action.dataCarnet,
            };
        case 'setDataCarnetGoogle':
            return {
                ...state,
                dataCarnetGoogle: action.dataCarnetGoogle,
            };
        case 'listingReceivedMsg':
            return {
                ...state,
                listingReceivedMsg: action.listingReceivedMsg,
            };
        case 'listingSendMsg':
            return {
                ...state,
                listingSendMsg: action.listingSendMsg,
            };
        case 'listingSendMsgReservation':
            return {
                ...state,
                listingSendMsgReservation: action.listingSendMsgReservation,
            };
        case 'settingsMsg':
            return {
                ...state,
                settingsMsg: action.settingsMsg,
            };
        case 'pingPort':
            return {
                ...state,
                pingPort: action.pingPort,
            };
        case 'EDIT_SETTINGS_MSG':
            return {
                ...state,
                editSettingsMsg: action.editSettingsMsg,
            };
        case 'TimelineSMSAuto':
            return {
                ...state,
                TimelineSMSAuto: action.TimelineSMSAuto,
            };
        case 'SetStatusPortable':
            return {
                ...state,
                portableStatus: action.portableStatus,
            };
        case 'SetSMSPortable':
            return {
                ...state,
                portableSms: action.portableSms,
            };
        default:
            return state;
    }
}
