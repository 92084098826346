import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, FormGroup, Input } from 'reactstrap';
import { LuBed, LuRadioTower } from 'react-icons/lu';
import { FiSend } from 'react-icons/fi';
import { PiAddressBookLight } from 'react-icons/pi';
import { GiVacuumCleaner } from 'react-icons/gi';
import { BsCart4 } from 'react-icons/bs';

import {
    CreateMsg,
    getDataCarnet,
    getDataCarnetGoogle,
    getSettingsMsg,
    setOpenCarnet,
} from '../../../Store/Actions/messenger.actions';
import CarnetAdressesGestion from './CarnetAdresses.jsx';
import DropDownSelect from './DropDownSelect.jsx';
import MessengerGestion from '../Messenger.jsx';

export default function MessengerCreateMsg() {
    document.title = 'SMS Reçus | Ma Maison Sereine';
    document.description = 'Messagerie de Ma Maison Sereine';

    const dispatch = useDispatch();
    const accessToken = useSelector((store) => store.User.accessToken);
    const phoneNumber = useSelector((store) => store.Messenger.phoneNumber);
    const openCarnet = useSelector((store) => store.Messenger.openCarnet);
    const pingPort = useSelector((store) => store.Messenger.pingPort);
    const dataCarnet = useSelector((store) => store.Messenger.dataCarnet);
    const dataCarnetGoogle = useSelector(
        (store) => store.Messenger.dataCarnetGoogle
    );
    const dataSettingsMsg = useSelector((store) => store.Messenger.settingsMsg);

    const [msgClient, setMsgClient] = useState(undefined);
    const [modeMsg, setModeMsg] = useState('sms');
    const [mailMsg, setMailMsg] = useState(undefined);
    useEffect(() => {
        setTimeout(() => {
            if (dataCarnet === null || dataCarnet === undefined) {
                dispatch(getDataCarnet(accessToken));
            }
            if (dataCarnetGoogle === null || dataCarnetGoogle === undefined) {
                dispatch(getDataCarnetGoogle(accessToken));
            }
        }, 500);
    }, [accessToken]);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getSettingsMsg(accessToken));
        }, 1000);
    }, [accessToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            accessToken !== null &&
                dispatch(getDataCarnet(accessToken)) &&
                dispatch(getDataCarnetGoogle(accessToken));
        }, 20000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
    }, [accessToken]);

    const handleSubmitMsg = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('msg', msgClient);
        formData.append('mode', modeMsg);
        formData.append('phone', phoneNumber);
        formData.append('email', mailMsg);

        dispatch(CreateMsg(accessToken, formData));
    };
    const [listCarnet, setListCarnet] = useState(undefined);
    useEffect(() => {
        const listCarnet = [];
        if (dataCarnet !== null) {
            dataCarnet.map((item) => {
                listCarnet.push(item);
            });
        }
        if (dataCarnetGoogle !== null) {
            dataCarnetGoogle.Google_contact.map((item) => {
                listCarnet.push(item);
            });
        }
        setListCarnet(listCarnet);
    }, [dataCarnet, dataCarnetGoogle]);

    return (
        <main className="d-flex flex-column mx-auto text-primary">
            <MessengerGestion />
            <div className="d-flex flex-row flex-wrap">
                {' '}
                <CarnetAdressesGestion />
                <div className="p-2  border border-2 border-primary rounded col-11 col-md-6 col-xl-5 mx-auto my-4">
                    <h2 className="text-center mb-3">
                        Ecrire via LogicielSer1
                    </h2>

                    <div className="d-flex flex-column p-3 text-center">
                        <form onSubmit={(e) => handleSubmitMsg(e)}>
                            <FormGroup>
                                <Label htmlFor="typeMsgSelect">
                                    Type de Message :{' '}
                                </Label>
                                <br />
                                <Input
                                    id="typeMsgSelect"
                                    name="select"
                                    type="select"
                                    className="w-fit mx-auto"
                                    onChange={(e) => setModeMsg(e.target.value)}
                                >
                                    <option value="sms">
                                        SMS via Portable
                                    </option>
                                    <option value="smsOVH">
                                        SMS via OVH (7ct € !)
                                    </option>
                                    <option value="email">Mail Ser1</option>
                                </Input>
                            </FormGroup>

                            {modeMsg === 'email' && (
                                <FormGroup>
                                    <Label htmlFor="email">
                                        Mail du Destinataire :
                                    </Label>
                                    <br />
                                    <Input
                                        type="email"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        placeholder="adresse@domaine.fr"
                                        id="mail"
                                        name="mail"
                                        onChange={(e) =>
                                            setMailMsg(e.target.value)
                                        }
                                    />
                                </FormGroup>
                            )}
                            {modeMsg !== 'email' && (
                                <FormGroup>
                                    <DropDownSelect
                                        className={'form-group'}
                                        id={'numberCarnet'}
                                        title={''}
                                        dataArray={
                                            listCarnet !== undefined
                                                ? listCarnet
                                                : []
                                        }
                                        keyToValue={'phone'}
                                    />
                                </FormGroup>
                            )}
                            {dataSettingsMsg !== null &&
                                dataSettingsMsg !== undefined && (
                                    <div className="my-3 d-flex flex-row flex-wrap justify-content-around align-items-center">
                                        {' '}
                                        <div>SMS préfaits:</div>
                                        <div
                                            className="btn btn-outline-warning shadow mx-auto"
                                            onClick={(e) => {
                                                document.getElementById(
                                                    'msgText'
                                                ).value =
                                                    dataSettingsMsg.sms_msg_linge;
                                                setMsgClient(
                                                    dataSettingsMsg.sms_msg_linge
                                                );
                                            }}
                                        >
                                            <LuBed />
                                        </div>
                                        <div
                                            className="btn btn-outline-warning shadow mx-auto"
                                            onClick={(e) => {
                                                document.getElementById(
                                                    'msgText'
                                                ).value =
                                                    dataSettingsMsg.sms_msg_menage;
                                                setMsgClient(
                                                    dataSettingsMsg.sms_msg_menage
                                                );
                                            }}
                                        >
                                            <GiVacuumCleaner />
                                        </div>
                                        <div
                                            className="btn btn-outline-warning shadow mx-auto"
                                            onClick={(e) => {
                                                document.getElementById(
                                                    'msgText'
                                                ).value =
                                                    dataSettingsMsg.sms_procedure_commande;
                                                setMsgClient(
                                                    dataSettingsMsg.sms_procedure_commande
                                                );
                                            }}
                                        >
                                            <BsCart4 />
                                        </div>
                                    </div>
                                )}
                            <FormGroup>
                                <Label htmlFor="msgText">Votre Message :</Label>
                                <Input
                                    id="msgText"
                                    name="text"
                                    type="textarea"
                                    className="col-10"
                                    rows={6}
                                    onChange={(e) =>
                                        setMsgClient(e.target.value)
                                    }
                                />
                            </FormGroup>

                            {modeMsg === 'email' && (
                                <p className="text-danger">
                                    Saisir que le contenu du message car
                                    "Bonjour [...] Cordialement Ma Maison
                                    Sereine" est déjà rajouté
                                </p>
                            )}
                            {modeMsg === 'smsOVH' && (
                                <p>
                                    <small>
                                        En cas d'erreur d'envoi OVH, supprimer
                                        les accents circonflexes ou caractères
                                        spéciaux
                                    </small>
                                </p>
                            )}
                            <button
                                className="btn btn-primary shadow mx-auto"
                                type="submit"
                            >
                                <FiSend className="me-2" />
                                Envoyer {modeMsg === 'sms' && 'SMS'}
                                {modeMsg === 'smsOVH' && <>"SMS € "</>}
                                {modeMsg === 'email' && <>"Mail"</>}
                            </button>
                        </form>
                        <small className="text-danger mt-3">
                            ! Les numéros étrangers ne fonctionnent pas !
                        </small>
                    </div>
                </div>
            </div>{' '}
            {modeMsg !== 'email' && (
                <>
                    <p>
                        Nbre Contact Google extrait :{' '}
                        {dataCarnetGoogle !== null &&
                            dataCarnetGoogle.total_items !== undefined &&
                            dataCarnetGoogle.total_items}
                    </p>
                    <div className="mx-auto">
                        <button
                            className="ms-1 btn btn-outline-primary fs-0 p-2 m-auto shadow rounded  d-flex flex-row justify-content-around align-items-center"
                            variant="primary shadow mx-auto"
                            onClick={(e) =>
                                dispatch(setOpenCarnet(!openCarnet))
                            }
                        >
                            <PiAddressBookLight />
                            Annuaire Interne
                        </button>{' '}
                    </div>
                </>
            )}
        </main>
    );
}
