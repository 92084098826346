/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import axios from 'axios';

export const getDataReservationId =
    (idReservation, accessToken) => async (dispatch) =>
        axios
            .get(
                `https://api.mamaisonsereine.fr/api/smoobu/v1/reservation/id/${idReservation}/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 20000,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    credentials: 'same-origin',
                }
            )
            .then((res) =>
                dispatch({
                    type: 'DataReservationId',
                    dataReservationId: res.data,
                })
            )

            .catch(() => {
                toast.error(
                    "Impossible d'obtenir les informations de réservations",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
                return dispatch({
                    type: 'DataReservationId',
                    dataReservationId: null,
                });
            });

export const getMsgSendedReservationId =
    (idReservation, accessToken) => async (dispatch) =>
        axios
            .get(
                `https://api.mamaisonsereine.fr/api/sendmsg/${idReservation}/listing/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 20000,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    credentials: 'same-origin',
                }
            )
            .then((res) =>
                dispatch({
                    type: 'MsgSendedReservationId',
                    MsgSendedReservationId: res.data,
                })
            )

            .catch(() => {
                toast.error(
                    "Impossible d'obtenir les Messages envoyées de la réservations",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
                return dispatch({
                    type: 'MsgSendedReservationId',
                    MsgSendedReservationId: null,
                });
            });

export const setMissionToEdit = (mission) => (dispatch) =>
    dispatch({
        type: 'MISSION_TO_EDIT',
        mission,
    });

export const createMissionToEdit =
    (mission, accessToken, idReservation) => async (dispatch) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            timeout: 20000,
            credentials: 'same-origin',
        };
        if (accessToken !== null) {
            headers = {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            };
        }

        const config = {
            method: 'post',
            url: `https://api.mamaisonsereine.fr/api/missions/v1/${idReservation}/`,
            data: mission,
            headers,
        };

        return axios(config)
            .then((res) => {
                toast.success("C'est créée ", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: 'MISSION_TO_CREATE',
                });
            })

            .catch((error) => {
                toast.error('Impossible de créer... ', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: 'MISSION_TO_CREATE',
                    recipe: null,
                });
            });
    };
export const putMissionToEdit =
    (mission, accessToken, idReservation) => async (dispatch) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            timeout: 20000,
            credentials: 'same-origin',
        };

        if (accessToken !== null) {
            headers = {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            };
        }

        const config = {
            method: 'put',
            url: `https://api.mamaisonsereine.fr/api/missions/v1/${idReservation}/`,
            data: mission,
            headers,
        };

        return axios(config)
            .then((res) => {
                toast.success('MAJ Enregistrée...', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return dispatch({
                    type: 'MISSION_TO_PUT',
                });
            })

            .catch((error) => {
                toast.error('Impossible de modifier les détails', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: 'MISSION_TO_PUT',
                });
            });
    };

export const deleteMissionToEdit =
    (accessToken, idReservation, idMission) => async (dispatch) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            timeout: 20000,
            credentials: 'same-origin',
        };

        if (accessToken !== null) {
            headers = {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            };
        }

        const config = {
            method: 'delete',
            url: `https://api.mamaisonsereine.fr/api/missions/v1/${idReservation}/${idMission}/`,
            headers,
        };

        return axios(config)
            .then((res) => {
                toast.success('Suppression OK...', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return dispatch({
                    type: 'MISSION_TO_DELETE',
                });
            })

            .catch((error) => {
                toast.error('Impossible de supprimer', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: 'MISSION_TO_DELETE',
                });
            });
    };

export const getListingMissions = (accessToken) => async (dispatch) =>
    axios
        .get('https://api.mamaisonsereine.fr/api/missions/v1/listing/', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            timeout: 20000,
            'Content-Type': 'application/x-www-form-urlencoded',
            credentials: 'same-origin',
        })
        .then((res) =>
            dispatch({
                type: 'ListMissions',
                listMissions: res.data.missions,
                expiredMissions: res.data.missions.reverse(),
            })
        )

        .catch(() =>
            dispatch({
                type: 'ListMissions',
                listMissions: null,
                expiredMissions: null,
            })
        );
