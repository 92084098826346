import { GiVacuumCleaner } from 'react-icons/gi';
import { MdPets } from 'react-icons/md';
import { FaExclamation, FaPlus, FaBed, FaSwimmer } from 'react-icons/fa';
import { Badge } from 'reactstrap';

export default function WidgetIconRese(props) {
    return (
        <span>
            {props.element.notice.includes('inge') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="purple"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaBed />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('Pack') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="purple"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>

                    <span className="fs-4">
                        <FaBed />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('pack') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="purple"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaBed />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('accuzi') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="warning"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <i className="fa-solid fa-hot-tub-person fs-4"></i>
                    <span className="fs-4">
                        <FaSwimmer />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('acuzi') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="warning"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaSwimmer />
                    </span>{' '}
                </Badge>
            )}
            {props.element.notice.includes('acuzzi') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="warning"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaSwimmer />
                    </span>{' '}
                </Badge>
            )}
            {props.element.notice.includes('accuzzi') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="warning"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaSwimmer />
                    </span>{' '}
                </Badge>
            )}
            {props.element.notice.includes('SPA') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="warning"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaSwimmer />
                    </span>{' '}
                </Badge>
            )}
            {props.element.notice.includes('spa') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="warning"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaSwimmer />
                    </span>{' '}
                </Badge>
            )}
            {props.element.notice.includes('Spa') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="warning"
                    pill
                >
                    <span className="fs-4">
                        <FaExclamation />
                    </span>
                    <span className="fs-4">
                        <FaSwimmer />
                    </span>{' '}
                </Badge>
            )}
            {props.element.notice.includes('nage') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="success"
                    pill
                >
                    <span className="fs-4">
                        <FaPlus />
                    </span>{' '}
                    <span className="fs-4">
                        <GiVacuumCleaner />
                    </span>{' '}
                </Badge>
            )}
            {props.element.notice.includes('chien') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="danger"
                    pill
                >
                    <span className="fs-4">
                        <MdPets />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('CHIEN') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="danger"
                    pill
                >
                    <span className="fs-4">
                        <MdPets />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('CHAT') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="danger"
                    pill
                >
                    <span className="fs-4">
                        <MdPets />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('chat') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="danger"
                    pill
                >
                    <span className="fs-4">
                        <MdPets />
                    </span>
                </Badge>
            )}
            {props.element.notice.includes('nimal') && (
                <Badge
                    className="ms-1 text-center d-inlineblock"
                    color="danger"
                    pill
                >
                    <span className="fs-4">
                        <MdPets />
                    </span>
                </Badge>
            )}
        </span>
    );
}
