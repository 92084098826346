const initialState = {
    settingMode: false,
    editMode: false,
    dateSelected: null,
    selectedAppart: null,
    voyageSmoobu: null,
    tarifsConciergerie: null,
    settingsLocations: null,
};

// eslint-disable-next-line default-param-last
export default function Config(state = initialState, action) {
    switch (action.type) {
        case 'settingMode':
            return {
                ...state,
                settingMode: action.settingMode,
            };
        case 'editMode':
            return {
                ...state,
                editMode: action.editMode,
            };
        case 'dateSelected':
            return {
                ...state,
                dateSelected: action.dateSelected,
            };
        case 'selectedAppart':
            return {
                ...state,
                selectedAppart: action.selectedAppart,
            };
        case 'voyageSmoobu':
            return {
                ...state,
                voyageSmoobu: action.voyageSmoobu,
            };
        case 'tarifsConciergerie':
            return {
                ...state,
                tarifsConciergerie: action.tarifsConciergerie,
            };
        case 'settingsLocations':
            return {
                ...state,
                settingsLocations: action.settingsLocations,
            };
        default:
            return state;
    }
}
