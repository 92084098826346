import { useEffect } from 'react';
import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import WidgetCGV from '../../../components/Widget/CGV.jsx';

export default function CGV() {
    document.title =
        'Conditions Générales de Ventes CGV & Mentions Legales| Ma Maison Sereine';
    document.description =
        'Présentation des Conditions Générales de Ventes & Mentions Legales de Ma Maison Sereine';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main className=" bg-white mt-5">
            <WidgetCGV />
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
