const initialState = {
    accessToken: null,
    refreshToken: null,
    authUser: null,
    grades: null,
    allUsers: null,
    editUser: null,
};

// eslint-disable-next-line default-param-last
export default function User(state = initialState, action) {
    switch (action.type) {
        case 'SEND_LOGIN_ACCESS':
            return {
                ...state,
                user: action.user,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
            };
        case 'AUTH_USER':
            return {
                ...state,
                authUser: action.authUser,
            };
        case 'GET_GRADES':
            return {
                ...state,
                grades: action.grades,
            };
        case 'GET_ALL_USERS':
            return {
                ...state,
                allUsers: action.allUsers,
            };
        case 'LOGOUT':
            return {
                ...state,
                authUser: null,
                accessToken: null,
                refreshToken: null,
            };
        case 'EDIT_USER':
            return {
                ...state,
                editUser: action.editUser,
            };
        case 'ERASE_EDIT_USER':
            return {
                ...state,
                editUser: null,
            };
        default:
            return state;
    }
}
