import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

export default function WidgetCGV() {
    const translate = useSelector((store) => store.Template.language);
    return (
        <>
            {translate === 'French' && (
                <>
                    <h1 className="fs-1 text-center">
                        Conditions Générales de Ventes & Mentions Legales <br />
                        à compter du 07 Avril 2023
                    </h1>
                    <p className="text-center fw-bold">
                        Les présentes mentions légales sont soumises à la loi
                        française.
                    </p>
                </>
            )}
            {translate === 'English' && (
                <>
                    <h1 className="fs-1 text-center">
                        General Conditions of Sale & Legal Mentions <br />
                        at from April 07, 2023
                    </h1>
                    <p className="text-center fw-bold">
                        These legal notices are subject to French law.
                    </p>
                </>
            )}
            <div className="bg-primary hr-h w-50 mx-auto"></div>
            <div className="m-4 p-4">
                {translate === 'French' && (
                    <section
                        id="sommaire"
                        className="mx-auto col-11 col-md-9 col-lg-6 "
                    >
                        <h2 className="text-center fs-1">Sommaire</h2>
                        <ul>
                            <li>
                                <a href="#preambule">Préambule</a>
                            </li>
                            <li>
                                <a href="#art1">
                                    Article 1 : Application des conditions
                                    générales de vente.
                                </a>
                            </li>
                            <li>
                                <a href="#art2">Article 2 : Prestations</a>
                            </li>
                            <li>
                                <a href="#art3">
                                    Article 3 : Prestations et prix
                                </a>
                            </li>
                            <li>
                                <a href="#art4">Article 4 : Devis</a>
                            </li>
                            <li>
                                <a href="#art5">Article 5 : Commande</a>
                            </li>
                            <li className="ms-3">
                                <a href="#art5.1">
                                    Article 5.1 : Confirmation de la commande
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art5.2">
                                    Article 5.2 : Acceptation de la prestation
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art5.2">
                                    Article 5.3 : Modification de la prestation,
                                    achat, et droit de rétractation.
                                </a>
                            </li>
                            <li>
                                <a href="#art6">Article 6 :Paiement</a>
                            </li>
                            <li className="ms-3">
                                <a href="#art6.1">
                                    Article 6.1 : Modalités de paiement
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art6.2">
                                    Article 6.2 : Retard et défaut de paiement
                                </a>
                            </li>
                            <li>
                                <a href="#art7">Article 7 : Livraison</a>
                            </li>
                            <li className="ms-3">
                                <a href="#art7.1">
                                    Article 7.1 : Les délais et conditions de
                                    livraison
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art7.2">
                                    Article 7.2 : Les prestations et achats
                                    commandés
                                </a>
                            </li>
                            <li>
                                <a href="#art8">Article 8 : Responsabilités</a>
                            </li>
                            <li>
                                <a href="#art9">
                                    Article 9 : Garanties Généralités
                                </a>
                            </li>
                            <li>
                                <a href="#art10">
                                    Article 10 : Garantie légale de conformité
                                </a>
                            </li>
                            <li>
                                <a href="#art11">
                                    Article 11 : Traitement des données
                                    personnelles
                                </a>
                            </li>
                            <li>
                                <a href="#art12">
                                    Article 12 : Partage des données collectées
                                </a>
                            </li>
                            <li>
                                <a href="#art13">
                                    Article 13 : Protection des données
                                </a>
                            </li>
                            <li>
                                <a href="#art14">Article 14 : Cookies</a>
                            </li>
                            <li>
                                <a href="#art15">Article 15 : Modifications</a>
                            </li>
                            <li>
                                <a href="#art16">
                                    Article 16 : Propriété intellectuelle
                                </a>
                            </li>
                            <li>
                                <a href="#art17">
                                    Article 17 : Clause attributive de
                                    compétence
                                </a>
                            </li>
                            <li>
                                <a href="#art18">
                                    Article 18 : Prêt de matériel et blanchiment
                                    et prêt de linge.
                                </a>
                            </li>
                            <li>
                                <a href="#art19">
                                    Article 19 : Information précontractuelle
                                </a>
                            </li>
                            <li>
                                <a href="#art20">
                                    Article 20 : Loi applicable au contrat{' '}
                                </a>
                            </li>
                            <li>
                                <a href="#art21">Article 21 : Litige</a>
                            </li>
                            <li>
                                <a href="#art22">
                                    Article 22 : Coordonnées du conciliateur
                                </a>
                            </li>
                        </ul>
                    </section>
                )}
                {translate === 'English' && (
                    <section
                        id="sommaire"
                        className="mx-auto col-11 col-md-9 col-lg-6 "
                    >
                        <h2 className="text-center fs-1">Summary</h2>
                        <ul>
                            <li>
                                <a href="#preamble">Preamble</a>
                            </li>
                            <li>
                                <a href="#art1">
                                    Article 1: Application of the general
                                    conditions of sale.
                                </a>
                            </li>
                            <li>
                                <a href="#art2">Article 2: Services</a>
                            </li>
                            <li>
                                <a href="#art3">
                                    Article 3: Services and prices
                                </a>
                            </li>
                            <li>
                                <a href="#art4">Article 4: Quote</a>
                            </li>
                            <li>
                                <a href="#art5">Article 5: Order</a>
                            </li>
                            <li className="ms-3">
                                <a href="#art5.1">
                                    Article 5.1: Confirmation of the order
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art5.2">
                                    Article 5.2: Acceptance of the service
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art5.2">
                                    Article 5.3: Modification of the service,
                                    purchase, and right of withdrawal.
                                </a>
                            </li>
                            <li>
                                <a href="#art6">Article 6: Payment</a>
                            </li>
                            <li className="ms-3">
                                <a href="#art6.1">
                                    Article 6.1: Methods of payment
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art6.2">
                                    Article 6.2: Late and non-payment
                                </a>
                            </li>
                            <li>
                                <a href="#art7">Article 7: Delivery</a>
                            </li>
                            <li className="ms-3">
                                <a href="#art7.1">
                                    Article 7.1: The deadlines and conditions of
                                    delivery
                                </a>
                            </li>
                            <li className="ms-3">
                                <a href="#art7.2">
                                    Article 7.2: Services and purchases orders
                                </a>
                            </li>
                            <li>
                                <a href="#art8">Article 8: Responsibilities</a>
                            </li>
                            <li>
                                <a href="#art9">
                                    Article 9: Warranties General
                                </a>
                            </li>
                            <li>
                                <a href="#art10">
                                    Article 10: Legal guarantee of conformity
                                </a>
                            </li>
                            <li>
                                <a href="#art11">
                                    Article 11: Processing of personal data
                                </a>
                            </li>
                            <li>
                                <a href="#art12">
                                    Article 12: Sharing of collected data
                                </a>
                            </li>
                            <li>
                                <a href="#art13">Article 13: Data protection</a>
                            </li>
                            <li>
                                <a href="#art14">Article 14: Cookies</a>
                            </li>
                            <li>
                                <a href="#art15">Article 15: Amendments</a>
                            </li>
                            <li>
                                <a href="#art16">
                                    Article 16: Intellectual property
                                </a>
                            </li>
                            <li>
                                <a href="#art17">
                                    Article 17: Jurisdiction clause
                                </a>
                            </li>
                            <li>
                                <a href="#art18">
                                    Article 18: Loan of equipment and laundering
                                    and linen loan.
                                </a>
                            </li>
                            <li>
                                <a href="#art19">
                                    Article 19: Pre-contractual information
                                </a>
                            </li>
                            <li>
                                <a href="#art20">
                                    Article 20: Law applicable to the contract{' '}
                                </a>
                            </li>
                            <li>
                                <a href="#art21">Article 21: Dispute</a>
                            </li>
                            <li>
                                <a href="#art22">
                                    Article 22: Contact details of the
                                    conciliator
                                </a>
                            </li>
                        </ul>
                    </section>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <section className="pt-5" id="preambule">
                        <h2 className="fs-1">Préambule</h2>
                        <p>
                            L’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial « SER1 » et l’enseigne : « Ma
                            maison sereine» dont le siège social est situé : 77
                            boulevard de l’océan 44770 La Plaine sur Mer,
                            immatriculées au Répertoire des métiers de Nantes,
                            sous le numéro Siret : 878 301 837 00018
                        </p>
                        <p>
                            Pour les prestations ne nécessitant pas de
                            qualification particulière, l’exploitant individuel
                            Hennion Fabien exerçant sous le nom commercial «
                            SER1 » et l’enseigne : « Ma maison sereine » est le
                            prestataire du service.
                        </p>
                        <p>
                            En vertu de l'article 6 de la loi n° 2004-575 du 21
                            juin 2004 pour la confiance dans l'économie
                            numérique, il est précisé aux utilisateurs du site
                            internet www.mamaisonsereine.fr l'identité des
                            différents intervenants dans le cadre de sa
                            réalisation et de son suivi : Propriétaire (statut :
                            particulier) - Responsable publication & Délégué à
                            la protection des données: Hennion Fabien -
                            contact@mamaisonsereine.fr - Le responsable
                            publication est une personne physique.
                        </p>
                        <p>
                            A compter du 07 Avril 2023, le présent site est
                            hébergé par l'Entreprise Individuelle TISSIER Lionel
                            sous le nom commercial "LiTiBoost". "LiTiBoost" est
                            une marque déposée auprès de l'INPI.
                            <br />
                            Le serveur est situé en France chez : <br />
                            OVH
                            <br />
                            SAS au capital de 10 174 560 €<br />
                            RCS Lille Métropole 424 761 419 00045
                            <br />
                            Code APE 2620Z
                            <br />
                            N° TVA : FR 22 424 761 419
                            <br />
                            Siège social : 2 rue Kellermann - 59100 Roubaix -
                            France
                            <br />
                            OVH SAS est une filiale de la société OVH Groupe SA,
                            société immatriculée au RCS de Lille sous le numéro
                            537 407 926 sise 2, rue Kellermann, 59100 Roubaix.
                            <br />
                        </p>
                    </section>
                )}
                {translate === 'English' && (
                    <section className="pt-5" id="preamble">
                        <h2 className="fs-1">Preamble</h2>
                        <p>
                            The sole proprietor Hennion Fabien operating under
                            the trade name “SER1” and the sign: “Ma maison
                            serene” whose head office is located: 77 boulevard
                            of the ocean 44770 La Plaine sur Mer, registered in
                            Directory of trades in Nantes, under the Siret
                            number: 878 301 837 00018
                        </p>
                        <p>
                            For services that do not require qualification
                            particular, the individual operator Hennion Fabien
                            operating under the trade name "SER1" and the sign:
                            "My serene house" is the service provider.
                        </p>
                        <p>
                            Pursuant to article 6 of law n° 2004-575 of June 21
                            2004 for confidence in the digital economy, it is
                            specified to users of the website
                            www.mamaisonsereine.fr the identity of the different
                            stakeholders in the context of its realization and
                            its monitoring: Owner (status: individual) -
                            Publication Manager & Data Protection Officer data:
                            Hennion Fabien - contact@mamaisonsereine.fr - The
                            publication manager is a natural person.
                        </p>
                        <p>
                            As of April 7, 2023, this site is hosted by the
                            Individual Company TISSIER Lionel under the trade
                            name “LiTiBoost”. “LiTiBoost” is a trademark
                            registered with the INPI.
                            <br />
                            The server is located in France at: <br />
                            OVH
                            <br />
                            SAS with capital of €10,174,560
                            <br />
                            RCS Lille Métropole 424 761 419 00045
                            <br />
                            APE code 2620Z
                            <br />
                            VAT number: FR 22 424 761 419
                            <br />
                            Head office: 2 rue Kellermann - 59100 Roubaix -
                            France
                            <br />
                            OVH SAS is a subsidiary of OVH Groupe SA, company
                            registered with the Lille RCS under number 537 407
                            926 located at 2, rue Kellermann, 59100 Roubaix.
                            <br />
                        </p>
                    </section>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <section className="pt-5" id="art1">
                        <h2 className="fs-1">
                            Article 1: Application des conditions générales de
                            vente.
                        </h2>
                        <p>
                            Les présentes Conditions Générales de Vente sont
                            accessibles sur les sites internet :
                            www.mamaisonsereine.fr et sont systématiquement
                            transmises à chaque client s’il en fait la demande.
                        </p>
                        <p>
                            En conséquence toute commande effectuée par le
                            client, toute commande de services, produits,
                            demande de devis, mise en place de contrat
                            d'accompagnement qu’il soit un particulier ou une
                            entreprise emporte son adhésion, sans restriction,
                            ni réserve aux présentes Conditions Générales de
                            Vente, lesquelles prévalent sur tout autre document
                            émis par l’exploitant individuel Hennion Fabien
                            exerçant sous le nom commercial « SER1 » et
                            l’enseigne : « Ma maison sereine »
                        </p>
                        <p>
                            Toute autre condition imposée par le client sera
                            irrecevable aux conditions générales de vente, quel
                            que soit le moment où elle aura pu être portée à la
                            connaissance de l’exploitant individuel Hennion
                            Fabien exerçant sous le nom commercial «SER1» et
                            l’enseigne : « Ma maison sereine »
                        </p>
                        <p>
                            Si l’exploitant individuel Hennion Fabien exerçant
                            sous le nom commercial « SER1 » et l’enseigne : « Ma
                            maison sereine » ne respecte pas l’une des
                            quelconques règles présentes aux Conditions
                            Générales de Vente, cette tolérance ne peut être
                            interprétée comme valant renonciation aux dites
                            conditions.
                        </p>
                        <p>
                            Le client, qu’il soit un particulier ou une
                            entreprise, déclare avoir pris connaissance et
                            accepté les présentes Conditions Générales de Vente
                            avant de passer toute commande de services, produit,
                            demande de devis, mise en place de contrat
                            d'accompagnement. Les présentes Conditions Générales
                            de Vente sont valables à compter du 1er octobre 2019
                            et s’appliquent jusqu’à ce qu’une nouvelle version
                            les remplace.
                        </p>
                    </section>
                )}
                {translate === 'English' && (
                    <section className="pt-5" id="art1">
                        <h2 className="fs-1">
                            Article 1: Application of the general conditions of
                            sale.
                        </h2>
                        <p>
                            These General Conditions of Sale are available on
                            the websites: www.mamaisonsereine.fr and are
                            systematically transmitted to each customer if he so
                            requests.
                        </p>
                        <p>
                            Consequently, any order placed by the customer, any
                            order for services, products, quote request,
                            implementation of a support contract whether it is a
                            individual or a company wins its membership, without
                            limitation or qualification to these Terms Terms of
                            Sale, which prevail over any other document issued
                            by the individual operator Hennion Fabien operating
                            under the trade name "SER1" and the sign: “My Serene
                            Home”
                        </p>
                        <p>
                            Any other condition imposed by the customer will be
                            inadmissible to the general conditions of sale,
                            whatever either the moment when it could have been
                            brought to the knowledge of the individual operator
                            Hennion Fabien operating under the trade name "SER1"
                            and the sign: " My serene home »
                        </p>
                        <p>
                            If the individual operator Hennion Fabien operating
                            under the commercial name “SER1” and the sign: “Ma
                            maison serene” does not respect any of the rules
                            present in the General Conditions of Sale, this
                            tolerance cannot be interpreted as being worth
                            waiver of these conditions.
                        </p>
                        <p>
                            The customer, whether an individual or a company,
                            declares to have read and accepted the present
                            General Conditions of Sale before passing any
                            ordering services, product, quote request,
                            commissioning place of support contract. The present
                            General Conditions of Sale are valid from 1 October
                            2019 and apply until a new version replaces them.
                        </p>
                    </section>
                )}
                {translate === 'French' && (
                    <section className="pt-5" id="art2">
                        <h2 className="fs-1">Article 2 : Prestations</h2>
                        <p>
                            Les services et ventes de produits proposés par
                            l’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial « SER1 » et l’enseigne : « Ma
                            maison sereine » se produisent normalement du Jeudi
                            au Lundi de 12h00 à 22h00. Néanmoins sur accord par
                            les parties du devis préalablement instauré,
                            l’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial « SER1 » et l’enseigne : « Ma
                            maison sereine » peut offrir d’autres créneaux de
                            disponibilité.
                        </p>
                        <p>
                            L’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial « SER1 » et l’enseigne « Ma maison
                            sereine » propose des prestations de ménage,
                            gardiennage, accueil, départ et de gouvernant
                            d’hôtel ainsi que la vente de produits. Dans
                            certaines situations une tarification particulière
                            pourra être appliquée selon le service demandé ainsi
                            que sa quantité. Pour toute demande complémentaire,
                            une étude sera produite et un devis sera proposé au
                            client.
                        </p>
                    </section>
                )}
                {translate === 'English' && (
                    <section className="pt-5" id="art2">
                        <h2 className="fs-1">Article 2: Services</h2>
                        <p>
                            The services and sales of products offered by the
                            sole proprietor Hennion Fabien operating under the
                            trade name “SER1” and the sign: “Ma maison serene"
                            normally occur from Thursday to Monday from 12:00
                            p.m. to 10:00 p.m. However, upon agreement by the
                            parties of the quote previously established, the
                            individual operator Hennion Fabien operating under
                            the trade name “SER1” and the sign: “My serene
                            house” can offer other availability slots.
                        </p>
                        <p>
                            The sole proprietor Hennion Fabien operating under
                            the trade name “SER1” and the sign “Ma maison
                            serene" offers cleaning services, guarding,
                            reception, departure and housekeeper as well as the
                            sale of products. In certain situations special
                            pricing may be applied according to the service
                            requested as well as its quantity. For any
                            additional request, a study will be produced and an
                            estimate will be offered to the customer.
                        </p>
                    </section>
                )}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art3">
                            <h2 className="fs-1">
                                Article 3 : Prestations et prix
                            </h2>
                            <p>
                                Les services et produits faisant l’objet des
                                CGV/CGU sont ceux qui figurent sur le site et la
                                brochure et qui sont proposés directement par
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » ou ses prestataires
                                partenaires.
                            </p>
                            <p>
                                Les services proposés et produits sont décrits
                                au sein du site et brochures avec toutes leurs
                                caractéristiques essentielles. L’exploitant
                                individuel Hennion Fabien exerçant sous le nom
                                commercial « SER1 » et l’enseigne : « Ma maison
                                sereine » ne peut être tenu responsable de
                                l’impossibilité d’effectuer une prestation ou
                                vente de produits quand le client n’est pas
                                éligible aux offres.
                            </p>
                            <p>
                                Les offres indiquées sur le site et brochures
                                sous le nom d’enseigne ma maison sereine
                                (www.mamaisonsereine.fr) concerne uniquement les
                                particuliers.
                            </p>
                            <p>
                                Lorsqu’un utilisateur souhaite obtenir une
                                prestation de service, produit, vendue par
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » à travers le site et
                                brochure, le prix indiqué de la prestation ou
                                produit correspond au prix en euros et tient
                                compte des réductions applicables et en vigueur
                                le jour de la commande.
                            </p>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » se réserve la possibilité
                                de modifier à tout moment ses tarifs.
                            </p>
                            <p>
                                Pour les prestations et produits dont le prix
                                n’est pas défini avec exactitude un devis ou
                                contrat sera proposé aux clients. En aucun cas
                                un client ne pourra exiger l’application de
                                réductions n’étant plus en vigueur le jour de la
                                commande.
                            </p>
                        </section>
                        <section className="pt-5" id="art4">
                            <h2 className="fs-1">Article 4 : Devis</h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » remet gratuitement un
                                devis au client qui en fait la demande sous
                                condition de créneaux et de temps disponibles.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art3">
                            <h2 className="fs-1">
                                Article 3: Services and prices
                            </h2>
                            <p>
                                The services and products subject to CGV/CGU are
                                those which appear on the site and the brochure
                                and which are offered directly by the sole
                                proprietor Hennion Fabien exercising under the
                                commercial name “SER1” and the sign: "My serene
                                house" or its service providers partners.
                            </p>
                            <p>
                                The services offered and products are described
                                within the site and brochures with all their
                                essential characteristics. The operator
                                individual Hennion Fabien practicing under the
                                name commercial “SER1” and the sign: “Ma maison
                                serene" cannot be held responsible for the
                                impossibility of performing a service or selling
                                products when the customer is not eligible for
                                offers.
                            </p>
                            <p>
                                The offers indicated on the site and brochures
                                under the sign name my serene house
                                (www.mamaisonsereine.fr) concerns only the
                                individuals.
                            </p>
                            <p>
                                When a user wishes to obtain a service, product,
                                sold by the sole proprietor Hennion Fabien
                                exercising under the commercial name “SER1” and
                                the sign: “My serene home” through the site and
                                brochure, the indicated price of the service or
                                product corresponds to the price in euros and
                                holds account of the applicable and current
                                reductions the day of the order.
                            </p>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene house" reserves the possibility to
                                modify its prices at any time.
                            </p>
                            <p>
                                For services and products whose price is not
                                accurately defined a quote or contract will be
                                offered to customers. Never a client may not
                                demand the application of reductions no longer
                                in effect on the day of the order.
                            </p>
                        </section>
                        <section className="pt-5" id="art4">
                            <h2 className="fs-1">Article 4: Quotation</h2>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene house" provides a free quote to the
                                customer who requests it under condition of
                                slots and time available.
                            </p>
                        </section>
                    </>
                )}{' '}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art5">
                            <h2 className="fs-1">Article 5 : Commande</h2>
                            <p>
                                Toute commande de prestations, de produits, ou
                                de demande de renseignement peut se faire par :
                            </p>
                            <ul>
                                <li>E-mail: contact@mamaisonsereine.fr</li>
                                <li>Téléphone : 07 66 79 99 50</li>
                                <li>
                                    Courrier : SER1 Hennion Fabien 77 Boulevard
                                    de l'Ocean 44770 La Plaine sur Mer.
                                </li>
                            </ul>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne:
                                «Ma maison sereine » se réserve le droit de
                                refuser toute commande de produits ou de
                                services qu’il jugerait dangereuse, illicite ou
                                contraire à l’ordre public et aux bonnes mœurs
                                de la société.
                            </p>
                            <p>
                                Le bénéfice de la commande est personnel au
                                bénéficiaire et ne peut être transféré au
                                bénéfice d’un tiers sans l’accord préalable
                                express de l’exploitant individuel Hennion
                                Fabien exerçant sous le nom commercial « SER1 »
                                et l’enseigne : « Ma maison sereine »
                            </p>
                            <p>
                                Pour toute commande, le client devra communiquer
                                son adresse personnelle, adresse électronique et
                                numéro de téléphone.
                            </p>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » s’engage à répondre à la
                                demande du client dans les meilleurs délais, par
                                courrier électronique ou téléphone.
                            </p>
                        </section>
                        <section className="pt-5" id="art5.1">
                            <h2 className="fs-1">
                                Article 5.1 : Confirmation de la commande
                            </h2>
                            <p>
                                L’acceptation de la commande au client par
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » s’effectuera par l’envoi
                                d’un devis ou d’un contrat d’accompagnement.
                            </p>
                        </section>
                        <section className="pt-5" id="art5.2">
                            <h2 className="fs-1">
                                Article 5.2 : Acceptation de la prestation
                            </h2>
                            <p>
                                Le client devra impérativement confirmer
                                l’acceptation de sa commande de prestation ou de
                                produit en acceptant le devis par écrit par
                                courrier électronique, sous 48 heures, et
                                s’acquitter du règlement intégral de la
                                prestation, service, produit avant que
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » ne démarre la réalisation
                                de la prestation ou la mise à disposition du
                                produit commandé. Suite à l’acceptation écrite
                                du devis par le client, toute prestation et
                                achat de produit sera due.
                            </p>
                        </section>
                        <section className="pt-5" id="art5.3">
                            <h2 className="fs-1">
                                Article 5.3 : Modification de la prestation,
                                achat, et droit de rétractation.
                            </h2>
                            <p>
                                Pour les particuliers : toute modification,
                                annulation, rétractation de commande faite par
                                le client devra intervenir au minimum 14 jours
                                calendaires avant la date de réalisation prévue,
                                pour être prise en considération par
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine ». Par conséquent toute
                                modification ou annulation de commande par le
                                client est soumise à l’acceptation de
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER » et l’enseigne : «
                                Ma maison sereine », si refus le prix de la
                                commande resterait dû, les sommes déjà versées
                                par le client ne lui seraient en aucun cas
                                rendues.
                            </p>
                            <p>
                                En cas d’exercice du droit de rétractation dans
                                le délai susvisé, sont remboursés : le prix de
                                la prestation, le remboursement sera effectué
                                dans un délai de 14 jours à réception de la
                                demande de rétractation par courrier recommandé
                                via le formulaire de rétractation disponible sur
                                simple demande ou fournie avec le devis pour les
                                particuliers.
                            </p>
                            <p className="fw-bold">
                                Dans le cas de la vente de produit lors d’une
                                demande de droit de rétractation :
                            </p>
                            <p>
                                Le client devra, sans retard excessif et, en
                                tout état de cause, au plus tard quatorze (14)
                                jours après communication de sa décision de
                                rétractation, renvoyer le produit à SER1 Hennion
                                Fabien, 77 boulevard de l’océan 44770 La
                                Plaine-sur-Mer. Le client devra prendre en
                                charge les frais directs de renvoi du produit.
                                Celui-ci doit être retourné suivant les
                                consignes du vendeur et doit comporter notamment
                                tous les accessoires livrés. Le produit doit
                                être non ouvert et propre à la revente.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art5">
                            <h2 className="fs-1">Article 5: Order</h2>
                            <p>
                                Any order for services, products, or request for
                                information can be made by:
                            </p>
                            <ul>
                                <li>E-mail: contact@mamaisonsereine.fr</li>
                                <li>Telephone: 07 66 79 99 50</li>
                                <li>
                                    Mail: SER1 Hennion Fabien 77 Boulevard of
                                    the Ocean 44770 La Plaine sur Mer.
                                </li>
                            </ul>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene house" reserves the right to refuse
                                any order for products or services that he deems
                                dangerous, illegal or contrary to public order
                                and good morals of the society.
                            </p>
                            <p>
                                The benefit of the order is personal to the
                                beneficiary and cannot be transferred to the
                                benefit of a third party without the prior
                                agreement express from the individual operator
                                Hennion Fabien practicing under the trade name
                                “SER1” and the sign: "My serene house"
                            </p>
                            <p>
                                For any order, the customer must communicate his
                                personal address, e-mail address and phone
                                number.
                            </p>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                “My serene house” undertakes to respond to the
                                customer's request as soon as possible, by email
                                or telephone.
                            </p>
                        </section>
                        <section className="pt-5" id="art5.1">
                            <h2 className="fs-1">
                                Article 5.1: Confirmation of the order
                            </h2>
                            <p>
                                The acceptance of the order to the customer by
                                the sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                “My serene house” will be done by sending an
                                estimate or a support contract.
                            </p>
                        </section>
                        <section className="pt-5" id="art5.2">
                            <h2 className="fs-1">
                                Article 5.2: Acceptance of the service
                            </h2>
                            <p>
                                The customer must imperatively confirm the
                                acceptance of his order for a service or product
                                by accepting the quotation in writing by email,
                                within 48 hours, and pay the full payment of the
                                delivery, service, product before the sole
                                proprietor Hennion Fabien exercising under the
                                commercial name “SER1” and the sign: "My serene
                                house" does not start the production of the
                                service or the provision of the product ordered.
                                Following written acceptance of the quote by the
                                customer, any service and purchase of product
                                will be due.
                            </p>
                        </section>
                        <section className="pt-5" id="art5.3">
                            <h2 className="fs-1">
                                Article 5.3: Modification of the service,
                                purchase, and right of withdrawal.
                            </h2>
                            <p>
                                For individuals: any modification, cancellation,
                                withdrawal of order made by the customer must
                                intervene at least 14 days calendar before the
                                scheduled completion date, to be considered by
                                the sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                “My serene home”. Therefore all modification or
                                cancellation of order by the customer is subject
                                to the acceptance of the sole proprietor Hennion
                                Fabien exercising under the commercial name
                                “SER” and the sign: “ My serene house", if
                                refused the price of the order would remain due,
                                the sums already paid by the client would in no
                                case be returned.
                            </p>
                            <p>
                                In case of exercise of the right of withdrawal
                                in the aforementioned period, are reimbursed:
                                the price of the service, the refund will be
                                made within 14 days of receipt of the request
                                for withdrawal by registered mail via the
                                withdrawal form available on simple request or
                                provided with the estimate for the individuals.
                            </p>
                            <p className="fw-bold">
                                In the case of the sale of product during a
                                request for the right of withdrawal:
                            </p>
                            <p>
                                The customer shall, without undue delay and, in
                                in any event, no later than fourteen (14) days
                                after communication of its decision to
                                withdrawal, return the product to SER1 Hennion
                                Fabien, 77 ocean boulevard 44770 La
                                Plain-sur-Mer. The customer must take charges
                                the direct cost of returning the product. This
                                must be returned according to the seller's
                                instructions and must include in particular all
                                accessories supplied. The product must be
                                unopened and fit for resale.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art6">
                            <h2 className="fs-1">Article 6 : Le Paiement</h2>
                        </section>
                        <section className="pt-5" id="art6.1">
                            <h2 className="fs-1">
                                Article 6.1 : Modalités de paiement
                            </h2>
                            <p>
                                Les services et produits seront facturés sur la
                                base des tarifs précisément indiqués, en euros,
                                sur le devis ou lors de la confirmation de la
                                commande par l’exploitant individuel Hennion
                                Fabien exerçant sous le nom commercial « SER1 »
                                et l’enseigne : « Ma maison sereine » Les
                                prestations et produits devront être réglées
                                intégralement, en espèces, par carte bancaire
                                (majoration possible) ou par virement bancaire
                                avant leur réalisation / livraison ou dans le
                                cadre d’un contrat sous 10 jours à la date de
                                facture. Pour les ventes de marchandise
                                inférieur à 25€ l’édition d’une facture ne sera
                                pas automatique sauf à la demande du client.
                            </p>
                        </section>
                        <section className="pt-5" id="art6.2">
                            <h2 className="fs-1">
                                Article 6.2 : Retard et défaut de paiement
                            </h2>
                            <p>
                                En cas de retard de paiement par le
                                bénéficiaire, les commandes en cours pourront
                                être interrompues, sans mise en demeure
                                préalable ni préjudice de toute indemnité et/ou
                                voie d’action.
                            </p>
                            <p>
                                Toute somme non payée à son échéance portera de
                                plein droit, et sans mise en demeure préalable,
                                des pénalités de retard de 10% tous les 10 jours
                                de retard, ainsi que l’application d’intérêts de
                                retard au taux légal.
                            </p>
                            <p>
                                Le défaut de paiement d’une facture à son
                                échéance autorise l’exploitant individuel
                                Hennion Fabien exerçant sous le nom commercial «
                                SER1 » et les enseignes : « Ma maison sereine »
                                et « Mon accueil serein » à annuler les
                                commandes en cours, sans mise en demeure
                                préalable ni préjudice de toute indemnité et/ou
                                voie d’action et sans préjudice du recouvrement
                                des sommes restant dues par le bénéficiaire.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art6">
                            <h2 className="fs-1">Article 6: Payment</h2>
                        </section>
                        <section className="pt-5" id="art6.1">
                            <h2 className="fs-1">
                                Article 6.1: Methods of payment
                            </h2>
                            <p>
                                Services and products will be invoiced on the
                                based on the prices precisely indicated, in
                                euros, on the quotation or when confirming the
                                order by the individual operator Hennion Fabien
                                practicing under the trade name “SER1” and the
                                sign: “My serene house” services and products
                                must be paid in full, in cash, by credit card
                                (surcharge possible) or by bank transfer before
                                their realization / delivery or in the under a
                                contract within 10 days of the date of Invoice.
                                For merchandise sales less than 25€ the edition
                                of an invoice will not be not automatic except
                                at the request of the customer.
                            </p>
                        </section>
                        <section className="pt-5" id="art6.2">
                            <h2 className="fs-1">
                                Article 6.2: Late and non-payment
                            </h2>
                            <p>
                                In the event of late payment by the beneficiary,
                                orders in progress may be interrupted, without
                                formal notice prior nor prejudice to any
                                compensation and/or course of action.
                            </p>
                            <p>
                                Any amount not paid when due will bear ipso
                                jure, and without prior notice, late penalties
                                of 10% every 10 days delay, as well as the
                                application of interest of delay at the legal
                                rate.
                            </p>
                            <p>
                                Failure to pay an invoice to its expiry
                                authorizes the individual operator Hennion
                                Fabien operating under the trade name " SER1”
                                and the signs: “My serene house” and “My serene
                                welcome” to cancel the orders in progress,
                                without formal notice prior nor prejudice to any
                                compensation and/or course of action and without
                                prejudice to recovery sums still owed by the
                                beneficiary.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art7">
                            <h2 className="fs-1">Article 7 : La Livraison</h2>
                        </section>
                        <section className="pt-5" id="art7.1">
                            <h2 className="fs-1">
                                Article 7.1 : Les délais et conditions de
                                livraison
                            </h2>
                            <p>
                                Les délais de livraison et condition seront
                                indiqués sur le devis qui sera adressé au client
                                par l’exploitant individuel Hennion Fabien
                                exerçant sous le nom commercial « SER1 » et
                                l’enseigne : « Ma maison sereine » Si les délais
                                de livraison ne sont pas respectés par
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine », il ne pourra être tenu
                                responsable des dommages ou préjudices causés
                                par un retard lors de la livraison d’un service.
                                Le non-respect des délais de livraison convenus
                                ne pourra entraîner aucune indemnité de quelque
                                nature.
                            </p>
                            <p>
                                La réalisation n’est entamée qu’après
                                confirmation du paiement par l’organisme
                                bancaire de l’exploitant individuel Hennion
                                Fabien exerçant sous le nom commercial « SER1 »
                                et l’enseigne : « Ma maison sereine » sauf aux
                                particulier, qui possèderaient un contrat
                                d’accompagnement et sous condition. Ces
                                conditions devront être stipulées par écrit.
                            </p>
                            <p>
                                <span className="fw-bold">
                                    A propos des produits achetés :
                                </span>{' '}
                                ils seront livrés lors du passage de
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » dans le cadre d’une
                                prestation effectuée ou lors de la rencontre du
                                client dans le cadre d’un achat immédiat
                                celui-ci emportera immédiatement le produit
                                acheté. Aucune livraison ni vente à distance
                                n’est possible.
                            </p>
                        </section>
                        <section className="pt-5" id="art7.2">
                            <h2 className="fs-1">
                                Article 7.2: Les prestations et achats commandés
                            </h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » s'engage à mettre en œuvre
                                tous les moyens humains et matériels pour
                                réaliser la prestation et mise à disposition des
                                achats dans les délais annoncés lors de la
                                passation de la commande. Mais il ne peut en
                                aucun cas être tenu responsable pour les retards
                                de réalisation occasionnés en raison de fautes
                                qui ne lui sont imputables. La prestation est
                                fournie à la date prévue sur le devis accepté
                                par le client ou dans un délai maximum de 30
                                jours suite à l’acceptation du devis par le
                                client.
                            </p>
                            <p>
                                Si les prestations n'ont pas été effectuées dans
                                le délai prévu, la résolution de la vente pourra
                                être demandée par le client dans les conditions
                                prévues aux articles L216-2 et L216-3 du Code de
                                la consommation. Les sommes versées par le
                                Client lui seront restituées au plus tard dans
                                les quatorze jours qui suivent la date de
                                dénonciation du contrat. Cette disposition ne
                                s'applique pas quand le retard de la société est
                                dû à une faute du client ou à un cas de force
                                majeure, c'est à dire à la survenance d'un
                                évènement imprévisible, irrésistible et
                                indépendant de la volonté de l’exploitant
                                individuel Hennion Fabien exerçant sous le nom
                                commercial « SER1 » et l’enseigne: « Ma maison
                                sereine ».
                            </p>
                            <p>
                                Dans le cas où l'exécution d'une prestation
                                physique n'aurait pas pu être effectuée du fait
                                d'une erreur sur l'adresse indiquée par le
                                Client, la prestation sera due. L’acheteur
                                veillera à donner accès au prestataire notamment
                                s’il n’est pas personnellement présent le jour
                                de la réalisation de la prestation.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art7">
                            <h2 className="fs-1">Article 7: Delivery</h2>
                        </section>
                        <section className="pt-5" id="art7.1">
                            <h2 className="fs-1">
                                Article 7.1: The deadlines and conditions of
                                delivery
                            </h2>
                            <p>
                                Delivery times and conditions will be indicated
                                on the estimate which will be sent to the
                                customer by the individual operator Hennion
                                Fabien trading under the trade name “SER1” and
                                the sign: “My serene house” If the deadlines of
                                delivery are not respected by the sole
                                proprietor Hennion Fabien exercising under the
                                commercial name “SER1” and the sign: "My serene
                                house", it cannot be held liable for damage or
                                injury caused by a delay in the delivery of a
                                service. Non-compliance with agreed delivery
                                times cannot result in any compensation of any
                                nature.
                            </p>
                            <p>
                                Implementation only begins after confirmation of
                                payment by the organization bank of the
                                individual operator Hennion Fabien practicing
                                under the trade name “SER1” and the sign: “My
                                serene house” except at individual, who would
                                have a contract accompanying and conditional.
                                These conditions must be stipulated in writing.
                            </p>
                            <p>
                                <span className="fw-bold">
                                    About the products purchased:
                                </span>{' '}
                                they will be delivered during the passage of the
                                sole proprietor Hennion Fabien exercising under
                                the commercial name “SER1” and the sign: “My
                                serene house” as part of a service performed or
                                during the meeting of the customer in the
                                context of an immediate purchase the latter will
                                immediately take away the product bought. No
                                delivery or distance selling is not possible.
                            </p>
                        </section>
                        <section className="pt-5" id="art7.2">
                            <h2 className="fs-1">
                                Article 7.2: Services and purchases ordered
                            </h2>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene house" undertakes to implement all
                                the human and material resources to perform the
                                service and make available the purchases within
                                the deadlines announced during the placing the
                                order. But he cannot no case be held responsible
                                for delays performance caused by faults which
                                are not attributable to him. The service is
                                provided on the date specified on the accepted
                                quote by the customer or within a maximum period
                                of 30 days following acceptance of the estimate
                                by the customer.
                            </p>
                            <p>
                                If the services have not been performed within
                                the time provided, the resolution of the sale
                                may be requested by the customer under the
                                conditions provided for in articles L216-2 and
                                L216-3 of the Code of the consumption. The
                                amounts paid by the Customer will be returned to
                                him no later than the fourteen days following
                                the date of termination of the contract. This
                                provision does not not apply when the company's
                                delay is due to a fault of the customer or a
                                case of force major, i.e. the occurrence of a
                                unforeseeable, irresistible and beyond the
                                control of the operator individual Hennion
                                Fabien practicing under the name commercial
                                “SER1” and the sign: “My house serene”.
                            </p>
                            <p>
                                In the event that the performance of a service
                                physics could not have been performed because an
                                error in the address indicated by the Customer,
                                the service will be due. The buyer will ensure
                                that the service provider has access, in
                                particular if he is not personally present on
                                the day of the performance of the service.
                            </p>
                        </section>
                    </>
                )}{' '}
                {translate === 'French' && (
                    <section className="pt-5" id="art8">
                        <h2 className="fs-1">Article 8 : Responsabilités </h2>
                        <p>
                            Dans la mesure où l’exploitant individuel Hennion
                            Fabien exerçant sous le nom commercial « SER1 » et
                            l’enseigne « Ma maison sereine » ne jouerait qu'un
                            rôle d'intermédiaire entre le client et un
                            fournisseur de services ou de biens tiers,
                            l’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial «SER1» et l’enseigne « Ma maison
                            sereine » n’est tenu à aucune obligation. Par
                            conséquence, seule la responsabilité du fournisseur
                            pourra être engagée pour tout litige relatif à
                            l’exécution des prestations dont ce dernier a la
                            maîtrise. Le client s’adressera donc directement au
                            fournisseur du service ou produit concerné pour
                            toute demande (réparation, dédommagement, etc.).
                        </p>
                        <p>
                            L’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial « SER1 » et l’enseigne : « Ma
                            maison sereine » est exonéré de toute responsabilité
                            concernant l’exécution du contrat de vente, la
                            livraison des produits et services commandés et
                            prestations exécutées par des prestataires
                            extérieurs.
                        </p>
                        <p>
                            L’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial « SER1 » et l’enseigne « Ma maison
                            sereine » ne pourra être tenu responsable de toute
                            dégradation, casse, dégâts, litige, vols subis par
                            le client lors et par la suite de l’achèvement d’une
                            prestation qu’il aura pu effectuer par ses soins ou
                            déléguer à un tiers.
                        </p>
                        <p>
                            Si le client décide de confier un jeu de clés de son
                            domicile ou autres structures à l’exploitant
                            individuel Hennion Fabien exerçant sous le nom
                            commercial « SER1 » et l’enseigne : « Ma maison
                            sereine » pour effectuer une prestation en son
                            absence. Une décharge ou contrat sera signée par le
                            client dans le but de dégager l’exploitant
                            individuel Hennion Fabien exerçant sous le nom
                            commercial « SER1 » et l’enseigne : « Ma maison
                            sereine » de toute responsabilité en cas de litige.
                            La mise à disposition et récupération des clés par
                            le client est à sa charge, un gardiennage des clés
                            est possible.
                        </p>
                        <p>
                            L’exploitant individuel Hennion Fabien exerçant sous
                            le nom commercial « SER1 » et l’enseigne « Ma maison
                            sereine » ne saurait être tenu responsable de
                            l’inexécution de ses obligations en cas de force
                            majeure définie par le Code Civil, et notamment en
                            cas de grève totale ou partielle, inondation,
                            incendie, panne informatique, etc. (liste non
                            exhaustive).
                        </p>
                        <p>
                            <span className="fw-bold">
                                Transfert des propriétés et des risques :
                            </span>{' '}
                            la propriété de la chose vendue est transférée dès
                            l’instant où les parties sont d’accord sur la chose
                            et sur le prix. En conséquence, le transfert de
                            propriété des produits et des risques de perte et de
                            détérioration s’y rapportant est réalisé, à la
                            charge de l’acheteur, dès acceptation du devis par
                            l’acheteur.
                        </p>
                    </section>
                )}
                {translate === 'English' && (
                    <section className="pt-5" id="art8">
                        <h2 className="fs-1">Article 8: Responsibilities </h2>
                        <p>
                            Insofar as the individual operator Hennion Fabien
                            operating under the trade name "SER1" and the sign "
                            My serene home” would only play a role intermediary
                            between the customer and a supplier of third-party
                            services or goods, the individual operator Hennion
                            Fabien operating under the trade name "SER1" and the
                            sign "My serene house" is not bound by any
                            obligation. Consequently, only the responsibility of
                            the supplier may be engaged for any dispute relating
                            the performance of the services for which the latter
                            is responsible mastery. The customer will therefore
                            contact the supplier of the service or product
                            concerned for any request (repair, compensation,
                            etc.).
                        </p>
                        <p>
                            The sole proprietor Hennion Fabien operating under
                            the trade name “SER1” and the sign: “Ma maison
                            serene" is exonerated from any responsibility
                            concerning the execution of the sales contract, the
                            delivery of products and services ordered and
                            services performed by external service providers.
                        </p>
                        <p>
                            The sole proprietor Hennion Fabien operating under
                            the trade name “SER1” and the sign “Ma maison
                            serene" cannot be held responsible for any
                            degradation, breakage, damage, litigation, theft
                            suffered by the customer on and after the completion
                            of a service that he could have carried out by
                            himself or delegate to a third party.
                        </p>
                        <p>
                            If the customer decides to entrust a set of keys
                            from his home or other structures to the individual
                            operator Hennion Fabien operating under the trade
                            name "SER1" and the sign: “My serene house” to carry
                            out a performance in his absence. A release or
                            contract will be signed by the customer in order to
                            release the operator individual Hennion Fabien
                            practicing under the name commercial “SER1” and the
                            sign: “My serene house » of any liability in the
                            event of a dispute. The update provision and
                            collection of keys by the customer is at charge, key
                            custody is possible.
                        </p>
                        <p>
                            The sole proprietor Hennion Fabien operating under
                            the trade name “SER1” and the sign “Ma maison
                            serene" cannot be held responsible for
                            non-performance of its obligations in the event of
                            force majeure defined by the Civil Code, and in
                            particular in the event of a strike total or
                            partial, flood, fire, breakdown computer, etc
                            (non-exhaustive list).
                        </p>
                        <p>
                            <span className="fw-bold">
                                Transfer of ownership and risk:
                            </span>{' '}
                            ownership of the thing sold is transferred as soon
                            as the moment the parties agree on the thing and on
                            the price. Consequently, the transfer of ownership
                            of the products and the risks of loss and
                            deterioration relating thereto is carried out, at
                            the expense of the buyer, upon acceptance of the
                            quote by the buyer.
                        </p>
                    </section>
                )}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art9">
                            <h2 className="fs-1">
                                Article 9 : Garanties Généralités{' '}
                            </h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » est garant de la
                                conformité des biens au contrat, permettant à
                                l'acheteur de formuler une demande au titre de
                                la garantie Légale de conformité prévue aux
                                articles L. 217-4 et suivants du code de la
                                consommation ou de la garantie des défauts de la
                                chose vendue au sens des articles 1641 et
                                suivants du code civil. Les produits vendus sont
                                également couverts par une garantie commerciale
                                visant à garantir leur conformité et assurant le
                                remboursement du prix d'achat, le remplacement
                                ou la réparation des biens. Cette garantie fait
                                l'objet d'un contrat écrit, dont un exemplaire
                                est remis à l'acheteur et précise l'objet de la
                                garantie, les modalités de sa mise en œuvre, son
                                prix, sa durée, son étendue territoriale. Elle
                                ne couvre pas les défauts occasionnés du fait
                                d'une utilisation anormale ou fautive ou
                                résultant d'une cause étrangère aux qualités
                                intrinsèques des produits. Les dispositions qui
                                précèdent ne sont pas exclusives de
                                l'application de la garantie légale de
                                conformité de l'article L. 217-4 du code de la
                                consommation et de la garantie des défauts de la
                                chose vendue des articles 1641 et suivants du
                                code civil.
                            </p>
                        </section>
                        <section className="pt-5" id="art10">
                            <h2 className="fs-1">
                                Article 10 : Garantie légale de conformité
                            </h2>
                            <p>
                                L'acheteur bénéficie de la garantie légale de
                                conformité. Dans le cadre de la mise en œuvre de
                                celle-ci, il est rappelé que :
                            </p>
                            <ul>
                                <li>
                                    l'acheteur bénéficie d'un délai de 2 ans à
                                    compter de la délivrance du bien pour agir ;
                                </li>
                                <li>
                                    l'acheteur peut choisir entre la réparation
                                    ou le remplacement du bien, sous réserve des
                                    conditions de coût prévues par l'article L.
                                    217-9 du code de la consommation ;
                                </li>
                                <li>
                                    l'acheteur est dispensé de rapporter la
                                    preuve de l'existence du défaut de
                                    conformité du bien durant les 24 mois
                                    suivant la délivrance du bien.
                                </li>
                            </ul>
                            <p>En outre, il est rappelé que :</p>
                            <ul>
                                <li>
                                    acheteur peut décider de mettre en œuvre la
                                    garantie contre les défauts cachés de la
                                    chose vendue au sens de l'article 1641 du
                                    code civil.
                                </li>
                                Dans cette hypothèse, il peut choisir entre la
                                résolution de la vente ou une réduction du prix
                                conformément à l'article 1644 du code civil.
                            </ul>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art9">
                            <h2 className="fs-1">
                                Article 9: Warranties General
                            </h2>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene house" guarantees the conformity of
                                the goods to the contract, allowing the buyer to
                                make a claim under the legal guarantee of
                                conformity provided for in articles L. 217-4 and
                                following of the code of consumption or warranty
                                for defects in the thing sold within the meaning
                                of articles 1641 and following of the civil
                                code. The products sold are also covered by a
                                commercial warranty aimed at guaranteeing their
                                compliance and ensuring the refund of the
                                purchase price, the replacement or repair of
                                property. This guarantee makes the subject of a
                                written contract, a copy of which is given to
                                the buyer and specifies the subject of the
                                warranty, the terms of its implementation, its
                                price, duration, territorial scope. She does not
                                cover defects caused by the fact abnormal or
                                faulty use or resulting from a cause unrelated
                                to the qualities intrinsic to the products. The
                                provisions which above are not exclusive of the
                                application of the legal guarantee of compliance
                                with article L. 217-4 of the code of consumption
                                and warranty against defects of the thing sold
                                of articles 1641 and following of the civil
                                Code.
                            </p>
                        </section>
                        <section className="pt-5" id="art10">
                            <h2 className="fs-1">
                                Article 10: Legal guarantee of conformity
                            </h2>
                            <p>
                                The buyer benefits from the legal guarantee of
                                compliance. As part of the implementation of
                                this, it is recalled that:
                            </p>
                            <ul>
                                <li>
                                    the buyer has a period of 2 years from from
                                    the delivery of the property to act;
                                </li>
                                <li>
                                    the buyer can choose between repair or
                                    replacement of the property, subject to the
                                    cost conditions provided for in article L.
                                    217-9 of the consumer code;
                                </li>
                                <li>
                                    the buyer is exempted from reporting the
                                    proof of the existence of the defect of
                                    conformity of the good during the 24 months
                                    following delivery of the property.
                                </li>
                            </ul>
                            <p>In addition, it is recalled that:</p>
                            <ul>
                                <li>
                                    buyer can decide to implement the warranty
                                    against hidden defects of the thing sold
                                    within the meaning of article 1641 of the
                                    civil Code.
                                </li>
                                In this case, he can choose between the
                                rescission of the sale or reduction of the price
                                in accordance with article 1644 of the civil
                                code.
                            </ul>
                        </section>
                    </>
                )}{' '}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art11">
                            <h2 className="fs-1">
                                Article 11 : Traitement des données personnelles
                            </h2>
                            <p>
                                L'enregistrement sur le site entraîne le
                                traitement des données à caractère personnel du
                                client. Si le client refuse le traitement de ses
                                données, il lui est demandé de s'abstenir
                                d’utiliser le site. Ce traitement des données à
                                caractère personnel se fait dans le respect du
                                Règlement Général sur la Protection des Données
                                2016/679 du 27 avril 2016.
                            </p>
                            <p>
                                Par ailleurs, conformément à la loi Informatique
                                et Libertés du 6 janvier 1978, le client
                                dispose, à tout moment, d'un droit
                                d'interrogation, d'accès, de rectification, de
                                modification et d'opposition à l'ensemble de ses
                                données personnelles. En écrivant, par courrier
                                et en justifiant de son identité, à l'adresse
                                suivante : HENNION Fabien SER1, 77 boulevard de
                                l’océan 44770 La-Plaine-sur-Mer. Ces données
                                personnelles sont nécessaires au traitement de
                                sa commande et à l'établissement de ses factures
                                le cas échéant, ainsi qu'à l'amélioration des
                                fonctionnalités du site.
                            </p>
                        </section>
                        <section className="pt-5" id="art12">
                            <h2 className="fs-1">
                                Article 12 : Partage des données collectées
                            </h2>
                            <p>
                                Le site peut avoir recours à des sociétés
                                tierces pour effectuer certaines opérations. En
                                naviguant sur le site, le client accepte que des
                                sociétés tierces puissent avoir accès à ses
                                données pour permettre le bon fonctionnement du
                                site. Ces sociétés tierces n'ont accès aux
                                données collectées que dans la cadre de la
                                réalisation d'une tâche précise. Le site reste
                                responsable du traitement de ces données. Par
                                ailleurs, l'utilisateur peut donc être amené à
                                recevoir des informations ou des offres
                                commerciales de la part de la société ou de ses
                                partenaires. L'utilisateur peut à tout moment
                                s'opposer à la réception de ces offres
                                commerciales, en écrivant à l'adresse de la
                                Société concernée. Par ailleurs, les
                                informations des Clients pourront être
                                transmises à des tiers sans leur accord express
                                préalable afin d'atteindre les buts suivants :
                                Respecter la loi Protéger toute personne contre
                                des dommages corporels graves, voire la mort
                                Lutter contre la fraude ou les atteintes portées
                                à la Société ou à ses utilisateurs Protéger les
                                droits de propriété de la Société.
                            </p>
                        </section>
                        <section className="pt-5" id="art13">
                            <h2 className="fs-1">
                                Article 13 : Protection des données
                            </h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » assure un niveau de
                                sécurité approprié et proportionnel aux risques
                                encourus ainsi qu'à leur probabilité,
                                conformément au Règlement Général sur la
                                Protection des Données 2016/679 du 27 avril
                                2016. Toutefois, ces mesures ne constituent en
                                aucun cas une garantie et n'engagent pas
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et les enseigne
                                « Ma maison sereine » et « Mon accueil serein »
                                à une obligation de résultat concernant la
                                sécurité des données.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art11">
                            <h2 className="fs-1">
                                Article 11: Processing of personal data
                            </h2>
                            <p>
                                Registration on the site entails the processing
                                of personal data of the customer. If the client
                                refuses the processing of his data, he is asked
                                to abstain to use the site. This data processing
                                to personal character is done with respect for
                                the General Data Protection Regulation 2016/679
                                of April 27, 2016.
                            </p>
                            <p>
                                Furthermore, in accordance with the Data
                                Protection Act et Libertés of January 6, 1978,
                                the customer has, at any time, a right
                                interrogation, access, rectification,
                                modification and opposition to all of its
                                personal data. By writing, by mail and by
                                proving his identity, to the address following:
                                HENNION Fabien SER1, 77 boulevard de the ocean
                                44770 La-Plaine-sur-Mer. Those data personal
                                information is necessary for the processing of
                                his order and the establishment of his invoices
                                where appropriate, as well as the improvement of
                                site features.
                            </p>
                        </section>
                        <section className="pt-5" id="art12">
                            <h2 className="fs-1">
                                Article 12: Sharing of collected data
                            </h2>
                            <p>
                                The site may use companies third parties to
                                perform certain operations. In browsing the
                                site, the customer accepts that third-party
                                companies may have access to its data to enable
                                the proper functioning of the site. These
                                third-party companies only have access to data
                                collected only within the framework of the
                                accomplishment of a specific task. The website
                                remains responsible for processing this data. By
                                elsewhere, the user may therefore be led to
                                receive information or offers commercial
                                activities on the part of the company or its
                                partners. The user can at any time oppose the
                                receipt of these offers commercial, by writing
                                to the address of the Company concerned.
                                Furthermore, the Customer information may be
                                transmitted to third parties without their
                                express consent in order to achieve the
                                following goals: Respect the law Protect
                                everyone against serious bodily harm or even
                                death Fight against fraud or infringements to
                                the Company or its users To protect the
                                proprietary rights of the Company.
                            </p>
                        </section>
                        <section className="pt-5" id="art13">
                            <h2 className="fs-1">
                                Article 13: Data protection
                            </h2>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene home" ensures a level of security
                                appropriate and proportionate to the risks
                                incurred as well as their probability, in
                                accordance with the General Regulations on the
                                Data Protection 2016/679 of April 27 2016.
                                However, these measures do not constitute in no
                                case a guarantee and do not engage the sole
                                proprietor Hennion Fabien exercising under the
                                commercial name "SER1" and teaches them “My
                                serene house” and “My serene welcome” to an
                                obligation of result concerning the data
                                security.
                            </p>
                        </section>
                    </>
                )}{' '}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art14">
                            <h2 className="fs-1">Article 14 : Cookies</h2>
                            <p>
                                Pour permettre à ses utilisateurs de bénéficier
                                d’une navigation optimale sur le site et d’un
                                meilleur fonctionnement des différentes
                                interfaces et applications, la société est
                                susceptible d’implanter un cookie sur
                                l’ordinateur de l’utilisateur. Ce cookie permet
                                de stocker des informations relatives à la
                                navigation sur le site, ainsi qu’aux éventuelles
                                données saisies par les utilisateurs (notamment
                                recherches, login, email, mot de passe).
                            </p>
                            <p>
                                L’utilisateur autorise expressément la Société à
                                déposer sur le disque dur de l’utilisateur un
                                fichier dit « cookie ». L’utilisateur dispose de
                                la possibilité de bloquer, modifier la durée de
                                conservation, ou supprimer ce cookie via
                                l’interface de son navigateur. Si la
                                désactivation systématique des cookies sur le
                                navigateur de l’utilisateur l’empêche d’utiliser
                                certains services ou fonctionnalités du site, ce
                                dysfonctionnement ne saurait en aucun cas
                                constituer un dommage pour le membre qui ne
                                pourra prétendre à aucune indemnité de ce fait.
                            </p>
                        </section>
                        <section className="pt-5" id="art15">
                            <h2 className="fs-1">Article 15 : Modifications</h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » se réserve le droit de
                                modifier le site, les services qui y sont
                                proposés, les CGV/CGU ainsi que toute procédure
                                de livraison ou autre élément constitutif des
                                prestations réalisées par l’exploitant
                                individuel Hennion Fabien exerçant sous le nom
                                commercial « SER1 » et l’enseigne « Ma maison
                                sereine » par le biais du site. Lorsqu'il passe
                                une commande, l'utilisateur est soumis aux
                                stipulations énoncées par les CGV/CGU en vigueur
                                lors de la passation de la commande.
                            </p>
                        </section>
                        <section className="pt-5" id="art16">
                            <h2 className="fs-1">
                                Article 16 : Propriété intellectuelle
                            </h2>
                            <p>
                                La marque, le logo, et la charte graphique du
                                présent site sont des marques déposées auprès de
                                l'INPI et des œuvres protégées au titre de la
                                propriété intellectuelle, dont la propriété
                                revient exclusivement à l’exploitant individuel
                                Hennion Fabien exerçant sous le nom commercial «
                                SER1 » et l’enseigne : « Ma maison sereine ».
                                Toute diffusion, exploitation, représentation,
                                reproduction, qu’elle soit partielle ou
                                intégrale sans l’autorisation expresse de ladite
                                société exposera le contrevenant à des
                                poursuites civiles et pénales.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art14">
                            <h2 className="fs-1">Article 14: Cookies</h2>
                            <p>
                                To allow its users to benefit from a optimal
                                navigation on the site and a better operation of
                                the various interfaces and applications, the
                                company is likely to implement a cookie on the
                                user's computer. This cookie allows you to store
                                information about the navigation on the site, as
                                well as any data entered by users (in particular
                                searches, login, email, password).
                            </p>
                            <p>
                                The user expressly authorizes the Company to
                                deposit on the user's hard disk a file called "
                                cookie”. The user has the option of block,
                                change the retention period, or delete this
                                cookie via the interface of its browser. If the
                                systematic deactivation of cookies on the
                                browser of the user prevents him from using
                                certain services or functionalities of the site,
                                this malfunction does not can in no way
                                constitute damage to the member who will not be
                                able to claim any compensation for this do.
                            </p>
                        </section>
                        <section className="pt-5" id="art15">
                            <h2 className="fs-1">Section 15: Amendments</h2>
                            <p>
                                The sole proprietor Hennion Fabien operating
                                under the trade name “SER1” and the sign: “Ma
                                maison serene" reserves the right to modify the
                                site, the services offered there, the T&Cs/T&Cs
                                as well as any delivery procedure or other
                                constituting the services provided by the
                                operator individual Hennion Fabien practicing
                                under the name commercial “SER1” and the sign
                                “Ma maison calmee” through the site. When
                                placing an order, the user is subject to the
                                stipulations set out by the CGV/CGU in force at
                                the time of the signing of the order.
                            </p>
                        </section>
                        <section className="pt-5" id="art16">
                            <h2 className="fs-1">
                                Article 16: Intellectual property
                            </h2>
                            <p>
                                The brand, the logo, and the graphic charter of
                                the present site are trademarks registered with
                                the INPI and copyrighted works intellectual
                                property, the property of which rests
                                exclusively to the individual operator Hennion
                                Fabien operating under the commercial name
                                “SER1” and the sign: “Ma maison serene”. Any
                                dissemination, exploitation, representation,
                                reproduction, whether partial or integral
                                without the express authorization of the said
                                company expose the offender to civil liability
                                and criminal.
                            </p>
                        </section>
                    </>
                )}{' '}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art17">
                            <h2 className="fs-1">
                                Article 17 : Clause attributive de compétence
                            </h2>
                            <p>
                                Le droit régissant les CGV/CGU est le droit
                                français. Tout litige pouvant survenir entre
                                l’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et les enseignes
                                : « Ma maison sereine » et « Mon accueil serein
                                » et un utilisateur lors de l’exécution des
                                présentes fera l’objet d’une tentative de
                                résolution à l’amiable. A défaut, les litiges
                                seront portés à la connaissance des tribunaux
                                compétents de droit commun.
                            </p>
                            <p>
                                Le client est informé qu'il peut recourir à une
                                médiation conventionnelle, auprès de la
                                Commission de la médiation de la Consommation
                                prévue à l'article L534-7 du Code de la
                                consommation ou auprès des instances de
                                médiation sectorielles existantes. Il peut
                                également recourir à tout mode alternatif de
                                règlement des différends en cas de litige.
                            </p>
                        </section>
                        <section className="pt-5" id="art18">
                            <h2 className="fs-1">
                                Article 18 : Prêt de matériel et blanchiment et
                                prêt de linge.
                            </h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » propose un service de
                                location de matériel et blanchiment et prêt de
                                linge.
                            </p>
                            <p>
                                <span className="text-primary fw-bold text-decoration-underline">
                                    Un contrat encadrera le service de prêt de
                                    matériel :
                                </span>{' '}
                                il est rappelé que toute détérioration du bien
                                et en cas de non-restitution du Bien, le
                                Locataire sera tenu de payer au Propriétaire la
                                valeur de la caution du Bien, si caution non
                                versée et non déterminée il devra rembourser le
                                bien à sa valeur d’origine.
                            </p>
                            <p>
                                <span className="text-primary fw-bold text-decoration-underline">
                                    A propos de la location et blanchiment de
                                    linge :
                                </span>{' '}
                                ce service est principalement réservé au
                                particulier. Le prêt de linge ne pourra excéder
                                7 nuits consécutives, si les sept nuits sont
                                dépassées une majoration sera demandée, il est
                                rappelé que toute détérioration du linge et en
                                cas de non-restitution du bien, le locataire
                                sera tenu de payer au propriétaire la valeur de
                                la caution du Bien, si la caution est non versée
                                et non déterminée il devra rembourser le bien à
                                sa valeur d’origine. La location et blanchiment
                                du linge est un service complémentaire d’une
                                prestation commandée (communication des
                                modalités sur simple demande), il ne peut être
                                vendu seul.
                            </p>
                            <p>
                                <span className="text-primary fw-bold text-decoration-underline">
                                    Blanchiment du linge du client :
                                </span>{' '}
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » ne pourra être tenu
                                responsable de toute dégradation, dégât, litige,
                                subis par le client lors et par la suite de
                                l’achèvement d’une prestation de blanchiment de
                                son linge qu’il aura pu effectuer par ses soins
                                ou déléguer à un tiers, le nettoyage du linge
                                sera effectué sous 7 jours.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art17">
                            <h2 className="fs-1">
                                Article 17: Jurisdiction clause
                            </h2>
                            <p>
                                The law governing the CGV/CGU is French law. Any
                                dispute that may arise between the operator
                                individual Hennion Fabien practicing under the
                                name commercial “SER1” and the signs: “Ma maison
                                serene” and “My serene welcome” and a user
                                during the execution of the present will be the
                                subject of a attempt at amicable resolution.
                                Otherwise, the disputes will be brought to the
                                attention of the courts jurisdiction under
                                common law.
                            </p>
                            <p>
                                The customer is informed that he can resort to a
                                conventional mediation, with the Commission of
                                the consumer mediation provided for in article
                                L534-7 of the Consumer Code or with the
                                authorities of existing sectoral mediation. He
                                can also resort to any alternative means of
                                settling disputes in the event of a dispute.
                            </p>
                        </section>
                        <section className="pt-5" id="art18">
                            <h2 className="fs-1">
                                Article 18: Loan of equipment and money
                                laundering and loan of laundry.
                            </h2>
                            <p>
                                The sole proprietor Hennion Fabien operating
                                under the trade name “SER1” and the sign: “Ma
                                maison serene" offers a rental service for
                                equipment and laundry and linen loan.
                            </p>
                            <p>
                                <span className="text-primary fw-bold text-decoration-underline">
                                    A contract will govern the equipment loan
                                    service :
                                </span>{' '}
                                it is recalled that any deterioration of the
                                property and in the event of non-return of the
                                Property, the Tenant will be required to pay the
                                Owner the value of the deposit for the Property,
                                if deposit not paid and not determined, he will
                                have to refund the property at its original
                                value.
                            </p>
                            <p>
                                <span className="text-primary fw-bold text-decoration-underline">
                                    About the rental and laundering of linen:
                                </span>{' '}
                                this service is mainly reserved for individuals.
                                THE linen loan may not exceed 7 consecutive
                                nights, if the seven nights are exceeded an
                                increase will be requested, it is reminded that
                                any deterioration of the linen and in the event
                                of non-return of the property, the tenant will
                                be required to pay the owner the value of the
                                deposit for the Property, if the deposit is not
                                paid and not determined he will have to
                                reimburse the property at its value of origin.
                                The rental and laundering of linen is a
                                complementary service to a service ordered
                                (communication of the terms on simple request),
                                it does not can be sold alone.
                            </p>
                            <p>
                                <span className="text-primary fw-bold text-decoration-underline">
                                    Laundering of the customer's laundry:
                                </span>{' '}
                                The sole proprietor Hennion Fabien operating
                                under the trade name “SER1” and the sign: “Ma
                                maison serene" cannot be held responsible for
                                any degradation, damage, litigation, suffered by
                                the customer during and following the completion
                                of a service of laundering of his linen that he
                                could have carried out by his care or delegate
                                to a third party, the cleaning of the linen will
                                be completed within 7 days.
                            </p>
                        </section>
                    </>
                )}{' '}
                {translate === 'French' && (
                    <>
                        <section className="pt-5" id="art19">
                            <h2 className="fs-1">
                                Article 19 : Information précontractuelle
                            </h2>
                            <p>
                                Préalablement à sa commande, le client reconnait
                                avoir eu communication, de façon lisible et
                                compréhensible, des CGV/CGU et des informations
                                et renseignements prévus aux articles L111-1 à
                                L111-7 du Code de la consommation, et notamment
                                :
                            </p>
                            <ul>
                                <li>
                                    Les caractéristiques essentielles des
                                    services{' '}
                                </li>
                                <li>Le prix des services</li>
                                <li>
                                    La date ou le délai auquel la Société
                                    s'engage à fournir le service
                                </li>
                                <li>
                                    Les informations relatives à l'identité de
                                    la Société (coordonnées postales,
                                    téléphoniques, électroniques)
                                </li>
                                <li>
                                    Les informations relatives aux garanties
                                    légales et contractuelles et à leurs
                                    modalités de mise en œuvre
                                </li>
                                <li>
                                    La possibilité de recourir à une médiation
                                    conventionnelle en cas de litige
                                </li>
                                <li>
                                    Les informations relatives au droit de
                                    rétractation (délai, modalités d'exercice).
                                </li>
                            </ul>
                            <Alert color="danger" isOpen={true}>
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                La passation d'une commande quelle que soit la
                                façon emporte adhésion et acceptation des
                                CGV/CGU. Le client ne pourra se prévaloir d'un
                                document contradictoire.
                            </Alert>
                        </section>
                        <section className="pt-5" id="art20">
                            <h2 className="fs-1">
                                Article 20 : Loi applicable au contrat
                            </h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » est une auto-entreprise de
                                droit français. La loi applicable aux relations
                                contractuelles est la loi française
                                exclusivement.
                            </p>
                        </section>
                        <section className="pt-5" id="art21">
                            <h2 className="fs-1">Article 21 : Litige</h2>
                            <p>
                                Tout litige relatif à l’interprétation et à
                                l’exécution des conditions générales de services
                                est soumis au droit français. A défaut de
                                résolution amiable, le litige sera porté devant
                                les tribunaux civils compétents.
                            </p>
                        </section>
                        <section className="pt-5" id="art22">
                            <h2 className="fs-1">
                                Article 22 : Coordonnées du conciliateur
                            </h2>
                            <p>
                                Conciliateur du Ministère de la Justice
                                Permanence sur rendez-vous auprès de la Pornic
                                Agglo Pays de Retz <br />2 Rue du Dr Ange Guépin
                                <br />
                                44210 Pornic <br />
                                Téléphone : 02 51 74 07 16 <br />
                                Télécopie : 02 51 74 10 10
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="pt-5" id="art19">
                            <h2 className="fs-1">
                                Article 19: Pre-contractual information
                            </h2>
                            <p>
                                Prior to his order, the customer acknowledges to
                                have had communication, in a legible and
                                comprehensible, of the CGV / CGU and information
                                and information provided for in articles L111-1
                                to L111-7 of the Consumer Code, and in
                                particular :
                            </p>
                            <ul>
                                <li>
                                    The essential characteristics of services{' '}
                                </li>
                                <li>The price of services</li>
                                <li>
                                    The date or time frame by which the Company
                                    undertakes to provide the service
                                </li>
                                <li>
                                    Information relating to the identity of the
                                    Company (postal address, telephone,
                                    electronic)
                                </li>
                                <li>
                                    Information relating to guarantees legal and
                                    contractual and to their implementation
                                    methods
                                </li>
                                <li>
                                    The possibility of resorting to mediation
                                    conventional in the event of a dispute
                                </li>
                                <li>
                                    Information relating to the right to
                                    withdrawal (deadline, terms of exercise).
                                </li>
                            </ul>
                            <Alert color="danger" isOpen={true}>
                                <i className="fa-solid fa-triangle-exclamation"></i>
                                Placing an order regardless of the manner
                                entails adhesion and acceptance of CGV/CGU. The
                                customer cannot claim a contradictory document.
                            </Alert>
                        </section>
                        <section className="pt-5" id="art20">
                            <h2 className="fs-1">
                                Article 20: Law applicable to the contract
                            </h2>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene house" is a self-employed company
                                French right. The law applicable to the
                                relationship contracts is French law
                                exclusively.
                            </p>
                        </section>
                        <section className="pt-5" id="art21">
                            <h2 className="fs-1">Article 21: Dispute</h2>
                            <p>
                                Any dispute relating to the interpretation and
                                the execution of the general conditions of
                                service is subject to French law. In the absence
                                of amicable resolution, the dispute will be
                                brought before competent civil courts.
                            </p>
                        </section>
                        <section className="pt-5" id="art22">
                            <h2 className="fs-1">
                                Article 22: Contact details of the conciliator
                            </h2>
                            <p>
                                Conciliator of the Ministry of Justice
                                Permanence by appointment at Pornic Agglo Pays
                                de Retz <br />2 Rue du Dr Ange Guépin
                                <br />
                                44210 Pornic <br />
                                Telephone: 02 51 74 07 16 <br />
                                Fax: 02 51 74 10 10
                            </p>
                        </section>
                    </>
                )}
            </div>
        </>
    );
}
