export default function LegendeReservations() {
    return (
        <div className=" m-3 d-flex  flex-column flex-lg-row flex-wrap  align-items-center ">
            Légende :
            <div className="bg-light p-1 opacity-25 rounded  m-1">
                Réservation passée
            </div>
            <div className="bg-antique p-1 rounded   m-1 border border-2 border-primary">
                Réservation en cours
            </div>
            <div className="bg-sereine-light p-1 rounded opacity-75  m-1">
                Réservation à venir
            </div>
        </div>
    );
}
