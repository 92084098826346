import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
    FaUserCircle,
    FaPencilAlt,
    FaUserPlus,
    FaUserTimes,
} from 'react-icons/fa';
import { FaSquarePhone } from 'react-icons/fa6';
import { IoTrash } from 'react-icons/io5';

import { Label, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Offcanvas from 'react-bootstrap/Offcanvas';
import {
    getDataCarnet,
    setPhoneNumber,
} from '../../../Store/Actions/messenger.actions';

export default function CarnetAdressesGestion() {
    const accessToken = useSelector((store) => store.User.accessToken);
    const openCarnet = useSelector((store) => store.Messenger.openCarnet);
    const dataCarnet = useSelector((store) => store.Messenger.dataCarnet);
    const [openContact, setOpenContact] = useState(false);
    const [openContactDelete, setOpenContactDelete] = useState(false);
    const [contactLastName, setContactLastName] = useState(undefined);
    const [contactFirstName, setContactFirstName] = useState(undefined);
    const [contactPhone, setContactPhone] = useState(undefined);
    const [contactId, setContactId] = useState(undefined);
    const [contactMode, setContactMode] = useState(undefined);
    const dispatch = useDispatch();

    const handleSubmitCreateContact = (e) => {
        e.preventDefault();
        async function PostCreateContact(formData) {
            try {
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/sendmsg/v1/carnet/',
                    {
                        method: 'post',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 202) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setOpenContact(!openContact);
                } else {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error('Oups! Création impossible 😔', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        const formData = new FormData();
        formData.append('last_name', contactLastName);
        formData.append('first_name', contactFirstName);
        formData.append('phone', contactPhone);
        PostCreateContact(formData);
    };
    const handleSubmitEditContact = (e) => {
        e.preventDefault();
        async function PutContact(formData) {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/sendmsg/v1/carnet/${contactId}/`,
                    {
                        method: 'put',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 202) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setOpenContact(!openContact);
                } else {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error('Oups! Modification impossible 😔', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        const formData = new FormData();
        formData.append('last_name', contactLastName);
        formData.append('first_name', contactFirstName);
        formData.append('phone', contactPhone);
        PutContact(formData);
    };
    const handleSubmitDeleteContact = (e) => {
        e.preventDefault();
        async function DeleteContact() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/sendmsg/v1/carnet/${contactId}/`,
                    {
                        method: 'delete',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                if (response.status === 202) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    setOpenContactDelete(false);
                } else {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error('Oups! Suppression impossible 😔', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }

        DeleteContact();
    };
    useEffect(() => {
        setTimeout(() => {
            dispatch(getDataCarnet(accessToken));
        }, 1000);
    }, [accessToken]);
    useEffect(() => {
        const interval = setInterval(
            () => dispatch(getDataCarnet(accessToken)),
            10000
        );
        return () => {
            clearInterval(interval);
        };
    }, [openCarnet]);

    return (
        <>
            {openCarnet === true && (
                <div className=" p-2  border border-2 border-primary rounded  mx-auto my-4">
                    <h2 className="text-center ">Mon Carnet</h2>
                    <div className="text-center mb-3 ">
                        <Button
                            variant="warning  text-white shadow"
                            onClick={(e) => {
                                setOpenContact(!openContact);
                                setContactMode('post');
                            }}
                        >
                            <FaUserPlus />
                        </Button>
                    </div>
                    {dataCarnet !== undefined && (
                        <div>
                            {dataCarnet !== null && dataCarnet.length === 0 && (
                                <span>Aucun Contact de créer...</span>
                            )}
                            {dataCarnet !== null && dataCarnet.length >= 1 && (
                                <>
                                    {dataCarnet.map((element) => (
                                        <div key={element.id}>
                                            {element.id === contactId ? (
                                                <Button variant="primary">
                                                    <FaUserCircle />
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant=""
                                                    onClick={(e) => {
                                                        setContactId(
                                                            element.id
                                                        );
                                                        dispatch(
                                                            setPhoneNumber(
                                                                element.phone
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <FaUserCircle />
                                                </Button>
                                            )}
                                            {element.first_name !== 'z' &&
                                                element.first_name}{' '}
                                            {element.last_name}{' '}
                                            <a
                                                href="tel:+33766799950"
                                                className="ms-2"
                                            >
                                                <FaSquarePhone />
                                                {element.phone}
                                            </a>
                                            <span
                                                className="mx-2"
                                                onClick={(e) => {
                                                    setContactId(element.id);
                                                    setContactLastName(
                                                        element.last_name
                                                    );
                                                    setContactFirstName(
                                                        element.first_name
                                                    );
                                                    setContactPhone(
                                                        element.phone
                                                    );
                                                    setOpenContact(true);
                                                    setContactMode('put');
                                                }}
                                            >
                                                <FaPencilAlt />
                                            </span>
                                            <span
                                                className="text-danger mx-2"
                                                onClick={(e) => {
                                                    setOpenContactDelete(true);
                                                    setOpenContact(false);
                                                }}
                                            >
                                                <IoTrash />
                                            </span>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    )}

                    <Offcanvas
                        show={openContact}
                        onHide={(e) => setOpenContact(!openContact)}
                        placement={'bottom'}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="text-warning">
                                {contactMode === 'post' ? (
                                    <>
                                        {' '}
                                        Créer un nouveau Contact
                                        <FaUserPlus />
                                    </>
                                ) : (
                                    <>
                                        Editer un Contact <FaPencilAlt />
                                    </>
                                )}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <form
                                onSubmit={(e) => {
                                    if (contactMode === 'post') {
                                        handleSubmitCreateContact(e);
                                    } else {
                                        handleSubmitEditContact(e);
                                    }
                                }}
                                className="d-flex flex-column"
                            >
                                <div className="d-flex flex-row justify-content-around">
                                    <FormGroup>
                                        <Label htmlFor="firstname">
                                            Prénom :
                                        </Label>{' '}
                                        <br />
                                        {contactMode === 'post' ? (
                                            <Input
                                                aria-label="firstname"
                                                id="firstname"
                                                maxLength={254}
                                                type="firstname"
                                                name="firstname"
                                                onChange={(e) =>
                                                    setContactFirstName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <Input
                                                aria-label="firstname"
                                                id="firstname"
                                                maxLength={254}
                                                type="firstname"
                                                name="firstname"
                                                defaultValue={contactFirstName}
                                                onChange={(e) =>
                                                    setContactFirstName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="lastname">Nom :</Label>{' '}
                                        <br />
                                        {contactMode === 'post' ? (
                                            <Input
                                                aria-label="lastname"
                                                id="lastname"
                                                maxLength={254}
                                                type="lastname"
                                                name="lastname"
                                                onChange={(e) =>
                                                    setContactLastName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <Input
                                                aria-label="lastname"
                                                id="lastname"
                                                maxLength={254}
                                                type="lastname"
                                                name="lastname"
                                                defaultValue={contactLastName}
                                                onChange={(e) =>
                                                    setContactLastName(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label htmlFor="phone">
                                            N° portable :
                                        </Label>{' '}
                                        <br />
                                        {contactMode === 'post' ? (
                                            <Input
                                                placeholder="06********"
                                                aria-label="portable"
                                                id="portable"
                                                maxLength={10}
                                                type="tel"
                                                name="phone"
                                                onChange={(e) =>
                                                    setContactPhone(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        ) : (
                                            <Input
                                                placeholder="06********"
                                                aria-label="portable"
                                                id="portable"
                                                maxLength={10}
                                                type="tel"
                                                name="phone"
                                                defaultValue={contactPhone}
                                                onChange={(e) =>
                                                    setContactPhone(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                    </FormGroup>
                                </div>
                                <Button
                                    variant="primary mx-auto shadow"
                                    type="submit"
                                >
                                    Sauvegarder
                                </Button>
                            </form>
                        </Offcanvas.Body>
                    </Offcanvas>

                    <Offcanvas
                        show={openContactDelete}
                        onHide={(e) => setOpenContactDelete(!openContactDelete)}
                        placement={'bottom'}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title className="text-warning">
                                Supprimer un Contact <FaUserTimes />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <p>Etes-vous sûr de supprimer ce contact ?</p>
                            <Button
                                variant="danger"
                                onClick={(e) => handleSubmitDeleteContact(e)}
                            >
                                Je confirme la suppression <IoTrash />
                            </Button>
                        </Offcanvas.Body>{' '}
                    </Offcanvas>
                </div>
            )}
        </>
    );
}
