import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';

import { useFetchGetLocationSettings } from '../../utils/Api/FetchLocationSettings.jsx';
import { getApartments } from '../../Store/Actions/smoobu.actions';

export default function StancerDetailsGestion() {
    const accessToken = useSelector((store) => store.User.accessToken);
    const dispatch = useDispatch();
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isLoading, setLoading] = useState(true);
    const { dataLocationSettings } = useFetchGetLocationSettings();
    const today = new Date();
    const deltaday = new Date().setDate(today.getDay() - 21);
    const deltaDateStart = new Date(deltaday).toISOString().split('T')[0];
    const [dateStart, setDateStart] = useState(
        String(new Date(deltaday).toISOString().split('T')[0])
    );
    const [setupDataCommande, setSetupDataCommande] = useState(undefined);
    const [setupDataCheckout, setSetupDataCheckout] = useState(undefined);
    const [setupDataPack, setSetupDataPack] = useState(undefined);
    const [dataPaymentId, setDataPaymentId] = useState(undefined);

    const [setupDataPayment, setSetupDataPayment] = useState(undefined);
    document.title = 'Stancer Bank | Ma Maison Sereine';
    document.description = 'Pages de gestion du logiciel Ma Maison Sereine';
    const handleRefresh = () => {
        window.location.reload();
    };
    const [openCommandeStancer, setOpenCommandeStancer] = useState(false);
    const [openPackStancer, setOpenPackStancer] = useState(false);
    const [openCheckOutStancer, setOpenCheckOutStancer] = useState(false);
    const [openCheckOutPackStancer, setOpenCheckOutPackStancer] =
        useState(false);
    const [openPaymentStancer, setOpenPaymentStancer] = useState(false);
    const [modalPaymentId, setModalPaymentId] = useState(false);
    const [paymentId, setPaymentId] = useState(0);
    useEffect(() => {
        async function fetchDataCommande() {
            setSetupDataCommande(undefined);
            const response = await fetch(
                'https://api.mamaisonsereine.fr/api/locations/stancer/get/reservation/',
                {
                    method: 'get',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json',
                    }),
                }
            );
            const data = await response.json();

            if (response.status === 200) {
                setSetupDataCommande(data);
                setLoading(false);
            } else if (data.status === 500) {
                toast.error(
                    'Le serveur Ser1/Stancer API Search Booking est en erreur',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        fetchDataCommande();

        const interval = setInterval(() => fetchDataCommande(), 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        async function fetchDataPack() {
            setSetupDataPack(undefined);
            const response = await fetch(
                'https://api.mamaisonsereine.fr/api/locations/stancer/get/pack/',
                {
                    method: 'get',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json',
                    }),
                }
            );
            const data = await response.json();

            if (response.status === 200) {
                setSetupDataPack(data);
                setLoading(false);
            } else if (data.status === 500) {
                toast.error(
                    'Le serveur Ser1/Stancer API Search Pack est en erreur',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        fetchDataPack();

        const interval = setInterval(() => fetchDataPack(), 60000);

        return () => {
            clearInterval(interval);
        };
    }, [openPackStancer]);
    useEffect(() => {
        async function fetchDataCheckout() {
            setSetupDataCheckout(undefined);
            const response = await fetch(
                `https://api.mamaisonsereine.fr/api/locations/stancer/get/checkout/${dateStart}/`,
                {
                    method: 'get',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json',
                    }),
                }
            );
            const data = await response.json();

            if (response.status === 200) {
                setSetupDataCheckout(data.payments);
                setLoading(false);
            } else if (response.status === 500) {
                toast.error(
                    'Le serveur Ser1/Stancer API Checkout est en erreur',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 401) {
                toast.error(
                    'Le serveur Stancer API checkout refuse la connexion',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        fetchDataCheckout();

        const interval = setInterval(() => fetchDataCheckout(), 60000);

        return () => {
            clearInterval(interval);
        };
    }, [dateStart]);

    useEffect(() => {
        async function fetchDataPayouts() {
            setSetupDataPayment(undefined);
            const response = await fetch(
                `https://api.mamaisonsereine.fr/api/locations/stancer/get/payouts/${dateStart}/`,
                {
                    method: 'get',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json',
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setSetupDataPayment(data.payouts);
                setLoading(false);
            } else if (response.status === 500) {
                toast.error(
                    'Le serveur Ser1/Stancer API Payouts est en erreur',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else if (response.status === 401) {
                toast.error(
                    'Le serveur Stancer API payouts refuse la connexion',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        fetchDataPayouts();

        const interval = setInterval(() => fetchDataPayouts(), 60000);

        return () => {
            clearInterval(interval);
        };
    }, [dateStart]);

    useEffect(() => {
        async function fetchDataPayouts() {
            setDataPaymentId(undefined);
            const response = await fetch(
                `https://api.mamaisonsereine.fr/api/locations/stancer/get_statut/order/${paymentId}/`,
                {
                    method: 'get',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        'content-type': 'application/json',
                    }),
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                setDataPaymentId(data);
            } else if (response.status === 500) {
                toast.error(
                    'Le serveur Ser1/Stancer API Payouts est en erreur',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        if (paymentId !== 0) {
            fetchDataPayouts();
        }
    }, [paymentId, setPaymentId]);

    return (
        <main>
            <h1 className="text-center mx-auto text-warning">Stancer</h1>
            <div>
                <p className="text-center">Critères de recherche :</p>
                <div className="d-flex flex-row flex-wrap justify-content-center">
                    <div className="d-flex flex-column justify-content-center align-items-center mx-3">
                        <label>Date de Début</label>
                        <input
                            className="text-control"
                            type="date"
                            id="dateStart"
                            name="dateStart"
                            defaultValue={deltaDateStart}
                            onChange={(e) => setDateStart(e.target.value)}
                            required
                        />
                    </div>{' '}
                    <Button
                        variant="primary shadow m-1"
                        onClick={handleRefresh}
                    >
                        <i className="fa-solid fa-arrows-rotate"></i>
                    </Button>
                </div>
                <div className="text-center">
                    <small>
                        MAJ auto toutes les minutes et affichage des 100
                        premiers résultats
                    </small>
                </div>
                <hr className="col-7  mx-auto m-1" />
            </div>{' '}
            <div className="logs_onglets d-flex flex-row justify-content-around">
                <div className="d-flex flex-column">
                    <Button
                        variant="primary shadow m-1"
                        onClick={(e) => {
                            setOpenCommandeStancer(!openCommandeStancer);
                            setOpenPackStancer(false);
                            setOpenCheckOutStancer(false);
                            setOpenCheckOutPackStancer(false);
                            setOpenPaymentStancer(false);
                        }}
                    >
                        <i className="fa-regular fa-rectangle-list"></i> Etat
                        Réservations
                    </Button>
                    <Button
                        variant="purple shadow m-1"
                        onClick={(e) => {
                            setOpenCommandeStancer(false);
                            setOpenPackStancer(!openPackStancer);
                            setOpenCheckOutStancer(false);
                            setOpenCheckOutPackStancer(false);
                            setOpenPaymentStancer(false);
                        }}
                    >
                        <i className="fa-solid fa-cubes"></i> Etat Packs Options
                    </Button>
                </div>

                <Button
                    variant="primary shadow m-1"
                    onClick={(e) => {
                        setOpenCommandeStancer(false);
                        setOpenPackStancer(false);
                        setOpenCheckOutStancer(!openCheckOutStancer);
                        setOpenCheckOutPackStancer(false);
                        setOpenPaymentStancer(false);
                    }}
                >
                    <i className="me-1 fa-regular fa-credit-card"></i> Paiement
                    CB
                </Button>

                <Button
                    variant="warning text-white shadow m-1"
                    onClick={(e) => {
                        setOpenCommandeStancer(false);
                        setOpenPackStancer(false);
                        setOpenCheckOutStancer(false);
                        setOpenCheckOutPackStancer(false);
                        setOpenPaymentStancer(!openPaymentStancer);
                    }}
                >
                    <i className="fa-solid fa-money-bill-transfer"></i>{' '}
                    Transfert Virement
                </Button>
            </div>
            {openCommandeStancer === true && isLoading !== true && (
                <>
                    <hr />
                    <h2 className="text-center">Etat Réservation</h2>
                    <hr />
                    {setupDataCommande !== undefined &&
                        setupDataCommande.map((element) => (
                            <>
                                <div
                                    key={element.id}
                                    className="d-flex flex-column m-2 p-2"
                                >
                                    <p className="font-body m-2">
                                        #{element.id} - Res.Smoobu N°{' '}
                                        {element.ref_reservation}
                                        <span className="m-1 fs-6 my-2  bg-purple text-white rounded p-2">
                                            {element.total_amount} €
                                        </span>
                                    </p>
                                    <div className="my-2">
                                        {element.statut === 'Annulée' && (
                                            <span className="m-1 fs-6  my-2 bg-danger text-white rounded p-2">
                                                {element.statut}
                                            </span>
                                        )}
                                        {element.statut ===
                                            'En Attente de Paiement' && (
                                            <span className="m-1 fs-6 bg-warning rounded p-2">
                                                {element.statut}
                                            </span>
                                        )}
                                        {element.statut === 'Payée' && (
                                            <span className="m-1 my-2 fs-6 bg-primary text-white rounded p-2">
                                                {element.statut}
                                            </span>
                                        )}
                                        {element.statut === 'Payée' ||
                                            (element.statut ===
                                                'Accepté - La Banque a autorisé le paiement' && (
                                                <span className="m-1 my-2  fs-6 bg-primary text-white rounded p-2">
                                                    {element.statut}
                                                </span>
                                            ))}
                                    </div>
                                    <p>
                                        Création : {element.date_created} - MAJ
                                        : {element.date_last_update}
                                        <br />
                                        {element.last_name} {element.first_name}{' '}
                                        - {element.mail} - {element.number_port}{' '}
                                        <Link
                                            to={`/gestion/reservation/${element.ref_reservation}/`}
                                        >
                                            <Button variant="light border-primary shadow m-1">
                                                Vers la Reservation{' '}
                                                {element.apartment}{' '}
                                                <i className="ms-2 fa-solid fa-arrow-up-right-from-square"></i>
                                            </Button>
                                        </Link>
                                        <br />
                                        {element.statut === 'Payée' && (
                                            <span>
                                                Ref.Paiement :{' '}
                                                {element.payment_id_stancer}
                                            </span>
                                        )}
                                        <>
                                            Du{' '}
                                            <span className="text-success">
                                                <i className="fa-solid fa-plane-arrival"></i>
                                                {element.arrival}
                                            </span>
                                            <span className="mx-1">au</span>
                                            <span className="text-danger">
                                                <i className="fa-solid fa-plane-departure"></i>
                                                {element.departure}
                                            </span>
                                            <span>
                                                <i className=" ms-2 fa-solid fa-home"></i>
                                                {dataApartments !== null &&
                                                    dataApartments.map(
                                                        (appart) =>
                                                            appart.id ===
                                                                element.apartment && (
                                                                <>
                                                                    {
                                                                        appart.name
                                                                    }
                                                                </>
                                                            )
                                                    )}
                                            </span>
                                            <span className="ms-2 p-1 bg-primary text-white rounded shadow">
                                                <i className="fa-solid fa-moon"></i>{' '}
                                                {Math.trunc(
                                                    (new Date(
                                                        element.departure.slice(
                                                            0,
                                                            4
                                                        ),
                                                        element.departure.slice(
                                                            5,
                                                            7
                                                        ),
                                                        element.departure.slice(
                                                            8,
                                                            10
                                                        )
                                                    ) -
                                                        new Date(
                                                            element.arrival.slice(
                                                                0,
                                                                4
                                                            ),
                                                            element.arrival.slice(
                                                                5,
                                                                7
                                                            ),
                                                            element.arrival.slice(
                                                                8,
                                                                10
                                                            )
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                )}{' '}
                                                Nuités
                                            </span>
                                        </>
                                    </p>
                                    {element.payment_id_stancer !== null ? (
                                        <div>
                                            <Button
                                                variant="secondary"
                                                onClick={(e) => {
                                                    setModalPaymentId(
                                                        !modalPaymentId
                                                    );
                                                    setPaymentId(
                                                        element.payment_id_stancer
                                                    );
                                                }}
                                            >
                                                Infos Complète Paiement{' '}
                                                <i className="fa-solid fa-up-right-from-square"></i>
                                            </Button>
                                        </div>
                                    ) : (
                                        <>Aucune saisie bancaire détectée</>
                                    )}
                                    <hr className="col-4" />
                                </div>
                                <hr />
                            </>
                        ))}
                </>
            )}
            {openPackStancer === true && isLoading !== true && (
                <>
                    <hr />
                    <h2 className="text-center text-purple">
                        <i className="fa-solid fa-cubes"></i> Etat Packs
                        Optionnels
                    </h2>
                    <hr />
                    {setupDataPack !== undefined &&
                        setupDataPack.map((element) => (
                            <>
                                <div
                                    key={element.id}
                                    className=" bg-antique-light d-flex flex-column m-2 p-2 rounded border border-1 border-warning shadow"
                                >
                                    <p className="font-body m-2">
                                        <i className="fa-solid fa-cubes"></i> #
                                        {element.id} - Res.Smoobu N°
                                        {element.ref_reservation}
                                        <span className=" ms-3 my-2 fs-6 bg-purple text-white rounded p-2">
                                            {element.total_amount} €
                                        </span>
                                    </p>
                                    <div className="my-2">
                                        {element.statut === 'Annulée' && (
                                            <span className="m-1 fs-6 bg-danger text-white rounded p-2">
                                                {element.statut}
                                            </span>
                                        )}
                                        {element.statut ===
                                            'En Attente de Paiement' && (
                                            <span className="m-1 fs-6 bg-warning rounded p-2">
                                                {element.statut}
                                            </span>
                                        )}
                                        {(element.statut === 'Payé' ||
                                            element.statut ===
                                                'Accepté - La Banque a autorisé le paiement') && (
                                            <span className="m-1 fs-6 bg-primary text-white rounded p-2">
                                                {element.statut}
                                            </span>
                                        )}
                                    </div>
                                    <p>
                                        Création : {element.date_created} - MAJ
                                        : {element.date_last_update}
                                        <br />
                                        {element.last_name} {element.first_name}{' '}
                                        - {element.mail} - {element.number_port}{' '}
                                        <a
                                            target="_blank"
                                            href={`/gestion/reservation/${element.ref_reservation}/`}
                                        >
                                            <Button variant="light border-primary shadow m-1">
                                                Vers la Reservation{' '}
                                                {element.ref_reservation}{' '}
                                                <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                            </Button>
                                        </a>
                                        <br />
                                        {element.statut === 'Payée' && (
                                            <span>
                                                Ref.Paiement :{' '}
                                                {element.payment_id_stancer}
                                            </span>
                                        )}
                                        <>
                                            Du{' '}
                                            <span className="text-success">
                                                <i className="fa-solid fa-plane-arrival"></i>
                                                {element.arrival}
                                            </span>
                                            <span className="mx-1">au</span>
                                            <span className="text-danger">
                                                <i className="fa-solid fa-plane-departure"></i>
                                                {element.departure}
                                            </span>
                                            <span>
                                                <i className="ms-2 fa-solid fa-home"></i>
                                                {dataApartments !== null &&
                                                    dataApartments.map(
                                                        (appart) =>
                                                            appart.id ===
                                                                element.apartment && (
                                                                <>
                                                                    {
                                                                        appart.name
                                                                    }
                                                                </>
                                                            )
                                                    )}
                                            </span>
                                            <span className="ms-2 p-1 bg-primary text-white rounded shadow">
                                                <i className="fa-solid fa-moon"></i>{' '}
                                                {Math.trunc(
                                                    (new Date(
                                                        element.departure.slice(
                                                            0,
                                                            4
                                                        ),
                                                        element.departure.slice(
                                                            5,
                                                            7
                                                        ),
                                                        element.departure.slice(
                                                            8,
                                                            10
                                                        )
                                                    ) -
                                                        new Date(
                                                            element.arrival.slice(
                                                                0,
                                                                4
                                                            ),
                                                            element.arrival.slice(
                                                                5,
                                                                7
                                                            ),
                                                            element.arrival.slice(
                                                                8,
                                                                10
                                                            )
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                )}{' '}
                                                Nuités
                                            </span>
                                        </>
                                    </p>

                                    <hr className="col-4" />
                                    {element.payment_id_stancer !== null && (
                                        <>
                                            {' '}
                                            <div>
                                                <Button
                                                    variant="secondary"
                                                    onClick={(e) => {
                                                        setModalPaymentId(
                                                            !modalPaymentId
                                                        );
                                                        setPaymentId(
                                                            element.payment_id_stancer
                                                        );
                                                    }}
                                                >
                                                    Infos Complète Paiement{' '}
                                                    <i className="fa-solid fa-up-right-from-square"></i>
                                                </Button>
                                            </div>
                                            <hr className="col-4" />
                                        </>
                                    )}
                                    <div className="d-flex flex-column">
                                        {element.price_spa !== 0.0 && (
                                            <span>
                                                {' '}
                                                <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                                Forfait SPA :{element.price_spa}{' '}
                                                EUR - {element.horaire_spa}
                                            </span>
                                        )}
                                        {element.price_menage !== 0.0 && (
                                            <span>
                                                {' '}
                                                <i className="mx-1 fa-solid fa-broom fs-5"></i>{' '}
                                                Forfait Ménage :{' '}
                                                {element.price_menage} EUR
                                            </span>
                                        )}
                                        {element.price_animaux !== 0.0 && (
                                            <span>
                                                <i className="mx-1 fa-solid fa-paw fs-5"></i>{' '}
                                                Forfait Animaux :{' '}
                                                {element.price_animaux} €
                                            </span>
                                        )}
                                        {element.nber_linge_2p_blanc !== 0 && (
                                            <span>
                                                {' '}
                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                Forfait Pack Linge 2p Blanc :{' '}
                                                {element.nber_linge_2p_blanc} x{' '}
                                                {Math.trunc(
                                                    (new Date(
                                                        element.departure.slice(
                                                            0,
                                                            4
                                                        ),
                                                        element.departure.slice(
                                                            5,
                                                            7
                                                        ),
                                                        element.departure.slice(
                                                            8,
                                                            10
                                                        )
                                                    ) -
                                                        new Date(
                                                            element.arrival.slice(
                                                                0,
                                                                4
                                                            ),
                                                            element.arrival.slice(
                                                                5,
                                                                7
                                                            ),
                                                            element.arrival.slice(
                                                                8,
                                                                10
                                                            )
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                ) >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>
                                                        {dataLocationSettings.price_linge_2p_blanc -
                                                            dataLocationSettings.price_linge_2p_couleur}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {
                                                            dataLocationSettings.price_linge_2p_blanc
                                                        }{' '}
                                                        €
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                        {element.nber_linge_1p_blanc !== 0 && (
                                            <span>
                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                Forfait Pack Linge 1p Blanc :{' '}
                                                {element.nber_linge_1p_blanc} x
                                                {Math.trunc(
                                                    (new Date(
                                                        element.departure.slice(
                                                            0,
                                                            4
                                                        ),
                                                        element.departure.slice(
                                                            5,
                                                            7
                                                        ),
                                                        element.departure.slice(
                                                            8,
                                                            10
                                                        )
                                                    ) -
                                                        new Date(
                                                            element.arrival.slice(
                                                                0,
                                                                4
                                                            ),
                                                            element.arrival.slice(
                                                                5,
                                                                7
                                                            ),
                                                            element.arrival.slice(
                                                                8,
                                                                10
                                                            )
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                ) >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>
                                                        {dataLocationSettings.price_linge_1p_blanc -
                                                            dataLocationSettings.price_linge_1p_couleur}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {
                                                            dataLocationSettings.price_linge_1p_blanc
                                                        }{' '}
                                                        €
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                        {element.nber_linge_2p_couleur !==
                                            0 && (
                                            <span>
                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                Forfait Pack Linge 2p Couleur :{' '}
                                                {element.nber_linge_2p_couleur}{' '}
                                                x{' '}
                                                {Math.trunc(
                                                    (new Date(
                                                        element.departure.slice(
                                                            0,
                                                            4
                                                        ),
                                                        element.departure.slice(
                                                            5,
                                                            7
                                                        ),
                                                        element.departure.slice(
                                                            8,
                                                            10
                                                        )
                                                    ) -
                                                        new Date(
                                                            element.arrival.slice(
                                                                0,
                                                                4
                                                            ),
                                                            element.arrival.slice(
                                                                5,
                                                                7
                                                            ),
                                                            element.arrival.slice(
                                                                8,
                                                                10
                                                            )
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                ) >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>Inclus</span>
                                                ) : (
                                                    <span>
                                                        {
                                                            dataLocationSettings.price_linge_2p_couleur
                                                        }{' '}
                                                        €
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                        {element.nber_linge_1p_couleur !==
                                            0 && (
                                            <span>
                                                <i className="mx-1 fa-solid fa-bed fs-5"></i>{' '}
                                                Forfait Pack Linge 1p Couleur :{' '}
                                                {element.nber_linge_1p_couleur}{' '}
                                                x{' '}
                                                {Math.trunc(
                                                    (new Date(
                                                        element.departure.slice(
                                                            0,
                                                            4
                                                        ),
                                                        element.departure.slice(
                                                            5,
                                                            7
                                                        ),
                                                        element.departure.slice(
                                                            8,
                                                            10
                                                        )
                                                    ) -
                                                        new Date(
                                                            element.arrival.slice(
                                                                0,
                                                                4
                                                            ),
                                                            element.arrival.slice(
                                                                5,
                                                                7
                                                            ),
                                                            element.arrival.slice(
                                                                8,
                                                                10
                                                            )
                                                        )) /
                                                        (1000 * 60 * 60 * 24)
                                                ) >
                                                dataLocationSettings.number_night_linge ? (
                                                    <span>Inclus</span>
                                                ) : (
                                                    <span>
                                                        {
                                                            dataLocationSettings.price_linge_1p_couleur
                                                        }{' '}
                                                        €
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <hr />
                            </>
                        ))}
                </>
            )}
            {openCheckOutStancer === true && isLoading !== true && (
                <>
                    <hr />
                    <h2 className="text-center">
                        <i className="me-1 fa-regular fa-credit-card"></i>{' '}
                        Paiement CB
                    </h2>
                    <hr />{' '}
                    {setupDataCheckout !== undefined &&
                        setupDataCheckout.map((element) => (
                            <div key={element.id} className="m-2 p-2">
                                <div className="text-primary">
                                    Saisie Paiement{' '}
                                    <i className="fa-solid fa-barcode"></i>{' '}
                                    {element.id}
                                </div>
                                <p>
                                    Initié le :
                                    <i className="mx-1 fa-regular fa-clock"></i>
                                    {new Date(
                                        parseInt(element.created + '000', 10)
                                    ).toLocaleString()}
                                    <br />
                                    <i className="fa-solid fa-house"></i>
                                    {element.description}
                                    {element.description.includes(
                                        'Réservation'
                                    ) === true && (
                                        <>
                                            <a
                                                target="_blank"
                                                href={`/gestion/reservation/${element.description.slice(
                                                    53,
                                                    68
                                                )}/`}
                                                className="mx-auto"
                                            >
                                                <Button
                                                    color="primary"
                                                    className="mx-auto my-1"
                                                >
                                                    <i className="fa-solid fa-up-right-from-square"></i>
                                                </Button>
                                            </a>
                                        </>
                                    )}
                                    {element.description.includes('PACK#') ===
                                        true && (
                                        <>
                                            <a
                                                target="_blank"
                                                href={`/gestion/reservation/${element.description.slice(
                                                    42,
                                                    50
                                                )}/`}
                                                className="mx-auto"
                                            >
                                                <Button
                                                    color="primary"
                                                    className="mx-auto my-1"
                                                >
                                                    <i className="fa-solid fa-up-right-from-square"></i>
                                                </Button>
                                            </a>
                                        </>
                                    )}
                                </p>
                                <p>
                                    <i className="fa-regular fa-credit-card"></i>{' '}
                                    {element.card !== undefined ? (
                                        <span>
                                            {element.card.brand} -{' '}
                                            {element.card.name} saisie le :{' '}
                                            {new Date(
                                                parseInt(
                                                    element.card.created +
                                                        '000',
                                                    10
                                                )
                                            ).toLocaleString()}
                                        </span>
                                    ) : (
                                        <span>Aucune CB de saisie</span>
                                    )}
                                </p>
                                <p className="text-primary">
                                    Montant :{' '}
                                    <i className="fa-solid fa-money-check-dollar"></i>{' '}
                                    {element.amount / 100} {element.currency}
                                    <br />
                                    {element.fee !== null && (
                                        <>
                                            Frais Stancer{' '}
                                            <span className="text-danger">
                                                <i className="fa-solid fa-minus"></i>{' '}
                                                {element.fee / 100}{' '}
                                                {element.currency}
                                            </span>
                                        </>
                                    )}{' '}
                                </p>

                                <p>
                                    Retour Stancer :{' '}
                                    {element.status === null ? (
                                        <span className="bg-danger text-white p-1 rounded">
                                            <i className=" fa-solid fa-triangle-exclamation"></i>
                                            Non aboutie
                                        </span>
                                    ) : element.status === 'captured' ? (
                                        <span className="bg-primary text-white p-1 rounded">
                                            <i className=" fa-solid fa-check me-1"></i>
                                            Réglé
                                        </span>
                                    ) : (
                                        <>
                                            {element.status} - Code Erreur
                                            Stancer : {element.response}
                                            <a
                                                href={
                                                    'https://www.stancer.com/documentation/fr/api/?lang=python#card-response-codes'
                                                }
                                                target="_blank"
                                            >
                                                {' '}
                                                <span className=" rounded shadow p-2 m-2 bg-danger text-white">
                                                    <i className="fa-solid fa-up-right-from-square"></i>
                                                </span>
                                            </a>
                                        </>
                                    )}
                                </p>
                                <Button
                                    color="danger"
                                    onClick={(e) => {
                                        setModalPaymentId(!modalPaymentId);
                                        setPaymentId(element.id);
                                    }}
                                >
                                    Infos Complète{' '}
                                    <i className="fa-solid fa-up-right-from-square"></i>
                                </Button>

                                <hr />
                            </div>
                        ))}
                </>
            )}
            {openPaymentStancer === true && isLoading !== true && (
                <>
                    <hr />
                    <h2 className="text-center">
                        <i className="fa-solid fa-money-bill-transfer"></i>{' '}
                        Transfert Virement Stancer
                    </h2>
                    <hr />
                    {setupDataPayment !== undefined &&
                        setupDataPayment.reverse().map((element) => (
                            <div key={element.id} className="m-2 p-2">
                                <div className="text-primary">
                                    Encaissement Paiement{' '}
                                    <i className="fa-solid fa-barcode"></i>{' '}
                                    {element.id}
                                </div>
                                <p>
                                    Créé le{' '}
                                    <i className="mx-1 fa-regular fa-clock"></i>{' '}
                                    {new Date(
                                        parseInt(element.created + '000', 10)
                                    ).toLocaleString()}{' '}
                                    <br /> Débité du client le :
                                    <i className="mx-1 fa-regular fa-clock"></i>
                                    {new Date(
                                        parseInt(element.date_paym + '000', 10)
                                    ).toLocaleString()}{' '}
                                    <br /> Encaissé Bank Ser1 le :{' '}
                                    {element.date_bank === null ? (
                                        <span className="bg-warning rounded px-1">
                                            En attente de virement sur Ser1
                                        </span>
                                    ) : (
                                        <>
                                            <i className="mx-1 fa-regular fa-clock"></i>
                                            {new Date(
                                                parseInt(
                                                    element.date_bank + '000',
                                                    10
                                                )
                                            ).toLocaleString()}
                                        </>
                                    )}
                                    <br />
                                    Description Stancer :
                                    {element.statement_description}
                                    <br />
                                    Montant initial :{' '}
                                    {element.payments.amount / 100}{' '}
                                    {element.currency} - {element.fees / 100}{' '}
                                    {element.currency}
                                    <br />
                                    <br />
                                    <span className="bg-primary text-white p-2 rounded">
                                        Montant frais déduit :{' '}
                                        {element.total / 100} {element.currency}
                                    </span>
                                </p>
                                <hr />
                            </div>
                        ))}
                </>
            )}
            <Modal isOpen={modalPaymentId} backdrop={false} size={'xl'}>
                <ModalHeader>
                    Détail Paiment{' '}
                    {dataPaymentId !== undefined && dataPaymentId.id}
                    <Button
                        color="secondary"
                        onClick={(e) => setModalPaymentId(false)}
                    >
                        Fermer
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <div className="p-2">
                        <h2>
                            {' '}
                            <i className="fa-solid fa-user "></i> Client :{' '}
                        </h2>
                        <p className="d-flex flex-column">
                            <span>
                                Email :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.customer !== undefined &&
                                    dataPaymentId.customer.email}
                            </span>
                            <span>
                                id :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.customer !== undefined &&
                                    dataPaymentId.customer.id}
                            </span>
                            <span>
                                Live_mode :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.customer !== undefined && (
                                        <>
                                            {dataPaymentId !== undefined &&
                                            dataPaymentId.customer !==
                                                undefined &&
                                            dataPaymentId.customer.live_mode ===
                                                true
                                                ? 'true'
                                                : 'false'}
                                        </>
                                    )}
                            </span>
                            <span>
                                Mobile :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.customer !== undefined &&
                                    dataPaymentId.customer.mobile}
                            </span>
                            <span>
                                Name :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.customer !== undefined &&
                                    dataPaymentId.customer.name}
                            </span>
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>
                            {' '}
                            <i className="fa-solid fa-money-bill-transfer"></i>
                            Paiement Détails :{' '}
                        </h2>
                        <p className="d-flex flex-column">
                            <span className="fs-6 text-warning fw-bold">
                                OrderID :{' '}
                                {dataPaymentId !== undefined && (
                                    <>{dataPaymentId.order_id}</>
                                )}
                            </span>
                            <span className="fs-6 text-warning fw-bold">
                                Description :{' '}
                                {dataPaymentId !== undefined && (
                                    <>{dataPaymentId.description}</>
                                )}
                            </span>
                            <span>
                                Créé :{' '}
                                {dataPaymentId !== undefined && (
                                    <>
                                        {new Date(
                                            dataPaymentId.created * 1000
                                        ).toLocaleDateString()}{' '}
                                        -{' '}
                                        {new Date(
                                            dataPaymentId.created * 1000
                                        ).toLocaleTimeString()}
                                    </>
                                )}
                            </span>
                            <span>
                                Montant :{' '}
                                {dataPaymentId !== undefined && (
                                    <>
                                        {dataPaymentId.amount / 100}{' '}
                                        {dataPaymentId.currency}
                                    </>
                                )}
                            </span>
                            <span className="text-danger">
                                Frais Stancer :{' '}
                                {dataPaymentId !== undefined && (
                                    <>
                                        {' '}
                                        {' - '}
                                        {dataPaymentId.fee / 100}{' '}
                                        {dataPaymentId.currency}
                                    </>
                                )}
                            </span>
                            <span>
                                Date Paiement :{' '}
                                {dataPaymentId !== undefined && (
                                    <>
                                        {new Date(
                                            dataPaymentId.date_paym * 1000
                                        ).toLocaleDateString()}{' '}
                                        -{' '}
                                        {new Date(
                                            dataPaymentId.date_paym * 1000
                                        ).toLocaleTimeString()}
                                    </>
                                )}
                            </span>
                            <span>
                                Date Virement Bank :{' '}
                                {dataPaymentId !== undefined &&
                                dataPaymentId.date_bank !== null ? (
                                    <span className="text-success">
                                        {new Date(
                                            dataPaymentId.date_bank * 1000
                                        ).toLocaleDateString()}{' '}
                                        -{' '}
                                        {new Date(
                                            dataPaymentId.date_bank * 1000
                                        ).toLocaleTimeString()}
                                    </span>
                                ) : (
                                    <span className="text-danger">
                                        Aucun Virement réalisé
                                    </span>
                                )}
                            </span>
                            <span>
                                Method :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.method}
                            </span>

                            <span className="bg-warning p-2 my-1">
                                Code Retour Stancer :{' '}
                                {dataPaymentId !== undefined &&
                                dataPaymentId.response !== null ? (
                                    <>
                                        {' '}
                                        Code{' '}
                                        {dataPaymentId.response === '00' ? (
                                            <>
                                                Approbation/achèvement réussi ou
                                                la vérification du code PIN VIP
                                                est valide
                                            </>
                                        ) : (
                                            <>{dataPaymentId.response}</>
                                        )}{' '}
                                        -{' '}
                                    </>
                                ) : (
                                    <>
                                        Aucun aboutissement de paiement - sans
                                        réponse Stancer
                                    </>
                                )}
                            </span>

                            <span className="bg-warning p-2 my-1">
                                Statut Définitif :{' '}
                                {dataPaymentId !== undefined &&
                                dataPaymentId.status !== null ? (
                                    <>{dataPaymentId.status}</>
                                ) : (
                                    <>Aucun Aboutissement - ECHEC</>
                                )}
                                <a
                                    href={
                                        'https://www.stancer.com/documentation/fr/api/?lang=python#card-response-codes'
                                    }
                                    target="_blank"
                                >
                                    {' '}
                                    <span className=" rounded shadow p-2 m-2 bg-danger text-white">
                                        <i className="fa-solid fa-up-right-from-square"></i>
                                    </span>
                                </a>
                            </span>
                        </p>
                        <hr className="col-6 mx-auto" />
                        <h2>
                            {' '}
                            <i className="fa-regular fa-credit-card"></i> Carte
                            Détails :{' '}
                        </h2>
                        <p className="d-flex flex-column">
                            <span>
                                Pays :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined &&
                                    dataPaymentId.card.country}
                            </span>
                            <span>
                                Created :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined && (
                                        <>
                                            {new Date(
                                                dataPaymentId.card.created *
                                                    1000
                                            ).toLocaleDateString()}{' '}
                                            -{' '}
                                            {new Date(
                                                dataPaymentId.card.created *
                                                    1000
                                            ).toLocaleTimeString()}
                                        </>
                                    )}
                            </span>
                            <span>
                                Live_mode :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined && (
                                        <>
                                            {dataPaymentId !== undefined &&
                                            dataPaymentId.card !== undefined &&
                                            dataPaymentId.card.live_mode ===
                                                true
                                                ? 'true'
                                                : 'false'}
                                        </>
                                    )}
                            </span>
                            <span>
                                Empreinte Card ID :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined && (
                                        <>
                                            {dataPaymentId !== undefined &&
                                                dataPaymentId.card !==
                                                    undefined &&
                                                dataPaymentId.card.id}
                                        </>
                                    )}
                            </span>
                            <span>
                                Marque - Réseau utilisé :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined &&
                                    dataPaymentId.card.brand}{' '}
                                &{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined &&
                                    dataPaymentId.card.network}
                            </span>

                            <span>
                                Number :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined && (
                                        <>
                                            {' '}
                                            **** **** ****{' '}
                                            {dataPaymentId !== undefined &&
                                                dataPaymentId.card !==
                                                    undefined &&
                                                dataPaymentId.card.last4}
                                        </>
                                    )}
                            </span>
                            <span>
                                Exp :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined && (
                                        <>
                                            {dataPaymentId !== undefined &&
                                                dataPaymentId.card !==
                                                    undefined &&
                                                dataPaymentId.card.exp_month}
                                            /
                                            {dataPaymentId !== undefined &&
                                                dataPaymentId.card !==
                                                    undefined &&
                                                dataPaymentId.card.exp_year}
                                        </>
                                    )}
                            </span>
                            <span>
                                Funding :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined && (
                                        <>
                                            {dataPaymentId !== undefined &&
                                                dataPaymentId.card !==
                                                    undefined &&
                                                dataPaymentId.card.funding}
                                        </>
                                    )}
                            </span>
                            <span>
                                Name - Nature :{' '}
                                {dataPaymentId !== undefined &&
                                    dataPaymentId.card !== undefined && (
                                        <>
                                            {dataPaymentId !== undefined &&
                                                dataPaymentId.card !==
                                                    undefined &&
                                                dataPaymentId.card.name}{' '}
                                            -{' '}
                                            {dataPaymentId !== undefined &&
                                                dataPaymentId.card !==
                                                    undefined &&
                                                dataPaymentId.card.nature}
                                        </>
                                    )}
                            </span>
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary"
                        onClick={(e) => setModalPaymentId(false)}
                    >
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
        </main>
    );
}
