import { useSelector } from 'react-redux';

import ABDLlogo from '../../assets/img/ABDL/logoABDL.jpg';
import ABDRlogo from '../../assets/img/ABDR/logoABDR.jpg';

export default function WidgetCGL() {
    const translate = useSelector((store) => store.Template.language);
    return (
        <>
            {translate === 'French' && (
                <>
                    <h1 className="fs-1  text-center ">
                        Conditions Générales de Locations - Reglement Interieur{' '}
                        <br />à compter du 28 Mars 2023
                    </h1>
                    <p className="text-center fw-bold">
                        Les présentes mentions légales sont soumises à la loi
                        française.
                    </p>
                </>
            )}
            {translate === 'English' && (
                <>
                    <h1 className="fs-1 text-center ">
                        General Rental Conditions - Internal Regulations <br />
                        from March 28, 2023
                    </h1>
                    <p className="text-center fw-bold">
                        These legal notices are subject to the law French.
                    </p>
                </>
            )}
            <div className="m-4 p-4">
                <div className="locations__header__details__logo d-flex justify-content-around w-75 mx-auto">
                    <img
                        src={ABDRlogo}
                        alt={'Logo - Au Bord du récif'}
                        title={'Logo - Au Bord du récif'}
                        loading="lazy"
                    />
                    <img
                        src={ABDLlogo}
                        alt={"Logo - Au Bord de l'Eau"}
                        title={"Logo - Au Bord de l'Eau"}
                        loading="lazy"
                    />
                </div>
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <section className="p-3">
                        <h2 className="fs-1">Dispositions générales</h2>
                        <p>
                            Le locataire ne pourra en aucune circonstance se
                            prévaloir d'un quelconque droit au maintien dans les
                            lieux à l'expiration de la période de location
                            initialement prévue sur le présent contrat, sauf
                            accord du propriétaire.
                        </p>
                        <p>
                            Aucune modification (rature, surcharge...) ne sera
                            acceptée dans la rédaction du contrat sans l'accord
                            des deux parties.
                        </p>
                        <p>
                            Le propriétaire s'engage à ne divulguer à aucun
                            tiers les informations de quelques natures que ce
                            soit, sur quelque support que ce soit, que le
                            locataire aura été amené à lui donner à l'occasion
                            de l'exécution du présent contrat.
                        </p>
                        <p>
                            Ces dernières dispositions ne sont toutefois pas
                            applicables s'agissant des demandes de
                            renseignements qui seraient formulées par les
                            administrations et/ou les tribunaux.
                        </p>
                    </section>
                )}
                {translate === 'English' && (
                    <section className="p-3">
                        <h2 className="fs-1">General Provisions</h2>
                        <p>
                            The tenant may not under any circumstances avail of
                            any right to maintenance in the places at the end of
                            the rental period initially provided for in this
                            contract, except owner consent.
                        </p>
                        <p>
                            No modification (erasures, overwriting, etc.) will
                            be accepted in the drafting of the contract without
                            the agreement of both parties.
                        </p>
                        <p>
                            The owner agrees not to disclose to any third
                            parties information of any nature whatsoever either,
                            on any medium whatsoever, that the tenant will have
                            been required to give him on occasion of the
                            execution of this contract.
                        </p>
                        <p>
                            However, these latter provisions are not applicable
                            with regard to requests for information that would
                            be provided by the administrations and/or the
                            courts.
                        </p>
                    </section>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">Paiement</h2>
                            <p>
                                La réservation deviendra effective dès lors que
                                le locataire aura retourné un exemplaire du
                                présent contrat signé accompagné du montant
                                total dû pour la location.
                            </p>
                            <p>
                                Le locataire accepte que l'état des lieux puisse
                                être effectué par le mandataire du propriétaire
                                dûment habilité et muni d'un pouvoir écrit.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Assurance</h2>
                            <p>
                                Le locataire doit fournir un justificatif
                                d’assurance de responsabilité civil pour la
                                location.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">Payment</h2>
                            <p>
                                The reservation will become effective when the
                                lessee will have returned a copy of the this
                                signed contract accompanied by the amount total
                                due for rental.
                            </p>
                            <p>
                                The tenant accepts that the inventory of
                                fixtures can be carried out by the agent of the
                                owner duly authorized and provided with a
                                written power of attorney.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Insurance</h2>
                            <p>
                                The tenant must provide proof liability
                                insurance for the lease.
                            </p>
                        </section>
                    </>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">
                                Dépôt de garantie (ou caution){' '}
                            </h2>
                            <p>
                                Le locataire devra fournir une caution d'un
                                montant de 300 € en même temps que la
                                réservation.
                            </p>
                            <p>
                                Celle-ci sera restituée dans un délai maximum de
                                sept jours après le départ du locataire,
                                déduction faite, par le gestionnaire des
                                montants à la charge du locataire aux fins de
                                remise en état des lieux, réparations diverses.
                                Attention quelque soit le montant des
                                dégradations le locataire devras les remboursés
                                caution verser au non dans un délais de 30
                                jours.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Article 4 : Devis</h2>
                            <p>
                                L’exploitant individuel Hennion Fabien exerçant
                                sous le nom commercial « SER1 » et l’enseigne :
                                « Ma maison sereine » remet gratuitement un
                                devis au client qui en fait la demande sous
                                condition de créneaux et de temps disponibles.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">
                                Security deposit (or surety){' '}
                            </h2>
                            <p>
                                The tenant must provide a deposit of one amount
                                of €300 at the same time as the reservation.
                            </p>
                            <p>
                                This will be returned within a maximum period of
                                seven days after the departure of the tenant,
                                after deduction, by the manager of the amounts
                                payable by the lessee for the purposes of
                                restoration of the premises, miscellaneous
                                repairs. Be careful whatever the amount of
                                damage the tenant will have to reimburse them
                                deposit paid or no within 30 days.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Article 4: Quotation</h2>
                            <p>
                                The sole proprietor Hennion Fabien exercising
                                under the commercial name “SER1” and the sign:
                                "My serene house" provides a free quote to the
                                customer who requests it under condition of
                                slots and time available.
                            </p>
                        </section>
                    </>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <section className="p-3">
                        <h2 className="fs-1">Arrivée — départ</h2>
                        <p>
                            <i className="text-success fa-solid fa-plane-arrival"></i>{' '}
                            Arrivée :
                        </p>
                        <ul>
                            <li>
                                Elle est prévue à partir de 17h via le système
                                de boite à clés. Le code lui sera donné quelques
                                heures avant son arrivée.
                            </li>
                        </ul>
                        <p>
                            <i className="text-danger fa-solid fa-plane-departure"></i>{' '}
                            Départ :
                        </p>
                        <ul>
                            <li>
                                Le locataire devra quitter les lieux avant 10h
                                et devra remettre la clé dans la boite à clés
                                associé au logement. Dans ce cas, le mandataire
                                passera faire l'état des lieux après le départ
                                du locataire.
                            </li>
                        </ul>
                        <p>
                            <i className="fa-solid fa-home"></i> Utilisation des
                            lieux :
                        </p>
                        <ul>
                            <li>
                                Le locataire jouira de la location d'une manière
                                paisible et en fera bon usage, conformément à la
                                Destination des lieux.
                            </li>
                            <li>
                                A son départ, le locataire s'engage à rendre la
                                location aussi propre qu'il la trouvé à son
                                arrivée. L'ensemble du matériel, devra être
                                remis à la place qu'il occupait lors de l'entrée
                                dans les lieux. Toute réparations quelle qu'en
                                soit l'importance, rendue nécessaire par la
                                négligence du locataire encours de location
                                seront à sa charge.
                            </li>
                        </ul>
                        <p>
                            La location ne peut en aucun cas bénéficier à des
                            tiers, sauf accord préalable du propriétaire. La
                            sous location est interdite au preneur, sous quelque
                            prétexte que ce soit, même à titre gratuit, sous
                            peine de résiliation du contrat. Le montant intégral
                            du loyer restant acquis ou dû reviendra au
                            propriétaire.
                        </p>
                        <p>
                            Les locaux loués sont à usage d'habitation
                            provisoire ou de vacances, excluant toute activité
                            professionnelle, commerciale ou artisanale de
                            quelque nature que ce soit.
                        </p>
                        <p>
                            Le propriétaire fournira le logement conforme à la
                            description qu'il en a faite et le maintiendra en
                            état de servir. En règle générale, le locataire
                            quitte les lieux à l'heure prévue au contrat ou à
                            une heure convenant au propriétaire, après état des
                            lieux.
                        </p>
                    </section>
                )}
                {translate === 'English' && (
                    <section className="p-3">
                        <h2 className="fs-1">Arrival — departure</h2>
                        <p>
                            <i className="text-success fa-solid fa-plane-arrival"></i>{' '}
                            Arrival :
                        </p>
                        <ul>
                            <li>
                                It is scheduled from 5 p.m. via the system of
                                key box. The code will be given to him a few
                                hours before his arrival.
                            </li>
                        </ul>
                        <p>
                            <i className="text-danger fa-solid fa-plane-departure"></i>{' '}
                            Departure :
                        </p>
                        <ul>
                            <li>
                                The tenant must leave the premises before 10
                                a.m. and will have to put the key back in the
                                key box associated with housing. In this case,
                                the agent will come to do the inventory after
                                departure of the tenant.
                            </li>
                        </ul>
                        <p>
                            <i className="fa-solid fa-home"></i> Using the
                            places :
                        </p>
                        <ul>
                            <li>
                                The tenant will enjoy the rental in a way
                                peaceful and will make good use of it, in
                                accordance with the Destination of places.
                            </li>
                            <li>
                                On his departure, the tenant agrees to return
                                the rental as clean as he found it at his
                                arrival. All equipment must be returned to the
                                place it occupied when entering in places. Any
                                repairs whatsoever either the importance, made
                                necessary by the negligence of the tenant
                                outstanding rental will be at his expense.
                            </li>
                        </ul>
                        <p>
                            The rental can in no way benefit from third parties,
                            except with the prior consent of the owner. There
                            subletting is prohibited to the lessee, under any
                            pretext whatsoever, even free of charge, under
                            penalty of termination of the contract. The full
                            amount of the rent remaining acquired or due will go
                            to the owner.
                        </p>
                        <p>
                            The rented premises are for residential use
                            temporary or vacation, excluding any activity
                            professional, commercial or artisanal any kind
                            whatsoever.
                        </p>
                        <p>
                            The owner will provide accommodation in accordance
                            with the description he made of it and will keep it
                            in state of serving. Generally, the tenant leaves
                            the premises at the time stipulated in the contract
                            or a time convenient to the owner, after state of
                            the places.
                        </p>
                    </section>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">Cas particulier</h2>
                            <p>
                                Le nombre de locataire ne peut être supérieur à
                                la capacité d'accueil maximum indiqué sur le
                                catalogue ou descriptif. A titre exceptionnel et
                                sous réserve de l'accord du propriétaire, il
                                pourra être dérogé à cette règle.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Animaux</h2>
                            <p>
                                Les animaux domestiques ne sont pas acceptés, ni
                                dans les logements, ni dans l'enceinte du parc
                                sans l'accord préalable du propriétaire. En cas
                                de non-respect de cette clause par le client, le
                                propriétaire peut refuser les animaux. Ce refus
                                ne peut en aucun cas être considéré comme une
                                modification ou rupture du contrat à
                                l'initiative du Propriétaire, de sorte qu'en cas
                                du départ du client, aucun remboursement ne peut
                                être envisagé.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">Special case</h2>
                            <p>
                                The number of tenants cannot be greater than the
                                maximum capacity indicated in the catalog or
                                descriptive. Exceptionally and subject to the
                                agreement of the owner, it could be derogated
                                from this rule.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Animals</h2>
                            <p>
                                Pets are not accepted, neither in accommodation,
                                or within the grounds of the park without the
                                agreement prior to the owner. In the event of
                                non-compliance with this clause by the customer,
                                the owner may refuse animals. This refusal can
                                in no case be considered as a modification or
                                termination of the contract to initiative of the
                                Owner, so that in the event of departure of the
                                customer, no refund can be considered.
                            </p>
                        </section>
                    </>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
                {translate === 'French' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">
                                Etat des lieux et inventaire
                            </h2>
                            <p>
                                L'état des lieux et inventaire du mobilier et
                                divers équipements, seront faits en début et fin
                                de séjour par le propriétaire ou son mandataire
                                et le locataire. En cas d'impossibilité de
                                procéder à l'inventaire lors de l'arrivée, le
                                locataire disposera de 24 H pour signaler au
                                propriétaire les anomalies constatées. Passé ce
                                délai, les biens loués seront considérés comme
                                exempts de dommages à l'entrée du locataire. Le
                                locataire accepte que cet état des lieux puisse
                                être effectué soit avec le propriétaire, soit
                                avec un mandataire du propriétaire.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Conditions d'annulation :</h2>
                            <p>
                                Si le locataire souhaite annuler son séjour, il
                                peut se rétracter deux mois avant son entrée
                                dans la location. Ou, si un état d'urgence
                                sanitaire est lié au Coronavirus et que la
                                réservation a lieu sur une période incluse dans
                                le confinement. Dans ces deux cas, le
                                Propriétaire remboursera la totalité de la
                                location qu'il s'agisse d'arrhes ou de solde.
                            </p>
                        </section>
                    </>
                )}
                {translate === 'English' && (
                    <>
                        <section className="p-3">
                            <h2 className="fs-1">
                                State of play and inventory
                            </h2>
                            <p>
                                The inventory and inventory of furniture and
                                various equipment, will be made at the beginning
                                and end stay by the owner or his agent and the
                                tenant. In case of impossibility of carry out
                                the inventory upon arrival, the tenant will have
                                24 hours to notify the owner the anomalies
                                observed. Past this period, the rented goods
                                will be considered as free of damage upon
                                tenant's entry. THE tenant accepts that this
                                inventory may be made either with the owner or
                                with an agent of the owner.
                            </p>
                        </section>
                        <div className="bg-primary hr-h w-50 mx-auto"></div>
                        <section className="p-3">
                            <h2 className="fs-1">Cancellation conditions:</h2>
                            <p>
                                If the tenant wishes to cancel his stay, he can
                                withdraw two months before entry in the rental.
                                Or, if a state of emergency health is linked to
                                the Coronavirus and that the reservation takes
                                place over a period included in containment. In
                                these two cases, the Owner will refund the
                                entire rental whether it is a deposit or a
                                balance.
                            </p>
                        </section>
                    </>
                )}
                <div className="bg-primary hr-h w-50 mx-auto"></div>
            </div>
        </>
    );
}
