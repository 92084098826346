import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export function useFetchGetRates(apartent, start_date, end_date, launch) {
    const [dataPlanning, setData] = useState(undefined);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/smoobu/v1/apartment/${apartent}/rates/${start_date}/${end_date}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                setData(data);
                setLoading(false);
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir les dates de Réservations disponibles",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        // eslint-disable-next-line no-lone-blocks
        {
            if (
                (launch === undefined || launch === false) &&
                apartent !== undefined
            ) {
                fetchData();
            }
        }
    }, [apartent, start_date, end_date, launch]);
    return { dataPlanning, isLoading };
}

export function useFetchGetReservationId(idReservation, accessToken) {
    const [dataReservationId, setData] = useState(undefined);
    const [isLoadingIdReservation, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/smoobu/v1/reservation/id/${idReservation}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            'content-type': 'application/json',
                        }),
                    }
                );
                const data = await response;
                const data2 = await response.json();

                if (data.status === 404) {
                    toast.error('Erreur de paramètres de recherche !', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else if (data.status === 200) {
                    setData(data2);
                    setLoading(false);
                } else if (data.status === 500) {
                    toast.error('Le serveur distant est en erreur', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir la Réservation N° " +
                        idReservation,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        if (accessToken !== null && idReservation !== null) {
            fetchData();
        }
    }, [accessToken, idReservation]);
    return { dataReservationId, isLoadingIdReservation };
}

export function useFetchGetReservationIdPriceElements(
    idReservation,
    accessToken
) {
    const [dataReservationIdPriceElements, setData] = useState(undefined);
    const [isLoadingIdReservationPriceElements, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/smoobu/v1/reservation/id/${idReservation}/price-elements/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            'content-type': 'application/json',
                        }),
                    }
                );
                const data = await response;
                const data2 = await response.json();

                if (data.status === 404) {
                    toast.error('Erreur de paramètres de recherche !', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else if (data.status === 200) {
                    setData(data2);
                    setLoading(false);
                } else if (data.status === 500) {
                    toast.error('Le serveur distant est en erreur', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir la Réservation N° " +
                        idReservation,
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        if (accessToken !== null && idReservation !== null) {
            fetchData();
        }
    }, [accessToken, idReservation]);
    return {
        dataReservationIdPriceElements,
        isLoadingIdReservationPriceElements,
    };
}

export function useFetchGetApartmentCities() {
    const [dataApartmentCities, setData] = useState(undefined);
    const [isLoadingAppartCities, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/smoobu/v1/apartments/cities/',
                    {
                        method: 'get',
                        headers: new Headers({
                            'content-type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                    setLoading(false);
                } else if (response.status === 500) {
                    toast.error('Le serveur distant est en erreur', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Impossible d'obtenir les différentes villes des logements proposés",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
    }, []);
    return { dataApartmentCities, isLoadingAppartCities };
}
