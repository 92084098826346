import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Alert,
    Input,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { FaPersonWalkingLuggage } from 'react-icons/fa6';

import {
    getApartments,
    getReservations,
} from '../../Store/Actions/smoobu.actions';
import ListingReservation from '../../components/Reservations/gestionAdmin/ListingReservation.jsx';

import LoadingK from '../../components/Widget/LoadingK.jsx';
import GraphCheckinOut from '../../components/Widget/GraphCheckinOut.jsx';
import LegendeReservations from '../../components/Reservations/gestionAdmin/LegendeReservations.jsx';

export default function ReservationsGestion() {
    const dispatch = useDispatch();
    const authUser = useSelector((store) => store.User.authUser);
    const accessToken = useSelector((store) => store.User.accessToken);
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    const dataReservations = useSelector(
        (store) => store.Smoobu.dataReservations
    );
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 1000);
    }, []);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getReservations(dateStart, dateEnd, size, accessToken));
        }, 2000);
    }, [accessToken]);

    const today = new Date();
    const deltaBeforeDate = new Date(new Date().setDate(today.getDate() - 2))
        .toISOString()
        .split('T')[0];
    const [dateStart, setDateStart] = useState(deltaBeforeDate);

    const deltaMonth = new Date().setMonth(today.getMonth() + 1);
    const deltaDate = new Date(deltaMonth).toISOString().split('T')[0];
    const [dateEnd, setDateEnd] = useState(String(deltaDate));
    const [size] = useState(100);

    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const [watchCancelled, setWatchcancelled] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        dispatch(getReservations(dateStart, dateEnd, size, accessToken));
    }, [dateStart, dateEnd, size]);
    document.title = 'Listings des Réservations | Ma Maison Sereine';
    document.description = 'Pages des réservations';
    return (
        <main className="d-flex flex-column flex-wrap justify-content-around align-items-center ">
            <h1 className="text-center mx-auto display-5">Réservations</h1>
            <div>
                <p className="text-center">Critères de recherche :</p>
                <div className="d-flex flex-row flex-wrap justify-content-center">
                    <div className="d-flex flex-column justify-content-center align-items-center mx-3">
                        <label>Date Début</label>
                        <input
                            className="text-control"
                            type="date"
                            id="dateStart"
                            name="dateStart"
                            defaultValue={dateStart}
                            onChange={(e) => setDateStart(e.target.value)}
                            required
                        />
                    </div>
                    <div className="d-flex flex-column  justify-content-center align-items-center mx-3">
                        <label>Date Fin</label>
                        <input
                            className="text-control"
                            type="date"
                            id="dateEnd"
                            name="dateEnd"
                            defaultValue={dateEnd}
                            onChange={(e) => setDateEnd(e.target.value)}
                            required
                        />
                    </div>

                    <div className="d-flex flex-row flex-wrap  justify-content-center align-items-center mx-3 text-danger border border-danger">
                        <small className="ms-2">Réservations Annulées ?</small>
                        <Input
                            type="checkbox"
                            className="mx-2 my-0"
                            onClick={(e) => {
                                setWatchcancelled(e.target.checked);
                            }}
                        />
                    </div>
                </div>

                <div className="mx-auto my-4 text-center">
                    A ce jour, vous avez{' '}
                    {dataReservations !== null ? (
                        <span className="fs-4 text-primary fw-bold">
                            {dataReservations.total_items}
                        </span>
                    ) : (
                        <LoadingK />
                    )}{' '}
                    Réservations sur la période.(max {size})
                </div>

                <hr className="col-7  mx-auto my-3" />
            </div>
            <LegendeReservations />
            {dataReservations !== null && dataApartments !== null ? (
                <Accordion
                    open={open}
                    toggle={toggle}
                    className="mb-5 col-11 col-xl-6"
                >
                    {dataApartments !== null ? (
                        dataApartments.map((appart) => (
                            <AccordionItem key={'id' + appart.id}>
                                <AccordionHeader targetId={appart.name}>
                                    <h2 className="text-center fs-2 ">
                                        <span className="me-2">
                                            {' '}
                                            <FaPersonWalkingLuggage />
                                        </span>
                                        {appart.name}
                                    </h2>
                                </AccordionHeader>
                                <AccordionBody
                                    accordionId={appart.name}
                                    className="p-1 "
                                >
                                    <ul key={'ul' + appart.id}>
                                        <ListingReservation
                                            appart={appart}
                                            listing={dataReservations}
                                            watchCancelled={watchCancelled}
                                        />
                                    </ul>
                                </AccordionBody>
                            </AccordionItem>
                        ))
                    ) : (
                        <LoadingK />
                    )}
                </Accordion>
            ) : (
                <LoadingK />
            )}
        </main>
    );
}
