import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IoTrash } from 'react-icons/io5';
import { RiReplyFill } from 'react-icons/ri';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import { BiEnvelopeOpen, BiEnvelope } from 'react-icons/bi';
import { FaPersonWalkingLuggage } from 'react-icons/fa6';

import {
    getDataCarnet,
    getDataCarnetGoogle,
    getListingLastReceived,
    getPingPort,
    setPhoneNumber,
} from '../../../Store/Actions/messenger.actions';
import MessengerGestion from '../Messenger.jsx';

export default function MessengerLastReceived() {
    document.title = 'SMS Reçus | Ma Maison Sereine';
    document.description = 'Messagerie  de Ma Maison Sereine';
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const accessToken = useSelector((store) => store.User.accessToken);
    const dataCarnet = useSelector((store) => store.Messenger.dataCarnet);
    const dataMsgReceived = useSelector(
        (store) => store.Messenger.listingReceivedMsg
    );
    const pingPort = useSelector((store) => store.Messenger.pingPort);
    const dataCarnetGoogle = useSelector(
        (store) => store.Messenger.dataCarnetGoogle
    );
    useEffect(() => {
        dispatch(getListingLastReceived(accessToken));
        dispatch(getDataCarnet(accessToken));
        dispatch(getDataCarnetGoogle(accessToken));
    }, [accessToken]);
    const [listCarnet, setListCarnet] = useState(undefined);
    useEffect(() => {
        const listCarnet = [];
        if (dataCarnet !== null) {
            dataCarnet.map((item) => {
                listCarnet.push(item);
            });
        }
        if (dataCarnetGoogle !== null) {
            dataCarnetGoogle.Google_contact.map((item) => {
                listCarnet.push(item);
            });
        }
        setListCarnet(listCarnet);
    }, [dataCarnet, dataCarnetGoogle]);
    useEffect(() => {
        const interval = setInterval(() => {
            accessToken !== null &&
                dispatch(getListingLastReceived(accessToken));
            accessToken !== null &&
                dispatch(getPingPort(accessToken)) &&
                dispatch(getDataCarnetGoogle(accessToken));
        }, 5000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
    }, [accessToken]);

    const handleArchiveMessage = (e) => {
        async function archiveMsg(idMsg) {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/sendmsg/v1/archived/msg/${idMsg}/`,
                    {
                        method: 'put',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                if (response.status === 200) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                    });
                } else {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'envoyer la demande de suppression 😔",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        archiveMsg(e);
    };
    const handleArchiveAllMessage = (e) => {
        async function archiveAllMsg() {
            try {
                toast('Envoi de la rêquete, merci de patienter !', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 10000,
                });
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/sendmsg/v1/archived/all_msg/',
                    {
                        method: 'put',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                if (response.status === 200) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                    });
                } else {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'envoyer la demande de purge 😔",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        archiveAllMsg();
    };
    const handleSubmitEditMsgNotReadToRead = (e) => {
        async function PutMsgToRead(idMsg) {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/sendmsg/v1/received_sms_not_read/read/${idMsg}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                if (response.status === 200) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                    });
                }
            } catch (err) {
                toast.error(
                    'Oups! impossible de modifier statut de lecture du message 😔',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        PutMsgToRead(e);
    };
    const handleSubmitEditMsgReadToNotRead = (e) => {
        async function PutMsgToRead(idMsg) {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/sendmsg/v1/received_sms_not_read/not_read/${idMsg}/`,
                    {
                        method: 'get',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                    }
                );
                const data = await response.json();
                if (response.status === 200) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 4000,
                    });
                }
            } catch (err) {
                toast.error(
                    'Oups! impossible de modifier statut de lecture du message 😔',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }

        PutMsgToRead(e);
    };
    return (
        <main className="d-flex flex-column mx-auto text-primary">
            <MessengerGestion />
            <div className="p-2 m-4 bg-white">
                <h2 className="text-center mb-3">50 Dernièrs Sms Reçus</h2>
                <div
                    className="border border-danger text-danger shadow mx-auto w-fit text-center p-2 rounded pointer"
                    onClick={(e) => {
                        handleArchiveAllMessage();
                    }}
                >
                    <IoTrash /> Purger tous les SMS
                </div>
                <hr className="mx-auto col-6 my-3" />
                {dataMsgReceived !== null && dataMsgReceived.length > 0 ? (
                    <>
                        {dataMsgReceived !== null &&
                            dataMsgReceived.length > 0 &&
                            dataMsgReceived.map((msg) => (
                                <div>
                                    Reçu le {msg.createdAt} <br />
                                    {msg.type === '1' ? 'SMS' : 'Email'}{' '}
                                    {msg.number_port}{' '}
                                    {listCarnet !== null &&
                                        listCarnet !== undefined &&
                                        listCarnet.map((element) => (
                                            <>
                                                {' '}
                                                <div>
                                                    {element.phone ===
                                                        msg.number_port && (
                                                        <small
                                                            key={
                                                                element.id +
                                                                msg.id
                                                            }
                                                            className="bg-warning text-white mx-1 rounded p-1"
                                                        >
                                                            {element.first_name}{' '}
                                                            {element.last_name}
                                                        </small>
                                                    )}
                                                </div>
                                            </>
                                        ))}
                                    <div className="d-flex flex-row">
                                        {msg.ref_reservation !== 0 ? (
                                            <>
                                                <Link
                                                    to={`/gestion/reservation/${msg.ref_reservation}/`}
                                                >
                                                    <button className="btn btn-outline-primary p-1">
                                                        <FaPersonWalkingLuggage />
                                                        <small>Détails</small>
                                                        <HiMiniArrowTopRightOnSquare />
                                                    </button>
                                                </Link>{' '}
                                                <div
                                                    className="bg-primary text-white shadow m-1 p-2 rounded pointer"
                                                    onClick={(e) => {
                                                        handleSubmitEditMsgNotReadToRead(
                                                            msg.id
                                                        );
                                                        setTimeout(() => {
                                                            navigate(
                                                                `/gestion/reservation/${msg.ref_reservation}/`
                                                            );
                                                        }, 500);
                                                    }}
                                                >
                                                    <RiReplyFill />
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                className="bg-primary text-white shadow m-1 p-2 rounded pointer"
                                                onClick={(e) => {
                                                    handleSubmitEditMsgNotReadToRead(
                                                        msg.id
                                                    );
                                                    dispatch(
                                                        setPhoneNumber(
                                                            msg.number_port
                                                        )
                                                    );
                                                    navigate(
                                                        '/gestion/messagerie/createmsg'
                                                    );
                                                }}
                                            >
                                                <RiReplyFill />
                                            </div>
                                        )}

                                        <div
                                            className="border border-danger text-danger shadow m-1 p-2 rounded pointer"
                                            onClick={(e) => {
                                                handleArchiveMessage(msg.id);
                                            }}
                                        >
                                            <IoTrash />
                                        </div>
                                        {msg.read === true ? (
                                            <div
                                                className="border border-success shadow rounded p-2 m-1 pointer text-success"
                                                onClick={(e) => {
                                                    handleSubmitEditMsgReadToNotRead(
                                                        msg.id
                                                    );
                                                }}
                                            >
                                                <BiEnvelopeOpen />
                                            </div>
                                        ) : (
                                            <div
                                                className="border border-danger shadow rounded p-2 m-1 pointer text-danger"
                                                onClick={(e) => {
                                                    handleSubmitEditMsgNotReadToRead(
                                                        msg.id
                                                    );
                                                }}
                                            >
                                                <BiEnvelope />
                                            </div>
                                        )}
                                    </div>
                                    {msg.read === true ? (
                                        <div className="bg-sereine-light border border-2 border-primary col-11 col-lg-9 mx-auto p-3 rounded">
                                            {msg.message}
                                        </div>
                                    ) : (
                                        <>
                                            <br />
                                            <span className="text-danger">
                                                SMS Non lu :{' '}
                                            </span>
                                            <div className="bg-antique-light border border-2 border-danger col-11 col-lg-9 mx-auto p-3 rounded">
                                                {msg.message}
                                            </div>
                                        </>
                                    )}
                                    <hr />
                                </div>
                            ))}
                    </>
                ) : (
                    <p>Aucun message non lu ...</p>
                )}
            </div>
        </main>
    );
}
