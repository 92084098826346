import { Link } from 'react-router-dom';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useSelector } from 'react-redux';
import { GrCircleInformation } from 'react-icons/gr';

import fabien from '../../assets/img/logo/fabien_hennion.png';
import logoStancer from '../../assets/img/logo/logo_stancer.png';
import logoLitiboost from '../../assets/img/logo/logo_chouette_litiboost_copyright.png';
import WidgetRGPD from './RGPD.jsx';
import logo from '../../assets/img/logo/logo__ma-maison-sereine.png';

export default function CardHomeWidgetsBottom() {
    const translate = useSelector((store) => store.Template.language);
    const [isOpenRGPD, setIsOpenRGPD] = useState(false);
    return (
        <div className="banner_footer">
            <article className="card_fab">
                <div>
                    <img
                        src={logo}
                        alt="Logo - Ma Maison Sereine"
                        className="logo__img"
                    />{' '}
                    <img
                        src={fabien}
                        alt="Fabien Hennion"
                        className="logo__img"
                    />
                </div>
                <h3>
                    {translate === 'French' && (
                        <>
                            Créée & Gérée par
                            <strong>Fabien Hennion</strong>
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            Created and managed by
                            <strong>Fabien Hennion</strong>
                        </>
                    )}
                </h3>
                <div className="text-wrap p-3 px-5">
                    {translate === 'French' && (
                        <>
                            Mes Valeurs : Propreté, Qualité, Confiance et
                            toujours à vos Côtés !
                        </>
                    )}
                    {translate === 'English' && (
                        <>
                            My Values: Cleanliness; Quality, Trust and always by
                            your side!
                        </>
                    )}
                </div>
                <div className="pb-4">
                    Entreprise Individuelle "SER1" avec le N° Siret
                    N°878.301.837.00018
                </div>
            </article>
            <div className="footer-details">
                <article id="informations_legales">
                    <h3 className="text-white">
                        <GrCircleInformation /> Informations
                    </h3>
                    <ul>
                        <li>
                            <Link to="/cgl-reglement-interieur">
                                {translate === 'French' && (
                                    <span>
                                        Conditions Générales de Locations &
                                        Réglement Intérieur
                                    </span>
                                )}
                                {translate === 'English' && (
                                    <span>
                                        General Rental Conditions & Regulations
                                        Interior
                                    </span>
                                )}
                            </Link>
                        </li>
                        <li>
                            <Link to="/cgv-mentions-legales">
                                {translate === 'French' && (
                                    <span>
                                        Conditions Générales de Ventes &
                                        Mentions Legales
                                    </span>
                                )}
                                {translate === 'English' && (
                                    <span>
                                        General Conditions of Sale & Mentions
                                        Legal
                                    </span>
                                )}
                            </Link>
                        </li>
                        <li>
                            <Link onClick={(e) => setIsOpenRGPD(!isOpenRGPD)}>
                                {translate === 'French' && (
                                    <span>Données Personnelles - RGPD</span>
                                )}
                                {translate === 'English' && (
                                    <span>Personal Data - GDPR</span>
                                )}
                            </Link>
                        </li>
                        <li>
                            <Link to="/contact">
                                {translate === 'French' && (
                                    <span>Demande de Contact</span>
                                )}
                                {translate === 'English' && (
                                    <span>Contact</span>
                                )}
                            </Link>
                        </li>
                        <li>
                            <Link to="/sitemap">
                                {translate === 'French' && (
                                    <span>Plan de Site</span>
                                )}
                                {translate === 'English' && (
                                    <span>SiteMap</span>
                                )}
                            </Link>
                        </li>
                    </ul>
                    <div className="">
                        Paiement CB via{' '}
                        <a href="https://www.stancer.com/fr/" target="_blank">
                            <img
                                src={logoStancer}
                                alt="Logo Stancer"
                                width={'30%'}
                                className="bg-white rounded-2"
                            />
                        </a>
                    </div>
                    <div className="my-4">
                        <a
                            href="https://litiboost.fr"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            Application Web réalisée par Litiboost{' '}
                            <img
                                src={logoLitiboost}
                                alt="LitiBoost.fr"
                                title="Logo LitiBoost.fr createur de l'application"
                            />
                        </a>
                    </div>
                    {translate === 'French' && (
                        <small>Le site ne comporte pas de cookie tiers!</small>
                    )}
                    {translate === 'English' && (
                        <small>The site does not contain cookies tiers !</small>
                    )}
                    <Offcanvas
                        show={isOpenRGPD}
                        onHide={(e) => setIsOpenRGPD(!isOpenRGPD)}
                        placement={'bottom'}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                {translate === 'French' && (
                                    <>
                                        {' '}
                                        L’utilisation de vos données
                                        personnelles
                                    </>
                                )}
                                {translate === 'English' && (
                                    <>The use of your data personal</>
                                )}
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <hr />
                        <Offcanvas.Body>
                            <WidgetRGPD />
                        </Offcanvas.Body>
                    </Offcanvas>
                </article>
            </div>
        </div>
    );
}
