import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function AccordeonConceptConcergerie() {
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const translate = useSelector((store) => store.Template.language);

    return (
        <div className="col-12 col-lg-5 mx-auto">
            <h2 className="text-white my-5 fs-1">
                {translate === 'French'
                    ? 'Le Concept de la Conciergerie'
                    : 'The Concierge Concept'}
            </h2>
            <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                    <AccordionHeader targetId="1">
                        <h3 className="fs-2" data-testid="accordConcept1">
                            {translate === 'French'
                                ? 'Les services de Conciergerie'
                                : 'Concierge services'}
                        </h3>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div>
                            {translate === 'French' ? (
                                <p>
                                    Lorsqu’on est propriétaire sur la plateforme{' '}
                                    <strong>Airbnb</strong>, il faut assurer
                                    tous les aspects liés à la{' '}
                                    <strong>location de l’hébergement</strong>.
                                    Préparation matérielle des locaux, accueil
                                    physique des voyageurs, un certain
                                    accompagnement de ces derniers durant leurs{' '}
                                    <strong>séjours</strong> jusqu’à leurs
                                    départs.
                                </p>
                            ) : (
                                <p>
                                    When you own the
                                    <strong>Airbnb</strong> platform, it must
                                    ensure all aspects related to the{' '}
                                    <strong>accommodation rental</strong>.
                                    Material preparation of the premises,
                                    physical reception of travelers, a some
                                    accompaniment of these during their{' '}
                                    <strong>stays</strong> until they leave.
                                </p>
                            )}
                            {translate === 'French' ? (
                                <p>
                                    Bref, il faut un service de conciergerie sur
                                    place, car le propriétaire soit ne veut pas
                                    se charger de ces tâches soit ne peut pas en
                                    raison de son éloignement géographique.{' '}
                                </p>
                            ) : (
                                <p>
                                    In short, you need a concierge service on
                                    the spot, because the owner is not does not
                                    want to take on these tasks either cannot
                                    due to remoteness geographic.{' '}
                                </p>
                            )}
                            {translate === 'French' ? (
                                <p>
                                    C’est pourquoi, à Pornic et sa région dans
                                    la <strong>Loire-Atlantique</strong>,{' '}
                                    <strong className="font-title fs-3">
                                        Ma Maison Sereine
                                    </strong>{' '}
                                    peut assurer toutes ces tâches liées à la
                                    location des locaux. Ces dernières se
                                    déclinent de la manière suivante.
                                </p>
                            ) : (
                                <p>
                                    This is why, in Pornic and its region in the{' '}
                                    <strong>Loire-Atlantique</strong>,{' '}
                                    <strong className="font-title fs-3">
                                        My Serene Home
                                    </strong>{' '}
                                    can perform all these tasks related to
                                    rental of premises. These last ones decline
                                    as follows.
                                </p>
                            )}
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="2">
                        <h3 className="fs-2" data-testid="accordConcept2">
                            {translate === 'French' &&
                                'Un service de gardiennage et surveillance'}
                            {translate === 'English' &&
                                'A security and surveillance service'}
                        </h3>
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                        {translate === 'French' && (
                            <>
                                <p>
                                    Lorsque l’hébergement est inoccupé, il est
                                    important qu’il bénéficie d’un service de
                                    gardiennage qui va se charger de plusieurs
                                    tâches.
                                </p>
                                <p>
                                    D’une part, d’une tache de sécurité. En
                                    effet, une maison inoccupée, fermée attise
                                    la convoitise de voleurs, notamment en
                                    périodes de fêtes de fin d’année. De fait,
                                    il est des périodes durant lesquelles les
                                    maisons ne sont pas occupées par les
                                    voyageurs.
                                </p>
                                <p>
                                    Aussi, chez{' '}
                                    <strong className="font-title fs-3">
                                        Ma Maison Sereine
                                    </strong>{' '}
                                    nous effectuons des visites régulières et
                                    nous vous informons immédiatement de l’état
                                    de la maison. Voire des tentatives
                                    d’effraction. C’est aussi l’occasion de
                                    vérifier qu’il n’y a pas d’anomalies ou
                                    dégradations dues à des causes diverses.
                                    Ceci, comme une infiltration d’eau suite à
                                    des intempéries, une fuite d’eau en raison
                                    d’une rupture de canalisation, etc. Suivant
                                    la configuration de votre hébergement, nous
                                    vérifions aussi l’état des abords.
                                </p>
                            </>
                        )}{' '}
                        {translate === 'English' && (
                            <>
                                <p>
                                    When the accommodation is unoccupied, it is
                                    important that he benefits from a service of
                                    caretaker who will take care of several
                                    tasks.
                                </p>
                                <p>
                                    On the one hand, a security stain. In
                                    effect, an unoccupied, closed house stirs up
                                    the lust of thieves, especially in
                                    end-of-year holiday periods. Fact, there are
                                    periods during which houses are not occupied
                                    by travellers.
                                </p>
                                <p>
                                    Also, at{' '}
                                    <strong className="font-title fs-3">
                                        My Serene Home
                                    </strong>{' '}
                                    we make regular visits and we will inform
                                    you immediately of the status of the House.
                                    Even attempts break-in. It is also an
                                    opportunity to check that there are no
                                    anomalies or damage due to various causes.
                                    This, like water infiltration following bad
                                    weather, a water leak due to a burst pipe,
                                    etc. Following the configuration of your
                                    hosting, we We also check the state of the
                                    surroundings.
                                </p>
                            </>
                        )}
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="3">
                        <h3 className="fs-2">
                            {translate === 'French'
                                ? 'Un service de ménage et d’entretien'
                                : 'Housekeeping and maintenance service'}
                        </h3>
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                        {translate === 'French' ? (
                            <p>
                                Accueillir des voyageurs demande que les lieux
                                soient nettoyés et bien entretenus. C’est
                                pourquoi nous assurons les tâches de ménage et
                                d’entretien des locaux. Y compris, l’aération,
                                la mise en chauffage éventuelle, la vérification
                                que les différentes installations fonctionnent
                                bien, etc.
                            </p>
                        ) : (
                            <p>
                                Welcoming travelers requires that places are
                                cleaned and well maintained. This is why we take
                                on the tasks of cleaning and upkeep of the
                                premises. Including, ventilation, possible
                                heating, verification that the different
                                facilities working well, etc.
                            </p>
                        )}
                        {translate === 'French' ? (
                            <p>
                                Nous nous assurons que les annonces qui
                                affichent l'option Équipements de base soient
                                respectées. À savoir la fourniture de papier
                                toilette, savon pour les mains et le corps, une
                                serviette par voyageur, un oreiller par
                                voyageur, du linge de lit pour chaque lit. On
                                notera que si ce n’est pas le cas, la
                                propriétaire peut avoir des pénalités de la
                                plateforme.
                            </p>
                        ) : (
                            <p>
                                We make sure that ads that display the Basic
                                equipment option are respected. Namely the
                                supply of toilet paper, hand soap and body, one
                                towel per traveler, one pillow per traveler, bed
                                linen for each bed. Note that if this is not the
                                cases, the landlord may have platform penalties.
                            </p>
                        )}
                        {translate === 'French' ? (
                            <p>
                                Par ailleurs et à défaut de l’absence de cette
                                option,
                                <strong className="font-title fs-3 mx-2">
                                    Ma Maison Sereine
                                </strong>
                                peut vous fournir ces produits de base et louer
                                le linge de maison.
                            </p>
                        ) : (
                            <p>
                                Furthermore, and in the absence of this option,
                                <strong className="font-title fs-3 mx-2">
                                    My Serene Home
                                </strong>
                                can provide you with these basic products and
                                rent linens.
                            </p>
                        )}
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="4">
                        <h3 className="fs-2">
                            {translate === 'French'
                                ? 'Gestion locative : un service d’accompagnement'
                                : 'Rental management: a support service'}
                        </h3>
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                        {translate === 'French' ? (
                            <p>
                                Louer son hébergement sur la plateforme{' '}
                                <strong>Airbnb</strong> demande de
                                l’organisation pour bien accueillir les
                                voyageurs qui ont réservé chez vous. Aussi, une
                                personne sur place est indispensable pour
                                l’accueil, le séjour et le départ de ces
                                derniers.
                            </p>
                        ) : (
                            <p>
                                Rent your accommodation on the platform{' '}
                                <strong>Airbnb</strong> request for the
                                organization to welcome the travelers who have
                                booked with you. Also, a person on site is
                                essential for the reception, stay and departure
                                of these latter.
                            </p>
                        )}
                        {translate === 'French' ? (
                            <p>
                                Bien entendu, nous préparons entièrement la
                                maison pour le jour d’arrivée (le check in).
                                Chez
                                <strong className="font-title fs-3 mx-2">
                                    Ma Maison Sereine
                                </strong>
                                , nous sommes présents physiquement pour que cet
                                accueil soit le plus agréable possible. Nous
                                faisons une visite du bien, remettons les clefs
                                et remplissons les formalités habituelles
                                demandées par la plateforme. Nous essayons aussi
                                de répondre aux demandes des nouveaux arrivants
                                concernant les bons plans et autres commodités.
                                Mais au-delà des questions matérielles et de
                                formalités, nous faisons en sorte que cet
                                accueil soit le plus convivial possible.
                            </p>
                        ) : (
                            <p>
                                Of course, we fully prepare the house for the
                                day of arrival (check in). At the house of
                                <strong className="font-title fs-3 mx-2">
                                    My Serene Home
                                </strong>
                                , we are physically present so that this welcome
                                is as pleasant as possible. We make a visit to
                                the property, hand over the keys and complete
                                the formalities usual requested by the platform.
                                We also try to respond to requests of new
                                arrivals concerning vouchers maps and other
                                conveniences. But beyond the material questions
                                and formalities, we let us ensure that this
                                welcome is the as friendly as possible.
                            </p>
                        )}
                        {translate === 'French' ? (
                            <p className="font-title fs-2 border-white text-center ">
                                C'est cet aspect humain que nous souhaitons
                                mettre en avant, conformément à notre
                                savoir-faire.
                            </p>
                        ) : (
                            <p className="font-title fs-2 border-white text-center ">
                                It is this human aspect that we wish highlight,
                                in accordance with our expertise.
                            </p>
                        )}
                        {translate === 'French' ? (
                            <p>
                                C'est le petit détail qui fait toute la
                                différence. De même, nous sommes présents durant
                                tout le séjour pour régler toutes sortes de
                                problèmes, liés à l’hébergement et parfois bien
                                au-delà.
                            </p>
                        ) : (
                            <p>
                                It's the little detail that makes all the
                                difference. Likewise, we are present throughout
                                the stay to settle all kinds of problems,
                                related to hosting and sometimes much more.
                            </p>
                        )}
                        {translate === 'French' ? (
                            <p>
                                Enfin, le jour du départ, le check out, nous
                                procédons à une visite de fin de séjour. Nous
                                récupérons les clefs, nous réglons les
                                formalités liées à la location Airbnb. Nous nous
                                inquiétons également de la satisfaction des
                                clients sur la maison et le service. Enfin, nous
                                faisons un compte-rendu au propriétaire.
                            </p>
                        ) : (
                            <p>
                                Finally, on the day of departure, check out, we
                                proceed to an end-of-stay visit. We collect the
                                keys, we settle the formalities related to the
                                Airbnb rental. We we also care about
                                satisfaction customers about the house and the
                                service. Finally, we report to owner.
                            </p>
                        )}
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        </div>
    );
}
