import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Badge, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { FaArrowsRotate } from 'react-icons/fa6';
import { FaSwimmer, FaBed } from 'react-icons/fa';
import { GiVacuumCleaner } from 'react-icons/gi';
import { MdPets } from 'react-icons/md';

import {
    getTarifsConciergerie,
    setSettingMode,
} from '../../Store/Actions/config.actions';

import { useFetchGetLocationSettings } from '../../utils/Api/FetchLocationSettings.jsx';
import logo from '../../assets/img/logo/logo__ma-maison-sereine.png';
import LoadingK from '../../components/Widget/LoadingK.jsx';

export default function SettingsGestion() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const authUser = useSelector((store) => store.User.authUser);
    const authTokens = useSelector((store) => store.User.accessToken);
    const settingsMode = useSelector((store) => store.Config.settingMode);

    const tarifsConciergerie = useSelector(
        (store) => store.Config.tarifsConciergerie
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (tarifsConciergerie === null) {
            dispatch(getTarifsConciergerie());
        }
    }, []);

    const { dataLocationSettings, isLoadingLocationSettings } =
        useFetchGetLocationSettings();
    document.title = 'Reglages | Ma Maison Sereine';
    document.description = 'Pages de reglages';
    let newPackLinge = {};
    if (tarifsConciergerie !== null) {
        newPackLinge = {
            couleur_client_2p: tarifsConciergerie.couleur_client_2p,
            couleur_client_1p: tarifsConciergerie.couleur_client_1p,
            couleur_mms_2p: tarifsConciergerie.couleur_mms_2p,
            couleur_mms_1p: tarifsConciergerie.couleur_mms_1p,
            blanc_mms_2p: tarifsConciergerie.blanc_mms_2p,
            blanc_mms_1p: tarifsConciergerie.blanc_mms_1p,
            blanchiment_piece: tarifsConciergerie.blanchiment_piece,
        };
    }

    let newSettingsLocation = {};
    if (
        isLoadingLocationSettings === false &&
        dataLocationSettings !== undefined
    ) {
        newSettingsLocation = {
            reservation_public: dataLocationSettings.reservation_public,
            horaire_spa: dataLocationSettings.horaire_spa,
            price_spa: dataLocationSettings.price_spa,
            number_night_short_booking:
                dataLocationSettings.number_night_short_booking,
            price_menage_cat1_inf: dataLocationSettings.price_menage_cat1_inf,
            price_menage_cat1_sup: dataLocationSettings.price_menage_cat1_sup,
            price_menage_cat2_inf: dataLocationSettings.price_menage_cat2_inf,
            price_menage_cat2_sup: dataLocationSettings.price_menage_cat2_sup,
            price_menage_cat3_inf: dataLocationSettings.price_menage_cat3_inf,
            price_menage_cat3_sup: dataLocationSettings.price_menage_cat3_sup,
            number_night_linge: dataLocationSettings.number_night_linge,
            price_animaux: dataLocationSettings.price_animaux,
            price_linge_2p_couleur: dataLocationSettings.price_linge_2p_couleur,
            price_linge_1p_couleur: dataLocationSettings.price_linge_1p_couleur,
            price_linge_2p_blanc: dataLocationSettings.price_linge_2p_blanc,
            price_linge_1p_blanc: dataLocationSettings.price_linge_1p_blanc,
        };
    }

    const handleChangeModeEdition = (e) => {
        if (authUser !== undefined && authUser.is_superuser === true) {
            if (settingsMode === true) {
                dispatch(setSettingMode(false));
            } else {
                dispatch(setSettingMode(true));
            }
        } else {
            toast.error(
                'Vous ne disposez pas des droits nécessaires pour réaliser cette opération. Sinon merci de rafraichir la page et de retester.',
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    };
    const handleRefresh = () => {
        window.location.reload();
    };

    const handleChangeReservationPublic = (e) => {
        newSettingsLocation.reservation_public = e.target.value;
    };

    function handleSubmitLinge(e) {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(newPackLinge).forEach((key) =>
            formData.append(key, newPackLinge[key])
        );
        async function fetchData() {
            try {
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/conciergerie/linge/update',
                    {
                        method: 'put',
                        headers: new Headers({
                            Authorization: `Bearer ${authTokens}`,
                            Accept: 'application/json',
                        }),
                        body: formData,
                    }
                );
                if (response.status === 401) {
                    toast.error(
                        "Vous n'êtes pas Administrateur, votre grade ne permet pas l'opération",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
                if (response.status === 404) {
                    toast.error(
                        'Impossible de trouver les prix des Packs Linges sur le Serveur donc sauvegarde impossible. Voir le support',

                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 202) {
                    toast.success(
                        'MAJ du Tarifs Packs Linges OK - merci de rafraîchir la page pour importer les nouveaux tarifs !',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                    dispatch(setSettingMode(false));
                } else if (response.status === 500) {
                    toast.error(
                        'Une erreur est survenue lors du traitement des données - PB serveur Base de données',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch (err) {
                toast.error("Oups! Impossible d'envoyer la modification", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        fetchData();
    }

    function handleSubmitLocation(e) {
        e.preventDefault();
        const formDataLocation = new FormData();
        Object.keys(newSettingsLocation).forEach((key) =>
            formDataLocation.append(key, newSettingsLocation[key])
        );
        async function fetchData() {
            try {
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/locations/settings/update',
                    {
                        method: 'put',
                        headers: new Headers({
                            Authorization: `Bearer ${authTokens}`,
                            Accept: 'application/json',
                        }),
                        body: formDataLocation,
                    }
                );
                const data = await response.json();
                if (response.status === 401) {
                    toast.error(
                        "Vous n'êtes pas Administrateur, votre grade ne permet pas l'opération",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
                if (response.status === 404) {
                    toast.error(
                        'Impossible de trouver les réglages concernant les locations sur le Serveur. Voir le support',

                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else if (response.status === 202) {
                    toast.success(
                        'MAJ des réglages concernant les locations OK - merci de rafraîchir la page pour importer les nouveaux réglages !',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                    dispatch(setSettingMode(false));
                } else if (response.status === 500) {
                    toast.error(
                        'Une erreur est survenue lors du traitement des données - PB serveur Base de données',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch (err) {
                toast.error("Oups! Impossible d'envoyer la modification", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        fetchData();
    }
    return (
        <main className="d-flex flex-column text-center p-2 mb-5">
            <div className=" m-auto">
                <img
                    src={logo}
                    alt={'Logo - Ma Maison Sereine'}
                    width="150px"
                    className="mx-auto mt-5"
                />
            </div>
            <h1 className="text-center mx-auto display-5">Reglages du Site</h1>{' '}
            <div className="bg-primary hr-h w-50 mx-auto"></div>
            <div id="IconEdit" className="mx-auto my-3">
                {settingsMode === true ? (
                    <button
                        className="btn btn-warning  p-1 text-white"
                        onClick={handleChangeModeEdition}
                        id="editModeBtn"
                    >
                        Mode Edition ON
                    </button>
                ) : (
                    <div className="d-flex flex-row align-items-center justify-content-center ">
                        {authUser !== null ? (
                            <>
                                <button
                                    className="btn btn-primary m-1 p-1 text-white"
                                    onClick={handleChangeModeEdition}
                                    id="editModeBtn"
                                >
                                    Mode Edition OFF
                                </button>
                                <button
                                    className="btn btn-primary m-1 p-1 px-3 text-white"
                                    onClick={handleRefresh}
                                >
                                    {' '}
                                    Rafraichir la Page suite à une MAJ{' '}
                                    <FaArrowsRotate />
                                </button>
                            </>
                        ) : (
                            <button
                                className="btn btn-primary m-1 p-1 px-3 text-white"
                                onClick={handleRefresh}
                            >
                                {' '}
                                Rafraichir la Page pour Activer le bouton Mode
                                Edition
                                <FaArrowsRotate />
                            </button>
                        )}
                    </div>
                )}
            </div>
            <div className="d-flex flex-row flex-wrap mb-5">
                {isLoadingLocationSettings === true && authUser === null ? (
                    <LoadingK />
                ) : (
                    <section
                        id="sectionLocationSettings"
                        className="col-12 col-md-10 col-xxl-6 mx-auto"
                    >
                        <form
                            onSubmit={handleSubmitLocation}
                            className="d-flex flex-column p-2"
                        >
                            <h2 className="text-center mx-auto display-6">
                                Réglages Location
                            </h2>

                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="reservation_public ">
                                    Activation Mode Réservation Online :
                                </label>
                                {dataLocationSettings !== undefined ? (
                                    settingsMode === true ? (
                                        <select
                                            className="col-6 mx-auto"
                                            onChange={
                                                handleChangeReservationPublic
                                            }
                                        >
                                            <option
                                                value={
                                                    dataLocationSettings.reservation_public
                                                }
                                            >
                                                {dataLocationSettings.reservation_public ===
                                                true
                                                    ? 'Actuellement : Oui'
                                                    : 'Actuellement : Non'}
                                            </option>
                                            {dataLocationSettings.reservation_public ===
                                            true ? (
                                                <option value="false">
                                                    Non
                                                </option>
                                            ) : (
                                                <option value="true">
                                                    Oui
                                                </option>
                                            )}
                                        </select>
                                    ) : dataLocationSettings.reservation_public ===
                                      true ? (
                                        <span className="text-success">
                                            Oui
                                        </span>
                                    ) : (
                                        <span className="text-danger">Non</span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <hr className="col-6 mx-auto" />
                            <div className="col-8 mx-auto text-center">
                                <Badge
                                    className="ms-1 text-center"
                                    color="warning"
                                    pill
                                >
                                    SPA - Jacuzzi
                                    <span className="ms-3 fs-3">
                                        <FaSwimmer />
                                    </span>
                                </Badge>
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="price_spa ">
                                    Tarif du forfait SPA - Jacuzzi -{' '}
                                </label>
                                {dataLocationSettings !== undefined ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="price_spa"
                                            name="price_spa"
                                            defaultValue={
                                                dataLocationSettings.price_spa
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newSettingsLocation.price_spa =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {dataLocationSettings.price_spa}
                                            {' €'}
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="horaire_spa ">
                                    Horaire du forfait SPA - Jacuzzi
                                </label>
                                {dataLocationSettings !== undefined ? (
                                    settingsMode === true ? (
                                        <input
                                            type="text"
                                            id="horaire_spa"
                                            name="horaire_spa"
                                            defaultValue={
                                                dataLocationSettings.horaire_spa
                                            }
                                            className=" rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newSettingsLocation.horaire_spa =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {dataLocationSettings !==
                                                undefined &&
                                                dataLocationSettings.horaire_spa}
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <hr className="col-6 mx-auto" />

                            <div className="col-8 mx-auto text-center">
                                <Badge
                                    className="ms-1 text-center "
                                    color="rose"
                                    pill
                                >
                                    Ménage
                                    <span className="ms-1 fs-3">
                                        <GiVacuumCleaner />
                                    </span>
                                </Badge>
                                <div className="d-flex flex-column justify-content-between my-2">
                                    <label htmlFor="number_night_short_booking ">
                                        Nombre de Nuités (inclus) pour court
                                        séjour
                                    </label>
                                    {dataLocationSettings !== undefined ? (
                                        settingsMode === true ? (
                                            <input
                                                type="number"
                                                id="number_night_short_booking"
                                                name="number_night_short_booking"
                                                defaultValue={
                                                    dataLocationSettings.number_night_short_booking
                                                }
                                                min="1"
                                                step="1"
                                                className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                                onChange={(e) =>
                                                    (newSettingsLocation.number_night_short_booking =
                                                        e.target.value)
                                                }
                                            />
                                        ) : (
                                            <span className="ms-3 text-primary">
                                                {
                                                    dataLocationSettings.number_night_short_booking
                                                }{' '}
                                                Jours
                                            </span>
                                        )
                                    ) : (
                                        <LoadingK />
                                    )}
                                </div>
                                <Table
                                    bordered
                                    hover
                                    className="text-center bg-light rounded"
                                >
                                    <tbody>
                                        <tr>
                                            <th className="bg-primary text-white">
                                                Maison
                                            </th>
                                            <th className="bg-primary text-white">
                                                <span>
                                                    Séjour 1 à{' '}
                                                    {dataLocationSettings !==
                                                        undefined &&
                                                        dataLocationSettings.number_night_short_booking}
                                                    j inclus{' '}
                                                </span>
                                            </th>
                                            <th className="bg-primary text-white">
                                                <span>
                                                    Séjour sup. à{' '}
                                                    {dataLocationSettings !==
                                                        undefined &&
                                                        dataLocationSettings.number_night_short_booking +
                                                            1}
                                                    j
                                                </span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="row"
                                                className="text-start"
                                            >
                                                Cat 1
                                            </th>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_menage_cat1_inf"
                                                            name="price_menage_cat1_inf"
                                                            defaultValue={
                                                                dataLocationSettings.price_menage_cat1_inf
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_menage_cat1_inf =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_menage_cat1_inf
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_menage_cat1_sup"
                                                            name="price_menage_cat1_sup"
                                                            defaultValue={
                                                                dataLocationSettings.price_menage_cat1_sup
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_menage_cat1_sup =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_menage_cat1_sup
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="row"
                                                className="text-start"
                                            >
                                                Cat 2
                                            </th>

                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_menage_cat2_inf"
                                                            name="price_menage_cat2_inf"
                                                            defaultValue={
                                                                dataLocationSettings.price_menage_cat2_inf
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_menage_cat2_inf =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_menage_cat2_inf
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_menage_cat2_sup"
                                                            name="price_menage_cat2_sup"
                                                            defaultValue={
                                                                dataLocationSettings.price_menage_cat2_sup
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_menage_cat2_sup =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_menage_cat2_sup
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="row"
                                                className="text-start"
                                            >
                                                Cat 3
                                            </th>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_menage_cat3_inf"
                                                            name="price_menage_cat3_inf"
                                                            defaultValue={
                                                                dataLocationSettings.price_menage_cat3_inf
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_menage_cat3_inf =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_menage_cat3_inf
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_menage_cat3_sup"
                                                            name="price_menage_cat3_sup"
                                                            defaultValue={
                                                                dataLocationSettings.price_menage_cat3_sup
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_menage_cat3_sup =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_menage_cat3_sup
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <small>
                                Cat 1 : ABDR / ABDL / SsLesPins Chambre
                            </small>
                            <small>Cat 2 : SsLesPins Maison</small>
                            <small>Cat 3 : Pêcherie / Pure</small>
                            <hr className="col-6  mx-auto" />
                            <div className="col-8 mx-auto text-center">
                                <Badge
                                    className="ms-1 text-center "
                                    color="danger"
                                    pill
                                >
                                    Forfait Animaux
                                    <span className="ms-1 fs-3">
                                        <MdPets />
                                    </span>
                                </Badge>
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="price_animaux">
                                    Forfait Animaux
                                </label>
                                {dataLocationSettings !== undefined ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="price_animaux"
                                            name="price_animaux"
                                            defaultValue={
                                                dataLocationSettings.price_animaux
                                            }
                                            min="0"
                                            step="0.5"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newSettingsLocation.price_animaux =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3 text-primary">
                                            {dataLocationSettings.price_animaux}
                                            {' €'}
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <hr className="col-6 mx-auto" />
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="number_night_linge ">
                                    Nombre de Nuités (inclus) pour Facturation
                                    linge
                                </label>
                                {dataLocationSettings !== undefined ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="number_night_linge"
                                            name="number_night_linge"
                                            defaultValue={
                                                dataLocationSettings.number_night_linge
                                            }
                                            min="1"
                                            step="1"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newSettingsLocation.number_night_linge =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3 text-primary">
                                            {
                                                dataLocationSettings.number_night_linge
                                            }{' '}
                                            Jours
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="col-8 mx-auto text-center">
                                <Badge
                                    className="ms-1 text-center "
                                    color="purple"
                                    pill
                                >
                                    Packs Linge Location
                                    <span className="ms-1 fs-3">
                                        <FaBed />
                                    </span>
                                </Badge>
                                <Table
                                    bordered
                                    hover
                                    className="text-center bg-light rounded"
                                >
                                    <tbody>
                                        <tr>
                                            <th className="bg-purple text-white">
                                                Pack
                                            </th>
                                            <th className="bg-purple text-white">
                                                <span>2p</span>
                                            </th>
                                            <th className="bg-purple text-white">
                                                <span>1p</span>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="row"
                                                className="text-start"
                                            >
                                                Couleur
                                            </th>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_linge_2p_couleur"
                                                            name="price_linge_2p_couleur"
                                                            defaultValue={
                                                                dataLocationSettings.price_linge_2p_couleur
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_linge_2p_couleur =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_linge_2p_couleur
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_linge_1p_couleur"
                                                            name="price_linge_1p_couleur"
                                                            defaultValue={
                                                                dataLocationSettings.price_linge_1p_couleur
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_linge_1p_couleur =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_linge_1p_couleur
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th
                                                scope="row"
                                                className="text-start"
                                            >
                                                Blanc
                                            </th>

                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_linge_2p_blanc"
                                                            name="price_linge_2p_blanc"
                                                            defaultValue={
                                                                dataLocationSettings.price_linge_2p_blanc
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_linge_2p_blanc =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_linge_2p_blanc
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                            <td className="text-primary">
                                                {dataLocationSettings !==
                                                undefined ? (
                                                    settingsMode === true ? (
                                                        <input
                                                            type="number"
                                                            id="price_linge_1p_blanc"
                                                            name="price_linge_1p_blanc"
                                                            defaultValue={
                                                                dataLocationSettings.price_linge_1p_blanc
                                                            }
                                                            min="0"
                                                            step="0.5"
                                                            className="rounded border border-1 border-primary mx-auto text-center"
                                                            onChange={(e) =>
                                                                (newSettingsLocation.price_linge_1p_blanc =
                                                                    e.target.value)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className="ms-3">
                                                            {
                                                                dataLocationSettings.price_linge_1p_blanc
                                                            }
                                                            {' €'}
                                                        </span>
                                                    )
                                                ) : (
                                                    <LoadingK />
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <hr className="col-6 mx-auto" />
                            {dataLocationSettings !== undefined &&
                                settingsMode === true && (
                                    <button
                                        className="btn btn-success text-white my-3 mx-auto text-center"
                                        type="submit"
                                    >
                                        Sauvegarder les nouveaux Réglages
                                        Locations
                                    </button>
                                )}
                        </form>
                    </section>
                )}
                {authUser === null || tarifsConciergerie === null ? (
                    <LoadingK />
                ) : (
                    <section
                        id="sectionLinge"
                        className="col-12 col-md-10 col-xxl-6 mx-auto"
                    >
                        <form
                            onSubmit={handleSubmitLinge}
                            className="d-flex flex-column p-2"
                        >
                            <h2 className="text-center mx-auto display-6">
                                Réglages Conciergerie
                            </h2>
                            <div className="col-8 mx-auto text-center">
                                <Badge
                                    className="ms-1 text-center "
                                    color="purple"
                                    pill
                                >
                                    Packs Linge Conciergerie
                                    <span className="ms-1 fs-3">
                                        <FaBed />
                                    </span>
                                </Badge>
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="couleur_client_2p ">
                                    Blanchiment lit de couleur 2p :
                                </label>
                                {tarifsConciergerie !== null ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="couleur_client_2p"
                                            name="couleur_client_2p"
                                            defaultValue={
                                                tarifsConciergerie.couleur_client_2p
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newPackLinge.couleur_client_2p =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {
                                                tarifsConciergerie.couleur_client_2p
                                            }{' '}
                                            €
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="couleur_client_1p ">
                                    Blanchiment lit de couleur 1p :
                                </label>
                                {tarifsConciergerie !== null ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="couleur_client_1p"
                                            name="couleur_client_1p"
                                            defaultValue={
                                                tarifsConciergerie.couleur_client_1p
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newPackLinge.couleur_client_1p =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {
                                                tarifsConciergerie.couleur_client_1p
                                            }{' '}
                                            €
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="couleur_mms_2p ">
                                    Blanchiment et Fourniture lit de couleur 2p
                                    :
                                </label>
                                {tarifsConciergerie !== null ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="couleur_mms_2p"
                                            name="couleur_mms_2p"
                                            defaultValue={
                                                tarifsConciergerie.couleur_mms_2p
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newPackLinge.couleur_mms_2p =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {tarifsConciergerie.couleur_mms_2p}{' '}
                                            €
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="couleur_mms_1p ">
                                    Blanchiment et Fourniture lit de couleur 1p
                                    :
                                </label>
                                {tarifsConciergerie !== null ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="couleur_mms_1p"
                                            name="couleur_mms_1p"
                                            defaultValue={
                                                tarifsConciergerie.couleur_mms_1p
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newPackLinge.couleur_mms_1p =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {tarifsConciergerie.couleur_mms_1p}{' '}
                                            €
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="blanc_mms_2p ">
                                    Location de linge Blanc via le pressing 2p :{' '}
                                </label>
                                {tarifsConciergerie !== null ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="blanc_mms_2p"
                                            name="blanc_mms_2p"
                                            defaultValue={
                                                tarifsConciergerie.blanc_mms_2p
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newPackLinge.blanc_mms_2p =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {tarifsConciergerie.blanc_mms_2p} €
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="blanc_mms_1p ">
                                    Location de linge Blanc via le pressing 1p:{' '}
                                </label>
                                {tarifsConciergerie !== null ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="blanc_mms_1p"
                                            name="blanc_mms_1p"
                                            defaultValue={
                                                tarifsConciergerie.blanc_mms_1p
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newPackLinge.blanc_mms_1p =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {tarifsConciergerie.blanc_mms_1p} €
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <div className="d-flex flex-column justify-content-between my-2">
                                <label htmlFor="blanchiment_piece ">
                                    Blanchiment à la pièce :
                                </label>
                                {tarifsConciergerie !== null ? (
                                    settingsMode === true ? (
                                        <input
                                            type="number"
                                            id="blanchiment_piece"
                                            name="blanchiment_piece"
                                            defaultValue={
                                                tarifsConciergerie.blanchiment_piece
                                            }
                                            min="0"
                                            step="0.01"
                                            className="col-2 rounded border border-1 border-chic mx-auto text-center"
                                            onChange={(e) =>
                                                (newPackLinge.blanchiment_piece =
                                                    e.target.value)
                                            }
                                        />
                                    ) : (
                                        <span className="ms-3">
                                            {
                                                tarifsConciergerie.blanchiment_piece
                                            }{' '}
                                            €
                                        </span>
                                    )
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            {tarifsConciergerie !== null &&
                                settingsMode === true && (
                                    <button
                                        className="btn btn-success text-white my-3 mx-auto text-center"
                                        type="submit"
                                    >
                                        Sauvegarder les nouveaux tarifs Pack
                                        Linges
                                    </button>
                                )}
                        </form>
                    </section>
                )}
            </div>
        </main>
    );
}
