import { toast } from 'react-toastify';
import axios from 'axios';

export const setSettingMode = (value) => (dispatch) =>
    dispatch({
        type: 'settingMode',
        settingMode: value,
    });

export const setEditMode = (value) => (dispatch) =>
    dispatch({
        type: 'editMode',
        editMode: value,
    });

export const setDateSelected = (value) => (dispatch) =>
    dispatch({
        type: 'dateSelected',
        dateSelected: value,
    });

export const setSelectedAppart = (value) => (dispatch) =>
    dispatch({
        type: 'selectedAppart',
        selectedAppart: value,
    });
export const setVoyageSmoobu = (value) => (dispatch) =>
    dispatch({
        type: 'voyageSmoobu',
        voyageSmoobu: value,
    });

export const getTarifsConciergerie = () => async (dispatch) =>
    axios
        .get('https://api.mamaisonsereine.fr/api/conciergerie/linge/', {
            timeout: 20000,
            'Content-Type': 'application/x-www-form-urlencoded',
            credentials: 'same-origin',
        })
        .then((res) =>
            dispatch({
                type: 'tarifsConciergerie',
                tarifsConciergerie: res.data,
            })
        )

        .catch(() => {
            toast.error("Impossible d'obtenir les tarifs de la Conciergerie", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return dispatch({
                type: 'tarifsConciergerie',
                tarifsConciergerie: null,
            });
        });

export const getSettingsLocation = () => async (dispatch) =>
    axios
        .get('https://api.mamaisonsereine.fr/api/locations/settings/', {
            timeout: 20000,
            'Content-Type': 'application/x-www-form-urlencoded',
            credentials: 'same-origin',
        })
        .then((res) =>
            dispatch({
                type: 'settingsLocations',
                settingsLocations: res.data,
            })
        )

        .catch(() => {
            toast.error(
                "Impossible d'obtenir les tarifs des services de Conciergerie",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return dispatch({
                type: 'settingsLocations',
                settingsLocations: null,
            });
        });
