import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

export function useFetchGetLocationSettings() {
    const [dataLocationSettings, setData] = useState(undefined);
    const [isLoadingLocationSettings, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/locations/settings/',
                    {
                        method: 'get',
                        headers: new Headers({
                            'content-type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                    setLoading(false);
                } else if (data.status === 500) {
                    toast.error(
                        'Le serveur distant API Reglages Location est en erreur - Voir avec le Support',
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir Réglages pour les Location.",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
    }, []);
    return { dataLocationSettings, isLoadingLocationSettings };
}

export function useFetchGetLocationSettingsReservationAccess() {
    const [dataLocationSettingsReservationAccess, setData] =
        useState(undefined);
    const [isLoadingLocationSettingsReservationAccess, setLoading] =
        useState(true);
    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/locations/settings/reservation_access',
                    {
                        method: 'get',
                        headers: new Headers({
                            'content-type': 'application/json',
                        }),
                    }
                );
                const data = await response.json();

                if (response.status === 200) {
                    setData(data);
                    setLoading(false);
                } else if (data.status === 500) {
                    toast.error(
                        "Le serveur distant API pour accéder à l'interface de réservation est en erreur",
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                }
            } catch (err) {
                toast.error(
                    "Oups! Impossible d'obtenir l'accès au planning de réservation",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        fetchData();
    }, []);
    return {
        dataLocationSettingsReservationAccess,
        isLoadingLocationSettingsReservationAccess,
    };
}
