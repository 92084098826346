/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import './css/theme.css';
import './css/core.css';
import './css/button.css';
import './css/selectmenu.css';
import './css/menu.css';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setPhoneNumber } from '../../../Store/Actions/messenger.actions';

export default function DropDownSelect(props) {
    const [selectedName, setSelectedName] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [onOverItemId, setOnOverItemIdd] = useState(undefined);
    const [openMenu, setOpenMenu] = useState(false);
    const phoneNumber = useSelector((store) => store.Messenger.phoneNumber);
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.keyToValueDefaultValue !== undefined) {
            props.dataArray !== undefined &&
                props.dataArray.length >= 1 &&
                props.dataArray.map((element) => {
                    if (
                        element[props.keyToValue] ===
                        props.keyToValueDefaultValue
                    ) {
                        setSelectedName(
                            element.first_name + '  ' + element.last_name
                        );
                        setSelectedValue(element[props.keyToValue]);
                    }
                });
        }
    }, [props]);

    // select previous or next element with keyboard
    const handleKeyDownSelect = (e) => {
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            const select = document.getElementById(props.id + '-select');
            if (select.children !== undefined) {
                const arrayHTMLUp = Array.from(select.children);
                const result = arrayHTMLUp.filter(
                    (option) => option.value === selectedValue
                );
                if (
                    result[0].previousElementSibling !== null &&
                    result[0].previousElementSibling.innerText !== null
                ) {
                    setSelectedName(result[0].previousElementSibling.innerText);
                    setSelectedValue(result[0].previousElementSibling.value);
                }
            }
        }
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const select = document.getElementById(props.id + '-select');
            if (select.children !== undefined) {
                const arrayHTMLDown = Array.from(select.children);
                const result = arrayHTMLDown.filter(
                    (option) => option.value === selectedValue
                );
                if (
                    result[0].nextElementSibling !== null &&
                    result[0].nextElementSibling.innerText !== null
                ) {
                    setSelectedName(result[0].nextElementSibling.innerText);
                    setSelectedValue(result[0].nextElementSibling.value);
                }
            }
        }
    };

    return (
        <div className={props.className}>
            <label htmlFor={props.id + '-select'}>{props.title}</label>
            <select
                name={props.id}
                id={props.id + '-select'}
                style={{ display: 'none' }}
            >
                {props.dataArray !== undefined &&
                    props.dataArray.length >= 1 &&
                    props.dataArray.map((element, index) => (
                        <option
                            key={'option' + index}
                            value={element[props.keyToValue]}
                        >
                            {element.first_name} {element.last_name}
                        </option>
                    ))}
            </select>
            <span
                tabIndex="0"
                aria-expanded={openMenu ? 'true' : 'false'}
                aria-autocomplete="list"
                aria-haspopup="true"
                className={
                    openMenu
                        ? 'ui-selectmenu-button ui-button ui-widget ui-selectmenu-button-open ui-corner-top'
                        : 'ui-selectmenu-button ui-selectmenu-button-closed ui-corner-all ui-button ui-widget'
                }
                onClick={() => setOpenMenu(!openMenu)}
                onKeyDown={(e) => handleKeyDownSelect(e)}
            >
                <Input
                    placeholder="06********"
                    aria-label="portable"
                    id="portable"
                    maxLength={12}
                    type="tel"
                    name="phone"
                    className="w-fit mx-auto"
                    value={phoneNumber}
                    onChange={(e) => {
                        dispatch(setPhoneNumber(e.target.value));
                        setSelectedName('');
                    }}
                />
            </span>
            <div
                className={
                    openMenu
                        ? 'ui-selectmenu-menu ui-front ui-selectmenu-open'
                        : 'ui-selectmenu-menu ui-front'
                }
            >
                <ul
                    aria-hidden={!openMenu}
                    aria-labelledby={props.id + '-menu'}
                    id={props.id + '-menu'}
                    role="listbox"
                    tabIndex="0"
                    className="ui-menu ui-widget ui-widget-content w-fit mx-auto"
                >
                    {props.dataArray !== undefined &&
                        props.dataArray.length >= 1 &&
                        props.dataArray.map((element) => (
                            <>
                                {((element.first_name !== null &&
                                    element.first_name
                                        .toLowerCase()
                                        .includes(
                                            phoneNumber !== null
                                                ? phoneNumber.toLowerCase()
                                                : ''
                                        )) ||
                                    (element.last_name !== null &&
                                        element.last_name
                                            .toLowerCase()
                                            .includes(
                                                phoneNumber !== null
                                                    ? phoneNumber.toLowerCase()
                                                    : ''
                                            )) ||
                                    (element.phone !== null &&
                                        element.phone !== '' &&
                                        element.phone.includes(
                                            phoneNumber !== null
                                                ? phoneNumber.slice(1, -1)
                                                : ''
                                        ))) && (
                                    <li
                                        className="ui-menu-item"
                                        key={element[props.keyToValue]}
                                        onClick={() => {
                                            setSelectedValue(
                                                element[props.keyToValue]
                                            );
                                            dispatch(
                                                setPhoneNumber(
                                                    element[props.keyToValue]
                                                )
                                            );
                                            setSelectedName(
                                                element.first_name +
                                                    '  ' +
                                                    element.last_name
                                            );
                                            setOpenMenu(!openMenu);
                                        }}
                                        onMouseOver={() => {
                                            setOnOverItemIdd(
                                                element[props.keyToValue]
                                            );
                                        }}
                                    >
                                        <div
                                            role="menuitem"
                                            className={
                                                element[props.keyToValue] ===
                                                onOverItemId
                                                    ? 'ui-menu-item-wrapper ui-state-active'
                                                    : 'ui-menu-item-wrapper '
                                            }
                                        >
                                            {element.first_name}{' '}
                                            {element.last_name} {element.phone}
                                        </div>
                                    </li>
                                )}
                            </>
                        ))}
                </ul>
            </div>
            <p className="mt-2">
                Envoi au {phoneNumber} {selectedName}
            </p>
        </div>
    );
}
