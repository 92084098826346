import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import { FaCommentSms, FaPersonWalkingLuggage, FaKey } from 'react-icons/fa6';
import { LiaBusinessTimeSolid } from 'react-icons/lia';
import { BsFillSendCheckFill } from 'react-icons/bs';
import { RiFileCopy2Line } from 'react-icons/ri';

import {
    getDataCarnet,
    getDataCarnetGoogle,
    getListingSendMsg,
    getTimelineSmsAuto,
} from '../../../Store/Actions/messenger.actions';
import MessengerGestion from '../Messenger.jsx';

export default function TimelineSmsAuto() {
    document.title = 'Timeline SMS Auto | Ma Maison Sereine';
    document.description = 'Messagerie de Ma Maison Sereine';

    const dispatch = useDispatch();
    const accessToken = useSelector((store) => store.User.accessToken);
    const dataMsgSended = useSelector(
        (store) => store.Messenger.listingSendMsg
    );
    const dataCarnet = useSelector((store) => store.Messenger.dataCarnet);
    const dataCarnetGoogle = useSelector(
        (store) => store.Messenger.dataCarnetGoogle
    );
    const TimelineSMSAuto = useSelector(
        (store) => store.Messenger.TimelineSMSAuto
    );
    useEffect(() => {
        dispatch(getListingSendMsg(accessToken));

        if (dataCarnet === null || dataCarnet === undefined) {
            dispatch(getDataCarnet(accessToken));
        }
        if (dataCarnet === null || dataCarnet === undefined) {
            dispatch(getDataCarnetGoogle(accessToken));
        }
        dispatch(getTimelineSmsAuto(accessToken));
    }, [accessToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            accessToken !== null && dispatch(getListingSendMsg(accessToken));
        }, 20000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
    }, [accessToken]);
    const [listCarnet, setListCarnet] = useState(undefined);
    useEffect(() => {
        const listCarnet = [];
        if (dataCarnet !== null) {
            dataCarnet.map((item) => {
                listCarnet.push(item);
            });
        }
        if (dataCarnetGoogle !== null) {
            dataCarnetGoogle.Google_contact.map((item) => {
                listCarnet.push(item);
            });
        }
        setListCarnet(listCarnet);
    }, [dataCarnet, dataCarnetGoogle]);

    return (
        <main className="d-flex flex-column mx-auto text-primary">
            <MessengerGestion />
            <h1 className="text-center mt-3 mx-auto display-5 ">
                <LiaBusinessTimeSolid /> TimeLine <FaCommentSms /> auto
            </h1>
            <section class="timeline_area p-4 ">
                {TimelineSMSAuto !== null && TimelineSMSAuto.length > 0 && (
                    <>
                        {TimelineSMSAuto.map((item) => (
                            <>
                                {(item.phone !== null || item.phone !== '') && (
                                    <>
                                        {item.element !== 'timeline' &&
                                            item.date_to_send !==
                                                'presentiel' && (
                                                <div class="apland-timeline-area d-flex flex-row flex-wrap justify-content-center">
                                                    <div class="single-timeline-area ">
                                                        <div class="timeline-date wow fadeInLeft flex-column align-items-center">
                                                            <div className="m-auto ">
                                                                <small>
                                                                    {
                                                                        item.element
                                                                    }
                                                                </small>

                                                                <br />
                                                                <small>
                                                                    {
                                                                        item.date_to_send
                                                                    }
                                                                </small>
                                                                {dataMsgSended !==
                                                                    null &&
                                                                    dataMsgSended.length >
                                                                        0 &&
                                                                    dataMsgSended.map(
                                                                        (
                                                                            msg
                                                                        ) => (
                                                                            <>
                                                                                {msg.number_port ===
                                                                                    item.phone &&
                                                                                    msg.out_in ===
                                                                                        false &&
                                                                                    msg.createdAt ===
                                                                                        item.date_to_send && (
                                                                                        <>
                                                                                            <btn
                                                                                                className="btn btn-outline-success"
                                                                                                onClick={() => {
                                                                                                    toast.info(
                                                                                                        msg.message,
                                                                                                        {
                                                                                                            position:
                                                                                                                toast
                                                                                                                    .POSITION
                                                                                                                    .BOTTOM_RIGHT,
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <BsFillSendCheckFill />
                                                                                            </btn>
                                                                                        </>
                                                                                    )}
                                                                            </>
                                                                        )
                                                                    )}
                                                                <btn
                                                                    className="btn btn-outline-warning"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        navigator.clipboard
                                                                            .writeText(
                                                                                item.message
                                                                            )
                                                                            .then(
                                                                                () => {
                                                                                    toast(
                                                                                        'Contenu du SMS copié',
                                                                                        {
                                                                                            position:
                                                                                                toast
                                                                                                    .POSITION
                                                                                                    .BOTTOM_RIGHT,
                                                                                        }
                                                                                    );
                                                                                    toast.info(
                                                                                        item.message,
                                                                                        {
                                                                                            position:
                                                                                                toast
                                                                                                    .POSITION
                                                                                                    .BOTTOM_RIGHT,
                                                                                        }
                                                                                    );
                                                                                }
                                                                            )
                                                                    }
                                                                >
                                                                    Copier SMS{' '}
                                                                    <RiFileCopy2Line />
                                                                </btn>
                                                            </div>
                                                        </div>

                                                        <div class="single-timeline-content d-flex wow fadeInLeft ">
                                                            <div class="timeline-icon">
                                                                <i
                                                                    class="fa fa-briefcase"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                            <div class="timeline-text">
                                                                <h2>
                                                                    {item.guest}
                                                                </h2>
                                                                <h3>
                                                                    {
                                                                        item.logement
                                                                    }
                                                                </h3>
                                                                <p>
                                                                    {item.phone}
                                                                </p>
                                                                <Link
                                                                    to={`/gestion/reservation/${item.id}/`}
                                                                    className="mx-auto"
                                                                >
                                                                    <button className="btn btn-outline-primary p-1">
                                                                        <FaPersonWalkingLuggage />
                                                                        <small>
                                                                            Détails
                                                                        </small>
                                                                        <HiMiniArrowTopRightOnSquare />
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        {item.element === 'timeline' && (
                                            <div className="d-flex flex-row justify-content-center align-items-center text-center bg-antique p-2 my-3 w-fit mx-auto rounded-2">
                                                Element en Attente d'envoi :{' '}
                                                <span class="loader_clock"></span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ))}
                    </>
                )}
            </section>
        </main>
    );
}
