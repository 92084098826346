import { toast } from 'react-toastify';
import axios from 'axios';

export const connectAccountStaff = (mail, pass) => async (dispatch) =>
    axios
        .post(
            'https://api.mamaisonsereine.fr/api/authentication/login/',
            {
                username: mail,
                password: pass,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'SEND_LOGIN_ACCESS',
                accessToken: res.data.access,
                refreshToken: res.data.refresh,
            })
        )

        .catch(() => {
            toast.error(
                'Impossible de se connecter, Verifier vos informations',
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return dispatch({
                type: 'SEND_LOGIN_ACCESS',
                user: null,
                accessToken: null,
                refreshToken: null,
            });
        });

export const getAuthUser = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/authentication/login/user_connected/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'AUTH_USER',
                authUser: res.data,
            })
        )

        .catch(() => {
            toast.error(
                'Impossible de se connecter, Verifier vos informations',
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return dispatch({
                type: 'AUTH_USER',
                authUser: null,
            });
        });

export const getGrades = (authUser, accessToken) => async (dispatch) => {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        timeout: 20000,
        credentials: 'same-origin',
    };

    let url = 'https://api.mamaisonsereine.fr/api/';
    if (accessToken !== null && authUser.profile.manage_staff_crud === true) {
        url = 'https://api.mamaisonsereine.fr/api/authentication/profiles/all/';
        headers = {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
        };
    }

    const config = {
        method: 'get',
        url,
        headers,
    };

    return axios(config)
        .then((res) =>
            dispatch({
                type: 'GET_GRADES',
                grades: res.data,
            })
        )

        .catch(() => {
            toast.error("Impossible d'obtenir les Grades", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return dispatch({
                type: 'GET_GRADES',
                grades: null,
            });
        });
};

export const getAllUsers = (accessToken) => async (dispatch) => {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        timeout: 20000,
        credentials: 'same-origin',
    };

    let url = 'https://api.mamaisonsereine.fr/api/';
    if (accessToken !== null) {
        url =
            'https://api.mamaisonsereine.fr/api/authentication/users/v1/listing/';
        headers = {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
        };
    }

    const config = {
        method: 'get',
        url,
        headers,
    };

    return axios(config)
        .then((res) =>
            dispatch({
                type: 'GET_ALL_USERS',
                allUsers: res.data,
            })
        )

        .catch(() =>
            dispatch({
                type: 'GET_ALL_USERS',
                allUsers: null,
            })
        );
};

export const logout = () => (dispatch) =>
    dispatch({
        type: 'LOGOUT',
    });

export const editUser = (user) => (dispatch) =>
    dispatch({
        type: 'EDIT_USER',
        editUser: user,
    });
export const eraseEditUser = () => (dispatch) =>
    dispatch({
        type: 'ERASE_EDIT_USER',
    });
