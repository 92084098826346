import { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { FaPersonShelter } from 'react-icons/fa6';
import {
    getApartments,
    getReservations,
} from '../../Store/Actions/smoobu.actions';
import ListingReservation from '../../components/Reservations/gestionAdmin/ListingReservation.jsx';
import LoadingK from '../../components/Widget/LoadingK.jsx';
import LegendeReservations from '../../components/Reservations/gestionAdmin/LegendeReservations.jsx';

export default function OccupationDirect() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [dateState] = useState(new Date());
    document.title = 'Occupation des Logements | Ma Maison Sereine';
    document.description = 'Pages des Occupations';
    const [watchCancelled, setWatchcancelled] = useState(false);

    const dispatch = useDispatch();
    const authUser = useSelector((store) => store.User.authUser);
    const accessToken = useSelector((store) => store.User.accessToken);
    const dataApartments = useSelector((store) => store.Smoobu.dataApartments);
    const dataReservations = useSelector(
        (store) => store.Smoobu.dataReservations
    );
    useEffect(() => {
        setTimeout(() => {
            dispatch(getApartments());
        }, 500);
    }, []);
    useEffect(() => {
        setTimeout(() => {
            dispatch(getReservations(dateStart, dateEnd, size, accessToken));
        }, 1000);
    }, [accessToken]);

    const today = new Date();
    const deltaBeforeDate = new Date(new Date().setDate(today.getDate() - 2))
        .toISOString()
        .split('T')[0];
    const [dateStart, setDateStart] = useState(deltaBeforeDate);

    const deltaMonth = new Date().setDate(today.getDate() + 8);
    const deltaDate = new Date(deltaMonth).toISOString().split('T')[0];
    const [dateEnd, setDateEnd] = useState(String(deltaDate));
    const [size] = useState(100);

    return (
        <main className="d-flex flex-column text-center mt-5">
            <h1 className="text-center mx-auto display-5 mt-2">
                <span className="me-3">
                    <FaPersonShelter />
                </span>
                Occupations Logements
            </h1>
            <p>
                Nous sommes le{' '}
                {dateState.toLocaleDateString('fr-fr', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}
                {' - '}
                {dateState.toLocaleString('fr-fr', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                })}{' '}
            </p>
            <div>
                <p className="text-center">Critères de recherche :</p>
                <div className="d-flex flex-row flex-wrap justify-content-center">
                    <div className="d-flex flex-column justify-content-center align-items-center mx-3">
                        <label>Date Début</label>
                        <input
                            className="text-control"
                            type="date"
                            id="dateStart"
                            name="dateStart"
                            defaultValue={dateStart}
                            onChange={(e) => setDateStart(e.target.value)}
                            required
                        />
                    </div>
                    <div className="d-flex flex-column  justify-content-center align-items-center mx-3">
                        <label>Date Fin</label>
                        <input
                            className="text-control"
                            type="date"
                            id="dateEnd"
                            name="dateEnd"
                            defaultValue={dateEnd}
                            onChange={(e) => setDateEnd(e.target.value)}
                            required
                        />
                    </div>

                    <div className="d-flex flex-row flex-wrap  justify-content-center align-items-center mx-3 text-danger border border-danger">
                        <small className="ms-2">Réservations Annulées ?</small>
                        <Input
                            type="checkbox"
                            className="mx-2 my-0"
                            onClick={(e) => {
                                setWatchcancelled(e.target.checked);
                            }}
                        />
                    </div>
                </div>{' '}
            </div>
            <LegendeReservations />
            <div className="container-fluid mx-auto my-3 d-flex flex-row flex-wrap justify-content-around align-items-start ">
                {dataApartments !== null ? (
                    dataApartments.map((appart) => (
                        <div
                            key={appart.id}
                            className="mx-auto rounded p-2 my-2 "
                        >
                            <h2 className="bg-primary color-white rounded shadow m-3 p-2">
                                {appart.name}
                            </h2>
                            <ul key={'ul' + appart.id}>
                                <ListingReservation
                                    appart={appart}
                                    listing={dataReservations}
                                    watchCancelled={watchCancelled}
                                />
                            </ul>
                        </div>
                    ))
                ) : (
                    <LoadingK />
                )}
            </div>
        </main>
    );
}
