import { useSelector } from 'react-redux';

export default function ErrorPage() {
    document.title = 'Oops! - Erreur 404 !';
    const translate = useSelector((store) => store.Template.language);

    return (
        <main id="error-page" className="mt-5 pt-5 vh-100">
            {translate === 'French' && (
                <>
                    <h1 className="mt-5 pt-5 text-center">
                        Oops! - Erreur 404 !
                    </h1>
                    <p className="text-center mt-3">
                        La page demandée n'existe pas, merci de vérifier
                        l'adresse saisie.
                    </p>
                </>
            )}
            {translate === 'English' && (
                <>
                    <h1 className="mt-5 pt-5 text-center">
                        Oops! - Error 404!
                    </h1>
                    <p className="text-center mt-3">
                        The requested page does not exist, please check the
                        entered address.
                    </p>
                </>
            )}
        </main>
    );
}
