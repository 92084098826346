import Calendar from 'react-calendar';
import { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheck, FaPersonWalkingLuggage } from 'react-icons/fa6';
import { FiAlertTriangle } from 'react-icons/fi';
import { BsFillMoonStarsFill } from 'react-icons/bs';
import { IoLockClosed } from 'react-icons/io5';

import {
    setDateSelected,
    setSelectedAppart,
} from '../../Store/Actions/config.actions';
import { useFetchGetRates } from '../../utils/Api/FetchSmoobu.jsx';
import replaceDay from '../../utils/functions.jsx';
import CoefPrixBaseToSite from './coefPrix.jsx';
import {
    useFetchGetLocationSettingsReservationAccess,
    useFetchGetLocationSettings,
} from '../../utils/Api/FetchLocationSettings.jsx';

import LoadingK from '../Widget/LoadingK.jsx';

export default function CalendarBooking(props) {
    const {
        dataLocationSettingsReservationAccess,
        isLoadingLocationSettingsReservationAccess,
    } = useFetchGetLocationSettingsReservationAccess();
    const dispatch = useDispatch();
    const translate = useSelector((store) => store.Template.language);
    const authUser = useSelector((store) => store.User.authUser);
    const dateSelected = useSelector((store) => store.Config.dateSelected);

    const { dataLocationSettings } = useFetchGetLocationSettings();

    const [dataCheck, setDataCheck] = useState(undefined);

    useEffect(() => {
        async function fetchData() {
            setDataCheck(undefined);
            let checkInDate = new Date();
            checkInDate.setFullYear(dateSelected[0].getFullYear());
            checkInDate.setMonth(dateSelected[0].getMonth());
            checkInDate.setDate(dateSelected[0].getDate());
            checkInDate = String(checkInDate.toISOString().split('T')[0]);
            let checkOutDate = new Date();
            checkOutDate.setFullYear(dateSelected[1].getFullYear());
            checkOutDate.setMonth(dateSelected[1].getMonth());
            checkOutDate.setDate(dateSelected[1].getDate());
            checkOutDate = String(checkOutDate.toISOString().split('T')[0]);

            const delta = Math.trunc(
                (dateSelected[1] - dateSelected[0]) / (1000 * 60 * 60 * 24)
            );

            setNberNight(delta);
            const response = await fetch(
                `https://api.mamaisonsereine.fr/api/smoobu/v1/apartments/cities/search/${checkInDate}/${checkOutDate}/all/`,
                {
                    method: 'get',
                    headers: new Headers({
                        'content-type': 'application/json',
                    }),
                }
            );
            const data = await response.json();

            if (response.status === 200) {
                setDataCheck(data);
            } else if (response.status === 500) {
                toast.error('Le serveur distant est en erreur', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else if (response.status === 503) {
                toast.error('Le prestataire est en erreur', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        if (
            dateSelected !== null &&
            dateSelected[0] !== undefined &&
            dateSelected[1] !== undefined
        ) {
            fetchData();
        }
    }, [dateSelected]);

    const today = new Date();
    const start_date = String(today.toISOString().split('T')[0]);
    // end date M+6
    const newDate = new Date();
    newDate.setMonth(today.getMonth() + 6);
    newDate.setDate(today.getDate() + 1);
    const end_date = String(newDate.toISOString().split('T')[0]);
    const { dataPlanning, isLoading } = useFetchGetRates(
        props.apartment.id,
        start_date,
        end_date
    );
    const [dateClickMsgError, setDateClickMsgError] = useState(undefined);
    const [nberNight, setNberNight] = useState(0);
    const [nberNightMin, setNberNightMin] = useState(0);
    function tileContent({ date, view }) {
        if (view === 'month') {
            const selectDate = new Date(date);
            selectDate.setDate(date.getDate() + 1);
            const myDate = selectDate.toJSON().split('T')[0];
            const searchedDate = Object.values(
                dataPlanning.data,
                props.apartment.id
            )[0];
            if (
                Object.hasOwn(searchedDate, myDate) &&
                Object.getOwnPropertyDescriptor(searchedDate, myDate).value
                    .available === 1 &&
                Object.getOwnPropertyDescriptor(searchedDate, myDate).value
                    .price !== null
            ) {
                const donnees = Object.getOwnPropertyDescriptor(
                    searchedDate,
                    myDate
                ).value;
                return (
                    <small className="text-success">
                        <FaCheck />
                    </small>
                );
            }
        }
        return (
            <small className="d-flex flex-column">
                <IoLockClosed className="text-danger opacity-0" />
                <IoLockClosed className="text-danger opacity-50" />
            </small>
        );
    }

    function detectMinNight(e) {
        dispatch(setDateSelected(e));

        setDateClickMsgError(undefined);
        const delta = Math.trunc((e[1] - e[0]) / (1000 * 60 * 60 * 24));
        const searchedDate = Object.values(
            dataPlanning.data,
            props.apartment.id
        )[0];
        let startDate = new Date();
        startDate.setFullYear(e[0].getFullYear());
        startDate.setMonth(e[0].getMonth());
        startDate.setDate(e[0].getDate());
        startDate = String(startDate.toISOString().split('T')[0]);
        setNberNightMin(
            Object.getOwnPropertyDescriptor(searchedDate, startDate).value
                .min_length_of_stay
        );
        setNberNight(delta);

        dispatch(setSelectedAppart(props.apartment));

        for (let i = 0; i < delta; i += 1) {
            const newDate = new Date();
            newDate.setFullYear(e[0].getFullYear());
            newDate.setMonth(e[0].getMonth());
            newDate.setDate(e[0].getDate() + i);
            const controlDate = String(newDate.toISOString().split('T')[0]);

            if (
                Object.getOwnPropertyDescriptor(searchedDate, controlDate).value
                    .min_length_of_stay > delta
            ) {
                {
                    translate === 'French' &&
                        setDateClickMsgError(
                            "Vous n'avez pas le nombre minimal de nuité requis pour réserver. " +
                                delta +
                                ' nuit(s) choisie(s) or ' +
                                Object.getOwnPropertyDescriptor(
                                    searchedDate,
                                    controlDate
                                ).value.min_length_of_stay +
                                ' demandée(s)'
                        );
                }
                {
                    translate === 'English' &&
                        setDateClickMsgError(
                            'You do not have the minimum number of nights required to book.' +
                                delta +
                                ' selected night(s) but ' +
                                Object.getOwnPropertyDescriptor(
                                    searchedDate,
                                    controlDate
                                ).value.min_length_of_stay +
                                ' requested'
                        );
                }
            }
            if (
                Object.hasOwn(searchedDate, controlDate) &&
                Object.getOwnPropertyDescriptor(searchedDate, controlDate).value
                    .available === 0
            ) {
                {
                    translate === 'French' &&
                        toast.error(
                            'Le logement est occupé le ' +
                                replaceDay(controlDate) +
                                ' au soir : réservation impossible',
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                }
                {
                    translate === 'French' &&
                        setDateClickMsgError(
                            'Le logement est occupé pour la période choisie, réservation impossible'
                        );
                }
                {
                    translate === 'English' &&
                        toast.error(
                            'The accommodation is occupied on' +
                                replaceDay(controlDate) +
                                'in the evening: reservation not possible',
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                }
                {
                    translate === 'English' &&
                        setDateClickMsgError(
                            'The accommodation is occupied for the chosen period, reservation not possible'
                        );
                }
            }

            if (
                Object.hasOwn(searchedDate, controlDate) &&
                Object.getOwnPropertyDescriptor(searchedDate, controlDate).value
                    .price === null
            ) {
                {
                    translate === 'French' &&
                        toast.error(
                            "Le logement n'est pas réservable le " +
                                replaceDay(controlDate) +
                                ' au soir',
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                }
                {
                    translate === 'French' &&
                        setDateClickMsgError(
                            "Le logement n'est pas réservable pour la période choisie, tarif non défini"
                        );
                }
                {
                    translate === 'English' &&
                        toast.error(
                            'The accommodation is not bookable on' +
                                replaceDay(controlDate) +
                                ' in the evening',
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        );
                }
                {
                    translate === 'English' &&
                        setDateClickMsgError(
                            'The accommodation is not bookable for the chosen period, price not defined'
                        );
                }
            }
        }
    }

    return (
        <>
            {isLoading ? (
                <LoadingK />
            ) : (
                <div className="calendrier_booking">
                    <div className="mx-auto">
                        {translate === 'French' && (
                            <Calendar
                                onChange={(e) => detectMinNight(e)}
                                tileContent={tileContent}
                                selectRange={true}
                                culture="fr"
                                minDate={new Date()}
                                maxDate={newDate}
                                className="mx-auto"
                            />
                        )}
                        {translate === 'English' && (
                            <Calendar
                                onChange={(e) => detectMinNight(e)}
                                tileContent={tileContent}
                                selectRange={true}
                                locale="en"
                                minDate={new Date()}
                                maxDate={newDate}
                                className="mx-auto"
                            />
                        )}
                        <hr className="col-6 mx-auto" />
                        <div className="my-2 text-center">
                            {translate === 'French' && (
                                <small className="d-flex flex-column">
                                    Légende :
                                    <span>
                                        Le cadenas{' '}
                                        <IoLockClosed className="text-danger opacity-50" />{' '}
                                        indique une indisponibilité du logement.
                                    </span>
                                </small>
                            )}
                            {translate === 'English' && (
                                <small className="d-flex flex-column">
                                    Legend :
                                    <span>
                                        The cross{' '}
                                        <IoLockClosed className="text-danger opacity-50" />
                                        indicates a unavailability housing.
                                    </span>
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="mx-auto  text-center ">
                        {dateSelected !== null && (
                            <div className="text-center visually-hidden">
                                {dateSelected !== null &&
                                    dateSelected.length > 0 && (
                                        <p>
                                            {translate === 'French' && (
                                                <>
                                                    Période choisie : <br />
                                                    du{' '}
                                                    <span className="text-success">
                                                        <i className="fa-solid fa-plane-arrival"></i>
                                                        {dateSelected[0].toLocaleDateString(
                                                            'fr-FR',
                                                            {
                                                                weekday:
                                                                    'short',
                                                                year: 'numeric',
                                                                month: 'numeric',
                                                                day: 'numeric',
                                                            }
                                                        )}{' '}
                                                        17h00
                                                    </span>{' '}
                                                    <br />
                                                    au{' '}
                                                    <span className="text-danger">
                                                        <i className="fa-solid fa-plane-departure"></i>
                                                        {dateSelected[1].toLocaleDateString(
                                                            'fr-FR',
                                                            {
                                                                weekday:
                                                                    'short',
                                                                year: 'numeric',
                                                                month: 'numeric',
                                                                day: 'numeric',
                                                            }
                                                        )}{' '}
                                                        10h00
                                                    </span>
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    Chosen period: <br />
                                                    of{' '}
                                                    <span className="text-success">
                                                        <i className="fa-solid fa-plane-arrival"></i>
                                                        {dateSelected[0].toLocaleDateString(
                                                            'fr-FR',
                                                            {
                                                                weekday:
                                                                    'short',
                                                                year: 'numeric',
                                                                month: 'numeric',
                                                                day: 'numeric',
                                                            }
                                                        )}{' '}
                                                        17h00
                                                    </span>{' '}
                                                    <br />
                                                    at{' '}
                                                    <span className="text-danger">
                                                        <i className="fa-solid fa-plane-departure"></i>
                                                        {dateSelected[1].toLocaleDateString(
                                                            'fr-FR',
                                                            {
                                                                weekday:
                                                                    'short',
                                                                year: 'numeric',
                                                                month: 'numeric',
                                                                day: 'numeric',
                                                            }
                                                        )}{' '}
                                                        10h00
                                                    </span>
                                                </>
                                            )}
                                        </p>
                                    )}
                            </div>
                        )}
                        {authUser !== null &&
                            authUser.is_superuser === true && (
                                <Link to={'/locations/reservation/create'}>
                                    <Button variant="warning shadow m-2">
                                        <i className="fa-solid fa-person-walking-luggage fs-4"></i>{' '}
                                        {translate === 'French' && (
                                            <>Créer ma Réservation Admin</>
                                        )}
                                        {translate === 'English' && (
                                            <>Create my Admin Booking</>
                                        )}
                                    </Button>
                                </Link>
                            )}
                        {nberNight >= nberNightMin && nberNight !== 0 && (
                            <>
                                <div className="text-center visually-hidden">
                                    <p className="text-primary">
                                        {translate === 'French' && (
                                            <>
                                                Vous souhaitez réserver pour{' '}
                                                {nberNight} Nuits.
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>
                                                You want to book for {nberNight}{' '}
                                                Nights.
                                            </>
                                        )}
                                    </p>
                                    {dataCheck !== undefined ? (
                                        <>
                                            {dataCheck.length > 0 &&
                                                dataCheck.map((element) => (
                                                    <p key={element.id}>
                                                        {parseInt(
                                                            element.id,
                                                            20
                                                        ) ===
                                                            parseInt(
                                                                props.apartment
                                                                    .id,
                                                                20
                                                            ) && (
                                                            <span className="text-center text-primary fs-5 mt-3">
                                                                {translate ===
                                                                    'French' && (
                                                                    <>Coût :</>
                                                                )}
                                                                {translate ===
                                                                    'English' && (
                                                                    <>Cost :</>
                                                                )}{' '}
                                                                {
                                                                    element.total_price
                                                                }
                                                                €{' '}
                                                            </span>
                                                        )}
                                                    </p>
                                                ))}
                                            {translate === 'French' && (
                                                <>
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <p>
                                                            Linge de Lit inclus{' '}
                                                            <i className="fa-solid fa-bed"></i>{' '}
                                                            <i className="fa-solid fa-check text-sucess"></i>
                                                        </p>
                                                    ) : (
                                                        <p className="text-danger">
                                                            Linge de Lit NON
                                                            compris{' '}
                                                            <i className="fa-solid fa-bed"></i>{' '}
                                                            <i className="fa-solid fa-xmark text-danger"></i>
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>
                                                    {nberNight >
                                                    dataLocationSettings.number_night_linge ? (
                                                        <p>
                                                            bedding included{' '}
                                                            <i className="fa-solid fa-bed"></i>{' '}
                                                            <i className="fa-solid fa-check text-sucess"></i>
                                                        </p>
                                                    ) : (
                                                        <p className="text-danger">
                                                            bedding not included{' '}
                                                            <i className="fa-solid fa-bed"></i>{' '}
                                                            <i className="fa-solid fa-xmark text-danger"></i>
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <LoadingK />
                                    )}
                                    {isLoadingLocationSettingsReservationAccess ===
                                        false &&
                                    dateClickMsgError === undefined &&
                                    dataLocationSettingsReservationAccess.reservation_public ===
                                        true &&
                                    props.channel === 'direct' ? (
                                        <Link
                                            to={'/locations/reservation/create'}
                                        >
                                            <Button variant="primary shadow m-2">
                                                <i className="fa-solid fa-person-walking-luggage fs-4"></i>{' '}
                                                {translate === 'French' && (
                                                    <>Créer ma Réservation</>
                                                )}
                                                {translate === 'English' && (
                                                    <>Create my Booking</>
                                                )}
                                            </Button>
                                        </Link>
                                    ) : (
                                        <>
                                            <div className="bg-secondary p-2 rounded shadow m-2 d-none">
                                                <i className="fa-solid fa-person-walking-luggage fs-4"></i>{' '}
                                                {translate === 'French' && (
                                                    <>
                                                        Réservation disponible
                                                        prochainement en ligne
                                                    </>
                                                )}
                                                {translate === 'English' && (
                                                    <>
                                                        Online Booking available
                                                        soon{' '}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {props.linkAirbnb !== undefined && (
                            <div>
                                <a href={props.linkAirbnb} rel="noreferrer">
                                    <Button variant="primary shadow m-2">
                                        <FaPersonWalkingLuggage className="fs-4 me-2" />
                                        {translate === 'French' && (
                                            <>Créer ma Réservation sur AirBnB</>
                                        )}
                                        {translate === 'English' && (
                                            <>Create my Booking on AirBnb</>
                                        )}
                                    </Button>
                                </a>
                            </div>
                        )}
                        {props.linkAbritel !== undefined && (
                            <div>
                                <a href={props.linkAbritel} rel="noreferrer">
                                    <Button variant="primary shadow m-2">
                                        <FaPersonWalkingLuggage className="fs-4 me-2" />
                                        {translate === 'French' && (
                                            <>
                                                Créer ma Réservation sur Abritel
                                            </>
                                        )}
                                        {translate === 'English' && (
                                            <>Create my Booking on Abritel</>
                                        )}
                                    </Button>
                                </a>
                            </div>
                        )}
                        {dateClickMsgError !== undefined && (
                            <Alert color={'danger'} className="text-center">
                                <FiAlertTriangle className="fs-3 me-2" />
                                {dateClickMsgError}
                            </Alert>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
