import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import imgCity from '../../assets/img/city-3234092_1280.jpg';

export default function TitleHome() {
    const translate = useSelector((store) => store.Template.language);

    return (
        <>
            <div className="homepage-banner">
                <div className="homepage-banner-text">
                    <h1 className="font-title mb-4">Ma Maison Sereine</h1>
                    <h2 className="text-center mb-2 font-body">
                        {translate === 'French' && (
                            <span>
                                Locations en AirBnb & Services de Conciergerie
                            </span>
                        )}
                        {translate === 'English' && (
                            <span>AirBnb Rentals & Concierge Services</span>
                        )}
                    </h2>
                </div>
            </div>

            <div className="homepage-beach">
                <div className="homepage-beach-img">
                    <img src={imgCity} alt="Image City" loading="lazy"></img>
                </div>
                <div className="homepage-beach-text">
                    <div className="homepage-beach-text-title">
                        {translate === 'French' && (
                            <>
                                <h2>
                                    Ma Maison Sereine change de localité et vous
                                    accueillera de nouveau, pour la saison
                                    prochaine !{' '}
                                </h2>
                                <h3>
                                    Nouvelle région, nouvelles maisons, toujours
                                    avec vous!
                                </h3>
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                <h2>
                                    Ma Maison Sereine is changing location and
                                    will welcome you again, for the next season!{' '}
                                </h2>
                                <h3>
                                    New region, new houses, always with you!
                                </h3>
                            </>
                        )}
                        <div
                            className="progress border border-1 bg-white mx-5 mt-5 "
                            role="progressbar"
                            aria-label="Animated striped example"
                            aria-valuenow="20"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated p-1"
                                style={{ width: '20%' }}
                            >
                                15 %
                            </div>
                        </div>
                        {translate === 'French' && (
                            <p>Fin de saison - déménagement</p>
                        )}
                        {translate === 'English' && (
                            <p>End of season - moving schedule</p>
                        )}
                    </div>
                    <p className="mt-5">
                        {translate === 'French' && (
                            <h2>
                                Merci à tous pour votre confiance et votre
                                fidélité sur Pornic, à très bientôt !
                            </h2>
                        )}
                        {translate === 'English' && (
                            <h2>
                                Thank you all for your trust and loyalty to
                                Pornic, see you soon!
                            </h2>
                        )}
                    </p>
                </div>
            </div>
        </>
    );
}
