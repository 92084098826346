const initialState = {};

// eslint-disable-next-line default-param-last
export default function Smoobu(state = initialState, action) {
    switch (action.type) {
        case 'getApartments':
            return {
                ...state,
                dataApartments: action.dataApartments,
            };

        default:
            return state;
    }
}
