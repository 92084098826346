import { useState } from 'react';
import { Alert, Badge, Button } from 'reactstrap';
import { useSelector } from 'react-redux';

import { useFetchStancerResaVoyageur } from '../../../utils/Api/FetchStancer.jsx';
import logo_stancer from '../../../assets/img/logo/logo_stancer.png';

export default function StatusStancerVoyageur(props) {
    const translate = useSelector((store) => store.Template.language);

    const [nberPhone] = useState(
        localStorage.getItem('authVoyageurPort')
            ? localStorage.getItem('authVoyageurPort')
            : undefined
    );
    const [codeSMSActive] = useState(
        localStorage.getItem('authVoyageurCode')
            ? localStorage.getItem('authVoyageurCode')
            : undefined
    );
    const { dataStancerResaVoyageur, isLoadingStancerResaVoyageur } =
        useFetchStancerResaVoyageur(
            props.idReservation,
            nberPhone,
            codeSMSActive
        );
    return (
        <div className="container-fluid mx-auto my-3 ">
            {isLoadingStancerResaVoyageur !== true &&
                props.idReservation !== undefined &&
                dataStancerResaVoyageur !== undefined &&
                props.idChannel === 2219288 && (
                    <div className="text-center">
                        {dataStancerResaVoyageur.statut === 'Annulée' && (
                            <Badge className="m-2 fs-6" color="Danger" pill>
                                {dataStancerResaVoyageur.statut}
                            </Badge>
                        )}
                        {dataStancerResaVoyageur.statut ===
                            'En Attente de Paiement' && (
                            <>
                                <Badge
                                    className="m-2 fs-6"
                                    color="warning"
                                    pill
                                >
                                    {dataStancerResaVoyageur.statut}
                                </Badge>
                                <Alert
                                    className="my-3 text-center mx-auto col-10 fw-bold d-flex flex-column"
                                    color="danger"
                                >
                                    {translate === 'French' && (
                                        <p>
                                            <i className="fa-solid fa-warning"></i>{' '}
                                            Vous avez 30 minutes à partir de{' '}
                                            {dataStancerResaVoyageur.date_created.slice(
                                                12 - 17
                                            )}{' '}
                                            pour procéder au paiement afin de
                                            valider votre réservation
                                            définitivement sinon le logement
                                            sera remis en vente automatiquement
                                        </p>
                                    )}
                                    {translate === 'English' && (
                                        <p>
                                            <i className="fa-solid fa-warning"></i>{' '}
                                            You have 30 minutes from{' '}
                                            {dataStancerResaVoyageur.date_created.slice(
                                                12 - 17
                                            )}{' '}
                                            to proceed with the payment in order
                                            to validate your reservation
                                            definitively otherwise the housing
                                            will be relisted automatically
                                        </p>
                                    )}
                                    <a
                                        href={`https://api.mamaisonsereine.fr/api/locations/stancer/connect/${props.idReservation}/${nberPhone}/${codeSMSActive}/`}
                                    >
                                        <Button
                                            color="primary"
                                            className="my-3"
                                        >
                                            {translate === 'French' && (
                                                <>
                                                    Je procède au règlement avec{' '}
                                                </>
                                            )}
                                            {translate === 'English' && (
                                                <>I proceed to payment with </>
                                            )}
                                            <img
                                                className="bg-white rounded"
                                                src={logo_stancer}
                                                alt={'Logo - Stancer'}
                                                width={120}
                                            />
                                        </Button>
                                    </a>
                                </Alert>
                            </>
                        )}
                        {dataStancerResaVoyageur.statut === 'Payée' && (
                            <>
                                <Badge
                                    className="m-2 fs-6"
                                    color="primary"
                                    pill
                                >
                                    {dataStancerResaVoyageur.statut}
                                </Badge>
                            </>
                        )}
                    </div>
                )}
        </div>
    );
}
