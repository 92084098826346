import { useEffect } from 'react';

import CardHomeWidgetsBottom from '../../../components/Widget/CardsWidgetsBottom.jsx';
import WidgetCGL from '../../../components/Widget/CGL.jsx';

export default function CGL() {
    document.title =
        'Conditions Générales de Locations & Réglement Intérieur | Ma Maison Sereine';
    document.description =
        'Présentation des Conditions Générales de Ventes & Mentions Legales de Ma Maison Sereine';

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main className="bg-white mt-5">
            <WidgetCGL />
            <section className="sect__widgets">
                <CardHomeWidgetsBottom />
            </section>
        </main>
    );
}
