const initialState = {
    dataReservationId: null,
    MsgSendedReservationId: null,
    missionToEdit: {
        creator: null,
        staff: null,
        reservation: null,
        date_run: null,
        logement: null,
        code_before: null,
        code_after: null,
        success: false,
        notes: null,
    },
    listMissions: null,
    expiredMissions: null,
};

// eslint-disable-next-line default-param-last
export default function Gestion(state = initialState, action) {
    switch (action.type) {
        case 'DataReservationId':
            return {
                ...state,
                dataReservationId: action.dataReservationId,
            };
        case 'MsgSendedReservationId':
            return {
                ...state,
                MsgSendedReservationId: action.MsgSendedReservationId,
            };
        case 'MISSION_TO_EDIT':
            return {
                ...state,
                missionToEdit: action.mission,
            };

        case 'ListMissions':
            return {
                ...state,
                listMissions: action.listMissions,
                expiredMissions: action.expiredMissions,
            };

        default:
            return state;
    }
}
