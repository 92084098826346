import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { BsQrCodeScan } from 'react-icons/bs';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';

import { Link } from 'react-router-dom';

import qrcode_Pecherie from '../../assets/qrcode/qrcode_Pecherie.png';
import qrcode_Pure from '../../assets/qrcode/qrcode_Pure.png';
import qrcode_SSlesPinsHome from '../../assets/qrcode/qrcode_SSlesPins-home.png';
import qrcode_Maison_charme_gourmalon from '../../assets/qrcode/qrcode_maison_charme_gourmalon.png';
import qrcode_Villarose from '../../assets/qrcode/qrcode_villa-rose.png';
import qrcode_CIHome from '../../assets/qrcode/qrcode_c&i-home.png';
import qrcode_PiedsDansLEau from '../../assets/qrcode/qrcode_pied-dans-leau.png';

export default function QrCodeBienvenueGestion() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    document.title = 'Gestion des QrCodes de Bienvenue | Ma Maison Sereine';
    document.description =
        'Gestion des QrCodes de Bienvenue  Ma Maison Sereine';

    return (
        <main className="d-flex flex-column text-center p-2  mx-auto mb-5">
            <h1 className="text-center mx-auto mt-5">
                <span className="me-3">
                    <BsQrCodeScan />
                </span>
                QrCodes de Bienvenue :
            </h1>
            <hr />
            <div className="d-flex flex-row justify-content-around align-items-center flex-wrap">
                <div className="d-flex flex-column justify-content-around align-items-center col-6 mx-auto my-3">
                    <h2>Pêcherie</h2>

                    <img
                        src={qrcode_Pecherie}
                        alt="QRCode Pecherie"
                        width={'160px'}
                    />

                    <Link to="/bienvenue/laplainesurmer/pecherie/">
                        <Button variant="light border-primary shadow m-2">
                            Bienvenue à "Pecherie"
                            <span className="fs-4 ms-3">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </Button>
                    </Link>
                </div>

                <div className="d-flex flex-column justify-content-around align-items-center  col-6 mx-auto my-3">
                    <h2>Pure</h2>

                    <img src={qrcode_Pure} alt="QRCode Pure" width={'160px'} />

                    <Link to="/bienvenue/pornic/pure">
                        <Button variant="light border-primary shadow m-2">
                            Bienvenue à "Pure"
                            <span className="fs-4 ms-3">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </Button>
                    </Link>
                </div>

                <div className="d-flex flex-column justify-content-around align-items-center  col-6 mx-auto my-3">
                    <h2>Sous Les Pins - La Maison</h2>

                    <img
                        src={qrcode_SSlesPinsHome}
                        alt="QRCode Sous Les Pins - La Maison"
                        width={'160px'}
                    />

                    <Link to="/bienvenue/prefailles/sous-les-pins/maison/">
                        <Button variant="light border-primary shadow m-2">
                            Bienvenue à "Sous Les Pins - La Maison"
                            <span className="fs-4 ms-3">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </Button>
                    </Link>
                </div>
                <hr />
                <div className="d-flex flex-column justify-content-around align-items-center  col-6 mx-auto my-3">
                    <h2>Maison de Charme au Coeur de Gourmalon</h2>

                    <img
                        src={qrcode_Maison_charme_gourmalon}
                        alt="QRCode Sous Les Pins - La Maison"
                        width={'160px'}
                    />
                    <Link to="/bienvenue/pornic/maison-charme-gourmalon">
                        <Button variant="light border-primary shadow m-2">
                            Bienvenue à "Maison de Charme au Coeur de Gourmalon"
                            <span className="fs-4 ms-3">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </Button>
                    </Link>
                </div>
                <div className="d-flex flex-column justify-content-around align-items-center  col-6 mx-auto my-3">
                    <h2>Maison Vue sur Mer - Villa Rose</h2>
                    <img
                        src={qrcode_Villarose}
                        alt="QRCode Villa Rose"
                        width={'160px'}
                    />
                    <Link to="/bienvenue/prefailles/villarose">
                        <Button variant="light border-primary shadow m-2">
                            Bienvenue à "Villa Rose"
                            <span className="fs-4 ms-3">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </Button>
                    </Link>
                </div>
                <div className="d-flex flex-column justify-content-around align-items-center  col-6 mx-auto my-3">
                    <h2>Maison C&I Home - Pornic</h2>
                    <img
                        src={qrcode_CIHome}
                        alt="QRCode C&I Home"
                        width={'160px'}
                    />
                    <Link to="/bienvenue/pornic/c&i-home">
                        <Button variant="light border-primary shadow m-2">
                            Bienvenue à "C&I Home"
                            <span className="fs-4 ms-3">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </Button>
                    </Link>
                </div>
                <div className="d-flex flex-column justify-content-around align-items-center  col-6 mx-auto my-3">
                    <h2>Les Pieds dans l'eau - La Plaine Sur Mer</h2>
                    <img
                        src={qrcode_PiedsDansLEau}
                        alt="QRCode Pieds Dans l'eau"
                        width={'160px'}
                    />
                    <Link to="/bienvenue/laplainesurmer/les-pieds-dans-l-eau/">
                        <Button variant="light border-primary shadow m-2">
                            Bienvenue à "Les Pieds dans l'eau"
                            <span className="fs-4 ms-3">
                                <HiMiniArrowTopRightOnSquare />
                            </span>
                        </Button>
                    </Link>
                </div>
            </div>
        </main>
    );
}
