import { useState, useEffect } from 'react';
import {
    Badge,
    Spinner,
    Alert,
    Input,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { FaArrowAltCircleDown, FaArrowAltCircleRight } from 'react-icons/fa';
import { MdMapsHomeWork } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { IoMdOpen } from 'react-icons/io';
import { IoWarningOutline } from 'react-icons/io5';
import { BsHouseLockFill } from 'react-icons/bs';
import { FaUserLock } from 'react-icons/fa6';
import { GiVacuumCleaner } from 'react-icons/gi';

import replaceDay from '../../utils/functions.jsx';
import { getListingMissions } from '../../Store/Actions/gestion.actions';
import { getAllUsers } from '../../Store/Actions/user.actions';

export default function MyMissions() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [dateState] = useState(new Date());
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const [dateStateYesterday] = useState(yesterday);
    document.title = 'Missions | Ma Maison Sereine';
    document.description = 'Pages de Missions';
    const accessToken = useSelector((store) => store.User.accessToken);
    const authUser = useSelector((store) => store.User.authUser);
    const listMissions = useSelector((store) => store.Gestion.listMissions);
    const expiredMissions = useSelector(
        (store) => store.Gestion.expiredMissions
    );

    const allUser = useSelector((store) => store.User.allUsers);
    const [open, setOpen] = useState('0');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const dispatch = useDispatch();
    useEffect(() => {
        setTimeout(() => {
            dispatch(getListingMissions(accessToken));
        }, 500);
    }, [accessToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            accessToken !== null && dispatch(getListingMissions(accessToken));
        }, 5000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
    }, [accessToken]);

    useEffect(() => {
        if (accessToken !== null && allUser === null) {
            dispatch(getAllUsers(accessToken));
        }
    }, [accessToken]);

    return (
        <main className="d-flex flex-column text-center mt-5">
            <h1 className="text-center mx-auto display-5 mt-2 text-rose">
                <span className="me-1">
                    <GiVacuumCleaner />
                </span>
                Missions
            </h1>

            <p>
                Nous sommes le{' '}
                {dateState.toLocaleDateString('fr-fr', {
                    weekday: 'short',
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                })}
                {' - '}
                {dateState.toLocaleString('fr-fr', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                })}{' '}
            </p>
            <hr className="col-6 mx-auto my-3" />
            {listMissions === undefined && (
                <p>
                    Une erreur est survenue lors de la récupération des
                    informations
                </p>
            )}
            {listMissions === null ? (
                <Spinner animation="border" className="text-rose mx-auto" />
            ) : (
                <div className="d-flex flex-row">
                    <Accordion
                        open={open}
                        toggle={toggle}
                        className=" col-11 col-lg-9 mx-auto"
                    >
                        {' '}
                        <AccordionItem>
                            <AccordionHeader targetId={'soon'}>
                                <h2 className="text-center fs-2 ">
                                    Missions à réaliser
                                </h2>
                            </AccordionHeader>{' '}
                            <AccordionBody
                                accordionId={'soon'}
                                className="p-1 "
                            >
                                {listMissions !== null &&
                                    listMissions !== undefined &&
                                    listMissions.map((mission) => (
                                        <div className="d-flex flex-column-reverse">
                                            {new Date(mission.date_run) >=
                                                dateStateYesterday && (
                                                <div className="bg-antique p-3 m-3 w-fit mx-auto rounded-2 shadow-lg border border-1 border-rose">
                                                    {allUser !== undefined &&
                                                        allUser !== null && (
                                                            <p className="text-center my-4">
                                                                {allUser !==
                                                                    undefined &&
                                                                    allUser !==
                                                                        null &&
                                                                    allUser.map(
                                                                        (
                                                                            user
                                                                        ) => (
                                                                            <>
                                                                                {user.id ===
                                                                                    mission.creator && (
                                                                                    <span className="text-primary">
                                                                                        Demande
                                                                                        de
                                                                                        Mission
                                                                                        par{' '}
                                                                                        {
                                                                                            user.first_name
                                                                                        }{' '}
                                                                                        {
                                                                                            user.last_name
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}
                                                                <br />
                                                                <FaArrowAltCircleDown />{' '}
                                                                <br />
                                                                {allUser !==
                                                                    undefined &&
                                                                    allUser !==
                                                                        null &&
                                                                    allUser.map(
                                                                        (
                                                                            user
                                                                        ) => (
                                                                            <>
                                                                                {user.id ===
                                                                                    mission.staff && (
                                                                                    <span className="text-rose">
                                                                                        pour{' '}
                                                                                        {
                                                                                            user.first_name
                                                                                        }{' '}
                                                                                        {
                                                                                            user.last_name
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}{' '}
                                                                <hr className="col-6 mx-auto my-3" />
                                                            </p>
                                                        )}
                                                    <div>
                                                        Date programmée :{' '}
                                                        {replaceDay(
                                                            mission.date_run
                                                        )}{' '}
                                                        à{' '}
                                                        {
                                                            mission.date_run.split(
                                                                'T'
                                                            )[1]
                                                        }
                                                    </div>
                                                    <MdMapsHomeWork />{' '}
                                                    {mission.logement}
                                                    <hr className="col-6 mx-auto my-3" />
                                                    <p className="my-2  fs-4">
                                                        <BsHouseLockFill />{' '}
                                                        <Badge
                                                            color="danger"
                                                            pill
                                                        >
                                                            {
                                                                mission.code_before
                                                            }
                                                        </Badge>{' '}
                                                        <FaArrowAltCircleRight />{' '}
                                                        <Badge
                                                            color="success"
                                                            pill
                                                        >
                                                            {mission.code_after}
                                                        </Badge>{' '}
                                                        <FaUserLock />{' '}
                                                    </p>
                                                    <hr className="col-6 mx-auto my-3" />
                                                    <p className="bg-white border border-1 border-danger my-4 p-4">
                                                        <IoWarningOutline />{' '}
                                                        {mission.notes}
                                                    </p>
                                                    {authUser !== null &&
                                                        authUser.is_superuser ===
                                                            true && (
                                                            <Link
                                                                to={`/gestion/reservation/${mission.reservations}/`}
                                                            >
                                                                <div className="bg-primary text-white shadow m-1 p-2 rounded pointer">
                                                                    Réservation
                                                                    N°
                                                                    {
                                                                        mission.reservations
                                                                    }{' '}
                                                                    <IoMdOpen />
                                                                </div>
                                                            </Link>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    ))}{' '}
                            </AccordionBody>
                        </AccordionItem>{' '}
                        <AccordionItem>
                            <AccordionHeader targetId={'expired'}>
                                <h2 className="text-center fs-2 ">
                                    Missions expirées
                                </h2>
                            </AccordionHeader>{' '}
                            <AccordionBody
                                accordionId={'expired'}
                                className="p-1 "
                            >
                                {expiredMissions !== null &&
                                    expiredMissions !== undefined &&
                                    expiredMissions.map((mission) => (
                                        <div className="d-flex flex-column-reverse">
                                            {new Date(mission.date_run) <
                                                dateStateYesterday && (
                                                <div className="bg-sereine-light p-3 m-3 w-fit mx-auto rounded-2 shadow-lg border border-1 border-rose">
                                                    {allUser !== undefined &&
                                                        allUser !== null && (
                                                            <p className="text-center my-4">
                                                                {allUser !==
                                                                    undefined &&
                                                                    allUser !==
                                                                        null &&
                                                                    allUser.map(
                                                                        (
                                                                            user
                                                                        ) => (
                                                                            <>
                                                                                {user.id ===
                                                                                    mission.creator && (
                                                                                    <span className="text-primary">
                                                                                        Demande
                                                                                        de
                                                                                        Mission
                                                                                        par{' '}
                                                                                        {
                                                                                            user.first_name
                                                                                        }{' '}
                                                                                        {
                                                                                            user.last_name
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}
                                                                <br />
                                                                <FaArrowAltCircleDown />{' '}
                                                                <br />
                                                                {allUser !==
                                                                    undefined &&
                                                                    allUser !==
                                                                        null &&
                                                                    allUser.map(
                                                                        (
                                                                            user
                                                                        ) => (
                                                                            <>
                                                                                {user.id ===
                                                                                    mission.staff && (
                                                                                    <span className="text-rose">
                                                                                        pour{' '}
                                                                                        {
                                                                                            user.first_name
                                                                                        }{' '}
                                                                                        {
                                                                                            user.last_name
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}{' '}
                                                                <hr className="col-6 mx-auto my-3" />
                                                            </p>
                                                        )}
                                                    <div>
                                                        Date programmée :{' '}
                                                        {replaceDay(
                                                            mission.date_run
                                                        )}{' '}
                                                        à{' '}
                                                        {
                                                            mission.date_run.split(
                                                                'T'
                                                            )[1]
                                                        }
                                                    </div>
                                                    <MdMapsHomeWork />{' '}
                                                    {mission.logement}
                                                    <hr className="col-6 mx-auto my-3" />
                                                    <p className="my-2  fs-4">
                                                        <BsHouseLockFill />{' '}
                                                        <Badge
                                                            color="danger"
                                                            pill
                                                        >
                                                            {
                                                                mission.code_before
                                                            }
                                                        </Badge>{' '}
                                                        <FaArrowAltCircleRight />{' '}
                                                        <Badge
                                                            color="success"
                                                            pill
                                                        >
                                                            {mission.code_after}
                                                        </Badge>{' '}
                                                        <FaUserLock />{' '}
                                                    </p>
                                                    <hr className="col-6 mx-auto my-3" />
                                                    <p className="bg-white border border-1 border-danger my-4 p-4">
                                                        <IoWarningOutline />{' '}
                                                        {mission.notes}
                                                    </p>
                                                    {authUser !== null &&
                                                        authUser.is_superuser ===
                                                            true && (
                                                            <Link
                                                                to={`/gestion/reservation/${mission.reservations}/`}
                                                            >
                                                                <div className="bg-primary text-white shadow m-1 p-2 rounded pointer">
                                                                    Réservation
                                                                    N°
                                                                    {
                                                                        mission.reservations
                                                                    }{' '}
                                                                    <IoMdOpen />
                                                                </div>
                                                            </Link>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    ))}{' '}
                            </AccordionBody>
                        </AccordionItem>{' '}
                    </Accordion>
                </div>
            )}
        </main>
    );
}
