import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert } from 'reactstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from 'react-redux';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';

import {
    getListStaff,
    getLogsAuthenticationStaff,
    getLogsAuthenticationVoyageurs,
} from '../../Store/Actions/logs.actions';

export default function LogsGestion() {
    const accessToken = useSelector((store) => store.User.accessToken);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    document.title = 'Journaux Logs | Ma Maison Sereine';
    document.description = 'Pages de gestion du logiciel Ma Maison Sereine';
    const dispatch = useDispatch();
    const dataAuthenticationStaff = useSelector(
        (store) => store.Logs.logsAuthenticationStaff
    );
    const dataListStaff = useSelector((store) => store.Logs.listStaff);
    const dataAuthenticationVoyageurs = useSelector(
        (store) => store.Logs.logsAuthenticationVoyageurs
    );
    useEffect(() => {
        dispatch(getLogsAuthenticationVoyageurs(accessToken));
        dispatch(getLogsAuthenticationStaff(accessToken));
        dispatch(getListStaff(accessToken));
    }, [accessToken]);
    useEffect(() => {
        const interval = setInterval(
            () => dispatch(getLogsAuthenticationVoyageurs(accessToken)),
            10000
        );

        return () => {
            clearInterval(interval);
        };
    }, [accessToken]);
    useEffect(() => {
        const interval = setInterval(
            () => dispatch(getLogsAuthenticationStaff(accessToken)),
            10000
        );

        return () => {
            clearInterval(interval);
        };
    }, [accessToken]);
    useEffect(() => {
        const interval = setInterval(
            () => dispatch(getListStaff(accessToken)),
            10000
        );

        return () => {
            clearInterval(interval);
        };
    }, [accessToken]);

    const [openAuthenticationStaff, setOpenAuthenticationStaff] =
        useState(false);
    const [openAuthenticationVoyageurs, setOpenAuthenticationVoyageurs] =
        useState(false);
    const [openListStaff, setOpenListStaff] = useState(false);
    const handleRefresh = () => {
        window.location.reload();
    };
    const [user, setUser] = useState(undefined);
    const [idUser, setIdUser] = useState(undefined);
    const [username, setUsername] = useState(undefined);
    const [isActive, setIsActive] = useState(undefined);
    const [isSuperUser, setIsSuperUser] = useState(undefined);
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [showOff, setShowOff] = useState(false);
    const handleClose = () => setShowOff(false);
    const handleShow = (user) => {
        setShowOff(true);
        setIdUser(user.id);
        setUsername(user.username);
        setPhone(user.phone_number);
        setIsActive(user.is_active);
        setIsSuperUser(user.is_superuser);
        setFirstName(user.first_name);
        setLastName(user.last_name.toUpperCase());
        setCheckedMailError(false);
        setUser(user);
    };
    const [showOffAdd, setShowOffAdd] = useState(false);
    const handleCloseAdd = () => setShowOffAdd(false);
    const handleShowAdd = (e) => {
        setShowOffAdd(true);
        setIdUser(undefined);
        setUsername('');
        setIsActive('true');
        setIsSuperUser('false');
        setFirstName('');
        setLastName('');
        setCheckedMailError(true);
    };
    const [checkedMailError, setCheckedMailError] = useState(false);
    function checkValueEmail() {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)) {
            setCheckedMailError(true);
            toast.error('Adresse Email invalide !', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        } else {
            setCheckedMailError(false);
        }
    }
    const handleSubmitAddUser = (e) => {
        e.preventDefault();
        PostAddUser();
    };
    const handleSubmitEditUser = (e) => {
        e.preventDefault();
        PostEditUser();
    };

    async function PostAddUser() {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('is_active', isActive);
        formData.append('is_superuser', isSuperUser);
        formData.append('last_name', lastName);
        formData.append('first_name', firstName);
        formData.append('phone_number', phone);
        formData.append('password', password);
        try {
            const response = await fetch(
                'https://api.mamaisonsereine.fr/api/authentication/user/v1/create/',
                {
                    method: 'post',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json',
                    }),
                    body: formData,
                }
            );
            if (response.status === 201) {
                toast.success("Création de l'utilisateur réalisée", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                setShowOffAdd(false);
            } else if (response.status === 500) {
                toast.error(
                    'Une erreur est survenue lors du traitement des données',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            } else {
                toast.error("Oups! Impossible d'ajouter l'utilisateur 😔", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch (err) {
            toast.error('Oups! Impossible de contacter le serveur 😔', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
    async function PostEditUser() {
        const formData = new FormData();
        formData.append('id', idUser);
        formData.append('username', username);
        formData.append('is_active', isActive);
        formData.append('is_superuser', isSuperUser);
        formData.append('last_name', lastName);
        formData.append('first_name', firstName);
        formData.append('phone_number', phone);
        try {
            const response = await fetch(
                'https://api.mamaisonsereine.fr/api/authentication/user/v1/edit/',
                {
                    method: 'put',
                    headers: new Headers({
                        Authorization: `Bearer ${accessToken}`,
                        Accept: 'application/json',
                    }),
                    body: formData,
                }
            );
            const data = await response.json();
            if (response.status === 202) {
                toast.success("MAJ de l'utilisateur réalisée", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                setShowOff(false);
            } else {
                toast.error(data.msg, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        } catch {
            toast.error("Oups! Impossible de modifier l'utilisateur 😔", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }

    return (
        <main className="mb-5 pb-5">
            <h1 className="text-center mx-auto pt-5 text-warning">
                Journaux (Logs) Base de données{' '}
                <i className="ms-2 fa-solid fa-book"></i>
            </h1>
            <div className="logs_onglets">
                <Button variant="warning shadow m-1" onClick={handleRefresh}>
                    {' '}
                    <i className="fa-solid fa-arrows-rotate"></i>
                </Button>
                <Button
                    variant="warning shadow m-1"
                    onClick={(e) => {
                        setOpenAuthenticationStaff(false);
                        setOpenAuthenticationVoyageurs(
                            !openAuthenticationVoyageurs
                        );
                        setOpenListStaff(false);
                    }}
                >
                    Login Voyageur
                </Button>
                <Button
                    variant="warning shadow m-1"
                    onClick={(e) => {
                        setOpenAuthenticationStaff(!openAuthenticationStaff);
                        setOpenAuthenticationVoyageurs(false);
                        setOpenListStaff(false);
                    }}
                >
                    Login Staff
                </Button>

                <Button
                    variant="warning shadow m-1"
                    onClick={(e) => {
                        setOpenAuthenticationStaff(false);
                        setOpenAuthenticationVoyageurs(false);
                        setOpenListStaff(!openListStaff);
                    }}
                >
                    Liste Staff
                </Button>
            </div>
            {openAuthenticationStaff === true && (
                <div className="bg-antique-light m-3 p-4 border border-2 border-primary rounded">
                    <h3 className="text-center">Connexion Staff (50 max.)</h3>
                    <ul>
                        {dataAuthenticationStaff !== null &&
                            dataAuthenticationStaff.map((element) => (
                                <li key={element.id}>
                                    <i className="fa-regular fa-clock"></i>{' '}
                                    {element.date} -{' '}
                                    {element.connected === true ? (
                                        <>
                                            <i className="fa-regular fa-user"></i>{' '}
                                            {element.user.first_name}{' '}
                                            {element.user.last_name} -{' '}
                                            <span className="text-success">
                                                <i className="fa-solid fa-lock-open"></i>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa-solid fa-user-secret"></i>{' '}
                                            {element.mail} -{' '}
                                            <span className="text-danger">
                                                <i className="fa-solid fa-lock"></i>
                                            </span>
                                        </>
                                    )}
                                    <hr />
                                </li>
                            ))}
                    </ul>
                </div>
            )}
            {openAuthenticationVoyageurs === true && (
                <div className="bg-antique-light m-3 p-4 border border-2 border-primary rounded">
                    <h3 className="text-center">
                        Connexion Voyageurs (50 max.)
                    </h3>
                    <ul>
                        {dataAuthenticationVoyageurs !== null &&
                            dataAuthenticationVoyageurs.map((element) => (
                                <li key={element.id}>
                                    <i className="fa-regular fa-clock"></i>{' '}
                                    {element.date_send} <br />{' '}
                                    <span className="ms-4">
                                        <i className="fa-solid fa-comment-sms"></i>{' '}
                                        0{element.number_port} -{' '}
                                        <i className="fa-solid fa-barcode"></i>{' '}
                                        : {element.code_sms} -{' '}
                                        {element.connected === true ? (
                                            <span className="text-success">
                                                Connexion Réalisée{' '}
                                                <i className="fa-solid fa-lock-open"></i>
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                Connexion non aboutie !{' '}
                                                <i className="fa-solid fa-lock"></i>
                                            </span>
                                        )}
                                    </span>
                                    {element.id_reservation_detected !==
                                        '0' && (
                                        <Link
                                            to={`/gestion/reservation/${element.id_reservation_detected}/`}
                                            className="mx-auto"
                                        >
                                            <Button variant="light border-primary shadow m-2">
                                                Réservation{' '}
                                                {
                                                    element.id_reservation_detected
                                                }
                                                <span className="fs-4 ms-3">
                                                    <HiMiniArrowTopRightOnSquare />
                                                </span>
                                            </Button>
                                        </Link>
                                    )}
                                    <hr />
                                </li>
                            ))}
                    </ul>
                </div>
            )}
            {openListStaff === true && (
                <>
                    <div className="bg-antique-light m-3 p-4 border border-2 border-primary rounded">
                        <h3 className="text-center">Liste du Staff</h3>
                        <Button
                            variant="success shadow mx-auto text-white my-3"
                            onClick={(e) => {
                                handleShowAdd(e);
                            }}
                        >
                            Ajouter <i className="fa-solid fa-user-plus"></i>
                        </Button>
                        <ul>
                            {dataListStaff !== null &&
                                dataListStaff.map((element) => (
                                    <li key={element.id}>
                                        {' '}
                                        <i className="fa-regular fa-user"></i>{' '}
                                        {element.last_name} {element.first_name}{' '}
                                        -{' '}
                                        {element.is_active === true ? (
                                            <span className="text-success">
                                                Compte activé
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                Compte Suspendu
                                            </span>
                                        )}{' '}
                                        -{' '}
                                        {element.is_superuser === true && (
                                            <span>
                                                Admin{' '}
                                                <i className="fa-solid fa-crown"></i>
                                            </span>
                                        )}{' '}
                                        <Button
                                            variant="chic shadow m-1 "
                                            onClick={(e) => {
                                                handleShow(element);
                                            }}
                                        >
                                            <i className="fa-solid fa-pen-to-square"></i>
                                        </Button>
                                        <br />
                                        Dernier login :{' '}
                                        <i className="fa-regular fa-clock"></i>{' '}
                                        {element.last_login} - Compte crée le{' '}
                                        {element.date_joined}
                                        <br />
                                        <i className="fa-solid fa-at"></i>{' '}
                                        {element.username}{' '}
                                        <i className="fa-solid fa-mobile-screen"></i>{' '}
                                        {element.phone_number}
                                        <hr />
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <Offcanvas show={showOff} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <span className="text-chic">
                                    Modifier l'Utilisateur N°
                                    {idUser}
                                </span>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="mx-auto bg-light border border-2 border-chic p-2">
                                {user !== undefined && (
                                    <form
                                        className="col-12"
                                        onSubmit={(e) =>
                                            handleSubmitEditUser(e)
                                        }
                                    >
                                        <div className="d-flex flex-column ">
                                            <h3 className="mt-2 text-wrap">
                                                Nom :
                                            </h3>

                                            <input
                                                className="rounded border border-1 border-chic mx-1"
                                                type="text"
                                                maxLength={150}
                                                id="lastName"
                                                name="lastName"
                                                defaultValue={lastName}
                                                onChange={(e) =>
                                                    setLastName(
                                                        e.target.value.toUpperCase()
                                                    )
                                                }
                                            />
                                            <h3 className="mt-2 text-wrap">
                                                Prénom :
                                            </h3>

                                            <input
                                                className="rounded border border-1 border-chic mx-1"
                                                type="text"
                                                maxLength={150}
                                                id="firstName"
                                                name="firstName"
                                                defaultValue={firstName}
                                                onChange={(e) =>
                                                    setFirstName(e.target.value)
                                                }
                                            />
                                            <h3 className=" mt-2 text-wrap">
                                                Mail :
                                            </h3>

                                            <input
                                                className="rounded border border-1 border-chic mx-1"
                                                type="mail"
                                                maxLength={254}
                                                id="username"
                                                name="username"
                                                defaultValue={username}
                                                onChange={(e) =>
                                                    setUsername(e.target.value)
                                                }
                                                onBlur={checkValueEmail}
                                            />
                                            <h3 className="mt-2 text-wrap">
                                                Phone :
                                            </h3>

                                            <input
                                                className="rounded border border-1 border-chic mx-1"
                                                type="phone"
                                                maxLength={10}
                                                id="phone"
                                                name="phone"
                                                defaultValue={phone}
                                                onChange={(e) =>
                                                    setPhone(e.target.value)
                                                }
                                            />

                                            <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                                Compte Activé :
                                            </h3>
                                            <select
                                                onChange={(e) =>
                                                    setIsActive(e.target.value)
                                                }
                                            >
                                                <option value={user.is_active}>
                                                    {user.is_active === true
                                                        ? 'Actuellement : Oui'
                                                        : 'Actuellement : Non'}
                                                </option>
                                                {user.is_active === true ? (
                                                    <option value="false">
                                                        Non
                                                    </option>
                                                ) : (
                                                    <option value="true">
                                                        Oui
                                                    </option>
                                                )}
                                            </select>

                                            <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                                Compte Administrateur :
                                            </h3>
                                            <select
                                                onChange={(e) =>
                                                    setIsSuperUser(
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value={isSuperUser}>
                                                    {user.is_superuser === true
                                                        ? 'Actuellement : Oui'
                                                        : 'Actuellement : Non'}
                                                </option>
                                                {user.is_superuser === true ? (
                                                    <option value="false">
                                                        Non
                                                    </option>
                                                ) : (
                                                    <option value="true">
                                                        Oui
                                                    </option>
                                                )}
                                            </select>
                                        </div>

                                        {checkedMailError === false ? (
                                            <div>
                                                <button
                                                    className="btn btn-chic text-white my-3 mx-auto text-center"
                                                    type="submit"
                                                >
                                                    Sauvegarder
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <button className="btn btn-chic text-white my-3 mx-auto text-center disabled">
                                                    Sauvegarder
                                                </button>
                                            </div>
                                        )}
                                    </form>
                                )}
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Offcanvas show={showOffAdd} onHide={handleCloseAdd}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                Ajouter un nouvel Utilisateur
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="col-12  mx-auto bg-light border border-2 border-success p-2">
                                <form onSubmit={(e) => handleSubmitAddUser(e)}>
                                    <div className="d-flex flex-column ">
                                        <h3 className=" mt-2 text-wrap">
                                            Nom :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="text"
                                            maxLength={150}
                                            id="lastName"
                                            name="lastName"
                                            defaultValue={lastName}
                                            onChange={(e) =>
                                                setLastName(
                                                    e.target.value.toUpperCase()
                                                )
                                            }
                                        />
                                        <h3 className=" mt-2 text-wrap">
                                            Prénom :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="text"
                                            maxLength={150}
                                            id="firstName"
                                            name="firstName"
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                        />
                                        <h3 className="mt-2 text-wrap">
                                            Phone :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="phone"
                                            maxLength={10}
                                            id="phone"
                                            name="phone"
                                            defaultValue={phone}
                                            onChange={(e) =>
                                                setPhone(e.target.value)
                                            }
                                        />
                                        <h3 className="mt-2 text-wrap">
                                            Mail :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="mail"
                                            maxLength={254}
                                            id="username"
                                            name="username"
                                            onChange={(e) =>
                                                setUsername(e.target.value)
                                            }
                                            onBlur={checkValueEmail}
                                        />
                                        <h3 className="mt-2 text-wrap">
                                            Mot de Passe :
                                        </h3>

                                        <input
                                            className="rounded border border-1 border-chic mx-1"
                                            type="password"
                                            maxLength={254}
                                            id="password"
                                            name="password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />

                                        <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                            Compte Activé :
                                        </h3>
                                        <select
                                            onChange={(e) =>
                                                setIsActive(e.target.value)
                                            }
                                        >
                                            <option value="false">Non</option>
                                            <option value="true">Oui</option>
                                        </select>

                                        <h3 className="font_ribeye text-chic mt-2 text-wrap">
                                            Compte Administrateur :
                                        </h3>
                                        <select
                                            onChange={(e) =>
                                                setIsSuperUser(e.target.value)
                                            }
                                        >
                                            <option value="false">Non</option>
                                            <option value="true">Oui</option>
                                        </select>
                                    </div>

                                    {checkedMailError === false ? (
                                        <button
                                            className="btn btn-chic text-white my-3 mx-auto text-center"
                                            type="submit"
                                        >
                                            Sauvegarder
                                        </button>
                                    ) : (
                                        <button className="btn btn-chic text-white my-3 mx-auto text-center disabled">
                                            Sauvegarder
                                        </button>
                                    )}
                                </form>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Alert className="" color="warning" isOpen={true}>
                        Merci de recharger la page après chaque modification...{' '}
                        <Button
                            variant="warning shadow m-1"
                            onClick={handleRefresh}
                        >
                            {' '}
                            <i className="fa-solid fa-arrows-rotate"></i>
                        </Button>
                    </Alert>
                </>
            )}
        </main>
    );
}
