import LoadingK from '../../Widget/LoadingK.jsx';
import DetailsReservation from './DetailsReservation.jsx';

// filtrage des réservations annulées ou non
export default function ListingReservation(props) {
    const todayDate = String(new Date().toISOString().split('T')[0]);

    return props.listing !== undefined && props.listing !== null ? (
        props.listing.bookings.map((element) => (
            <>
                {/* réservation annulée */}
                {element.apartment.id === props.appart.id &&
                    element.type === 'cancellation' &&
                    props.watchCancelled === true && (
                        <DetailsReservation
                            element={element}
                            className="bg-danger p-1 opacity-25 rounded  my-1"
                            appartSee={props.appartSee}
                            checkSee={props.checkSee}
                            key={element.id}
                        />
                    )}

                {/* réservation passée */}
                {element.apartment.id === props.appart.id &&
                    element.type !== 'cancellation' &&
                    todayDate > element.departure && (
                        <DetailsReservation
                            element={element}
                            className="bg-light p-1 opacity-25 rounded  my-1"
                            appartSee={props.appartSee}
                            checkSee={props.checkSee}
                            key={element.id}
                        />
                    )}
                {/* réservation en cours */}
                {element.apartment.id === props.appart.id &&
                    element.type !== 'cancellation' &&
                    todayDate >= element.arrival &&
                    todayDate <= element.departure && (
                        <DetailsReservation
                            element={element}
                            className="bg-antique p-1 rounded  my-1 border border-2 border-primary"
                            appartSee={props.appartSee}
                            checkSee={props.checkSee}
                            key={element.id}
                        />
                    )}
                {element.apartment.id === props.appart.id &&
                    element.type !== 'cancellation' &&
                    todayDate < element.arrival && (
                        <DetailsReservation
                            element={element}
                            className="bg-sereine-light p-1 rounded opacity-75 my-1"
                            appartSee={props.appartSee}
                            checkSee={props.checkSee}
                            key={element.id}
                        />
                    )}
            </>
        ))
    ) : (
        <LoadingK />
    );
}
