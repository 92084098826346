import { toast } from 'react-toastify';
import axios from 'axios';

export const getLogsAuthenticationStaff = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/logs/v1/authentication/staff/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'logsAuthenticationStaff',
                logsAuthenticationStaff: res.data,
            })
        )

        .catch(() => {
            toast.error("Impossible d'obtenir les getLogsAuthenticationStaff", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return dispatch({
                type: 'logsAuthenticationStaff',
                logsAuthenticationStaff: null,
            });
        });

export const getLogsAuthenticationVoyageurs =
    (accessToken) => async (dispatch) =>
        axios
            .get(
                'https://api.mamaisonsereine.fr/api/logs/v1/authentication/voyageurs/',
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 20000,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    credentials: 'same-origin',
                }
            )
            .then((res) =>
                dispatch({
                    type: 'logsAuthenticationVoyageurs',
                    logsAuthenticationVoyageurs: res.data,
                })
            )

            .catch(() => {
                toast.error(
                    "Impossible d'obtenir les logsAuthenticationVoyageurs",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
                return dispatch({
                    type: 'logsAuthenticationVoyageurs',
                    logsAuthenticationVoyageurs: null,
                });
            });

export const getListStaff = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/authentication/users/v1/listing/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'listStaff',
                listStaff: res.data,
            })
        )

        .catch(() => {
            toast.error("Impossible d'obtenir les listStaff", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return dispatch({
                type: 'listStaff',
                listStaff: null,
            });
        });
