import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaPersonShelter, FaPersonWalkingLuggage } from 'react-icons/fa6';
import { LiaPiggyBankSolid } from 'react-icons/lia';

import { IoCalendarOutline } from 'react-icons/io5';
import { BsQrCodeScan } from 'react-icons/bs';
import { LuMessagesSquare } from 'react-icons/lu';
import { MdOutlineListAlt } from 'react-icons/md';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { GiVacuumCleaner } from 'react-icons/gi';

export default function HomeGestion() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const authUser = useSelector((store) => store.User.authUser);
    const numberSMSNotRead = useSelector(
        (store) => store.Messenger.numberSMSNotRead
    );
    document.title = 'Centre de Gestion | Ma Maison Sereine';
    document.description = 'Pages de gestion du logiciel Ma Maison Sereine';

    return (
        <main>
            <h1 className="text-center mx-auto">Espace de Gestion</h1>{' '}
            {authUser !== null && (
                <p className=" text-center mx-auto  mb-3">
                    Bonjour {authUser.first_name} 😀 !
                </p>
            )}
            <div className="d-flex flex-row flex-wrap mb-5 pb-5">
                <div className="col-6 mx-auto d-flex flex-column text-center">
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link to="/gestion/occupation" className="mx-auto my-1">
                            <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded  d-flex flex-row justify-content-around align-items-center">
                                <span className="fs-5 me-2">
                                    {' '}
                                    <FaPersonShelter />
                                </span>
                                Etat d'Occupation en Direct
                            </button>
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link
                            to="/gestion/reservations"
                            className="mx-auto my-1"
                        >
                            <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                                <span className="fs-5 me-2">
                                    {' '}
                                    <FaPersonWalkingLuggage />
                                </span>
                                Liste des Réservations
                            </button>
                        </Link>
                    )}
                    {authUser !== null && authUser.is_superuser === true && (
                        <Link to="/gestion/agenda" className="mx-auto my-1">
                            <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded  d-flex flex-row justify-content-around align-items-center">
                                <span className="fs-5 me-2">
                                    {' '}
                                    <IoCalendarOutline />
                                </span>
                                Calendriers Logements
                            </button>
                        </Link>
                    )}

                    <Link to="/gestion/bienvenue" className="mx-auto my-1">
                        <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                            <span className="fs-5 me-2">
                                {' '}
                                <BsQrCodeScan />
                            </span>
                            QrCodes de Bienvenue
                        </button>
                    </Link>
                    <Link to="/gestion/missions" className="mx-auto my-1">
                        <button className="btn btn-outline-primary fs-0  m-2 shadow rounded  d-flex flex-row justify-content-around align-items-center">
                            <span className="fs-5 me-2">
                                {' '}
                                <GiVacuumCleaner />
                            </span>
                            Missions
                        </button>
                    </Link>
                </div>
                {authUser !== null && authUser.is_superuser === true && (
                    <div className="col-6 mx-auto d-flex flex-column text-center">
                        <Link to="/gestion/messagerie" className="mx-auto my-1">
                            <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded  d-flex flex-row justify-content-around align-items-center">
                                <span className="fs-5 me-2">
                                    {' '}
                                    <LuMessagesSquare />
                                </span>
                                Messagerie{' '}
                                {numberSMSNotRead !== null &&
                                    numberSMSNotRead > 0 && (
                                        <span className="m-1 bg-purple text-white rounded  px-2">
                                            <small>{numberSMSNotRead}</small>
                                        </span>
                                    )}
                            </button>
                        </Link>
                        <Link to="/gestion/stancer" className="mx-auto my-1">
                            <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                                <span className="fs-5 me-2">
                                    {' '}
                                    <LiaPiggyBankSolid />
                                </span>
                                Stancer
                            </button>
                        </Link>
                        <Link to="/gestion/logs" className="mx-auto my-1">
                            <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                                <span className="fs-5 me-2">
                                    {' '}
                                    <MdOutlineListAlt />
                                </span>
                                Logs & Staff
                            </button>
                        </Link>
                        <Link to="/gestion/settings" className="mx-auto my-1">
                            <button className="btn btn-outline-primary fs-0 p-2 m-2 shadow rounded d-flex flex-row justify-content-around align-items-center">
                                <span className="fs-5 me-2">
                                    {' '}
                                    <HiOutlineWrenchScrewdriver />
                                </span>
                                Réglages
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        </main>
    );
}
