import { combineReducers } from 'redux';
import Config from './Reducers/config.reducer';
import Gestion from './Reducers/gestion.reducer';
import Logs from './Reducers/logs.reducer';
import Messenger from './Reducers/messenger.reducer';
import Smoobu from './Reducers/smoobu.reducer';
import Stancer from './Reducers/stancer.reducer';
import User from './Reducers/user.reducer';
import Template from './Reducers/template.reducer';

export default combineReducers({
    Config,
    Gestion,
    Logs,
    Messenger,
    Smoobu,
    Stancer,
    Template,
    User,
});
