import { toast } from 'react-toastify';
import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export const getReservations =
    (dateStart, dateEnd, size, accessToken) => async (dispatch) =>
        axios
            .get(
                `https://api.mamaisonsereine.fr/api/smoobu/v1/reservations/date/${dateStart}/${dateEnd}/${size}/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 20000,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    credentials: 'same-origin',
                }
            )
            .then((res) =>
                dispatch({
                    type: 'getReservations',
                    dataReservations: res.data,
                })
            )

            .catch(() => {
                toast.error("Impossible d'obtenir les Reservations", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return dispatch({
                    type: 'getReservations',
                    dataReservations: null,
                });
            });

export const getApartments = () => async (dispatch) =>
    axios
        .get('https://api.mamaisonsereine.fr/api/smoobu/v1/apartments/', {
            timeout: 20000,
            'Content-Type': 'application/x-www-form-urlencoded',
            credentials: 'same-origin',
        })
        .then((res) =>
            dispatch({
                type: 'getApartments',
                dataApartments: res.data.apartments,
            })
        )

        .catch(() => {
            dispatch({
                type: 'getApartments',
                dataApartments: null,
            });
        });
