/* eslint-disable arrow-body-style */
export const templateDetectMode = () => {
    return (dispatch) => {
        const getCurrentTheme = window.matchMedia(
            '(prefers-color-scheme: dark)'
        ).matches;

        return dispatch({
            type: 'TEMPLATE_DETECT_MODE',
            darkMode: getCurrentTheme,
        });
    };
};
export const switchToLightMode = () => {
    return (dispatch) => {
        return dispatch({
            type: 'TEMPLATE_DETECT_MODE',
            darkMode: false,
        });
    };
};
export const switchToDarkMode = () => {
    return (dispatch) => {
        return dispatch({
            type: 'TEMPLATE_DETECT_MODE',
            darkMode: true,
        });
    };
};

export const switchToEnglish = () => {
    return (dispatch) => {
        return dispatch({
            type: 'LANGUAGE_EN',
            language: 'English',
        });
    };
};
export const switchToFrench = () => {
    return (dispatch) => {
        return dispatch({
            type: 'LANGUAGE_FR',
            language: 'French',
        });
    };
};
