import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MdNotificationsActive } from 'react-icons/md';
import {
    Alert,
    Badge,
    Input,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';

import {
    SetSettingsMsg,
    getSettingsMsg,
    putSettingsMsg,
} from '../../../Store/Actions/messenger.actions';
import { setSettingMode } from '../../../Store/Actions/config.actions';
import LoadingK from '../../../components/Widget/LoadingK.jsx';

export default function MessengerSettings() {
    document.title = 'Reglages SMS | Ma Maison Sereine';
    document.description = 'Messagerie de Ma Maison Sereine';
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const dispatch = useDispatch();
    const authUser = useSelector((store) => store.User.authUser);
    const accessToken = useSelector((store) => store.User.accessToken);
    const dataSettingsMsg = useSelector((store) => store.Messenger.settingsMsg);
    const editSettingsMsg = useSelector(
        (store) => store.Messenger.editSettingsMsg
    );

    const settingMode = useSelector((store) => store.Config.settingMode);

    useEffect(() => {
        setTimeout(() => {
            dispatch(getSettingsMsg(accessToken));
        }, 1000);
    }, [accessToken]);

    useEffect(() => {
        const interval = setInterval(() => {
            accessToken !== null && dispatch(getSettingsMsg(accessToken));
        }, 15000);

        return () => {
            if (accessToken !== null) {
                clearInterval(interval);
            }
        };
    }, [accessToken]);
    const handleChangeModeEdition = (e) => {
        if (authUser !== undefined && authUser.is_superuser === true) {
            dispatch(setSettingMode(!settingMode));
        } else {
            toast.error(
                'Vous ne disposez pas des droits nécessaires pour réaliser cette opération. Sinon merci de rafraichir la page et de retester.',
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    };

    return (
        <main className="d-flex flex-column mt-5 mx-auto text-primary">
            <h1 className="text-center mt-3 mx-auto display-5 ">
                Reglages Sms
            </h1>
            <div className="p-2 m-1">
                <div id="IconEdit" className="mx-auto my-1">
                    {settingMode === true ? (
                        <div className="d-flex flex-row align-items-center justify-content-center ">
                            <button
                                className="btn btn-warning  p-1 text-white"
                                onClick={handleChangeModeEdition}
                                id="editModeBtn"
                            >
                                Mode Edition ON
                            </button>
                        </div>
                    ) : (
                        <div className="d-flex flex-row align-items-center justify-content-center ">
                            {authUser !== undefined && (
                                <button
                                    className="btn btn-primary m-1 p-1 text-white"
                                    onClick={() => {
                                        handleChangeModeEdition();
                                        dispatch(
                                            SetSettingsMsg(dataSettingsMsg)
                                        );
                                    }}
                                    id="editModeBtn"
                                >
                                    Mode Edition OFF
                                </button>
                            )}
                        </div>
                    )}
                </div>

                <div className="d-flex flex-row flex-wrap mx-auto">
                    {settingMode !== true && (
                        <>
                            <div className="text-center p-1 col-11 col-md-6 col-xxl-4 mx-auto">
                                {' '}
                                IP Portable Fabien à détecter sur le VPN :
                                <Alert className="mx-auto" color="primary">
                                    {dataSettingsMsg !== null ? (
                                        dataSettingsMsg.ip_phone_sms
                                    ) : (
                                        <LoadingK />
                                    )}
                                </Alert>
                                {dataSettingsMsg !== null && (
                                    <p>
                                        Clé Token Authentification sur Portable
                                        : <br />
                                        {dataSettingsMsg.token_phone_sms.slice(
                                            0,
                                            10
                                        )}
                                        ***********
                                    </p>
                                )}
                            </div>
                            <Accordion
                                flush
                                open={open}
                                toggle={toggle}
                                className="w-100 mx-auto p-2 border border-2 border-primary rounded m-1"
                            >
                                <AccordionItem>
                                    <AccordionHeader targetId="2">
                                        <span className="text-danger font-body">
                                            {' '}
                                            Répondeur Automatique{' '}
                                        </span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="2">
                                        {dataSettingsMsg !== null && (
                                            <p>
                                                Envoyer Email pour chaque sms
                                                reçu : <br />
                                                {dataSettingsMsg.email_every_sms ===
                                                true ? (
                                                    <Badge color="success">
                                                        Activé
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger">
                                                        Désactivé
                                                    </Badge>
                                                )}
                                            </p>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <p>
                                                Activer le répondeur automatique
                                                SMS pour chaque sms reçu :{' '}
                                                <br />
                                                {dataSettingsMsg.active_repondeur_sms ===
                                                true ? (
                                                    <Badge color="success">
                                                        Activé
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger">
                                                        Désactivé
                                                    </Badge>
                                                )}
                                            </p>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message Répondeur SMS
                                                    Voyageurs:
                                                </span>
                                                <br />
                                                <p className="text-dark pt-2">
                                                    {
                                                        dataSettingsMsg.active_repondeur_sms_msg
                                                    }
                                                </p>
                                            </div>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message Répondeur SMS
                                                    Propriétaires
                                                </span>
                                                <br />
                                                <small>
                                                    (N° dans carnet addresses)
                                                </small>
                                                :
                                                <p className="text-dark pt-2">
                                                    {
                                                        dataSettingsMsg.active_repondeur_sms_msg_proprio
                                                    }
                                                </p>
                                            </div>
                                        )}
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionHeader targetId="3">
                                        <span className="text-danger font-body">
                                            Envoi SMS Automatique (Livret / Code
                                            Clé)
                                        </span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        {dataSettingsMsg !== null && (
                                            <p>
                                                Activer envoi SMS de Bienvenue
                                                avec lien logement : <br />
                                                {dataSettingsMsg.active_sms_bienvenue ===
                                                true ? (
                                                    <Badge color="success">
                                                        Activé
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger">
                                                        Désactivé
                                                    </Badge>
                                                )}
                                            </p>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div>
                                                Nombre d'heure avant SMS
                                                Bienvenue: <br />{' '}
                                                {
                                                    dataSettingsMsg.hours_before_sms_bienvenue
                                                }{' '}
                                                heures
                                            </div>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message Code Boite SMS :
                                                </span>
                                                <br />
                                                {
                                                    dataSettingsMsg.hours_before_sms_bienvenue_msg
                                                }
                                            </div>
                                        )}
                                        <hr />
                                        {dataSettingsMsg !== null && (
                                            <p>
                                                Activer envoi code boite à clé
                                                par SMS: <br />
                                                {dataSettingsMsg.active_sms_before_checkin ===
                                                true ? (
                                                    <Badge color="success">
                                                        Activé
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger">
                                                        Désactivé
                                                    </Badge>
                                                )}
                                            </p>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <p>
                                                Nombre d'heure avant Checkin
                                                code boite à clé par SMS :{' '}
                                                <br />{' '}
                                                {
                                                    dataSettingsMsg.hours_before_checkin_sms
                                                }{' '}
                                                heures
                                            </p>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message Code Boite SMS :{' '}
                                                </span>
                                                <br />
                                                {
                                                    dataSettingsMsg.hours_before_checkin_sms_msg
                                                }
                                            </div>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <p>
                                                Activer envoi SMS de
                                                remerciement de séjour: <br />
                                                {dataSettingsMsg.active_sms_before_checkout ===
                                                true ? (
                                                    <Badge color="success">
                                                        Activé
                                                    </Badge>
                                                ) : (
                                                    <Badge color="danger">
                                                        Désactivé
                                                    </Badge>
                                                )}
                                            </p>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <p>
                                                Nombre d'heure envoi SMS
                                                remerciement avant Checkout :{' '}
                                                <br />{' '}
                                                {
                                                    dataSettingsMsg.hours_before_checkout_sms
                                                }{' '}
                                                heures
                                            </p>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message SMS Remerciement:{' '}
                                                </span>
                                                <br />
                                                {
                                                    dataSettingsMsg.hours_before_checkout_sms_msg
                                                }
                                            </div>
                                        )}
                                    </AccordionBody>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionHeader targetId="4">
                                        <span className="text-danger font-body">
                                            SMS Type Linge / Ménage / Procédure
                                            d'achat
                                        </span>
                                    </AccordionHeader>
                                    <AccordionBody accordionId="4">
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message Pack Linge :{' '}
                                                </span>
                                                <br />
                                                {dataSettingsMsg.sms_msg_linge}
                                            </div>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message forfait Ménage :{' '}
                                                </span>
                                                <br />
                                                {dataSettingsMsg.sms_msg_menage}
                                            </div>
                                        )}
                                        {dataSettingsMsg !== null && (
                                            <div className="border border-2 border-primary p-2 rounded my-2">
                                                <span className="bg-primary text-white p-1 rounded">
                                                    Message Procédure Commande :{' '}
                                                </span>{' '}
                                                <br />
                                                {
                                                    dataSettingsMsg.sms_procedure_commande
                                                }
                                            </div>
                                        )}{' '}
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </>
                    )}
                    {editSettingsMsg !== null && settingMode === true && (
                        <Accordion
                            flush
                            open={open}
                            toggle={toggle}
                            className="w-100 mx-auto p-2 border border-2 border-primary rounded m-1"
                        >
                            <AccordionItem>
                                <AccordionHeader targetId="2">
                                    <span className="text-danger font-body">
                                        {' '}
                                        Répondeur Automatique{' '}
                                    </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <div
                                        className="pointer d-flex flex-column justify-content-center align-items-center"
                                        onClick={() =>
                                            dispatch(
                                                SetSettingsMsg({
                                                    ...editSettingsMsg,
                                                    email_every_sms:
                                                        !editSettingsMsg.email_every_sms,
                                                })
                                            )
                                        }
                                    >
                                        <p>
                                            Activer Envoi Email pour chaque SMS
                                            reçu
                                            {editSettingsMsg.email_every_sms !==
                                            true ? (
                                                <span className="text-danger fs-4">
                                                    <MdNotificationsActive />
                                                </span>
                                            ) : (
                                                <span className="text-success fs-4">
                                                    <MdNotificationsActive />
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <div
                                        className="pointer d-flex flex-column justify-content-center align-items-center"
                                        onClick={() =>
                                            dispatch(
                                                SetSettingsMsg({
                                                    ...editSettingsMsg,
                                                    active_repondeur_sms:
                                                        !editSettingsMsg.active_repondeur_sms,
                                                })
                                            )
                                        }
                                    >
                                        <p>
                                            Activer Répondeur SMS
                                            {editSettingsMsg.active_repondeur_sms !==
                                            true ? (
                                                <span className="text-danger fs-4">
                                                    <MdNotificationsActive />
                                                </span>
                                            ) : (
                                                <span className="text-success fs-4">
                                                    <MdNotificationsActive />
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                    <p> Répondeur SMS Voyageurs: </p>
                                    <Input
                                        id="active_repondeur_sms_msg"
                                        type="textarea"
                                        rows={6}
                                        name="active_repondeur_sms_msg"
                                        defaultValue={
                                            editSettingsMsg.active_repondeur_sms_msg
                                        }
                                        onChange={(e) =>
                                            dispatch(
                                                SetSettingsMsg({
                                                    ...editSettingsMsg,
                                                    active_repondeur_sms_msg:
                                                        e.target.value,
                                                })
                                            )
                                        }
                                    />
                                    <p> Répondeur SMS Propriétaires: </p>
                                    <Input
                                        id="active_repondeur_sms_msg_proprio"
                                        type="textarea"
                                        rows={6}
                                        name="active_repondeur_sms_msg_proprio"
                                        defaultValue={
                                            editSettingsMsg.active_repondeur_sms_msg_proprio
                                        }
                                        onChange={(e) =>
                                            dispatch(
                                                SetSettingsMsg({
                                                    ...editSettingsMsg,
                                                    active_repondeur_sms_msg_proprio:
                                                        e.target.value,
                                                })
                                            )
                                        }
                                    />
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="3">
                                    <span className="text-danger font-body">
                                        Envoi SMS Automatique (Livret / Code
                                        Clé)
                                    </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="3">
                                    <>
                                        <div
                                            className="pointer d-flex flex-column justify-content-center align-items-center"
                                            onClick={() =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        active_sms_bienvenue:
                                                            !editSettingsMsg.active_sms_bienvenue,
                                                    })
                                                )
                                            }
                                        >
                                            <p>
                                                Activer envoi Sms Bienvenue
                                                {editSettingsMsg.active_sms_bienvenue !==
                                                true ? (
                                                    <span className="text-danger fs-4">
                                                        <MdNotificationsActive />
                                                    </span>
                                                ) : (
                                                    <span className="text-success fs-4">
                                                        <MdNotificationsActive />
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        {editSettingsMsg !== null && (
                                            <p>
                                                Nombre d'heure avant Checkin
                                                pour SMS Bievenue: <br />{' '}
                                                {
                                                    editSettingsMsg.hours_before_sms_bienvenue
                                                }{' '}
                                                heures
                                            </p>
                                        )}
                                        <Input
                                            id="hours_before_sms_bienvenue"
                                            type="number"
                                            min={0}
                                            max={72}
                                            className="w-fit mx-auto"
                                            name="hours_before_sms_bienvenue"
                                            defaultValue={
                                                editSettingsMsg.hours_before_sms_bienvenue
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        hours_before_sms_bienvenue:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />

                                        <p>SMS Bienvenue : </p>
                                        <Input
                                            id="hours_before_sms_bienvenue_msg"
                                            type="textarea"
                                            rows={6}
                                            name="hours_before_sms_bienvenue_msg"
                                            defaultValue={
                                                editSettingsMsg.hours_before_sms_bienvenue_msg
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        hours_before_sms_bienvenue_msg:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />

                                        <small className="text-danger">
                                            !! Ce qui est compris entre les
                                            dièses ## ... ## est envoyé aux
                                            logement SAUF PURE !!{' '}
                                        </small>
                                        <hr />
                                        <div
                                            className="pointer d-flex flex-column justify-content-center align-items-center"
                                            onClick={() =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        active_sms_before_checkin:
                                                            !editSettingsMsg.active_sms_before_checkin,
                                                    })
                                                )
                                            }
                                        >
                                            <p>
                                                Activer envoi code boite à clé
                                                par SMS
                                                {editSettingsMsg.active_sms_before_checkin !==
                                                true ? (
                                                    <span className="text-danger fs-4">
                                                        <MdNotificationsActive />
                                                    </span>
                                                ) : (
                                                    <span className="text-success fs-4">
                                                        <MdNotificationsActive />
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        {editSettingsMsg !== null && (
                                            <p>
                                                Nombre d'heure avant Checkin
                                                code boite à clé par SMS :{' '}
                                                <br />{' '}
                                                {
                                                    editSettingsMsg.hours_before_checkin_sms
                                                }{' '}
                                                heures
                                            </p>
                                        )}
                                        <Input
                                            id="hours_before_checkin_sms"
                                            type="number"
                                            min={0}
                                            max={72}
                                            className="w-fit mx-auto"
                                            name="hours_before_checkin_sms"
                                            defaultValue={
                                                editSettingsMsg.hours_before_checkin_sms
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        hours_before_checkin_sms:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />

                                        <p>SMS Code Clé: </p>
                                        <Input
                                            id="hours_before_checkin_sms_msg"
                                            type="textarea"
                                            rows={6}
                                            name="hours_before_checkin_sms_msg"
                                            defaultValue={
                                                editSettingsMsg.hours_before_checkin_sms_msg
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        hours_before_checkin_sms_msg:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                        <hr />
                                        <div
                                            className="pointer d-flex flex-column justify-content-center align-items-center"
                                            onClick={() =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        active_sms_before_checkout:
                                                            !editSettingsMsg.active_sms_before_checkout,
                                                    })
                                                )
                                            }
                                        >
                                            <p>
                                                Activer envoi SMS Remerciement
                                                {editSettingsMsg.active_sms_before_checkout !==
                                                true ? (
                                                    <span className="text-danger fs-4">
                                                        <MdNotificationsActive />
                                                    </span>
                                                ) : (
                                                    <span className="text-success fs-4">
                                                        <MdNotificationsActive />
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        {editSettingsMsg !== null && (
                                            <p>
                                                Nombre d'heure avant Checkout
                                                pour sms remerciement : <br />{' '}
                                                {
                                                    editSettingsMsg.hours_before_checkout_sms
                                                }{' '}
                                                heures
                                            </p>
                                        )}
                                        <Input
                                            id="hours_before_checkout_sms"
                                            type="number"
                                            min={0}
                                            max={72}
                                            className="w-fit mx-auto"
                                            name="hours_before_checkout_sms"
                                            defaultValue={
                                                editSettingsMsg.hours_before_checkout_sms
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        hours_before_checkout_sms:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />

                                        <p>SMS Remerciement: </p>
                                        <Input
                                            id="hours_before_checkout_sms_msg"
                                            type="textarea"
                                            rows={6}
                                            name="hours_before_checkout_sms_msg"
                                            defaultValue={
                                                editSettingsMsg.hours_before_checkout_sms_msg
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        hours_before_checkout_sms_msg:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                        <hr />
                                        <button
                                            className="btn btn-success text-white my-3 mx-auto text-center"
                                            onClick={(e) => {
                                                dispatch(
                                                    putSettingsMsg(
                                                        editSettingsMsg,
                                                        accessToken,
                                                        authUser
                                                    )
                                                );
                                            }}
                                        >
                                            Sauvegarder
                                        </button>
                                    </>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="4">
                                    <span className="text-danger font-body">
                                        SMS Type Linge / Ménage / Procédure
                                        d'achat
                                    </span>
                                </AccordionHeader>
                                <AccordionBody accordionId="4">
                                    <>
                                        <p>SMS Type Linge : </p>
                                        <Input
                                            id="sms_msg_linge"
                                            type="textarea"
                                            rows={6}
                                            name="sms_msg_linge"
                                            defaultValue={
                                                editSettingsMsg.sms_msg_linge
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        sms_msg_linge:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                        <hr />
                                        <p>SMS Type Ménage : </p>
                                        <Input
                                            id="sms_msg_menage"
                                            type="textarea"
                                            rows={6}
                                            name="sms_msg_menage"
                                            defaultValue={
                                                editSettingsMsg.sms_msg_menage
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        sms_msg_menage:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />{' '}
                                        <hr />
                                        <p>
                                            SMS Type Procédure d'achat site :{' '}
                                        </p>
                                        <Input
                                            id="sms_procedure_commande"
                                            type="textarea"
                                            rows={6}
                                            name="sms_procedure_commande"
                                            defaultValue={
                                                editSettingsMsg.sms_procedure_commande
                                            }
                                            onChange={(e) =>
                                                dispatch(
                                                    SetSettingsMsg({
                                                        ...editSettingsMsg,
                                                        sms_procedure_commande:
                                                            e.target.value,
                                                    })
                                                )
                                            }
                                        />
                                    </>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    )}
                </div>
                {editSettingsMsg !== null && settingMode === true && (
                    <div className="d-flex flex-row justify-content-center">
                        <button
                            className="btn btn-success text-white my-3 mx-auto text-center"
                            onClick={(e) => {
                                dispatch(
                                    putSettingsMsg(
                                        editSettingsMsg,
                                        accessToken,
                                        authUser
                                    )
                                );
                            }}
                        >
                            Sauvegarder
                        </button>
                    </div>
                )}
            </div>
        </main>
    );
}
