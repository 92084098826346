export default function bookingLogo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="140"
            height="20"
            viewBox="0 0 118.5995 20.12286"
        >
            <defs>
                <clipPath id="f">
                    <path d="M589.5 28.972H684V12.937h-94.5v16.035z" />
                </clipPath>
                <clipPath id="g">
                    <path d="M589.5 12.937H684v16.035h-94.5V12.937z" />
                </clipPath>
                <clipPath id="c">
                    <path d="M589.5 28.972H684V12.937h-94.5v16.035z" />
                </clipPath>
                <clipPath id="d">
                    <path d="M589.5 28.656h94.48V12.958H589.5v15.698z" />
                </clipPath>
                <clipPath id="e">
                    <path d="M589.5 12.937H684v16.035h-94.5V12.937z" />
                </clipPath>
                <clipPath id="a">
                    <path d="M589.5 28.972H684V12.937h-94.5v16.035z" />
                </clipPath>
                <clipPath id="b">
                    <path d="M589.5 12.937H684v16.035h-94.5V12.937z" />
                </clipPath>
            </defs>
            <g
                clipPath="url(#a)"
                transform="matrix(1.25 0 0 -1.25 -736.625 36.07)"
            >
                <g clipPath="url(#b)">
                    <path
                        d="M626.558 27.217a1.436 1.436 0 1 0 2.872 0 1.437 1.437 0 0 0-2.872 0"
                        fill="#273b7d"
                    />
                    <path
                        d="M649.215 17.64c0 .797.642 1.441 1.433 1.441a1.44 1.44 0 0 0 0-2.88c-.79 0-1.433.645-1.433 1.44"
                        fill="#499fdd"
                    />
                    <path
                        d="M602.991 18.263c-1.236 0-2.096.982-2.096 2.386 0 1.403.86 2.384 2.097 2.384 1.243 0 2.112-.981 2.112-2.384 0-1.426-.85-2.386-2.113-2.386zm0 6.867c-2.616 0-4.515-1.885-4.515-4.481 0-2.597 1.9-4.481 4.515-4.481 2.627 0 4.533 1.884 4.533 4.48 0 2.597-1.906 4.482-4.532 4.482M623.777 20.424a2.136 2.136 0 0 1-.342.483l-.08.083.084.08c.12.127.243.277.361.451l2.31 3.432h-2.804l-1.735-2.685c-.098-.144-.296-.216-.593-.216h-.395v5.076c0 1.015-.633 1.153-1.316 1.153h-1.17L618.1 16.3h2.484v3.594h.233c.283 0 .475-.033.564-.187l1.37-2.586c.383-.702.764-.821 1.482-.821h1.903l-1.418 2.344-.94 1.78M635.835 25.15c-1.264 0-2.07-.562-2.522-1.037l-.15-.152-.054.207c-.132.509-.58.788-1.253.788h-1.113l.007-8.653h2.467v3.988c0 .39.05.728.154 1.037.274.935 1.04 1.516 1.997 1.516.77 0 1.071-.407 1.071-1.457v-3.769c0-.896.415-1.315 1.312-1.315h1.174l-.004 5.504c0 2.186-1.067 3.342-3.086 3.342M628.024 24.953h-1.169l.008-6.691V16.3h1.247l.044-.002.582.002h.578v.003h.004l.005 7.335c0 .885-.423 1.314-1.298 1.314M612.68 18.263c-1.236 0-2.097.982-2.097 2.386 0 1.403.861 2.384 2.098 2.384 1.24 0 2.112-.981 2.112-2.384 0-1.426-.85-2.386-2.112-2.386zm0 6.867c-2.618 0-4.518-1.885-4.518-4.481 0-2.597 1.9-4.481 4.519-4.481 2.623 0 4.533 1.884 4.533 4.48 0 2.597-1.91 4.482-4.533 4.482"
                        fill="#273b7d"
                    />
                </g>
            </g>
            <g
                clipPath="url(#c)"
                transform="matrix(1.25 0 0 -1.25 -736.625 36.07)"
            >
                <g clipPath="url(#d)">
                    <g clipPath="url(#e)">
                        <path
                            d="M665.555 18.263c-1.236 0-2.098.982-2.098 2.386 0 1.403.862 2.384 2.098 2.384 1.242 0 2.113-.981 2.113-2.384 0-1.426-.85-2.386-2.113-2.386zm0 6.867c-2.618 0-4.517-1.885-4.517-4.481 0-2.597 1.899-4.481 4.517-4.481 2.624 0 4.533 1.884 4.533 4.48 0 2.597-1.91 4.482-4.533 4.482"
                            fill="#499fdd"
                        />
                        <path
                            d="M644.122 18.644c-1.349 0-1.829 1.176-1.829 2.279 0 .486.123 2.069 1.7 2.069.783 0 1.826-.224 1.826-2.15 0-1.817-.923-2.198-1.697-2.198zm2.978 6.332c-.468 0-.828-.187-1.009-.528l-.068-.132-.114.1c-.398.344-1.112.753-2.271.753-2.307 0-3.86-1.733-3.86-4.31 0-2.576 1.607-4.376 3.906-4.376.785 0 1.406.184 1.898.556l.19.143v-.24c0-1.156-.747-1.794-2.102-1.794-.659 0-1.258.16-1.66.306-.522.158-.83.027-1.041-.498l-.196-.484-.277-.708.171-.091c.868-.46 1.997-.735 3.017-.735 2.1 0 4.554 1.075 4.554 4.101l.009 7.937H647.1"
                            fill="#273b7d"
                        />
                    </g>
                </g>
            </g>
            <g
                clipPath="url(#f)"
                transform="matrix(1.25 0 0 -1.25 -736.625 36.07)"
            >
                <g clipPath="url(#g)">
                    <path
                        d="M593.805 18.362l-2.008.002v2.4c0 .514.199.78.638.842h1.37c.977 0 1.609-.616 1.61-1.613-.001-1.024-.617-1.63-1.61-1.63zm-2.008 6.476v.632c0 .553.234.816.747.85h1.028c.881 0 1.409-.527 1.409-1.41 0-.672-.362-1.457-1.377-1.457h-1.807v1.385zm4.572-2.396l-.363.204.317.271c.369.317.986 1.03.986 2.26 0 1.884-1.46 3.1-3.721 3.1h-2.874a1.26 1.26 0 0 1-1.214-1.244v-10.69h4.139c2.513 0 4.135 1.368 4.135 3.487 0 1.141-.524 2.116-1.405 2.612"
                        fill="#273b7d"
                    />
                    <path
                        d="M681.107 25.121a3.4 3.4 0 0 1-2.648-1.283l-.178-.226-.14.253c-.458.833-1.244 1.256-2.337 1.256-1.147 0-1.916-.64-2.273-1.02l-.234-.253-.09.333c-.13.48-.557.743-1.203.743h-1.037l-.01-8.62h2.355v3.805c0 .334.042.663.125 1.008.225.92.843 1.909 1.882 1.81.64-.062.954-.557.954-1.513v-5.11h2.372v3.805c0 .417.039.729.133 1.041.19.878.836 1.778 1.838 1.778.726 0 .994-.41.994-1.514v-3.85c0-.87.388-1.26 1.259-1.26h1.108l.002 5.503c0 2.199-.968 3.314-2.872 3.314M659.673 19.297c-.007-.009-1.02-1.077-2.355-1.077-1.216 0-2.444.746-2.444 2.411 0 1.438.952 2.443 2.316 2.443.442 0 .946-.158 1.025-.425l.011-.045a.866.866 0 0 1 .84-.637l1.29-.002v1.128c0 1.488-1.893 2.028-3.166 2.028-2.724 0-4.7-1.896-4.7-4.508 0-2.61 1.954-4.504 4.65-4.504 2.338 0 3.61 1.537 3.622 1.552l.068.084-1.022 1.695-.135-.143"
                        fill="#499fdd"
                    />
                </g>
            </g>
        </svg>
    );
}
