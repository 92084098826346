import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { logout } from '../../Store/Actions/user.actions';

function Logout() {
    const getBody = document.body;
    getBody.classList.add('bg_vert_jaune_rose');
    document.title = 'Ma Maison Sereine - Déconnexion ';
    document.description = 'Page de déconnexion';

    const translate = useSelector((store) => store.Template.language);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function redirectLogin() {
        navigate('/');
    }
    useEffect(() => {
        dispatch(logout());
        setTimeout(() => {
            redirectLogin();
        }, 1000);
    }, []);

    return (
        <div className="p-5  min-vh-100 bg-light text-center d-flex flex-column ">
            <span className="text-primary my-4">
                {translate === 'French' && <>Vous avez été déconnecté</>}
                {translate === 'English' && <>You have been disconnected</>}
            </span>
            <button className="mx-auto btn btn-primary my-4 text-white">
                <Link
                    to="/connexion"
                    style={{ textDecoration: 'none' }}
                    className="text-white"
                >
                    {translate === 'French' && <>Se reconnecter</>}
                    {translate === 'English' && <>Reconnect</>}
                </Link>
            </button>
            <button className="mx-auto btn btn-primary  my-4  text-white">
                <Link
                    to="/"
                    style={{ textDecoration: 'none' }}
                    className="text-white"
                >
                    {translate === 'French' && <>Retour à l'accueil</>}
                    {translate === 'English' && <>Back to Home</>}
                </Link>
            </button>
        </div>
    );
}

export default Logout;
