import React from 'react';

import { createRoot } from 'react-dom/client';

import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import Router from './Router/router.jsx';
import rootStore from './Store';
import './utils/sass/index.scss';
import reportWebVitals from './reportWebVitals';

const router = Router();
const store = configureStore({
    reducer: rootStore,
    devTools: false,
});

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <ToastContainer />
        <RouterProvider router={router} />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
