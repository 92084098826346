import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { Alert, Badge, Label, FormGroup, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { MdContactPhone } from 'react-icons/md';
import { FaPencilAlt, FaHouseUser, FaBaby } from 'react-icons/fa';
import { BsMailboxFlag } from 'react-icons/bs';
import { FaPerson, FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa6';
import { GrDocumentNotes } from 'react-icons/gr';

import { useFetchGetReservationIdPriceElements } from '../../utils/Api/FetchSmoobu.jsx';
import { useFetchGetLocationSettings } from '../../utils/Api/FetchLocationSettings.jsx';
import { useFetchStancerResaDetails } from '../../utils/Api/FetchStancer.jsx';

import replaceDay from '../../utils/functions.jsx';
import bookingLogo from '../../assets/img/logo/bookingLogo.jsx';
import logo from '../../assets/img/logo/logo__ma-maison-sereine.png';
import LoadingK from '../../components/Widget/LoadingK.jsx';
import StatusStancerPackVoyageur from '../../components/Reservations/buy/StatusStancerPackVoyageur.jsx';
import StatusStancerVoyageur from '../../components/Reservations/buy/StatusStancerVoyageur.jsx';
import { getListingSendMsgReservation } from '../../Store/Actions/messenger.actions';
import {
    getDataReservationId,
    getMsgSendedReservationId,
} from '../../Store/Actions/gestion.actions';
import WidgetMission from '../../components/Reservations/gestionAdmin/WidgetMission.jsx';
import WidgetIconRese from '../../components/Reservations/gestionAdmin/widgetIconRese.jsx';

export default function ReservationIdGestion() {
    const dispatch = useDispatch();
    const accessToken = useSelector((store) => store.User.accessToken);
    const dataReservationId = useSelector(
        (store) => store.Gestion.dataReservationId
    );
    const dataMsgReservation = useSelector(
        (store) => store.Messenger.listingSendMsgReservation
    );
    const dataSendMsg = useSelector(
        (store) => store.Gestion.MsgSendedReservationId
    );

    const { idReservation } = useParams();
    const { dataLocationSettings } = useFetchGetLocationSettings();
    const [editAlertNotes, setEditAlertNotes] = useState(false);
    const [deleteReservation, setDeleteReservation] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        dispatch(getDataReservationId(idReservation, accessToken));
    }, [accessToken]);

    document.title = 'Réservation' + idReservation + '| Ma Maison Sereine';
    document.description = 'Page information réservations';

    useEffect(() => {
        dispatch(getMsgSendedReservationId(idReservation, accessToken));
        const interval = setInterval(
            () =>
                dispatch(getMsgSendedReservationId(idReservation, accessToken)),
            20000
        );
        return () => {
            clearInterval(interval);
        };
    }, [accessToken, idReservation]);
    const {
        dataReservationIdPriceElements,
        isLoadingIdReservationPriceElements,
    } = useFetchGetReservationIdPriceElements(idReservation, accessToken);
    const { dataStancerResaDetails, isLoadingStancerResaDetails } =
        useFetchStancerResaDetails(idReservation, accessToken);
    const [msgClient, setMsgClient] = useState(undefined);
    const [modeMsg, setModeMsg] = useState('sms');

    const [dataMsg] = useState({
        email: '',
        phone: '',
        msg: msgClient,
        mode: modeMsg,
    });

    useEffect(() => {
        if (dataSendMsg !== null && dataReservationId !== null) {
            dataMsg.email = dataReservationId.email ?? '';
            dataMsg.phone = dataReservationId.phone ?? '';
            dataMsg.msg = msgClient;
            dataMsg.mode = modeMsg;
            dataMsg.first_name = dataReservationId.firstname ?? '';
        }
    }, [dataMsg, modeMsg, msgClient, dataReservationId]);

    useEffect(() => {
        dispatch(getListingSendMsgReservation(idReservation, accessToken));

        const interval = setInterval(
            () =>
                dispatch(
                    getListingSendMsgReservation(idReservation, accessToken)
                ),
            20000
        );
        return () => {
            clearInterval(interval);
        };
    }, [accessToken, idReservation]);

    const handleSubmitMsg = (e) => {
        e.preventDefault();
        async function PostCreateMsg(formData) {
            try {
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/sendmsg/${idReservation}/create/`,
                    {
                        method: 'post',
                        headers: new Headers({
                            Authorization: `Bearer ${accessToken}`,
                            Accept: 'application/json',
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (response.status === 202) {
                    toast.success(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error('Oups! Connexion API Envoi Message impossible 😔', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        const formData = new FormData();
        Object.keys(dataMsg).forEach((key) =>
            formData.append(key, dataMsg[key])
        );
        PostCreateMsg(formData);
    };
    const handleSubmitPutReservation = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('idReservation', idReservation);
        formData.append('adults', document.getElementById('adults').value);
        formData.append('children', document.getElementById('children').value);
        formData.append('lastname', document.getElementById('lastname').value);
        formData.append(
            'firstname',
            document.getElementById('firstname').value
        );
        formData.append('email', document.getElementById('email').value);
        formData.append('phone', document.getElementById('phone').value);
        formData.append('notes', document.getElementById('notes').value);
        async function PutReservationAdmin(formData) {
            try {
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/smoobu/v1/booking/edit/',
                    {
                        method: 'put',
                        headers: new Headers({
                            Accept: 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        }),
                        body: formData,
                    }
                );
                const data = await response.json();
                if (data.status === 200) {
                    toast.success('MAJ ✅ - Rafraichir la page...', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error(data, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error('Oups! Impossible de modifier la réservation 😔 ', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        PutReservationAdmin(formData);
    };

    const handleSubmitDeleteReservation = (e) => {
        async function DeleteReservationAdmin(idReservation) {
            try {
                toast('Demande de Suppression en cours...', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 15000,
                });
                const response = await fetch(
                    `https://api.mamaisonsereine.fr/api/smoobu/v1/booking/delete/${idReservation}/`,
                    {
                        method: 'delete',
                        headers: new Headers({
                            Accept: 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        }),
                    }
                );
                const data = await response.json();
                if (response.status === 200) {
                    toast.success(data, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else if (data.status === 401) {
                    toast.error(
                        'Echec de Grade SuperAdmin' +
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                    );
                } else if (data.status === 404) {
                    toast.error(
                        'La réservation est introuvable côté Smoobu ' +
                            data.detail,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }
                    );
                } else {
                    toast.error('ERREUR survenue ...', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch (err) {
                toast.error(
                    'Oups! Impossible de supprimer 😔 Contactez le support',
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        DeleteReservationAdmin(idReservation);
    };
    return (
        <main className="vw-100">
            <h1 className="text-center mx-auto display-5">
                Informations Réservation
            </h1>
            {dataReservationId !== null ? (
                <div className="my-3 text-center">
                    {dataReservationId !== null &&
                        dataReservationId.type === 'cancellation' && (
                            <Alert
                                className="mx-auto text_clignote"
                                color="danger"
                            >
                                Reservation annulée !!
                            </Alert>
                        )}
                    Réservation{' '}
                    {dataReservationId !== null &&
                        dataReservationId.channel !== undefined &&
                        dataReservationId.channel.name}
                    <Badge className="ms-2 " color="primary" pill>
                        {dataReservationId !== null &&
                            dataReservationId.lastname !== undefined &&
                            dataReservationId.lastname}{' '}
                        {dataReservationId !== null &&
                            dataReservationId.firstname !== undefined &&
                            dataReservationId.firstname}
                    </Badge>
                    <br />
                    {dataReservationId.phone !== undefined &&
                    dataReservationId.phone !== '' ? (
                        <>
                            {dataReservationId.phone}
                            <a
                                href={`tel:${dataReservationId.phone}`}
                                className="ms-2"
                            >
                                <span className="btn btn-outline-primary px-1 py-0">
                                    <MdContactPhone /> Appeler
                                </span>
                            </a>{' '}
                        </>
                    ) : (
                        <p>N° de téléphone non renseigné</p>
                    )}
                    <br />
                    <BsMailboxFlag />
                    {dataReservationId.email !== null
                        ? dataReservationId.email
                        : 'Mail non renseigné'}
                </div>
            ) : (
                <LoadingK />
            )}
            {isLoadingStancerResaDetails !== true &&
                dataReservationId !== undefined &&
                dataStancerResaDetails !== undefined &&
                dataReservationId.channel.id === 2219288 && (
                    <div className="text-center">
                        {dataStancerResaDetails.statut === 'Annulée' && (
                            <Badge className="m-2 fs-6" color="danger" pill>
                                Réservation {dataStancerResaDetails.statut}
                            </Badge>
                        )}
                        {dataStancerResaDetails.statut ===
                            'En Attente de Paiement' && (
                            <Badge className="m-2 fs-6" color="warning" pill>
                                Réservation {dataStancerResaDetails.statut}
                            </Badge>
                        )}
                        {dataStancerResaDetails.statut === 'Payée' && (
                            <Badge className="m-2 fs-6" color="primary" pill>
                                Réservation {dataStancerResaDetails.statut}
                            </Badge>
                        )}
                    </div>
                )}
            <>
                {dataReservationId !== null ? (
                    <div className="mx-auto text-center">
                        {editAlertNotes === false ? (
                            <>
                                <Button
                                    variant=" border-primary shadow m-2"
                                    onClick={(e) =>
                                        setEditAlertNotes(!editAlertNotes)
                                    }
                                >
                                    <FaPencilAlt />
                                    Editer
                                </Button>
                            </>
                        ) : (
                            <div className="mx-auto">
                                <Button
                                    variant="warning border-primary shadow m-2"
                                    onClick={(e) =>
                                        setEditAlertNotes(!editAlertNotes)
                                    }
                                >
                                    Annuler
                                </Button>
                                <Button
                                    variant="success border-primary shadow m-2 text-white"
                                    onClick={(e) =>
                                        handleSubmitPutReservation(e)
                                    }
                                >
                                    Sauvegarder
                                </Button>{' '}
                                <Button
                                    variant="danger border-primary shadow m-2"
                                    onClick={(e) =>
                                        setDeleteReservation(!deleteReservation)
                                    }
                                >
                                    Annuler la réservation
                                </Button>
                                <Offcanvas
                                    show={deleteReservation}
                                    onHide={(e) =>
                                        setDeleteReservation(!deleteReservation)
                                    }
                                    placement={'start'}
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title>
                                            Confirmation de suppression de la
                                            Réservation {dataReservationId.id}
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <hr />
                                    <Offcanvas.Body>
                                        <p>
                                            Vous avez demandé l'annulation de
                                            réservation. Merci de confirmer et
                                            de rembourser votre client le cas
                                            échéant.
                                        </p>
                                        <p>
                                            <Button
                                                variant="secondary shadow m-2"
                                                onClick={(e) =>
                                                    setDeleteReservation(
                                                        !deleteReservation
                                                    )
                                                }
                                            >
                                                Abandonner
                                            </Button>{' '}
                                            <Button
                                                variant="danger  shadow m-2"
                                                onClick={(e) =>
                                                    handleSubmitDeleteReservation(
                                                        dataReservationId.id
                                                    )
                                                }
                                            >
                                                Annuler la réservation pour{' '}
                                                {dataReservationId.lastname}{' '}
                                                {dataReservationId.firstname}
                                            </Button>
                                        </p>
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </div>
                        )}
                    </div>
                ) : (
                    <LoadingK />
                )}
                <div className="text-center">
                    {dataReservationId !== null && (
                        <WidgetMission
                            idReservation={
                                dataReservationId !== null &&
                                dataReservationId.id
                            }
                            logement={
                                dataReservationId !== null &&
                                dataReservationId.apartment.name
                            }
                            logementId={
                                dataReservationId !== null &&
                                dataReservationId.apartment.id
                            }
                            dateCkeckIn={
                                dataReservationId !== null &&
                                dataReservationId.arrival
                            }
                            code={
                                dataReservationId !== null &&
                                dataReservationId.phone !== null
                                    ? (dataReservationId !== null &&
                                          dataReservationId.phone.substring(
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 3,
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 2
                                          ) === ' ') |
                                      (dataReservationId !== null &&
                                          dataReservationId.phone.substring(
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 3,
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 2
                                          ) === '.')
                                        ? dataReservationId !== null &&
                                          dataReservationId.phone.substring(
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 5,
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 3
                                          ) +
                                              dataReservationId !==
                                              null &&
                                          dataReservationId.phone.substring(
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 2,
                                              dataReservationId !== null &&
                                                  dataReservationId.phone.length
                                          )
                                        : dataReservationId !== null &&
                                          dataReservationId.phone.substring(
                                              dataReservationId !== null &&
                                                  dataReservationId.phone
                                                      .length - 4,
                                              dataReservationId !== null &&
                                                  dataReservationId.phone.length
                                          )
                                    : '0000'
                            }
                        />
                    )}
                </div>
            </>
            <div className=" d-flex flex-row flex-wrap m-auto justify-content-around mb-5">
                <div className="d-flex flex-column border-1 border border-primary rounded bg-antique-light col-11 col-md-5 col-lg-5 col-xl-5 p-3 mx-auto ">
                    <form
                        className="mx-auto text-center"
                        onSubmit={(e) => {
                            handleSubmitPutReservation(e);
                        }}
                    >
                        <h2 className="text-center">Détails</h2>
                    </form>
                    <div>
                        <div>
                            <span className="me-1">
                                <FaHouseUser />
                            </span>
                            Logement :
                            {dataReservationId !== null ? (
                                <>
                                    <Badge
                                        className="ms-2 fs-6"
                                        color="primary"
                                        pill
                                    >
                                        {dataReservationId.apartment !==
                                            undefined &&
                                            dataReservationId.apartment.name}
                                    </Badge>
                                    <br /> <FaPerson />
                                    {editAlertNotes === false ? (
                                        <>{dataReservationId.adults} </>
                                    ) : (
                                        <>
                                            <input
                                                className="text-control"
                                                type="number"
                                                id="adults"
                                                name="adults"
                                                min={'1'}
                                                max={'6'}
                                                required
                                                defaultValue={
                                                    dataReservationId.adults ===
                                                    null
                                                        ? '0'
                                                        : dataReservationId.adults
                                                }
                                            />
                                        </>
                                    )}
                                    Adultes -
                                    <FaBaby />
                                    {editAlertNotes === false ? (
                                        <>{dataReservationId.children} </>
                                    ) : (
                                        <>
                                            <input
                                                className="text-control"
                                                type="number"
                                                id="children"
                                                name="children"
                                                min={'0'}
                                                max={'6'}
                                                required
                                                defaultValue={
                                                    dataReservationId.children ===
                                                    null
                                                        ? '0'
                                                        : dataReservationId.children
                                                }
                                            />
                                        </>
                                    )}
                                    Enfants
                                </>
                            ) : (
                                <LoadingK />
                            )}
                        </div>

                        <div className="text-success">
                            <FaPlaneArrival />
                            {dataReservationId !== null ? (
                                <>
                                    {replaceDay(dataReservationId.arrival)}{' '}
                                    {dataReservationId['check-in']}
                                </>
                            ) : (
                                <LoadingK />
                            )}
                        </div>
                        <div className="text-danger">
                            <FaPlaneDeparture />
                            {dataReservationId !== null ? (
                                <>
                                    {replaceDay(dataReservationId.departure)}{' '}
                                    {dataReservationId['check-out']}
                                </>
                            ) : (
                                <LoadingK />
                            )}
                        </div>
                        {dataReservationId !== null && (
                            <WidgetIconRese element={dataReservationId} />
                        )}
                        <hr />
                        <Alert color="primary">
                            <GrDocumentNotes />{' '}
                            {dataReservationId !== null &&
                            editAlertNotes === true ? (
                                <textarea
                                    className="text-area w-100"
                                    id="notes"
                                    name="notes"
                                    rows={15}
                                    defaultValue={dataReservationId.notice}
                                ></textarea>
                            ) : (
                                <>
                                    {dataReservationId !== null ? (
                                        dataReservationId.notice !== '' ? (
                                            dataReservationId.notice
                                        ) : (
                                            <span>
                                                Pas de note dans le dossier.
                                            </span>
                                        )
                                    ) : (
                                        <LoadingK />
                                    )}
                                </>
                            )}
                        </Alert>
                        <hr />
                        {dataReservationId !== null &&
                            editAlertNotes === true && (
                                <>
                                    <h3 className="mx-auto my-3 fs-2 text-center">
                                        Coordonnées du Voyageur:
                                    </h3>
                                    <div className="d-flex flex-column mx-auto">
                                        <div className="formData">
                                            <label htmlFor="lastname">
                                                Nom
                                            </label>
                                            <input
                                                className="text-control"
                                                type="text"
                                                id="lastname"
                                                name="lastname"
                                                placeholder="ex: Doe"
                                                defaultValue={
                                                    dataReservationId.lastname
                                                }
                                            />
                                        </div>
                                        <div className="formData">
                                            <label htmlFor="firstname">
                                                Prénom
                                            </label>
                                            <input
                                                className="text-control"
                                                type="text"
                                                id="firstname"
                                                name="firstname"
                                                autoFocus
                                                placeholder="ex: John"
                                                defaultValue={
                                                    dataReservationId.firstname
                                                }
                                            />
                                        </div>
                                        <div className="formData">
                                            <label htmlFor="email">
                                                E-mail
                                            </label>
                                            <input
                                                className="text-control"
                                                type="email"
                                                id="email"
                                                name="email"
                                                placeholder="ex: adresse@domaine.fr"
                                                defaultValue={
                                                    dataReservationId.email
                                                }
                                            />
                                        </div>
                                        <div className="formData">
                                            <label htmlFor="phone">
                                                Portable (SMS)
                                            </label>
                                            <input
                                                className="text-control"
                                                type="phone"
                                                id="phone"
                                                name="phone"
                                                autoComplete="phone"
                                                required
                                                placeholder="ex: 0601020304"
                                                defaultValue={
                                                    dataReservationId.phone
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                        <hr />
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-row flex-wrap justify-content-start align-items-center">
                                Réservation{' '}
                                {dataReservationId !== null ? (
                                    <>
                                        {dataReservationId.channel !==
                                            undefined &&
                                            dataReservationId.channel.id ===
                                                2219294 && (
                                                <span className="text-danger">
                                                    <i className="fa-brands fa-airbnb fs-3"></i>{' '}
                                                    N°{' '}
                                                    {
                                                        dataReservationId[
                                                            'reference-id'
                                                        ]
                                                    }
                                                </span>
                                            )}
                                        {dataReservationId.channel !==
                                            undefined &&
                                            dataReservationId.channel.id ===
                                                2219315 && (
                                                <span className="text-blue">
                                                    {bookingLogo} N°{' '}
                                                    {
                                                        dataReservationId[
                                                            'reference-id'
                                                        ]
                                                    }
                                                </span>
                                            )}
                                        {dataReservationId.channel !==
                                            undefined &&
                                            dataReservationId.channel.id ===
                                                2219288 && (
                                                <span className="text-success">
                                                    <i className="fa-solid fa-hand-holding-hand me-2"></i>{' '}
                                                    Direct
                                                </span>
                                            )}
                                        - Statut : {dataReservationId.type}
                                    </>
                                ) : (
                                    <LoadingK />
                                )}
                            </div>
                            <span>
                                <i className="fa-solid fa-barcode me-1"></i>
                                Ref Smoobu N°{' '}
                                {dataReservationId !== null ? (
                                    dataReservationId.id
                                ) : (
                                    <LoadingK />
                                )}
                            </span>
                            <span>
                                <i className="fa-regular fa-calendar-plus me-1"></i>
                                Créée le{' '}
                                {dataReservationId !== null ? (
                                    dataReservationId['created-at']
                                ) : (
                                    <LoadingK />
                                )}
                            </span>
                            <span>
                                <i className="fa-solid fa-calendar-day me-1"></i>
                                Modifiée le{' '}
                                {dataReservationId !== null ? (
                                    dataReservationId.modifiedAt
                                ) : (
                                    <LoadingK />
                                )}
                            </span>
                            <span>
                                {' '}
                                <i className="fa-regular fa-credit-card me-1"></i>{' '}
                                Prix :
                                {dataReservationId !== null ? (
                                    <>
                                        {' '}
                                        {dataReservationId.price} € (dont{' '}
                                        {
                                            dataReservationId[
                                                'commission-included'
                                            ]
                                        }
                                        € de commission incluse)
                                    </>
                                ) : (
                                    <LoadingK />
                                )}
                            </span>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <h3 className="text-center">
                            Elements de Facturation :{' '}
                        </h3>
                        {dataReservationId !== null &&
                            dataReservationId.type === 'cancellation' && (
                                <Alert
                                    className="mx-auto text_clignote"
                                    color="danger"
                                >
                                    Reservation annulée !!
                                </Alert>
                            )}
                        {isLoadingIdReservationPriceElements !== true &&
                        dataReservationIdPriceElements !== undefined &&
                        dataReservationIdPriceElements.priceElements !==
                            undefined ? (
                            dataReservationIdPriceElements.priceElements.map(
                                (element) => (
                                    <div key={element.name}>
                                        #{element.sortOrder} {element.name} :{' '}
                                        {element.amount} €
                                    </div>
                                )
                            )
                        ) : (
                            <LoadingK />
                        )}
                    </div>

                    {dataReservationId !== null &&
                        dataReservationId.channel.id === 2219288 && (
                            <StatusStancerVoyageur
                                idReservation={dataReservationId.id}
                                idChannel={2219288}
                            />
                        )}
                    {dataReservationId !== null && (
                        <StatusStancerPackVoyageur
                            idReservation={dataReservationId.id}
                            dataLocationSettings={dataLocationSettings}
                        />
                    )}
                </div>
                <div className="border-1 border border-primary rounded col-11  col-md-5 col-lg-6 col-xl-5 p-2 bg-sereine-light mx-auto">
                    <h2 className="text-center">Messagerie</h2>
                    <div className="d-flex flex-column md:p-3">
                        <Alert className="mx-auto bg-light" color="primary">
                            Liste des Messages en Canal Extérieur :
                        </Alert>

                        {dataMsgReservation !== null &&
                        dataReservationId !== undefined ? (
                            <>
                                {dataMsgReservation.length > 0 &&
                                dataMsgReservation.messages !== undefined ? (
                                    dataMsgReservation.messages.map((msg) =>
                                        msg.type === 1 ? (
                                            <div
                                                className="my-3 text-start col-10"
                                                key={msg.id}
                                            >
                                                <span className="text-primary fs-6 ">
                                                    {msg.createdAt} -{' '}
                                                    <Badge
                                                        className="me-2"
                                                        color="primary"
                                                        pill
                                                    >
                                                        {
                                                            dataReservationId.lastname
                                                        }{' '}
                                                        {
                                                            dataReservationId.firstname
                                                        }
                                                    </Badge>
                                                    :
                                                </span>

                                                <div className="mt-3 text-start bg-antique-light border border-primary rounded p-3">
                                                    {msg.message}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="my-3 d-flex flex-row justify-content-end">
                                                <div className="text-end bg-light rounded border p-3 border-primary col-10 ">
                                                    <span className="text-primary fs-6">
                                                        {msg.createdAt} -
                                                        Message{' '}
                                                        <img
                                                            src={logo}
                                                            alt={
                                                                'Logo - Ma Maison Sereine'
                                                            }
                                                            className={
                                                                'logo__img'
                                                            }
                                                            style={{
                                                                width: '80px',
                                                            }}
                                                        />
                                                        :
                                                    </span>
                                                    <div>{msg.message}</div>
                                                    <div>
                                                        {msg.htmlMessage
                                                            .body !==
                                                            undefined &&
                                                            msg.htmlMessage.body.replace(
                                                                '  ',
                                                                '<br/>'
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <small className="text-center my-3 ">
                                        Aucune trace de message...
                                    </small>
                                )}
                            </>
                        ) : (
                            <LoadingK />
                        )}
                        <Alert className="mx-auto bg-light" color="primary">
                            Liste des Messages via LogicielSer1 :
                        </Alert>
                        {dataSendMsg !== null && dataReservationId !== null ? (
                            <>
                                {dataSendMsg !== null &&
                                dataSendMsg.length > 0 ? (
                                    <>
                                        {dataSendMsg.map((msg) =>
                                            msg.out_in === true ? (
                                                <div
                                                    className="my-3 text-start col-10"
                                                    key={msg.id}
                                                >
                                                    <span className="text-primary fs-6 ">
                                                        {msg.type === '1' && (
                                                            <>
                                                                <i className="mx-2 fa-solid fa-comment-sms fs-4"></i>
                                                            </>
                                                        )}
                                                        {msg.type === '0' && (
                                                            <i className="mx-2 fa-solid fa-at fs-4"></i>
                                                        )}{' '}
                                                        {msg.createdAt} -{' '}
                                                        <Badge
                                                            className="me-2"
                                                            color="primary"
                                                            pill
                                                        >
                                                            {
                                                                dataReservationId.lastname
                                                            }{' '}
                                                            {
                                                                dataReservationId.firstname
                                                            }
                                                        </Badge>
                                                        :
                                                    </span>

                                                    <div className="mt-3 text-start bg-antique-light border border-primary rounded p-3">
                                                        {msg.message}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className="my-3 d-flex flex-row justify-content-end"
                                                    key={msg.id}
                                                >
                                                    <div className="text-end bg-light rounded border p-3 border-primary col-10 ">
                                                        {msg.type === '1' && (
                                                            <>
                                                                <i className="mx-2 fa-solid fa-comment-sms fs-4"></i>
                                                                {msg.canal}
                                                            </>
                                                        )}
                                                        {msg.type === '0' && (
                                                            <i className="mx-2 fa-solid fa-at fs-4"></i>
                                                        )}{' '}
                                                        <span className="text-primary fs-6">
                                                            {msg.createdAt} -
                                                            Message{' '}
                                                            <img
                                                                src={logo}
                                                                alt={
                                                                    'Logo - Ma Maison Sereine'
                                                                }
                                                                className={
                                                                    'logo__img'
                                                                }
                                                                style={{
                                                                    width: '80px',
                                                                }}
                                                            />
                                                            :
                                                        </span>
                                                        <div className="text-wrap overflow-scroll">
                                                            {msg.message}
                                                        </div>
                                                        <div>
                                                            {msg.htmlMessage !==
                                                                undefined &&
                                                                msg.htmlMessage !==
                                                                    '' &&
                                                                msg.htmlMessage
                                                                    .body !==
                                                                    undefined &&
                                                                msg.htmlMessage.body.replace(
                                                                    '  ',
                                                                    '<br/>'
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </>
                                ) : (
                                    <small className="text-center my-3 ">
                                        Aucun message envoyé
                                    </small>
                                )}
                            </>
                        ) : (
                            <LoadingK />
                        )}
                    </div>
                    <small>Rafraichissement auto : 5sec</small>
                    <hr />
                    <div className="d-flex flex-column p-1">
                        <Alert className="bg-light mx-auto " color="primary">
                            Créer un message via LogicielSer1 :
                        </Alert>

                        <form onSubmit={(e) => handleSubmitMsg(e)}>
                            <div className="d-flex flex-row flex-wrap justify-content-start align-items-center mx-auto">
                                <FormGroup>
                                    <Label htmlFor="typeMsgSelect">
                                        Type de Message :
                                    </Label>
                                    <Input
                                        id="typeMsgSelect"
                                        name="select"
                                        type="select"
                                        className=""
                                        onChange={(e) =>
                                            setModeMsg(e.target.value)
                                        }
                                    >
                                        <option value="sms">
                                            SMS via Portable
                                        </option>
                                        <option value="smsOVH">
                                            SMS via OVH (7ct € !)
                                        </option>
                                        <option value="email">Mail Ser1</option>
                                    </Input>
                                </FormGroup>
                            </div>
                            <FormGroup>
                                <Label htmlFor="msgText">Votre Message :</Label>
                                <Input
                                    id="msgText"
                                    name="text"
                                    type="textarea"
                                    className="col-11 text-sms"
                                    rows={15}
                                    onChange={(e) =>
                                        setMsgClient(e.target.value)
                                    }
                                />
                            </FormGroup>
                            {modeMsg === 'email' && (
                                <p className="text-danger">
                                    Saisir que le contenu du message car
                                    "Bonjour Prénom [...] Cordialement Ma Maison
                                    Sereine" est déjà renseigné dans le contenu
                                    du mail
                                </p>
                            )}
                            <div className="mx-auto text-center">
                                <Button
                                    variant="primary shadow mx-auto"
                                    type="submit"
                                >
                                    <i className="fa-solid fa-paper-plane fs-4 me-2"></i>
                                    Envoyer le message
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    );
}
