/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */
/* eslint-disable no-return-await */
import { toast } from 'react-toastify';
import axios from 'axios';

export const setPhoneNumber = (number) => (dispatch) =>
    dispatch({
        type: 'setPhoneNumber',
        phoneNumber: number,
    });
export const setOpenCarnet = (value) => (dispatch) =>
    dispatch({
        type: 'setOpenCarnet',
        openCarnet: value,
    });

export const getReceivedSmsNotRead = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/sendmsg/v1/received_sms_not_read/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'SetNumberSMSNotRead',
                numberSMSNotRead: res.data.msg_not_read,
            })
        )

        .catch(() => {
            return dispatch({
                type: 'SetNumberSMSNotRead',
                numberSMSNotRead: null,
            });
        });

export const getPortableStatus = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/sendmsg/v1/connect_port/status/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'SetStatusPortable',
                portableStatus: res.data,
            })
        )
        .catch(() => {
            return dispatch({
                type: 'SetStatusPortable',
                portableStatus: null,
            });
        });

export const getPortableSms = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/sendmsg/v1/connect_port/sended/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) => {
            dispatch({
                type: 'SetSMSPortable',
                portableSms: res.data.all_ser1,
            });
        })
        .catch(() => {
            return dispatch({
                type: 'SetSMSPortable',
                portableSms: null,
            });
        });

export const getDataCarnet = (accessToken) => async (dispatch) =>
    axios
        .get('https://api.mamaisonsereine.fr/api/sendmsg/v1/carnet/', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            timeout: 20000,
            'Content-Type': 'application/x-www-form-urlencoded',
            credentials: 'same-origin',
        })
        .then((res) =>
            dispatch({
                type: 'setDataCarnet',
                dataCarnet: res.data,
            })
        )

        .catch(() => {
            toast.error("Impossible d'avoir le carnet d'address", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return dispatch({
                type: 'setDataCarnet',
                dataCarnet: null,
            });
        });

export const getDataCarnetGoogle = (accessToken) => async (dispatch) =>
    axios
        .get('https://api.mamaisonsereine.fr/api/sendmsg/v1/contact/', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            timeout: 20000,
            'Content-Type': 'application/x-www-form-urlencoded',
            credentials: 'same-origin',
        })
        .then((res) =>
            dispatch({
                type: 'setDataCarnetGoogle',
                dataCarnetGoogle: res.data,
            })
        )

        .catch(() => {
            toast.error("Impossible d'avoir le carnet d'addresse Google", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return dispatch({
                type: 'setDataCarnetGoogle',
                dataCarnetGoogle: null,
            });
        });

export const getListingLastReceived = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/sendmsg/v1/get/listing/received/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'listingReceivedMsg',
                listingReceivedMsg: res.data,
            })
        )

        .catch(() => {
            toast.error(
                "Impossible d'obtenir la liste des Messages Ser1 Reçus ",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return dispatch({
                type: 'listingReceivedMsg',
                listingReceivedMsg: null,
            });
        });
export const getListingSendMsg = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/sendmsg/v1/get/listing/send/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'listingSendMsg',
                listingSendMsg: res.data,
            })
        )

        .catch(() => {
            toast.error(
                "Impossible d'obtenir la liste des Messages Ser1 Envoyés ",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
            return dispatch({
                type: 'listingSendMsg',
                listingSendMsg: null,
            });
        });

export const getListingSendMsgReservation =
    (reservationId, accessToken) => async (dispatch) =>
        axios
            .get(
                `https://api.mamaisonsereine.fr/api/sendmsg/${reservationId}/listing/`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    timeout: 20000,
                    'Content-Type': 'application/x-www-form-urlencoded',
                    credentials: 'same-origin',
                }
            )
            .then((res) =>
                dispatch({
                    type: 'listingSendMsgReservation',
                    listingSendMsgReservation: res.data,
                })
            )

            .catch(() => {
                toast.error(
                    "Impossible d'obtenir la liste des Messages Ser1 listingSendMsgReservation ",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
                return dispatch({
                    type: 'listingSendMsgReservation',
                    listingSendMsgReservation: null,
                });
            });
export const getSettingsMsg = (accessToken) => async (dispatch) =>
    axios
        .get('https://api.mamaisonsereine.fr/api/sendmsg/settings/', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            timeout: 20000,
            'Content-Type': 'application/x-www-form-urlencoded',
            credentials: 'same-origin',
        })
        .then((res) =>
            dispatch({
                type: 'settingsMsg',
                settingsMsg: res.data,
            })
        )

        .catch(() => {
            toast.error("Impossible d'obtenir les réglages de Messagerie ", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return dispatch({
                type: 'settingsMsg',
                settingsMsg: null,
            });
        });

export const getPingPort = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/sendmsg/v1/received_sms/statut/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'pingPort',
                pingPort: res.data.ping,
            })
        )

        .catch(() => {
            return dispatch({
                type: 'pingPort',
                pingPort: null,
            });
        });

export const CreateMsg = (accessToken, formData) => {
    return async (dispatch) => {
        const headers = {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
        };

        const config = {
            method: 'post',
            url: 'https://api.mamaisonsereine.fr/api/sendmsg/v1/create/0/',
            headers: headers,
            data: formData,
        };

        return await axios(config)
            .then((res) => {
                toast.success('Envoyé', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                new Audio('https://mamaisonsereine.fr/mp3/notif.mp3').play();
                new Audio('https://mamaisonsereine.fr/mp3/notif.mp3').pause();
                return dispatch({
                    type: 'CREATE_MSG',
                    msg: res.data,
                });
            })

            .catch(() => {
                toast.error('Envoi impossible 😔', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: 'CREATE_MSG',
                    msg: null,
                });
            });
    };
};

export const SetSettingsMsg = (element) => {
    return async (dispatch) => {
        return dispatch({
            type: 'EDIT_SETTINGS_MSG',
            editSettingsMsg: element,
        });
    };
};

export const putSettingsMsg = (element, accessToken, authUser) => {
    return async (dispatch) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            timeout: 20000,
            credentials: 'same-origin',
        };

        if (accessToken !== null && authUser.is_superuser === true) {
            headers = {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            };
        }

        const config = {
            method: 'put',
            url: 'https://api.mamaisonsereine.fr/api/sendmsg/settings/update',
            data: element,
            headers: headers,
        };

        return await axios(config)
            .then(() => {
                toast.success('MAJ Enregistrée...', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return dispatch({
                    type: 'settingsMsg',
                    settingsMsg: element,
                });
            })

            .catch(() => {
                toast.error('Impossible de modifier les détails', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });

                return dispatch({
                    type: 'PUT_ASSO_TO_EDIT',
                    asso: null,
                });
            });
    };
};

export const getTimelineSmsAuto = (accessToken) => async (dispatch) =>
    axios
        .get(
            'https://api.mamaisonsereine.fr/api/sendmsg/v1/detect_futur_send/',
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                timeout: 20000,
                'Content-Type': 'application/x-www-form-urlencoded',
                credentials: 'same-origin',
            }
        )
        .then((res) =>
            dispatch({
                type: 'TimelineSMSAuto',
                TimelineSMSAuto: res.data.listing.sort((a, b) => {
                    return a.date_to_send_timestamp - b.date_to_send_timestamp;
                }),
            })
        )

        .catch(() => {
            return dispatch({
                type: 'TimelineSMSAuto',
                TimelineSMSAuto: null,
            });
        });
