import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Form from 'react-bootstrap/Form';
import logoSupport from '../../assets/img/illustrations/undraw_Active_support_re_b7sj.png';

export default function Contact() {
    document.title = 'Demande de Contact | Ma Maison Sereine';
    document.description = 'Une question ? un Bug rencontré ? Contactez Nous !';
    const [mailSupport, setMailSupport] = useState(undefined);
    const [phoneSupport, setPhoneSupport] = useState(undefined);
    const [msgSupport, setMsgSupport] = useState(undefined);
    const [modeSupport, setModeSupport] = useState(undefined);
    const [sendMsg, setSendMsg] = useState(undefined);
    const [dataSupport] = useState({
        email: mailSupport,
        phone: phoneSupport,
        msg: msgSupport,
        mode: modeSupport,
    });
    const [confirmForm, setConfirmForm] = useState(false);

    const translate = useSelector((store) => store.Template.language);
    useEffect(() => {
        dataSupport.email = mailSupport;
        dataSupport.phone = phoneSupport;
        dataSupport.msg = msgSupport;
        dataSupport.mode = modeSupport;
    }, [dataSupport, modeSupport, msgSupport, phoneSupport, mailSupport]);

    const handleSubmitSupport = (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.keys(dataSupport).forEach((key) =>
            formData.append(key, dataSupport[key])
        );
        async function PostSupport(formData) {
            try {
                setSendMsg(false);
                const response = await fetch(
                    'https://api.mamaisonsereine.fr/api/settings/support/',
                    {
                        method: 'post',
                        headers: new Headers({
                            Accept: 'application/json',
                        }),
                        body: formData,
                    }
                );
                const data = await response;
                if (response.status === 200) {
                    setSendMsg(true);
                } else if (response.status === 500) {
                    setSendMsg(undefined);
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else if (response.status === 503) {
                    setSendMsg(undefined);
                    toast.error(data.msg, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
            } catch {
                setSendMsg(undefined);
                toast.error(
                    "Oups! Impossible d'envoyer la demande de contact 😔",
                    {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    }
                );
            }
        }
        PostSupport(formData);
    };

    return (
        <main className="bg-white mb-5 pb-5">
            <div className=" mx-auto col-8 col-md-6 text-center pt-5">
                <div className="mx-auto">
                    <img
                        src={logoSupport}
                        alt="Hotline"
                        style={{ width: '200px' }}
                        className="img-fluid"
                    />
                </div>

                <h1 className="text-center text-primary display-6">
                    {translate === 'French' && <>Demande de Contact</>}
                    {translate === 'English' && <>Request contact</>}
                </h1>

                <hr className="text-purple col-6 mx-auto" />
                <div className="mb-2">
                    {translate === 'French' && (
                        <>Une question ? Une demande de renseignements ? </>
                    )}
                    {translate === 'English' && (
                        <>A question ? A request for informations ?</>
                    )}
                </div>
                <hr className="text-purple col-6 mx-auto" />
            </div>
            {sendMsg === undefined ? (
                <>
                    <div className="w-75 mx-auto text-center my-3">
                        {translate === 'French' && (
                            <>
                                Vous pouvez nous contactez par Mail{' '}
                                <a href="mailto:contact@mamaisonsereine.fr">
                                    contact@mamaisonsereine.fr
                                </a>{' '}
                                <br />
                                ou via le formulaire ci-dessous. Nous vous
                                recontacterons dans les plus brefs délais.
                            </>
                        )}
                        {translate === 'English' && (
                            <>
                                You can contact us by Mail{' '}
                                <a href="mailto:contact@mamaisonsereine.fr">
                                    contact@mamaisonsereine.fr
                                </a>{' '}
                                <br />
                                or via the form below. We will contact you in as
                                soon as possible.
                            </>
                        )}
                    </div>

                    <Form
                        className="col-10 col-md-6 mx-auto mb-5 border border-2 border-primary rounded p-2 text-center"
                        onSubmit={(e) => handleSubmitSupport(e)}
                    >
                        <Form.Group>
                            <Form.Label>
                                {translate === 'French' && (
                                    <>Je souhaite être recontacté par :</>
                                )}
                                {translate === 'English' && (
                                    <>I would like to be contacted by:</>
                                )}
                            </Form.Label>
                            <div key={`inline-${'radio'}`} className="mb-3">
                                {translate === 'French' && (
                                    <>
                                        <Form.Check
                                            inline
                                            label="Email"
                                            name="response"
                                            type={'radio'}
                                            id={'ResponseMail'}
                                            onClick={(e) =>
                                                setModeSupport('mail')
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            label="Téléphone"
                                            name="response"
                                            type={'radio'}
                                            id={'ResponsePhone'}
                                            onClick={(e) =>
                                                setModeSupport('phone')
                                            }
                                        />
                                    </>
                                )}
                                {translate === 'English' && (
                                    <>
                                        <Form.Check
                                            inline
                                            label="Mail"
                                            name="response"
                                            type={'radio'}
                                            id={'ResponseMail'}
                                            onClick={(e) =>
                                                setModeSupport('mail')
                                            }
                                        />
                                        <Form.Check
                                            inline
                                            label="Phone"
                                            name="response"
                                            type={'radio'}
                                            id={'ResponsePhone'}
                                            onClick={(e) =>
                                                setModeSupport('phone')
                                            }
                                        />
                                    </>
                                )}
                            </div>
                        </Form.Group>
                        <hr />
                        <div className="d-flex flex-column mb-3 justify-content-around">
                            <label className="me-2">
                                {translate === 'French' && (
                                    <> Votre N° de Portable</>
                                )}
                                {translate === 'English' && (
                                    <> Your Mobile Number</>
                                )}
                            </label>
                            <input
                                placeholder="06********"
                                aria-label="portable"
                                id="portable"
                                maxLength={10}
                                pattern="[0-9]{10}"
                                type="tel"
                                name="phone"
                                onChange={(e) =>
                                    setPhoneSupport(e.target.value)
                                }
                            />
                        </div>
                        <div className="d-flex flex-column mb-3" id="mail">
                            <label className="mt-2">
                                {translate === 'French' && <> Votre Mail</>}
                                {translate === 'English' && <> Your Mail</>}
                            </label>

                            <input
                                type="email"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                placeholder="adresse@domaine.fr"
                                id="mail"
                                name="mail"
                                onChange={(e) => setMailSupport(e.target.value)}
                            />
                        </div>

                        <div className="d-flex flex-column mb-4">
                            <label>
                                {translate === 'French' && (
                                    <>
                                        Votre Message - oubliez pas d'indiquer
                                        votre Nom et Prénom
                                    </>
                                )}
                                {translate === 'English' && (
                                    <>
                                        {' '}
                                        Your Message - don't forget to indicate
                                        your Name and first name
                                    </>
                                )}
                            </label>
                            <textarea
                                aria-label="Votre Message"
                                rows={5}
                                onChange={(e) => setMsgSupport(e.target.value)}
                                maxLength={3000}
                            />
                        </div>
                        <div className="d-flex flex-row mb-4">
                            <input
                                id="confirmForm"
                                type="checkbox"
                                className=""
                                onClick={() => {
                                    setConfirmForm(!confirmForm);
                                }}
                            />
                            <label
                                htmlFor="confirmForm"
                                className="text-start ms-3"
                            >
                                Je donne mon accord pour que mes données soient
                                utilisées dans le cadre de ma demande. (simple
                                envoi de mail, pas de stockage de données.)
                            </label>
                        </div>

                        <div className="text-center mb-4">
                            {confirmForm === false ? (
                                <div className="btn btn-secondary text-center denied">
                                    {translate === 'French' && (
                                        <>Envoyer ma demande</>
                                    )}
                                    {translate === 'English' && (
                                        <> Send my request</>
                                    )}
                                </div>
                            ) : (
                                <button
                                    className="btn btn-primary text-white text-center"
                                    type="submit"
                                >
                                    {translate === 'French' && (
                                        <>Envoyer ma demande</>
                                    )}
                                    {translate === 'English' && (
                                        <> Send my request</>
                                    )}
                                </button>
                            )}
                        </div>
                    </Form>
                </>
            ) : (
                <>
                    {sendMsg === false && (
                        <div className="text-center">
                            Merci de patienter
                            <div className="pt-4 mt-4">
                                <span className="loader_msg"></span>
                            </div>
                        </div>
                    )}{' '}
                    {sendMsg === true && (
                        <div className="text-center">
                            Votre demande a bien été envoyé. merci
                        </div>
                    )}
                </>
            )}
        </main>
    );
}
