const initialState = {
    darkMode: false,
    language: 'French',
};

// eslint-disable-next-line default-param-last
export default function Template(state = initialState, action) {
    switch (action.type) {
        case 'TEMPLATE_DETECT_MODE':
            return {
                ...state,
                darkMode: action.darkMode,
            };
        case 'LANGUAGE_FR':
            return {
                ...state,
                language: action.language,
            };
        case 'LANGUAGE_EN':
            return {
                ...state,
                language: action.language,
            };
        default:
            return state;
    }
}
